import React, { FC, MouseEvent, TouchEvent, useEffect, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import { Button } from '@components/Button';
import { DateField } from '@components/Form/DateField';
import { InputField } from '@components/Form/InputField';
import { PasswordField } from '@components/Form/PasswordField';
import { PhoneField } from '@components/Form/PhoneField';
import { SelectField } from '@components/Form/SelectField';
import { Link } from '@components/Link';
import { useNavigate } from 'react-router-dom';
import { IModalRef } from '@components/Modal/types';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { useCreateUserMutation, usePhoneVerifyingMutation } from '@api/user/userAPI';
import { GenderOptions } from '@constants/options/genderOptions';
import { onSignUp } from '@helpers/onSignUp';
import sighUpValidationSchemaWithParent from '@helpers/validationSchemas/sighUpValidationSchemaWithParent';
import { Verification } from '@pages/SignUp/components/Verification';
import { errorAlert } from '@helpers/alerts/alerts';
import { ISignUpProps } from '@pages/SignUp/components/Verification/types';
import { IUserRegistration } from '@store/types';
import { Routes } from '../../../../router';
import 'react-datepicker/dist/react-datepicker.css';

export const SignUpForm:FC<ISignUpProps> = ({ setUserRegisterEmail, email, token }) => {
  const { dictionary } = useLanguageContext();

  const navigate = useNavigate();

  const verificationModalRef = useRef<IModalRef>(null);

  const [adult, setAdult] = useState<boolean>(false);

  const [isVerifyPhone, setIsVerifyPhone] = useState<boolean>(false);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the threshold as needed
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [isVerifyParentPhone, setIsVerifyParentPhone] = useState<boolean>(true);

  const [usersPhone, setUsersPhone] = useState<string>('');

  const [registerUser] = useCreateUserMutation();

  const [verifyPhone] = usePhoneVerifyingMutation();

  const initialRegisterValues: IUserRegistration = {
    name: '',
    surname: '',
    dateOfBirth: '',
    gender: '',
    email: email || '',
    phone: '',
    password: '',
    repeatedPassword: '',
    parentName: '',
    parentEmail: '',
    parentSurname: '',
    parentPhone: '',
  };

  const onPhoneVerificationClick = (e: MouseEvent<HTMLElement> | TouchEvent<HTMLElement>, phoneNumber?: string, parentPhone?: boolean) => {
    if (phoneNumber) {
      setUsersPhone(phoneNumber);
      verificationModalRef.current?.openModal();
      verifyPhone({
        phone: phoneNumber,
      }).unwrap().then(async ({ success, message }) => {
        if (success === 0) {
          errorAlert(message);
        }
      })
        .catch(({ message }) => errorAlert(message));
    }
  };

  return (
    <>
      <Formik
        initialValues={initialRegisterValues}
        validationSchema={isMobile ? null : sighUpValidationSchemaWithParent(dictionary)}
        onSubmit={(values) => onSignUp(
          dictionary,
          values,
          email,
          token,
          registerUser,
          isVerifyPhone,
          isVerifyParentPhone,
          setUserRegisterEmail,
          navigate,
        )}
        validateOnBlur
        validateOnChange
      >
        <Form className="form" noValidate>
          <div className="form-body">
            <section className="form-section">
              <h2 className="form-title title-md">{dictionary.titles.patientInformation}</h2>
              <div className="row">
                <div className="col-xs-12 col-md-6">
                  <InputField
                    placeholder={dictionary.placeholders.yourFirstName}
                    label={dictionary.labels.firstName}
                    required
                    name="name"
                  />
                </div>
                <div className="col-xs-12 col-md-6">
                  <InputField
                    placeholder={dictionary.placeholders.yourLastName}
                    label={dictionary.labels.lastName}
                    required
                    name="surname"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-md-6">
                  <DateField
                    maxDate={false}
                    onChangeAge={setAdult}
                    type="text"
                    placeholder="MM/DD/YYYY"
                    label={dictionary.labels.dateOfBirth}
                    required
                    name="dateOfBirth"
                  />
                </div>
                <div className="col-xs-12 col-md-6">
                  <SelectField
                    selectOptions={GenderOptions()}
                    placeholder={dictionary.placeholders.chooseGenderByBirth}
                    label={dictionary.labels.genderByBirth}
                    required
                    name="gender"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <InputField
                    type="email"
                    disabled={!!email?.length}
                    placeholder={dictionary.placeholders.enterYourEmail}
                    label={dictionary.labels.email}
                    required
                    name="email"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <PhoneField
                    type="text"
                    placeholder={dictionary.placeholders.phoneMask}
                    label={dictionary.labels.phoneNumber}
                    required
                    isVerified={isVerifyPhone}
                    name="phone"
                    showVerification
                    handleVerificationClick={onPhoneVerificationClick}
                  />
                </div>
              </div>
            </section>
            {adult && (
              <section className="form-section">
                <h2 className="form-title title-md">{dictionary.titles.parentInformation}</h2>
                <div className="row">
                  <div className="col-xs-12 col-md-6">
                    <InputField
                      placeholder={dictionary.placeholders.firstName}
                      label={dictionary.labels.parentFirstName}
                      required
                      name="parentName"
                    />
                  </div>
                  <div className="col-xs-12 col-md-6">
                    <InputField
                      placeholder={dictionary.placeholders.lastName}
                      label={dictionary.labels.parentLastName}
                      required
                      name="parentSurname"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <InputField
                      type="email"
                      placeholder={dictionary.placeholders.enterYourEmail}
                      label={dictionary.labels.email}
                      required
                      name="parentEmail"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <PhoneField
                      type="text"
                      placeholder={dictionary.placeholders.phoneMask}
                      label={dictionary.labels.phoneNumber}
                      required
                      name="parentPhone"
                    />
                  </div>
                </div>
              </section>
            )}
            <section className="form-section">
              <h2 className="form-title title-md">{dictionary.titles.security}</h2>
              <div className="row">
                <div className="col-xs-12">
                  <PasswordField
                    placeholder={dictionary.placeholders.createPassword}
                    label={dictionary.labels.createPassword}
                    required
                    name="password"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <PasswordField
                    placeholder={dictionary.placeholders.confirmPassword}
                    label={dictionary.labels.confirmPassword}
                    required
                    name="repeatedPassword"
                  />
                </div>
              </div>
            </section>
          </div>
          <div className="form-footer">
            <section className="form-section">
              <Button
                label={dictionary.signUp.signUp}
                type="submit"
                size="large"
              />
            </section>
            <section className="form-section text-center">
              <p className="text">
                {dictionary.signIn.bySigningInYouAcceptOur}
                &nbsp;
                <Link
                  to={Routes.PrivacyPolicy}
                  label={dictionary.links.privacyPolicy}
                  variant="outlined"
                  target="_blank"
                  inline
                />
              </p>
              <p className="text-md text-semibold">
                {dictionary.signUp.alreadyHaveAccount}
                &nbsp;
                <Link
                  to={Routes.SignIn}
                  label={dictionary.signUp.logIn}
                  variant="primary"
                  inline
                />
              </p>
            </section>
          </div>
        </Form>
      </Formik>
      <Verification
        phone={usersPhone}
        verifyParentPhone={setIsVerifyParentPhone}
        verifyPhone={setIsVerifyPhone}
        ref={verificationModalRef}
      />
    </>
  );
};
