import React from 'react';
import { Link } from 'react-router-dom';
import { redirectPaths } from '@constants/redirectPaths';
import { getUserRole } from '@helpers/getUserRole';
import { ILoggedInUser } from '@api/user/types';
import { loadState } from '@store/browserStorage';
import { IUserData } from '@store/types';
import { persistAuth } from '@constants/persist';
import logo from '../../../AuthLayout/img/logo-laguna.png';
import './styles.scss';
import { Routes } from '../../../../router';

export const Logo = () => {
  const authData = loadState<IUserData>(persistAuth)?.auth;

  const { user, token }: ILoggedInUser = authData ? JSON.parse(authData as unknown as string) : {
    user: null,
    token: '',
  };

  const userRole = getUserRole(user, token);

  return (
    <Link to={redirectPaths[userRole] || Routes.SignIn} className="logo">
      <img className="logo-img" src={logo} alt="Laguna" />
    </Link>
  );
};
