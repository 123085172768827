import React, { FC } from 'react';
import { InputField } from '@components/Form/InputField';
import { PhoneField } from '@components/Form/PhoneField';
import { IDictionary } from '@constants/languages/types';

interface IPhysicianFormProps {
  dictionary: IDictionary
}
export const PhysicianForm: FC<IPhysicianFormProps> = ({ dictionary }) => (
  <>
    <div className="row">
      <div className="col-xs-12">
        <InputField
          type="text"
          placeholder={dictionary.placeholders.enterPhysicianName}
          label={dictionary.labels.physicianName}
          required
          name="name"
        />
      </div>
    </div>
    <div className="row">
      <div className="col-xs-12">
        <PhoneField
          type="number"
          placeholder={dictionary.placeholders.phoneMask}
          label={dictionary.labels.physicianPhone}
          required
          name="phone"
        />
      </div>
    </div>
  </>
);
