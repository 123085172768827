import React from 'react';
import { LanguageMenu } from '@components/LanguageMenu';
import { ProfileMenu } from '@components/ProfileMenu';
import { getUserRole } from '@helpers/getUserRole';
import { Roles } from '@constants/roles';
import { ILoggedInUser } from '@api/user/types';
import { loadState } from '@store/browserStorage';
import { IUserData } from '@store/types';
import { persistAuth } from '@constants/persist';
import { Burger } from '../Burger';
import { Notifications } from '../Notifications';

export const HeaderControls = () => {
  const authData = loadState<IUserData>(persistAuth)?.auth;

  const { user, token }: ILoggedInUser = authData ? JSON.parse(authData as unknown as string) : {
    user: null,
    token: '',
  };

  const userRole = getUserRole(user, token);

  return (
    <ul className="header-controls">
      <li className="header-control">
        {(userRole === Roles.staff || userRole === Roles.admin || userRole === Roles.superAdmin) && <Notifications />}
      </li>
      <li className="header-control">
        <LanguageMenu />
      </li>
      <li className="header-control">
        <ProfileMenu />
      </li>
      <li className="header-control">
        <Burger />
      </li>
    </ul>
  );
};
