export const initialParentInfoValues = {
  name: '',
  surname: '',
  phone: '',
  email: '',
  updatedAt: '',
  createdAt: '',
  id: '',
  userId: '',
};
