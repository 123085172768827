import * as React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { Roles } from '@constants/roles';
import { ActivateAccount } from '@pages/ActivateAccount';
import { CreateAppointment } from '@pages/Appointment/pages/CreateAppointment';
import { Appointments } from '@pages/Appointments';
import { ForgotPassword } from '@pages/ForgotPassword';
import { Locations } from '@pages/Locations';
import { Patient } from '@pages/Patient';
import { Patients } from '@pages/Patients';
import { Physician } from '@pages/Physician';
import { AddPhysician } from '@pages/Physician/pages/AddPhysician';
import { Physicians } from '@pages/Physicians';
import { Profile } from '@pages/Profile';
import { ResetPassword } from '@pages/ResetPassword';
import { Schedule } from '@pages/Schedule';
import { Day } from '@pages/Schedule/components/Day';
import { Month } from '@pages/Schedule/components/Month';
import { Week } from '@pages/Schedule/components/Week';
import { SignIn } from '@pages/SignIn';
import { SignUp } from '@pages/SignUp';
import { Trial } from '@pages/Trial';
import { CreateTrial } from '@pages/Trial/pages/CreateTrial';
import { Trials } from '@pages/Trials';
import { Appointment } from '@pages/Appointment';
import { EditAppointments } from '@pages/Appointment/pages/EditAppointments';
import { SchedulePatient } from '@pages/SchedulePatient';
import { PatientAppointments } from '@pages/PatientAppointments';
import { EditTrial } from '@pages/Trial/pages/EditTrial';
import { Organizations } from '@pages/Organizations';
import { Organization } from '@pages/Organization';
import { OrganizationMember } from '@pages/OrganizationMember';
import { EmployeeSignUp } from '@pages/EmployeeSignUp';
import { PrivacyPolicy } from '@pages/PrivacyPolicy';
import { AppLayout } from '../layout/AppLayout';
import { AuthLayout } from '../layout/AuthLayout';
import { Routes } from './constants/routes';
import { ProtectedRoute } from './ProtectedRoute';
import { PrivacyLayout } from '../layout/AppLayout/privacyLayout';

export const Router = () => useRoutes([
  {
    element: <AuthLayout />,
    children: [
      {
        element: <ProtectedRoute roles={[Roles.guest, Roles.staff, Roles.superAdmin, Roles.patient, Roles.admin]} />,
        children: [
          {
            path: Routes.SignUp,
            element: <SignUp />,
            children: [
              {
                path: Routes.SignUpByInvite,
              },
            ],
          },
          {
            path: Routes.EmployeeSignUp,
            element: <EmployeeSignUp />,
            children: [
              {
                path: Routes.SignUpByInvite,
              },
            ],
          },
          {
            path: Routes.SignIn,
            element: <SignIn />,
          },
          {
            path: Routes.ForgotPassword,
            element: <ForgotPassword />,
          },
          {
            path: Routes.ActivateAccount,
            element: <ActivateAccount />,
          },
          {
            path: Routes.ResetPassword,
            element: <ResetPassword />,
          },
        ],
      },
    ],
  },
  {
    element: <PrivacyLayout />,
    children: [
      {
        element: <ProtectedRoute roles={[Roles.guest]} />,
        children: [
          {
            path: Routes.PrivacyPolicy,
            element: <PrivacyPolicy />,
          },
        ],
      },
    ],
  },
  {
    element: <AppLayout />,
    children: [
      {
        element: <ProtectedRoute roles={[Roles.patient]} />,
        children: [
          {
            path: Routes.SchedulePatient,
            element: <SchedulePatient />,
          },
          {
            path: Routes.RequestedAppointments,
            element: <PatientAppointments />,
          },
          {
            path: Routes.Profile,
            element: <Profile />,
            children: [
              {
                path: Routes.ProfileWithId,
              },
            ],
          },
        ],
      },
      {
        element: <ProtectedRoute roles={[Roles.admin, Roles.superAdmin, Roles.staff]} />,
        children: [
          {
            path: Routes.Member,
            element: <OrganizationMember />,
            children: [
              {
                path: `${Routes.Patient}`,
              },
            ],
          },
          {
            path: Routes.MyProfile,
            element: <Profile />,
          },
          {
            path: Routes.Profile,
            element: <Profile />,
            children: [
              {
                path: Routes.ProfileWithId,
              },
            ],
          },
          {
            path: Routes.Organization,
            element: <Organization />,
            children: [
              {
                path: Routes.OrganisationWithId,
              },
            ],
          },
          {
            path: Routes.Schedule,
            element: <Schedule />,
            children: [
              {
                index: true,
                element: <Navigate to={Routes.Day} replace />,
              },
              {
                path: Routes.Day,
                element: <Day />,
              },
              {
                path: Routes.Week,
                element: <Week />,
              },
              {
                path: Routes.Month,
                element: <Month />,
              },
              {
                path: '*',
                element: <Navigate to={Routes.Day} replace />,
              },
            ],
          },
          {
            path: Routes.Appointments,
            element: <Appointments />,
          },
          {
            path: Routes.Appointments,
            element: <Appointment />,
            children: [
              {
                path: Routes.AppointmentWithId,
              },
            ],
          },
          {
            path: `${Routes.Appointments}${Routes.Edit}`,
            element: <EditAppointments />,
            children: [
              {
                path: `${Routes.CurrentPatientId}/${Routes.trialId}`,
              },
            ],
          },
          {
            path: `${Routes.Appointments}${Routes.Add}`,
            element: <CreateAppointment />,
          },
          {
            path: Routes.Patients,
            children: [
              {
                path: Routes.Patients,
                element: <Patients />,
              },
              {
                path: Routes.Patient,
                element: <Patient />,
                children: [
                  {
                    path: Routes.PatientId,
                  },
                ],
              },
            ],
          },
          {
            path: Routes.Physicians,
            children: [
              {
                path: Routes.Physicians,
                element: <Physicians />,
              },
              {
                path: Routes.PhysicianId,
                element: <Physician />,
              },
            ],
          },
          {
            path: `${Routes.Physician}${Routes.Add}`,
            element: <AddPhysician />,
          },
          {
            path: Routes.Locations,
            element: <Locations />,
          },
          {
            path: Routes.Trials,
            element: <Trials />,
          },
          {
            path: `${Routes.Trials}${Routes.Add}`,
            element: <CreateTrial />,
          },
          {
            path: `${Routes.Trials}${Routes.Edit}`,
            element: <EditTrial />,
            children: [
              {
                path: Routes.TrialId,
              },
            ],
          },
          {
            path: `${Routes.Trial}`,
            element: <Trial />,
            children: [
              {
                path: Routes.TrialId,
              },
            ],
          },
        ],
      },
      {
        element: <ProtectedRoute roles={[Roles.superAdmin]} />,
        children: [
          {
            path: Routes.Member,
            element: <OrganizationMember />,
            children: [
              {
                path: `${Routes.Patient}`,
              },
            ],
          },
          {
            path: Routes.MyProfile,
            element: <Profile />,
          },
          {
            path: Routes.Profile,
            element: <Profile />,
            children: [
              {
                path: Routes.ProfileWithId,
              },
            ],
          },
          {
            path: Routes.Organizations,
            element: <Organizations />,
          },
          {
            path: Routes.Organization,
            element: <Organization />,
            children: [
              {
                path: Routes.OrganisationWithId,
              },
            ],
          },
          {
            path: Routes.Schedule,
            element: <Schedule />,
            children: [
              {
                path: Routes.Day,
                element: <Day />,
              },
              {
                path: Routes.Week,
                element: <Week />,
              },
              {
                path: Routes.Month,
                element: <Month />,
              },
              {
                path: '*',
                element: <Navigate to={Routes.Day} replace />,
              },
            ],
          },
          {
            path: Routes.Appointments,
            element: <Appointments />,
          },
          {
            path: Routes.Appointments,
            element: <Appointment />,
            children: [
              {
                path: Routes.AppointmentWithId,
              },
            ],
          },
          {
            path: `${Routes.Appointments}${Routes.Edit}`,
            element: <EditAppointments />,
            children: [
              {
                path: `${Routes.CurrentPatientId}/${Routes.trialId}`,
              },
            ],
          },
          {
            path: `${Routes.Appointments}${Routes.Add}`,
            element: <CreateAppointment />,
          },
          {
            path: Routes.Patients,
            children: [
              {
                path: Routes.Patients,
                element: <Patients />,
              },
              {
                path: Routes.Patient,
                element: <Patient />,
                children: [
                  {
                    path: Routes.PatientId,
                  },
                ],
              },
            ],
          },
          {
            path: Routes.Physicians,
            children: [
              {
                path: Routes.Physicians,
                element: <Physicians />,
              },
              {
                path: Routes.PhysicianId,
                element: <Physician />,
              },
            ],
          },
          {
            path: `${Routes.Physician}${Routes.Add}`,
            element: <AddPhysician />,
          },
          {
            path: Routes.Locations,
            element: <Locations />,
          },
          {
            path: Routes.Trials,
            element: <Trials />,
          },
          {
            path: `${Routes.Trials}${Routes.Add}`,
            element: <CreateTrial />,
          },
          {
            path: `${Routes.Trials}${Routes.Edit}`,
            element: <EditTrial />,
            children: [
              {
                path: Routes.TrialId,
              },
            ],
          },
          {
            path: `${Routes.Trial}`,
            element: <Trial />,
            children: [
              {
                path: Routes.TrialId,
              },
            ],
          },
        ],
      },
      {
        element: <ProtectedRoute roles={[Roles.staff]} />,
        children: [
          {
            path: Routes.MyProfile,
            element: <Profile />,
          },
          {
            path: Routes.Schedule,
            element: <Schedule />,
            children: [
              {
                index: true,
                element: <Navigate to={Routes.Day} replace />,
              },
              {
                path: Routes.Day,
                element: <Day />,
              },
              {
                path: Routes.Week,
                element: <Week />,
              },
              {
                path: Routes.Month,
                element: <Month />,
              },
              {
                path: '*',
                element: <Navigate to={Routes.Day} replace />,
              },
            ],
          },
          {
            path: Routes.Appointments,
            element: <Appointments />,
          },
          {
            path: Routes.Appointments,
            element: <Appointment />,
            children: [
              {
                path: Routes.AppointmentWithId,
              },
            ],
          },
          {
            path: `${Routes.Appointments}${Routes.Edit}`,
            element: <EditAppointments />,
            children: [
              {
                path: `${Routes.CurrentPatientId}/${Routes.trialId}`,
              },
            ],
          },
          {
            path: `${Routes.Appointments}${Routes.Add}`,
            element: <CreateAppointment />,
          },
          {
            path: Routes.Patients,
            children: [
              {
                path: Routes.Patients,
                element: <Patients />,
              },
              {
                path: Routes.Patient,
                element: <Patient />,
                children: [
                  {
                    path: Routes.PatientId,
                  },
                ],
              },
            ],
          },
          {
            path: Routes.Physicians,
            children: [
              {
                path: Routes.Physicians,
                element: <Physicians />,
              },
              {
                path: Routes.PhysicianId,
                element: <Physician />,
              },
            ],
          },
          {
            path: `${Routes.Physician}${Routes.Add}`,
            element: <AddPhysician />,
          },
          {
            path: Routes.Locations,
            element: <Locations />,
          },
          {
            path: Routes.Trials,
            element: <Trials />,
          },
          {
            path: `${Routes.Trials}${Routes.Add}`,
            element: <CreateTrial />,
          },
          {
            path: `${Routes.Trials}${Routes.Edit}`,
            element: <EditTrial />,
            children: [
              {
                path: Routes.TrialId,
              },
            ],
          },
          {
            path: `${Routes.Trial}`,
            element: <Trial />,
            children: [
              {
                path: Routes.TrialId,
              },
            ],
          },
        ],
      },
      {
        path: '*',
        element: <Navigate replace to={Routes.Schedule} />,
      },
    ],
  },
]);
