import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ILinkProps, LinkVariants } from '@components/Link/types';
import './styles.scss';

export const Link:FC<ILinkProps> = (props) => {
  const { label,
    variant = LinkVariants.text,
    inline = false,
    iconPrepend = null,
    iconAppend = null,
    handleClick } = props;

  const linkClassName = `link ${variant && `link-${variant}`} ${inline ? 'link-inline' : ''}`;

  return (
    <RouterLink
      onClick={handleClick}
      className={linkClassName}
      relative="route"
      {...props}
    >
      {iconPrepend && (
        <span className="link-prepend-icon" role="presentation">
          {iconPrepend}
        </span>
      )}
      {label && (
        <span className="link-label">
          {label}
        </span>
      )}
      {iconAppend && (
        <span className="link-append-icon" role="presentation">
          {iconAppend}
        </span>
      )}
    </RouterLink>
  );
};
