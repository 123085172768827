import * as Yup from 'yup';
import { IDictionary } from '@constants/languages/types';

function membersValidationSchema(dictionary: IDictionary) {
  return Yup.object({
    email: Yup.string()
      .required(dictionary.validation.memberEmail)
      .email(dictionary.validation.invalidEmail)
      .min(1, dictionary.validation.emptyEmail),
    role: Yup.string()
      .required(dictionary.validation.invalidRole),
  });
}

export default membersValidationSchema;
