import { Dispatch, ReactNode } from 'react';

export enum AutocompleteVariants {
  off = 'off',
  on = 'on',
}

export interface IInputProps {
  id?: string;
  name: string;
  type?: string;
  excludeDates?: Date[]
  unavailableDays?: string[];
  visitRange?: number;
  onChangeAge?: Dispatch<boolean> | boolean;
  placeholder?: string;
  maxDate?: boolean;
  autocomplete?: `${AutocompleteVariants}`;
  disabled?: boolean;
  value?: string,
  selectedDate?: Date,
  protocolWindow?: number,
  calendarStartDay?: number,
  dateValue?: Date,
  onEffect?: () => void,
  isWeekend?: (date: Date) => boolean
  hasError?: boolean;
  showRequired?: boolean;
  required?: boolean;
  iconPrepend?: ReactNode;
  iconAppend?: ReactNode;
  clearValue?: () => void;
}
