import { IEditPhysician, ISearchPhysicians } from '@api/physicians/types';
import { IResponse } from '@api/user/types';
import { RequestRoutes } from '@constants/requestRoutes';
import { apiHeader, headerJson } from '@helpers/commonHeaders';
import { convertToCamelCase } from '@helpers/convertToCamelCase';
import {
  IOnePhysiciansResponse,
  IPhysiciansResponse,
  TPhysician,
} from '@pages/Physicians/components/PhysiciansTable/types';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { physiciansTag } from '@constants/apiTags';
import { language } from '@constants/language';

const lang = localStorage.getItem(language)?.slice(1, 3) || 'en';

export const physiciansAPI = createApi({
  reducerPath: 'physiciansAPI',
  tagTypes: [physiciansTag],
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_API_URL}`,
    prepareHeaders: apiHeader,
  }),
  endpoints: (build) => ({
    fetchPhysicianById: build.query<IOnePhysiciansResponse, string>({
      query: (id) => ({
        url: `${RequestRoutes.Physician}${RequestRoutes.GetOne}`,
        params: {
          id,
          lang,
        },
      }),
      transformResponse(response: IOnePhysiciansResponse) {
        return convertToCamelCase(response);
      },
    }),
    fetchAllPhysicians: build.query<IPhysiciansResponse, void>({
      query: () => ({
        url: `${RequestRoutes.Physician}${RequestRoutes.All}`,
        keepUnusedDataFor: 5,
        params: {
          lang,
        },
      }),
      providesTags: [physiciansTag],
    }),
    fetchAllPhysiciansWithPagination: build.query<IPhysiciansResponse, { page: number, limit: number }>({
      query: ({ page,
        limit }) => ({
        url: `${RequestRoutes.Physician}${RequestRoutes.All}`,
        params: {
          page,
          limit,
          lang,
        },
        headers: headerJson,
        keepUnusedDataFor: 5,
      }),
      providesTags: [physiciansTag],
    }),
    searchPhysicians: build.query<ISearchPhysicians, string>({
      query: (title) => ({
        url: `${RequestRoutes.Physician}${RequestRoutes.Search}`,
        params: {
          searchString: title,
          lang,
        },
        method: 'POST',
        keepUnusedDataFor: 5,
      }),
      providesTags: [physiciansTag],
    }),
    addNewPhysicians: build.mutation<IPhysiciansResponse, TPhysician>({
      query: (physician: TPhysician) => ({
        url: `${RequestRoutes.Physician}${RequestRoutes.AddNew}`,
        headers: headerJson,
        method: 'POST',
        body: {
          ...physician,
          phone: physician.phone.replace(/[-()\s]/g, ''),
        },
        params: {
          lang,
        },
      }),
      invalidatesTags: [physiciansTag],
    }),
    editPhysicians: build.mutation<IPhysiciansResponse, { physician: IEditPhysician, id: string }>({
      query: ({ physician, id }) => ({
        url: `${RequestRoutes.Physician}${RequestRoutes.EditInfo}`,
        headers: headerJson,
        params: {
          id,
          lang,
        },
        method: 'PATCH',
        body: physician.phone ? {
          ...physician,
          phone: physician.phone.replace(/[-()\s]/g, ''),
        } : physician,
      }),
      invalidatesTags: [physiciansTag],
    }),
    deletePhysicians: build.mutation<IResponse, string>({
      query: (physicianId: string) => ({
        url: `${RequestRoutes.Physician}${RequestRoutes.Delete}`,
        method: 'DELETE',
        params: {
          id: physicianId,
          lang,
        },
        keepUnusedDataFor: 5,
      }),
      invalidatesTags: () => [physiciansTag],
    }),
  }),
});

export const { useSearchPhysiciansQuery,
  useFetchAllPhysiciansQuery,
  useFetchAllPhysiciansWithPaginationQuery,
  useDeletePhysiciansMutation,
  useEditPhysiciansMutation,
  useFetchPhysicianByIdQuery,
  useAddNewPhysiciansMutation } = physiciansAPI;
