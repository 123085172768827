import * as Yup from 'yup';
import { IDictionary } from '@constants/languages/types';

const signInValidationSchema = (dictionary: IDictionary) => Yup.object({
  email: Yup.string()
    .email(dictionary.validation.invalidEmail)
    .required(dictionary.validation.emptyEmail),
  password: Yup.string()
    .required(dictionary.validation.password),
});

export default signInValidationSchema;
