import React, { FC } from 'react';
import { IFieldProps } from './types';
import './styles.scss';

export const Field:FC<IFieldProps> = ({ id = '',
  showLabel = true,
  label = '',
  showError = true,
  error = '',
  showHint = true,
  hint = '',
  showRequired = false,
  isRequired = true,
  children,
  status,
  fieldControls }) => {
  const isLabelVisible = showLabel && label;

  const isErrorVisible = showError && error;

  const isHintVisible = showHint && hint && !isErrorVisible;

  return (
    <div className="field">
      {isLabelVisible && (
        <label className="field-label" htmlFor={id}>
          {label}
          {status}
          {showRequired && isRequired && <span className="field-required">*</span>}
          {fieldControls && <span className="field-controls">{fieldControls}</span>}
        </label>
      )}
      {children}
      {isHintVisible && (
        <p className="field-hint">{hint}</p>
      )}
      {isErrorVisible && (
        <p className="field-error">{error}</p>
      )}
    </div>
  );
};
