import React, { FC } from 'react';
import { useField } from 'formik';
import PhoneInput from 'react-phone-input-2';
import { Button } from '@components/Button';
import { Status } from '@components/Status';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { TPhoneProps } from '@components/Phone/types';
import './styles.scss';

export const Phone: FC<TPhoneProps> = (props) => {
  const { isDisabled = false,
    isRequired = false,
    value = '',
    handleBlur,
    handleChange,
    placeholder = 'Some text...',
    hasError = false,
    showVerification = false,
    iconAppend,
    iconPrepend,
    connectionStatus = false,
    handleVerificationClick } = props;

  const { dictionary } = useLanguageContext();

  const [field] = useField(props);

  const inputClassName = `input-control ${hasError ? 'has-error' : ''}`;

  return (
    <div className="input">
      {iconPrepend && (
      <span className="input-prepend-icon" role="presentation">
        {iconPrepend}
      </span>
      )}
      {iconAppend && (
      <span className="input-append-icon" role="presentation">
        {iconAppend}
      </span>
      )}
      <div className="form-group">
        <div className="form-group-item">
          <PhoneInput
            inputClass={inputClassName}
            onlyCountries={['us']}
            disabled={isDisabled || connectionStatus}
            specialLabel=""
            masks={{
              es: '(...) ..-....', us: '(...) ...-....',
            }}
            country="us"
            value={value}
            enableAreaCodes
            disableDropdown
            placeholder={placeholder}
            inputProps={{
              name: {
                ...props,
              }.name,
              required: isRequired,
            }}
            onChange={(phoneNumber, country, e) => {
              handleChange(e);
            }}
            onBlur={handleBlur}
          />
        </div>
        {showVerification && (
        <div className="form-group-item">
          {connectionStatus
            ? <Status text={dictionary.statuses.verified} variant="success" />
            : (
              <Button
                isDisabled={field.value?.length !== 17 || hasError}
                handleClick={(e) => (handleVerificationClick?.(e, field?.value))}
                label={dictionary.actions.verify}
                variant="success"
              />
            )}
        </div>
        )}
      </div>
    </div>
  );
};
