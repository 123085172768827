import { IDictionary } from '@constants/languages/types';
import { nullValue, oneDay, oneHour, threeDays, twelfthHours, twoDays } from '@constants/notificationTime';

export const firstNotificationOptions = (dictionary:IDictionary) => [
  {
    value: threeDays,
    label: dictionary.appointments.notifications.threeDays,
  },
  {
    value: twoDays,
    label: dictionary.appointments.notifications.twoDays,
  },
  {
    value: oneDay,
    label: dictionary.appointments.notifications.oneDay,
  },
  {
    value: twelfthHours,
    label: dictionary.appointments.notifications.twelfthHours,
  },
  {
    value: oneHour,
    label: dictionary.appointments.notifications.oneHour,
  },
];

export const secondNotificationOptions = (dictionary: IDictionary) => [
  {
    value: threeDays,
    label: dictionary.appointments.notifications.threeDays,
  },
  {
    value: twoDays,
    label: dictionary.appointments.notifications.twoDays,
  },
  {
    value: oneDay,
    label: dictionary.appointments.notifications.oneDay,
  },
  {
    value: twelfthHours,
    label: dictionary.appointments.notifications.twelfthHours,
  },
  {
    value: oneHour,
    label: dictionary.appointments.notifications.oneHour,
  },
  {
    value: nullValue,
    label: '-',
  },
];
