import React from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { CloseButton } from '@components/Alert/CloseButton';
import { AuthHeader } from './components/AuthHeader';
import headliner from './img/Haber-DHL.png';
import logo from './img/logo-laguna.png';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';

export const AuthLayout = () => (
  <>
    <ToastContainer className="toasts" closeButton={CloseButton} />
    <main className="auth">
      <div className="auth-banner">
        <div className="auth-logo">
          <img className="auth-logo-img" src={logo} alt="Laguna" />
        </div>
        <div className="auth-headliner">
          <img className="auth-headliner-img" src={headliner} alt="Haber" />
          <h3>
            Milton Haber, M.D. (1952-2023)
          </h3>
          <h3>Founder of Laguna Research</h3>
        </div>
      </div>
      <div className="auth-content">
        <AuthHeader />
        <Outlet />
      </div>
    </main>
  </>
);
