import React from 'react';
import { useNavigate } from 'react-router-dom';
import { format, isSameDay, isSameMonth } from 'date-fns';
import { IMonthSlotProps } from '@pages/Schedule/components/Month/types';
import { Slot } from '@pages/Schedule/components/Slot';
import { useScheduleContext } from '@pages/Schedule/context/useScheduleContext';
import { Routes } from '../../../../router';

export const MonthSlot = ({ date, appointmentsAmount, eventTypes }: IMonthSlotProps) => {
  const schedule = useScheduleContext();

  const navigate = useNavigate();

  const selectedDate = schedule.selectedDate;

  const cellClassName = () => {
    if (!isSameMonth(date, selectedDate)) {
      return 'is-disabled';
    }
    if (isSameDay(date, selectedDate)) {
      return 'is-selected';
    }
    return '';
  };

  const onClick = () => {
    schedule.setDate(date);

    navigate(Routes.Day);
  };

  const slotDetails = !!appointmentsAmount && `${appointmentsAmount}${'\u00A0'}appointment${appointmentsAmount > 1 ? 's' : ''}`;

  return (
    <div className={`schedule-cell table-cell ${cellClassName()}`}>
      <div className="schedule-cell-content">
        <span className="schedule-day">
          { isSameMonth(date, selectedDate) ? format(date, 'dd') : format(date, 'MMM dd') }
        </span>
        {!!appointmentsAmount && (
        <Slot
          variant="info"
          details={slotDetails}
          shortDetails={appointmentsAmount}
          eventTypes={eventTypes}
          handleClick={onClick}
        />
        )}
        {/* TODO uncomment if it'll be necessary to show empty days */}
        {/* {!appointmentsAmount && ( */}
        {/* <Slot variant="info" details="Free day" handleClick={onClick} /> */}
        {/* )} */}
      </div>
    </div>
  );
};
