import React, { FC } from 'react';
import { Link } from '@components/Link';
import { Routes } from '../../../../router';

export interface INameFormatterProps {
    id: string;
    name: string;
}

export const nameFormatter:FC<INameFormatterProps> = ({ id, name }) => <Link variant="primary" to={`${Routes.Organization}/${id}`} label={`${name}`} />;
