import React, { FC } from 'react';
import { FieldArray, FormikValues, useFormikContext } from 'formik';
import { isSameDay } from 'date-fns';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { durationOptions } from '@constants/options/durationOptions';
import { InputField } from '@components/Form/InputField';
import { TypeOptions } from '@constants/options/typeOptions';
import { SelectField } from '@components/Form/SelectField';
import { DateField } from '@components/Form/DateField';
import { IAppointmentSettings } from '@pages/Appointment/pages/CreateAppointment/components/DetailsForm/types';
import { TWorkingDays } from '@pages/Physicians/components/PhysiciansTable/types';
import { getFreeOfDay } from '@helpers/getFreeOfDay';
import { convertTimeToOptions } from '@helpers/convertTimeToOptions';
import { IBusySlot } from '@api/trial/types';
import { timeFormatter } from '@helpers/timeFormatter';
import { checkIsWeekday } from '@helpers/checkIsWeekday';
import { getDuration } from '@helpers/getDuration';
import { checkTimeInRange } from '@helpers/checkTimeInRange';
import './styles.scss';

interface IDetailsFormProps {
  appointment: any,
}

export const EditAppointmentsForm:FC<IDetailsFormProps> = ({ appointment }) => {
  const { dictionary } = useLanguageContext();

  const { values } = useFormikContext<FormikValues>();

  const unavailableDays = (index: number) => appointment[index].trials.physicians.schedule && Object.keys(appointment[index].trials.physicians.schedule)
    .filter((day) => appointment[index].trials.physicians.schedule && !appointment[index].trials.physicians.schedule[day as keyof TWorkingDays]?.length)
    .map((days) => days);

  const getDatesInRange = (date: string, duration: number, index: number) => {
    const currentDay = new Date(date).toLocaleDateString('en-us', {
      weekday: 'long',
    }).toLowerCase();

    const currentAppointmentId = values.infoToChange[index].id;

    const busySlots = appointment[index].trials.busySlots.map(({ from, to, appointmentId }: {from: string, to:string, appointmentId: string}) => {
      if (isSameDay(new Date(from), new Date(date)) && isSameDay(new Date(to), new Date(date)) && appointmentId !== currentAppointmentId) {
        return {
          from: timeFormatter(new Date(from)),
          to: timeFormatter(new Date(to)),
        };
      }
      return undefined;
    }).filter((slot: any) => slot !== undefined);

    return (appointment[index].trials.physicians.schedule && busySlots) && getFreeOfDay(duration || 15, appointment[index].trials.physicians.schedule[currentDay as keyof TWorkingDays], busySlots as IBusySlot[] || []);
  };

  const timeRange = (index: number) => convertTimeToOptions(getDatesInRange(values.infoToChange[index].date, values.infoToChange[index].duration, index));

  const isEditing = (index: number) => (getDuration(values.infoToChange[index].time) !== Number(values.infoToChange[index].duration)) || !checkTimeInRange(timeRange(index), values.infoToChange[index].time);

  return (
    <section className="form-section">
      <div className="edit-form">
        {values.infoToChange.length && (
        <FieldArray
          name="infoToChange"
          render={(arrayHelpers) => (
            <section>
              {arrayHelpers.form.values.infoToChange.length && arrayHelpers.form.values.infoToChange
                .map((visit: IAppointmentSettings, index: number) => (
                  <div key={index} className="form-field">
                    <section className="form-section">
                      <h2 className="form-title title-md is-underlined">{dictionary.appointments.visitsRange[index]}</h2>
                      <h3 className="form-title title-md">{dictionary.labels.appointmentDetails}</h3>
                      <div className="row">
                        <div className="col-xs-12">
                          <InputField
                            type="text"
                            placeholder={dictionary.placeholders.enterAppointmentName}
                            label={dictionary.labels.appointmentName}
                            required
                            name={`infoToChange.${index}.name`}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-12">
                          <SelectField
                            chooseNested
                            selectOptions={TypeOptions(dictionary)}
                            placeholder={dictionary.placeholders.chooseType}
                            label={dictionary.labels.type}
                            required
                            name={`infoToChange.${index}.type`}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-12">
                          <SelectField
                            isDisabled={!values.infoToChange[index].type}
                            selectOptions={durationOptions(dictionary)}
                            placeholder={dictionary.placeholders.chooseDuration}
                            label={dictionary.labels.appointmentDuration}
                            required
                            name={`infoToChange.${index}.duration`}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-12 col-md-6">
                          <DateField
                            selectedDate={index >= 1 ? values.infoToChange[index - 1].date : undefined}
                            maxDate={false}
                            protocolWindow={Number(appointment[index].trials.protocolWindow)}
                          // visitRange={Number(appointment[index].trials.appointmentTemplate[index as unknown as keyof IVisitsRange].date)}
                            disabled={index >= 1 ? !values.infoToChange[index - 1].time : false}
                            unavailableDays={unavailableDays(index)}
                            isWeekend={(day) => checkIsWeekday(day, unavailableDays(index))}
                            excludeDates={values.infoToChange?.map(({ date }:{date: Date}) => new Date(date))}
                            type="text"
                            calendarStartDay={1}
                            placeholder="MM/DD/YYYY"
                            label={dictionary.appointments.table.date}
                            required
                            name={`infoToChange.${index}.date`}
                          />
                        </div>
                        <div className="col-xs-12 col-md-6">
                          <SelectField
                            isEditing={isEditing(index)}
                            value={values.infoToChange[index].time}
                            selectOptions={convertTimeToOptions(getDatesInRange(values.infoToChange[index].date, values.infoToChange[index].duration, index))}
                            placeholder={dictionary.placeholders.chooseTime}
                            label={dictionary.appointments.table.time}
                            required
                            name={`infoToChange.${index}.time`}
                          />
                        </div>
                      </div>
                    </section>
                  </div>
                ))}
            </section>
          )}
        />
        )}
      </div>
    </section>
  );
};
