import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { Button } from '@components/Button';
import { InputField } from '@components/Form/InputField';
import { PasswordField } from '@components/Form/PasswordField';
import { Info } from '@components/Info';
import { Link } from '@components/Link';
import { ILoggedInUser, IUserLogin } from '@api/user/types';
import { useLoginUserMutation } from '@api/user/userAPI';
import { useLanguageContext } from '@hooks/useLanguageContext';
import signInValidationSchema from '@helpers/validationSchemas/signInValidationSchema';
import { loadState } from '@store/browserStorage';
import { IUserData } from '@store/types';
import { persistAuth } from '@constants/persist';
import { getUserRole } from '@helpers/getUserRole';
import { Roles } from '@constants/roles';
import { Routes } from '../../../../router';

export const SignInForm = () => {
  const [loginUser] = useLoginUserMutation();

  const { dictionary } = useLanguageContext();

  const navigate = useNavigate();

  const [isError, setIsError] = useState<string>('');

  const initialValues = {
    email: '',
    password: '',
  };

  const loginUserWithRetry = async (formValues: IUserLogin, retryCount = 3): Promise<void> => {
    try {
      const { success, message, userData } = await loginUser(formValues).unwrap();

      if (success === 0) {
        setIsError(message);
        return;
      }

      let { token: tok }: ILoggedInUser = JSON.parse(loadState<IUserData>(persistAuth)?.auth as unknown as string);

      if (!tok && retryCount > 0) {
        await new Promise<void>((resolve) => {
          const checkTokenInterval = setInterval(() => {
            tok = JSON.parse(loadState<IUserData>(persistAuth)?.auth as unknown as string);

            if (tok) {
              clearInterval(checkTokenInterval);
              resolve();
            }
          }, 100);
        });

        await loginUserWithRetry(formValues, retryCount - 1);
        return;
      }

      const userRole = getUserRole(userData.user, userData.token);

      const route = userRole === Roles.superAdmin ? Routes.Schedule : Routes.RequestedAppointments;

      if (userData.token?.length) {
        navigate(route);
      } else {
        setIsError(dictionary.signIn.emailOrPasswordIncorrect);
      }
    } catch (error) {
      setIsError(dictionary.signIn.emailOrPasswordIncorrect);
    }
  };

  const onSignIn = (formValues: IUserLogin) => {
    loginUserWithRetry(formValues);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={signInValidationSchema(dictionary)}
      onSubmit={onSignIn}
    >
      <Form className="form" noValidate>
        {!!isError.length && (
          <div className="form-section">
            <div className="row">
              <div className="col-xs-12">
                <Info variant="danger" text={isError} />
              </div>
            </div>
          </div>
        )}
        <div className="form-body">
          <section className="form-section">
            <div className="row">
              <div className="col-xs-12">
                <InputField
                  type="email"
                  placeholder={dictionary.placeholders.enterYourEmail}
                  label={dictionary.labels.email}
                  required
                  name="email"
                  autocomplete="on"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <PasswordField
                  placeholder={dictionary.placeholders.yourPassword}
                  label={dictionary.labels.password}
                  required
                  name="password"
                  fieldControls={<Link to={Routes.ForgotPassword} label={dictionary.forgotPassword.forgotPassword} />}
                />
              </div>
            </div>
          </section>
        </div>
        <div className="form-footer">
          <section className="form-section">
            <Button
              label={dictionary.signIn.signIn}
              type="submit"
              size="large"
            />
          </section>
          <section className="form-section text-center">
            <p className="text">
              {dictionary.signIn.bySigningInYouAcceptOur}
              &nbsp;
              <Link
                to={Routes.PrivacyPolicy}
                label={dictionary.links.privacyPolicy}
                variant="outlined"
                target="_blank"
                inline
              />
            </p>
            <p className="text-md text-semibold">
              {dictionary.signIn.newToSchedulapp}
              &nbsp;
              <Link
                to={Routes.SignUp}
                label={dictionary.signIn.createAnAccount}
                variant="primary"
                inline
              />
            </p>
          </section>
        </div>
      </Form>
    </Formik>
  );
};
