import * as Yup from 'yup';
import { IDictionary } from '@constants/languages/types';
import { phoneExpression } from '@constants/regularExpression';

function locationsValidationSchema(dictionary: IDictionary) {
  return Yup.object({
    name: Yup.string()
      .required(dictionary.validation.locationName)
      .max(50, dictionary.validation.locationCharacters),
    number: Yup.string()
      .required(dictionary.validation.locationPhone)
      .min(12, dictionary.validation.invalidLocationPhone)
      .matches(phoneExpression, dictionary.validation.invalidLocationPhone),
    address: Yup.string()
      .required(dictionary.validation.locationAddress)
      .max(100, dictionary.validation.locationAddressCharacters),
  });
}

export default locationsValidationSchema;
