import { Dispatch } from 'react';
import { FormikValues } from 'formik';
import { IResponse } from '@api/user/types';
import { errorAlert } from '@helpers/alerts/alerts';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition,
} from '@reduxjs/toolkit/query';
import { IUserRegistration } from '@store/types';
import { emptyString } from '@constants/emptyString';
import { NavigateFunction } from 'react-router-dom';
import { IDictionary } from '@constants/languages/types';
import { Routes } from '../router';

export const onSignUp = async (
  dictionary: IDictionary,
  formValues: FormikValues,
  inviteEmail: string | null,
  token: string | null,
  registerUser: MutationTrigger<MutationDefinition<{userData: IUserRegistration, invitation: boolean | undefined, token: string | null}, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta>, 'Users', IResponse, 'userAPI'>>,
  isVerifyPhone: boolean,
  isVerifyParentPhone: boolean,
  setUserRegisterEmail: Dispatch<string>,
  navigate: NavigateFunction,
) => {
  const { parentName, parentSurname, parentEmail, parentPhone, ...rest } = formValues;

  let payload: IUserRegistration = {
    dateOfBirth: '',
    email: '',
    gender: '',
    name: '',
    password: '',
    phone: '',
    repeatedPassword: '',
    surname: '',
    ...rest,
  };

  const parentInformation = {
    parentName, parentSurname, parentEmail, parentPhone,
  };

  const inviteEmailExist = inviteEmail?.length ? true : undefined;

  const parentPhoneExist = Boolean(parentPhone);

  if (!isVerifyPhone) {
    errorAlert(emptyString, dictionary.labels.verifyPhone);
    return;
  }

  if (parentPhoneExist && !isVerifyParentPhone) {
    errorAlert(emptyString, dictionary.labels.verifyParentPhone);
    return;
  }

  switch (true) {
    case !parentPhoneExist && isVerifyPhone: {
      payload = {
        ...payload,
      };
      break;
    }
    case parentPhoneExist && isVerifyPhone && isVerifyParentPhone: {
      payload = {
        ...payload, ...parentInformation,
      };
      break;
    }
  }

  registerUser({
    token,
    invitation: inviteEmailExist,
    userData: {
      ...payload,
    },
  })
    .unwrap()
    .then(({ success,
      message }) => {
      if (success === 1) {
        setUserRegisterEmail(formValues.email);
        if (!!token || !!inviteEmail) {
          navigate(Routes.SignIn);
        }
      } else errorAlert(emptyString, message);
    })
    .catch(({ data }) => errorAlert(emptyString, data.message));
};
