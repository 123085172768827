import React from 'react';
import { MonthBody } from '@pages/Schedule/components/Month/MonthBody';
import { MonthHeader } from '@pages/Schedule/components/Month/MonthHeader';
import { TableEmpty } from '@components/Table/components/TableEmpty';
import { useScheduleContext } from '@pages/Schedule/context/useScheduleContext';
import { useFetchScheduleByMonthQuery } from '@api/schedule/scheduleAPI';
import { convertTime } from '@helpers/convertTime';

export const Month = () => {
  const { selectedDate,
    selectedPatient,
    selectedLocation,
    selectedPhysician,
    selectedType,
    selectedTrial,
    selectedHourFrom,
    selectedHourTo } = useScheduleContext();

  const getMonth = (date: Date) => (`0${date.getMonth() + 1}`).slice(-2);

  const { data: appointments } = useFetchScheduleByMonthQuery({
    month: getMonth(selectedDate),
    patient: selectedPatient || undefined,
    location: selectedLocation || undefined,
    physician: selectedPhysician || undefined,
    type: selectedType || undefined,
    trial: selectedTrial || undefined,
    from: !!selectedHourFrom && !!selectedDate ? convertTime(selectedHourFrom) : undefined,
    to: !!selectedHourTo && !!selectedDate ? convertTime(selectedHourTo) : undefined,
  }, {
    refetchOnMountOrArgChange: true,
  });

  const unscheduledAppointments = (selectedTrial?.length || selectedHourFrom?.length || selectedHourTo?.length || selectedType?.length || selectedPhysician?.length || selectedLocation?.length || selectedPatient?.length) && !appointments;

  return (
    <div className="schedule-table table month">
      { unscheduledAppointments ? <TableEmpty emptyType="search" />
        : (
          <div className="schedule-content table-content">
            <MonthHeader />
            <MonthBody appointments={appointments} />
          </div>
        )}
    </div>
  );
};
