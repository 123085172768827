import { getYear } from 'date-fns';

export const generateYearsBetween = (startYear = getYear(new Date()) - 1, endYear = getYear(new Date()) + 1) => {
  const years = [];

  let currentYear = startYear;

  while (endYear >= currentYear) {
    years.push(currentYear);
    currentYear += 1;
  }

  return years.map((year) => ({
    id: String(year),
    value: year,
    label: year,
  }));
};
