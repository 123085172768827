import React from 'react';
import { Form, Formik, FormikValues } from 'formik';
import { format } from 'date-fns';
import { useNavigate, useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { Button } from '@components/Button';
import { Container } from '@components/Container';
import { Section } from '@components/Section';
import { ModalTypes } from '@components/Confirm/types';
import { useConfirmationModal } from '@components/Confirm/context/useConfirmContext';
import { EditAppointmentsForm } from '@pages/Appointment/pages/EditAppointments/components/EditAppointmentsForm';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { useEditAppointmentsInfoMutation, useFetchAllUserAppointmentsQuery } from '@api/appointments/appointmentsApi';
import { isEqual } from 'lodash';
import { TAppointment } from '@api/appointments/types';
import { errorAlert, successAlert } from '@helpers/alerts/alerts';
import { getEndTime, getStartTime } from '@helpers/getVisitTime';
import { convertTime } from '@helpers/convertTime';
import { emptyString } from '@constants/emptyString';
import { timeToTimestampFormatter } from '@helpers/timeToTimestampFormatter';

export const EditAppointments = () => {
  const { patientId, trialId } = useParams();

  const { dictionary } = useLanguageContext();

  const { data: allUserAppointments } = useFetchAllUserAppointmentsQuery({
    patientId: String(patientId), trialId: String(trialId),
  } ?? skipToken);

  const [editAppointments] = useEditAppointmentsInfoMutation();

  const navigate = useNavigate();

  const previousPage = -1;

  const { openConfirmationModal } = useConfirmationModal();

  const initialValue = {
    infoToChange: allUserAppointments?.data.map(({ id, name, type, duration, date, time, trials }) => ({
      id,
      name,
      type,
      duration,
      date: format(new Date(date), 'MM/dd/yyyy'),
      time,
      physicianId: trials.physicians.id,
    })).sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()) || [],
  };

  const onSubmitForm = (formValues: FormikValues) => {
    const changedValues = Object.entries(formValues.infoToChange).reduce((acc, [key, value]) => {
      if (!isEqual(value, initialValue.infoToChange[key as any])) {
        acc.push(value as never);
      }
      return acc;
    }, []);

    editAppointments({
      infoToChange: changedValues.map(({ id, name, type, duration, date, time, physicianId }: TAppointment) => ({
        appointment: {
          id,
          name,
          type,
          duration,
          date: format(new Date(date), 'MM/dd/yyyy'),
          time,
        },
        additional_info: {
          physician_id: physicianId,
          from: timeToTimestampFormatter(getStartTime(time), new Date(date)),
          to: timeToTimestampFormatter(getEndTime(time), new Date(date)),
          fromTime: convertTime(getStartTime(time)),
          toTime: convertTime(getEndTime(time)),
        },
      })) as never,
    }).unwrap()
      .then(async ({ message }) => {
        successAlert(emptyString, message);
        navigate(previousPage);
      })
      .catch(({ message }) => errorAlert(message));
  };

  const onCancel = () => {
    openConfirmationModal({
      variant: ModalTypes.decline,
      title: dictionary.appointments.modals.cancel.title2,
      text: dictionary.appointments.modals.cancel.text2,
      submitButtonTitle: dictionary.actions.ok,
      handleConfirmClick: () => {
        navigate(previousPage);
      },
    });
  };

  return (
    <div className="main-content">
      <Container size="lg">
        <div className="panel">
          <Section containerSize="md">
            <Formik
              enableReinitialize
              initialValues={initialValue}
              validationSchema={null}
              onSubmit={onSubmitForm}
            >
              <Form className="form" noValidate>
                <div className="form-body">
                  <section className="form-section">
                    {!!allUserAppointments
                      && (
                      <EditAppointmentsForm appointment={allUserAppointments.data.map((appointments) => ({
                        ...appointments,
                      }))
                        .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())}
                      />
                      )}
                  </section>
                  <div className="form-section form-footer">
                    <Button
                      label={dictionary.actions.cancel}
                      variant="default"
                      size="static"
                      handleClick={onCancel}
                    />
                    <Button
                      type="submit"
                      label={dictionary.actions.save}
                      size="static"
                    />
                  </div>
                </div>
              </Form>
            </Formik>
          </Section>
        </div>
      </Container>
    </div>
  );
};
