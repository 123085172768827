import React, { ReactNode } from 'react';
import { isSameDay } from 'date-fns';
import { generateWeekDays } from '@helpers/generateWeekDays';
import { WeekSlot } from '@pages/Schedule/components/Week/WeekSlot';
import { useScheduleContext } from '@pages/Schedule/context/useScheduleContext';
import { useFetchAllPhysiciansQuery } from '@api/physicians/physiciansAPI';
import { calcAmountByProperty } from '@pages/Schedule/utils/calcAmountByProperty';
import { TEventTypes } from '@pages/Schedule/components/Slot/types';
import { TAppointment } from '@api/appointments/types';
import { IScheduleResponse } from '@api/schedule/types';

interface IWeekBodyProps {
  appointments: IScheduleResponse | undefined,
}

export const WeekBody = ({ appointments }: IWeekBodyProps) => {
  const { selectedDate, selectedPhysician } = useScheduleContext();

  const tableData = generateWeekDays(selectedDate);

  const scheduleData: TAppointment[] | undefined = appointments?.data;

  const { data: AllPhysicians } = useFetchAllPhysiciansQuery();

  const tableBody = tableData.reduce<ReactNode[]>((acc, currentWeekDay, rowIndex) => {
    const physicians = AllPhysicians?.data.results.filter((physician) => (selectedPhysician?.length ? physician.id === selectedPhysician : physician)).map((physician) => physician.id);

    const tableRow = physicians?.map((physician) => {
      const currentDateAppointments = scheduleData?.filter(({ date }) => isSameDay(new Date(date), currentWeekDay.value));

      const currentPhysicianAppointments = currentDateAppointments?.filter(({ trials }) => trials.physicianId === physician);

      const groupedAppointmentsByType = currentPhysicianAppointments?.length ? calcAmountByProperty(currentPhysicianAppointments, 'type') : [];

      const appointmentsAmount = currentPhysicianAppointments?.length;

      return (
        <WeekSlot
          date={currentWeekDay.value}
          appointmentsAmount={appointmentsAmount}
          eventTypes={groupedAppointmentsByType as TEventTypes[]}
        />
      );
    });

    return [...acc,
      <div className="schedule-row table-row" key={`table-row-${rowIndex}`}>
        <div className="schedule-cell table-cell">
          {tableData[rowIndex].label}
        </div>
        { tableRow }
      </div>,
    ];
  }, []);

  return <div className="schedule-body table-body">{ tableBody }</div>;
};
