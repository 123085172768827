import React, { FC } from 'react';
import { Button } from '@components/Button';
import { useModal } from '@components/Modal/context/useModalContext';
import { IModalButtonProps } from '@components/Modal/ModalToggler/types';
import { UilPlus } from '@iconscout/react-unicons';

export const ModalToggle:FC<IModalButtonProps> = ({ buttonTitle, modalProps }) => {
  const { openModal } = useModal();

  const onAdd = () => {
    openModal(modalProps);
  };

  return (
    <div className="section-control">
      <Button
        label={buttonTitle}
        iconPrepend={<UilPlus />}
        handleClick={() => onAdd()}
        size="static"
      />
    </div>
  );
};
