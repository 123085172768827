import React, { useState } from 'react';
import VerificationInput from 'react-verification-input';
import { Info } from '@components/Info';
import { Modal } from '@components/Modal';
import { IModalRef } from '@components/Modal/types';
import { ResendCode } from '@components/ResendCode/ResendCode';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { useSmsVerifyingMutation } from '@api/user/userAPI';
import { IVerificationProps } from '@pages/SignUp/components/Verification/types';
import './styles.scss';

export const Verification = React.forwardRef<IModalRef, IVerificationProps>(({ phone, verifyParentPhone, verifyPhone }, ref) => {
  const [code, setCode] = useState('');

  const [smsVerify] = useSmsVerifyingMutation();

  const [isError, setIsError] = useState<string>('');

  const { dictionary } = useLanguageContext();

  const onVerifyClick = () => {
    if (code.length === 6 && ref && 'current' in ref) {
      smsVerify({
        code, phone,
      }).unwrap().then(({ message, success }) => {
        if (success === 1) {
          ref.current?.closeModal();
          setCode('');
          verifyPhone(true);
        } else {
          setIsError(message);
        }
      })
        .catch(({ data }) => setIsError(data.message));
    } else {
      setIsError(dictionary.signUp.smsError);
    }

    setCode('');
  };

  const onCloseVerification = () => {
    setCode('');
    setIsError('');
  };

  return (
    <Modal
      submitButtonTitle={dictionary.actions.confirm}
      title={dictionary.titles.verificationCode}
      handleCancelClick={onCloseVerification}
      handleConfirmClick={onVerifyClick}
      ref={ref}
    >
      <div className="verification">
        {!!isError.length && (
        <div className="row">
          <div className="col-xs-12">
            <Info variant="danger" text={isError} />
          </div>
        </div>
        )}
        <div className="row">
          <div className="col-xs-12">
            <p className="verification-text text text-semibold">{dictionary.signUp.enterText}</p>
            <div className="verification-form">
              <VerificationInput autoFocus validChars="0-9" value={code} onChange={setCode} />
            </div>
            <ResendCode isModalVisible={ref && 'current' in ref && ref.current?.isModalVisible} phone={phone} setCode={setCode} />
          </div>
        </div>
      </div>
    </Modal>
  );
});
