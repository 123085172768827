import React, { ReactNode } from 'react';
import { useLanguageContext } from '@hooks/useLanguageContext';

export interface IVisitFormatterProps {
  protocolWindow: string
}

export const VisitDateFormatter = ({ protocolWindow } : IVisitFormatterProps): ReactNode => {
  const { dictionary } = useLanguageContext();

  return (
    <span>{`+/- ${protocolWindow} ${dictionary.trials.modals.createTrial.Day}`}</span>
  );
};
