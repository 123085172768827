import React from 'react';
import { Container } from '@components/Container';
import { ILoggedInUser } from '@api/user/types';
import { loadState } from '@store/browserStorage';
import { IUserData } from '@store/types';
import { persistAuth } from '@constants/persist';
import { useMenu } from './context/useMenuContext';
import { MenuList } from './MenuList';
import './styles.scss';

export const Menu = () => {
  const { isMenuOpen } = useMenu();

  const authData = loadState<IUserData>(persistAuth)?.auth;

  const { user }: ILoggedInUser = authData ? JSON.parse(authData as unknown as string) : {
    user: null,
  };

  const username = `${user?.name || ''} ${user?.surname || ''}`;

  const menuClassName = `menu ${isMenuOpen ? 'is-open' : 'is-collapsed'}`;

  return (
    <nav className={menuClassName}>
      <Container>
        <div className="menu-title title-md">{username}</div>
        <MenuList />
      </Container>
    </nav>
  );
};
