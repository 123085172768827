import * as Yup from 'yup';
import { IDictionary } from '@constants/languages/types';

const scheduleValidationSchema = (dictionary: IDictionary) => Yup.object({
  schedule: Yup.object().shape({
    friday: Yup.array()
      .of(Yup.object()
        .shape({
          from: Yup.string()
            .required(dictionary.validation.indicateSlots),
          to: Yup.string()
            .required(dictionary.validation.indicateSlots),
        })),
    monday: Yup.array()
      .of(Yup.object()
        .shape({
          from: Yup.string()
            .required(dictionary.validation.indicateSlots),
          to: Yup.string()
            .required(dictionary.validation.indicateSlots),
        })),
    saturday: Yup.array()
      .of(Yup.object()
        .shape({
          from: Yup.string()
            .required(dictionary.validation.indicateSlots),
          to: Yup.string()
            .required(dictionary.validation.indicateSlots),
        })),
    sunday: Yup.array()
      .of(Yup.object()
        .shape({
          from: Yup.string()
            .required(dictionary.validation.indicateSlots),
          to: Yup.string()
            .required(dictionary.validation.indicateSlots),
        })),
    thursday: Yup.array()
      .of(Yup.object()
        .shape({
          from: Yup.string()
            .required(dictionary.validation.indicateSlots),
          to: Yup.string()
            .required(dictionary.validation.indicateSlots),
        })),
    tuesday: Yup.array()
      .of(Yup.object()
        .shape({
          from: Yup.string()
            .required(dictionary.validation.indicateSlots),
          to: Yup.string()
            .required(dictionary.validation.indicateSlots),
        })),
    wednesday: Yup.array()
      .of(Yup.object()
        .shape({
          from: Yup.string()
            .required(dictionary.validation.indicateSlots),
          to: Yup.string()
            .required(dictionary.validation.indicateSlots),
        })),
  }),
});

export default scheduleValidationSchema;
