import React from 'react';
import { useLanguageContext } from '@hooks/useLanguageContext';
import './styles.scss';

export const ColorDescription = () => {
  const { dictionary } = useLanguageContext();

  return (
    <>
      <h2 className="form-title text-md">{dictionary.appointments.modals.editAppointment.colorDescription}</h2>
      <div className="row">
        <div className="col-xs-12">
          <ul className="calendar-legend">
            <li className="calendar-legend-item">
              <span className="available-days">1</span>
              <label className="day-label">{dictionary.appointments.modals.editAppointment.availableDay}</label>
            </li>
            <li className="calendar-legend-item">
              <span className="selected-day">1</span>
              <label className="day-label">{dictionary.appointments.modals.editAppointment.selectedDay}</label>
            </li>
            <li className="calendar-legend-item">
              <span className="unavailable-days">1</span>
              <label className="day-label">{dictionary.appointments.modals.editAppointment.outsideDay}</label>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
