import { ILoggedInUser } from '@api/user/types';
import { persistAuth } from '@constants/persist';
import { loadState } from '@store/browserStorage';
import { IUserData } from '@store/types';

export const apiHeader = (headers: Headers) => {
  // const user: ILoggedInUser = localStorage.getItem(persistAuth)?.length ? JSON.parse(loadState<IUserData>(persistAuth)?.auth as unknown as string) : {
  // };
  const currentUser: ILoggedInUser = JSON.parse(loadState<IUserData>(persistAuth)?.auth as unknown as string);

  const token = currentUser.token;

  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
  }

  return headers;
};

export const headerJson = {
  'Content-Type': 'application/json',
};
