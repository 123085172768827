import React from 'react';
import { FormikValues } from 'formik';
import { useParams } from 'react-router-dom';
import { Breadcrumbs } from '@components/Breadcrumbs';
import { Card } from '@components/Card';
import { useModal } from '@components/Modal/context/useModalContext';
import { Section } from '@components/Section';
import { IAction } from '@components/Table/utils/actionsFormatter';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { useEditPhysiciansMutation, useFetchPhysicianByIdQuery } from '@api/physicians/physiciansAPI';
import { errorAlert } from '@helpers/alerts/alerts';
import { PhysicianForm } from '@pages/Physician/components/PhysicianForm';
import physiciansValidationSchema from '@helpers/validationSchemas/physiciansValidationSchema';
import { ScheduleTable } from '@pages/Physician/components/ScheduleTable';
import { WorkingHours } from '@pages/Physician/pages/AddPhysician/components/WorkingHours';
import scheduleValidationSchema from '@helpers/validationSchemas/scheduleValidationSchema';
import { skipToken } from '@reduxjs/toolkit/query';
import { Loader } from '@components/Loader';
import { ContainerSizes } from '@components/Container/types';
import { Routes } from '../../router';

export const Physician = () => {
  const { dictionary } = useLanguageContext();

  const { id } = useParams();

  const { openModal } = useModal();

  const { data: physician, refetch, isLoading } = useFetchPhysicianByIdQuery(id ?? skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const [editPhysician] = useEditPhysiciansMutation();

  const initialPhysicianValues = {
    name: physician?.data.name,
    phone: physician?.data.phone,
  };

  const defaultWorkingHoursValues = {
    schedule: {
      sunday: [],
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
    },
  };

  const initialWorkingHoursValues = {
    schedule: physician?.data.schedule,
  };

  const physicianData = [
    {
      label: dictionary.physicians.table.name,
      value: physician?.data.name,
    },
    {
      label: dictionary.physicians.table.phone,
      value: physician?.data.phone,
    },
  ];

  const onSubmitForm = async (formValues: FormikValues, closeModal: () => void) => {
    editPhysician({
      physician: formValues.phone === physician?.data.phone ? {
        name: formValues.name,
      } : formValues,
      id: String(physician?.data.id),
    })
      .unwrap()
      .then(async ({ success,
        message }) => {
        if (success === 1) {
          await closeModal();
          refetch();
        } else {
          errorAlert(message);
        }
      })
      .catch(({ message }) => errorAlert(message));
  };

  const onEditPhysician = () => {
    openModal({
      children: <PhysicianForm dictionary={dictionary} />,
      initialValues: initialPhysicianValues,
      validationSchema: physiciansValidationSchema(dictionary),
      title: dictionary.physician.modals.editPhysician.title,
      submitButtonTitle: dictionary.actions.save,
      containerSize: ContainerSizes.md,
      onSubmit: onSubmitForm,
    });
  };

  const onEditSchedule = () => {
    openModal({
      children: (
        <ul className="working-hours-list">
          {Object.keys(defaultWorkingHoursValues.schedule).map((day) => <WorkingHours day={day} dictionary={dictionary} />)}
        </ul>),
      initialValues: initialWorkingHoursValues,
      errorTitle: dictionary.physician.modals.editPhysician.errorTitle,
      validationSchema: scheduleValidationSchema(dictionary),
      title: dictionary.physician.modals.editWorkingHours.title,
      submitButtonTitle: dictionary.actions.save,
      containerSize: ContainerSizes.md,
      onSubmit: onSubmitForm,
    });
  };

  const physicianActions: IAction<Record<string, unknown>>[] = [
    {
      type: 'edit',
      text: dictionary.table.edit,
      handleClick: () => onEditPhysician(),
    },
  ];

  const scheduleActions: IAction<Record<string, unknown>>[] = [
    {
      type: 'edit',
      text: dictionary.table.edit,
      handleClick: () => onEditSchedule(),
    },
  ];

  return (
    <>
      <Breadcrumbs
        currentPageTitle={physician?.data.name || ''}
        prevPageTitle={dictionary.physicians.title}
        prevPageRoute={Routes.Physicians}
      />
      <Section title={dictionary.physician.title1}>
        <Card
          title={dictionary.physician.cards.physician.title}
          data={physicianData}
          actions={physicianActions}
          row={physician?.data}
        />
      </Section>
      {isLoading ? <Loader /> : (
        <Section title={dictionary.physician.title2}>
          {physician?.data.schedule && (
          <Card
            title={dictionary.physician.cards.schedule.title}
            actions={scheduleActions}
            row={physician?.data}
            details={(
              <ScheduleTable workingHours={physician.data.schedule} />
          )}
            isDetailsCollapsible={false}
          />
          )}
        </Section>
      )}
    </>
  );
};
