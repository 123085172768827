import React from 'react';
import { components, GroupBase, InputProps } from 'react-select';
import { TTrial } from '@pages/Trials/components/TrialsTable/types';

export const SelectInput = (props: InputProps<TTrial, boolean, GroupBase<TTrial>>) => {
  const { hasValue } = props;

  const placeholder = hasValue ? 'Add...' : '';

  return (
    <components.Input {...props} placeholder={placeholder} />
  );
};
