import * as Yup from 'yup';
import { numberExpression } from '@constants/regularExpression';
import { IDictionary } from '@constants/languages/types';

const createTrialValidationSchema = (dictionary: IDictionary) => Yup.object({
  protocolName: Yup.string()
    .required(dictionary.validation.protocolName)
    .max(50, dictionary.validation.protocolNameMax),
  protocolNumber: Yup.string()
    .required(dictionary.validation.protocolNumber)
    .nullable()
    .max(50, dictionary.validation.protocolNumberMax)
    .matches(numberExpression, dictionary.validation.invalidProtocolNumber),
  sponsorName: Yup.string()
    .required(dictionary.validation.sponsorName)
    .max(100, dictionary.validation.sponsorNameMax),
  physicianId: Yup.string()
    .required(dictionary.validation.physicianName)
    .max(100, dictionary.validation.invalidPhysicianName),
  appointmentTemplate: Yup.array()
    .of(Yup.object()
      .shape({
        name: Yup.string()
          .max(50, dictionary.validation.appointmentMax)
          .required(dictionary.validation.appointmentName),
        type: Yup.string()
          .required(dictionary.validation.type),
        duration: Yup.number()
          .required(dictionary.validation.duration),
        protocolWindow: Yup.string()
          .required(dictionary.validation.protocolWindow),
        date: Yup.string()
          .required(dictionary.validation.appointmentDate),
        notificationsSettings: Yup.object()
          .shape({
            types: Yup.object().shape({
              email: Yup.string().nullable()
                .when('sms', {
                  is: (sms: string) => !sms || sms.length === 0,
                  then: Yup.string()
                    .required(dictionary.validation.requireField),
                }),
              sms: Yup.string().nullable()
                .when('email', {
                  is: (email: string) => !email || email.length === 0,
                  then: Yup.string()
                    .required(dictionary.validation.requireField),
                }),
            }, ['sms', 'email'] as any),
            first: Yup.number()
              .required(dictionary.validation.notification),
            second: Yup.number()
              .nullable()
              .lessThan(Yup.ref('first'), dictionary.validation.laterNotification),
          }),
      })),
});

export default createTrialValidationSchema;
