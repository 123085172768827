import React, { FC } from 'react';
import { IStatusProps, statusVariants } from '@components/Status/types';
import { UilCheckCircle, UilInfoCircle } from '@iconscout/react-unicons';
import './styles.scss';

export const Status:FC<IStatusProps> = ({ text, variant }) => {
  const statusClassName = `status ${variant && `is-${variant}`}`;

  const getAppropriateIcon = () => {
    if (variant === statusVariants.warning) {
      return <UilInfoCircle />;
    } if (variant === statusVariants.success) {
      return <UilCheckCircle />;
    }
    return <UilInfoCircle />;
  };

  const statusIcon = getAppropriateIcon();

  return (
    <span className={statusClassName}>
      <span className="status-content">
        <span className="status-icon">{statusIcon}</span>
        {text}
      </span>
    </span>
  );
};
