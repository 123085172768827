import React, { FC } from 'react';

export interface IPatientIdFormatterProps {
  userIdFromSponsor?: string;
}

export const patientIdFormatter:FC<IPatientIdFormatterProps> = ({ userIdFromSponsor }) => {
  if (!userIdFromSponsor?.length) {
    return <span className="table-cell-placeholder">-</span>;
  }

  return <span>{userIdFromSponsor}</span>;
};
