import { useContext } from 'react';
import { ModalContext } from '@components/Modal/context/ModalContext';

export const useModal = () => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useModal used outside ModalContext');
  }

  return context;
};
