import snakecaseKeys from 'snakecase-keys';
import { IInitialTrial, IOneTrialResponse, ITrialData, ITrialResponse } from '@api/trial/types';
import { IResponse } from '@api/user/types';
import { RequestRoutes } from '@constants/requestRoutes';
import { apiHeader, headerJson } from '@helpers/commonHeaders';
import { convertToCamelCase } from '@helpers/convertToCamelCase';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { trialTag } from '@constants/apiTags';
import { language } from '@constants/language';

const lang = localStorage.getItem(language)?.slice(1, 3) || 'en';

export const trialAPI = createApi({
  reducerPath: 'trialAPI',
  tagTypes: [trialTag],
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_API_URL}`,
    prepareHeaders: apiHeader,
  }),
  endpoints: (build) => ({
    fetchAllTrials: build.query<ITrialResponse, {searchString?: string, limit: number, page?: number, sortField?: string}>({
      query: ({ searchString, page, sortField, limit }) => ({
        url: `${RequestRoutes.Trial}${RequestRoutes.All}`,
        params: {
          limit,
          page,
          sortField,
          searchString,
          lang,
        },
      }),
      transformResponse(response: ITrialResponse) {
        return convertToCamelCase(response);
      },
    }),
    fetchAllTrialsWithoutPagination: build.query<ITrialResponse, void>({
      query: () => ({
        url: `${RequestRoutes.Trial}${RequestRoutes.All}`,
        params: {
          searchString: '',
          lang,
        },
      }),
      transformResponse(response: ITrialResponse) {
        return convertToCamelCase(response);
      },
    }),
    fetchTrialById: build.query<IOneTrialResponse, string>({
      query: (id) => ({
        url: `${RequestRoutes.Trial}${RequestRoutes.GetOne}`,
        params: {
          id,
          lang,
        },
      }),
      transformResponse(response: IOneTrialResponse) {
        return convertToCamelCase(response);
      },
    }),
    addTrial: build.mutation<IResponse, ITrialData>({
      query: (trialData: ITrialData) => ({
        url: `${RequestRoutes.Trial}${RequestRoutes.AddNew}`,
        headers: headerJson,
        method: 'POST',
        body: trialData,
        params: {
          lang,
        },
      }),
      invalidatesTags: [trialTag],
    }),
    deleteTrial: build.mutation<IResponse, string>({
      query: (trialId: string) => ({
        url: `${RequestRoutes.Trial}${RequestRoutes.DeleteTrial}`,
        method: 'DELETE',
        body: {
          id: trialId,
        },
        params: {
          lang,
        },
        keepUnusedDataFor: 5,
      }),
      invalidatesTags: () => [trialTag],
    }),
    editTrial: build.mutation<IResponse, { trial: IInitialTrial, id: string }>({
      query: ({ trial, id }) => ({
        url: `${RequestRoutes.Trial}${RequestRoutes.EditTrial}`,
        headers: headerJson,
        params: {
          id,
          lang,
        },
        method: 'PATCH',
        body: snakecaseKeys(trial),
      }),
      invalidatesTags: () => [trialTag],
    }),
  }),
});

export const { useFetchAllTrialsQuery,
  useAddTrialMutation,
  useFetchTrialByIdQuery,
  useFetchAllTrialsWithoutPaginationQuery,
  useEditTrialMutation,
  useDeleteTrialMutation } = trialAPI;
