import { ITimeRange } from '@pages/Appointment/pages/CreateAppointment/components/DetailsForm/types';

export const convertTimeToOptions = (freeSlots?: ITimeRange[], currentSlot?: string) => {
  let options: { label: string; value: string; }[] = [];
  if (freeSlots) {
    options = freeSlots.map((slot) => ({
      label: `${slot.from} - ${slot.to}`,
      value: `${slot.from} - ${slot.to}`,
    }));
    if (currentSlot) {
      options.unshift({
        label: currentSlot, value: currentSlot,
      });
    }
  }
  return options;
};
