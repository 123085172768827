import React, { FC } from 'react';
import { InputField } from '@components/Form/InputField';
import { IDictionary } from '@constants/languages/types';
import { IModifyOrganization } from '@api/organizations/types';
import { PhoneField } from '@components/Form/PhoneField';

interface IFormContentProps {
    dictionary: IDictionary
}

export const editOrganizationInitialValues = (name: string, owner: string, phone: string, id?: number) => ({
  name,
  owner,
  phone,
  id,
});

export const addOrganizationInitialValues: IModifyOrganization = {
  name: '',
  owner: '',
  phone: '',
};

export const FormContent: FC<IFormContentProps> = ({ dictionary }) => (
  <div className="form-field">
    <div className="row">
      <div className="col-xs-12">
        <InputField
          type="text"
          placeholder={dictionary.placeholders.enterOrganizationName}
          label={dictionary.labels.organizationName}
          required
          name="name"
        />
      </div>
    </div>
    <div className="row">
      <div className="col-xs-12">
        <InputField
          type="text"
          placeholder={dictionary.placeholders.enterOwner}
          label={dictionary.labels.owner}
          required
          name="owner"
        />
      </div>
    </div>
    <div className="row">
      <div className="col-xs-12">
        <PhoneField
          type="text"
          placeholder={dictionary.placeholders.enterPhoneNumber}
          label={dictionary.labels.phoneNumber}
          required
          name="phone"
        />
      </div>
    </div>
  </div>
);
