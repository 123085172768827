import gCalendarIcon from '@pages/Profile/img/g-calendar.svg';
import outlookCalendarIcon from '@pages/Profile/img/outlook-calendar.svg';

export const userIntegrations = (googleConnection?: boolean, outlookConnection?: boolean) => [
  {
    title: 'Google Calendar',
    icon: gCalendarIcon,
    isConnected: googleConnection || false,
    type: 'google',
  },
  {
    title: 'Outlook Calendar',
    icon: outlookCalendarIcon,
    isConnected: outlookConnection || false,
    type: 'microsoft',
  },
];
