import React, { FC } from 'react';
import { Link } from '@components/Link';
import { Routes } from '../../../../router';

export interface INameFormatterProps {
  id: string;
  protocolName: string;
}

export const nameFormatter:FC<INameFormatterProps> = ({ id, protocolName }) => <Link variant="primary" to={`${Routes.Trial}/${id}`} label={`${protocolName}`} />;
