import React from 'react';
import { FieldArray } from 'formik';
import { Button } from '@components/Button';
import { InputGroupField } from '@components/Form/InputGroupField';
import { IInvite } from '@api/patients/types';
import { UilPlus, UilTrash } from '@iconscout/react-unicons';
import { IDictionary } from '@constants/languages/types';

export const patientsInitialValues: IInvite = {
  invite: [{
    email: '',
  }],
};

export const FormContent = (dictionary: IDictionary) => {
  const { labels, placeholders, patients } = dictionary;

  return (
    <FieldArray
      name="invite"
      render={(arrayHelpers) => (
        <>
          {arrayHelpers.form.values.invite.map((email: string, index: number) => (
            <div key={index} className="form-field">
              <InputGroupField
                type="email"
                placeholder={placeholders.enterPatientEmail}
                label={index === 0 ? labels.patientEmail : ''}
                required
                name={`invite.${index}.email`}
                fieldBtn={arrayHelpers.form.values.invite.length > 1 && (
                <Button
                  isDisabled={arrayHelpers.form.values.invite.length === 1}
                  size="small"
                  type="button"
                  variant="danger"
                  handleClick={() => arrayHelpers.remove(index)}
                  iconAppend={<UilTrash />}
                />
                )}
              />
            </div>
          ))}
          {arrayHelpers.form.values.invite.length < 5 && (
          <Button
            variant="link"
            type="submit"
            handleClick={() => arrayHelpers.insert(patientsInitialValues.invite.length, {
              email: '',
            })}
            label={patients.modals.addPatients.addAnotherPatient}
            iconPrepend={<UilPlus />}
          />
          )}
        </>
      )}
    />
  );
};
