import { Roles } from '@constants/roles';
import { TEmployee } from '@api/user/types';

export const getUserRole = (user: TEmployee | null | undefined) => {
  if (user?.employee === 1 && user?.superAdmin === 0 && user?.admin === 0) {
    return Roles.staff;
  } if (user?.superAdmin === 1 && user?.employee === 1 && user?.admin === 1) {
    return Roles.superAdmin;
  } if (user?.admin === 1 && user?.superAdmin === 0 && user?.employee === 1) {
    return Roles.admin;
  }
  return Roles.patient;
};
