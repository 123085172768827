import React, { FC } from 'react';
import { TAppointment } from '@api/appointments/types';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { secondNotificationOptions } from '@constants/options/notificationOptions';

export const SecondNotificationFormatter:FC<TAppointment> = ({ notificationsSettings }) => {
  const notification = notificationsSettings.second;

  const { dictionary } = useLanguageContext();

  return <ul>{ notification ? secondNotificationOptions(dictionary).find(({ value }) => value === Number(notification))?.label : '-'}</ul>;
};
