import React, { FC } from 'react';
import { IPhysicianSettings, TInterval, TWorkingDays } from '@pages/Physicians/components/PhysiciansTable/types';
import { useLanguageContext } from '@hooks/useLanguageContext';

export const WorkingHoursFormatter:FC<IPhysicianSettings> = (physician, accessor) => {
  // eslint-disable-next-line react/destructuring-assignment
  const currentDay = physician[accessor as keyof TWorkingDays];

  const { dictionary } = useLanguageContext();

  if (!currentDay?.length) {
    return <span className="table-cell-placeholder">{dictionary.labels.unavailable}</span>;
  }

  const workingHoursList = currentDay.map(({ from, to }: TInterval) => <li>{`${from}-${to}`}</li>);

  return <ul className="table-working-hours">{workingHoursList}</ul>;
};
