import React, { FC } from 'react';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import snakecaseKeys from 'snakecase-keys';
import { Button } from '@components/Button';
import { useConfirmationModal } from '@components/Confirm/context/useConfirmContext';
import { ModalTypes } from '@components/Confirm/types';
import { Section } from '@components/Section';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { useFetchAllPhysiciansQuery } from '@api/physicians/physiciansAPI';
import { useAddTrialMutation } from '@api/trial/trialAPI';
import { IInitialTrial } from '@api/trial/types';
import { errorAlert, successAlert } from '@helpers/alerts/alerts';
import { ScheduleForm } from '@pages/Trial/pages/CreateTrial/components/ScheduleForm';
import { TrialForm } from '@pages/Trial/pages/CreateTrial/components/TrialForm';
import createTrialValidationSchema from '@helpers/validationSchemas/createTrialValidationSchema';
import { emptyString } from '@constants/emptyString';
import { Routes } from '../../../../../router';

export const CreateTrialForm: FC = () => {
  const { dictionary } = useLanguageContext();

  const navigate = useNavigate();

  const { data: physicians } = useFetchAllPhysiciansQuery();

  const [createTrial] = useAddTrialMutation();

  const visitValues = {
    name: '',
    type: '',
    protocolWindow: '',
    duration: '',
    date: '',
    notificationsSettings: {
      types: {
        sms: '',
        email: '',
      },
      first: '',
      second: '',
    },
  };

  const initialValues: IInitialTrial = {
    protocolName: '',
    protocolNumber: null,
    sponsorName: '',
    physicianId: '',
    appointmentTemplate: [visitValues, visitValues],
  };

  const physiciansOptions = physicians?.data.results.map(({ name: label,
    id: value }) => ({
    label,
    value,
  }));

  const onSubmitForm = async (formValues: IInitialTrial) => {
    createTrial(snakecaseKeys({
      ...formValues,
      appointmentTemplate: Object.assign({
      }, ...(formValues.appointmentTemplate.map((item: any, index: any) => {
        const updatedItem: Record<string, any> = {
          [index]: {
            ...item, time: '',
          },
        };

        return updatedItem;
      }))),
    }))
      .unwrap()
      .then(async ({ success,
        message }) => {
        if (success === 1) {
          navigate(Routes.Trials);
          successAlert(emptyString, message);
        } else {
          errorAlert(message);
        }
      })
      .catch(({ message }) => errorAlert(message));
  };

  const { openConfirmationModal } = useConfirmationModal();

  const onCancelEditing = () => openConfirmationModal({
    variant: ModalTypes.decline,
    title: dictionary.trials.confirmModals.title,
    text: dictionary.trials.confirmModals.text,
    submitButtonTitle: dictionary.table.ok,
    handleConfirmClick: () => navigate(Routes.Trials),
  });

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={createTrialValidationSchema(dictionary)}
      onSubmit={onSubmitForm}
    >
      <Form className="form" noValidate>
        <div className="form-body">
          <Section title={dictionary.trials.modals.createTrial.title}>
            <section className="form-section">
              <h2 className="form-title title-md is-underlined">{dictionary.trials.modals.createTrial.generalInformation}</h2>
            </section>
            <TrialForm physiciansOptions={physiciansOptions} dictionary={dictionary} />
            <section className="form-section">
              <ScheduleForm dictionary={dictionary} visitValues={visitValues} />
            </section>
            <section className="form-section form-footer">
              <Button
                label={dictionary.actions.cancel}
                variant="default"
                size="static"
                handleClick={onCancelEditing}
              />
              <Button
                type="submit"
                label={dictionary.actions.create}
                size="static"
              />
            </section>
          </Section>
        </div>
      </Form>
    </Formik>
  );
};
