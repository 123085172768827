import React, { ReactNode } from 'react';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { Roles } from '@constants/roles';

export interface IRoleFormatterProps {
  admin: number;
  superAdmin: number;
  employee: number;
}

export const RoleFormatter = ({ admin, superAdmin, employee } : IRoleFormatterProps): ReactNode => {
  const { dictionary } = useLanguageContext();

  const getCurrentRole = () => {
    if (employee === 1 && superAdmin === 0 && admin === 0) {
      return Roles.staff;
    } if (superAdmin === 1 && employee === 1 && admin === 1) {
      return Roles.superAdmin;
    } if (admin === 1 && superAdmin === 0 && employee === 1) {
      return Roles.admin;
    }
    return Roles.staff;
  };

  return (
    <span>{dictionary.role[getCurrentRole()]}</span>
  );
};
