import { ReactNode } from 'react';

export enum SlotVariants {
  success = 'success',
  warning = 'warning',
  danger = 'danger',
  info = 'info',
  default = 'default'
}

export interface ISlotProps {
  details: ReactNode;
  shortDetails?: ReactNode;
  variant?: `${SlotVariants}`;
  handleClick?: () => void;
  eventTypes?: TEventTypes[] | [];
}

export type TEventTypes = {
  type: string;
  amount: number;
}
