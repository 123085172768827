import { NavigateOptions } from 'react-router/dist/lib/context';

export enum statusVariants {
  success = 'success',
  warning = 'warning',
  danger = 'danger',
}

export interface IStatusProps extends NavigateOptions {
  variant: `${statusVariants}`;
  text: string;
}
