import React, { FC } from 'react';
import { InvitationStatuses } from '@constants/invitationStatuses';

export interface INameFormatterProps {
  id: string;
  phone: string;
  registrationStatus?: string;
}

export const phoneFormatter: FC<INameFormatterProps> = ({ id, phone, registrationStatus = '' }) => {
  if (!id || registrationStatus === InvitationStatuses.danger || registrationStatus === InvitationStatuses.warning) {
    return <span className="table-cell-placeholder">-</span>;
  }

  return (
    <div className="table-name">
      <span>{phone}</span>
    </div>
  );
};
