import { ITableSettings } from '@components/Table/types';
import { TAppointment } from '@api/appointments/types';
import { statusFormatter } from '@pages/Appointments/components/utils/statusFormatter';
import { locationFormatter } from '@pages/Appointments/components/utils/locationFormatter';
import { physicianFormatter } from '@pages/Appointments/components/utils/physicianFormatter';
import { dateFormatter } from '@pages/Appointment/utils/dateFormatter';
import { IDictionary } from '@constants/languages/types';
import { nameFormatter } from '../../utils/nameFormatter';

export const tableSettings = (dictionary: IDictionary): ITableSettings<TAppointment>[] => [
  {
    accessor: 'name',
    title: 'Appointment name',
    formatter: nameFormatter,
  },
  {
    accessor: 'status',
    title: 'Status',
    formatter: (props) => statusFormatter({
      dictionary, status: props.status,
    }),
  },
  {
    accessor: 'date',
    title: 'Date',
    formatter: dateFormatter,
  },
  {
    accessor: 'time',
    title: 'Time',
  },
  {
    accessor: 'locations',
    title: 'Location',
    formatter: locationFormatter,
  },
  {
    accessor: 'trials',
    title: 'Physician\'s name (PI) ',
    formatter: physicianFormatter,
  },
  {
    accessor: 'serialNumber',
    title: 'Serial number',
  },
];
