import React, { FC, useEffect, useId, useState } from 'react';
import { ICheckboxProps } from '@components/Checkbox/types';
import { UilCheck } from '@iconscout/react-unicons';
import './styles.scss';
import { Field } from '@components/Field';
import { useField } from 'formik';
import { emptyString } from '@constants/emptyString';

export const Checkbox: FC<ICheckboxProps> = (props) => {
  const { id = emptyString, label = emptyString, showError, disabled = false, checked = false, handleChange } = props;

  const uniqId = useId();

  const fieldId = id || uniqId;

  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (handleChange) {
      handleChange(e);
    }
    setIsChecked(!isChecked);
  };

  const [field, meta] = useField(props);

  const checkboxClass = `checkbox ${disabled ? 'is-disabled' : ''}`;

  return (
    <Field
      id={fieldId}
      showError={showError !== undefined ? showError : meta.touched}
      error={meta.error}
    >
      <div className="field-control">
        <label className={checkboxClass} htmlFor={fieldId}>
          <input
            {...field}
            type="checkbox"
            id={fieldId}
            className="checkbox-input visually-hidden"
            {...props}
            checked={isChecked}
            onChange={onChangeHandler}
          />
          <div className="checkbox-mark">
            {isChecked && <UilCheck size={16} />}
          </div>
          {label && <div className="checkbox-label">{label}</div>}
        </label>
      </div>
    </Field>
  );
};
