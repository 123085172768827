import * as Yup from 'yup';
import { IDictionary } from '@constants/languages/types';

const schedulesValidationSchema = (dictionary: IDictionary) => Yup.object({
  protocolName: Yup.string()
    .required(dictionary.validation.protocolName)
    .max(50, dictionary.validation.protocolNameMax),
  protocolNumber: Yup.string()
    .required(dictionary.validation.protocolNumber)
    .nullable()
    .max(50, dictionary.validation.protocolNumberMax),
  sponsorName: Yup.string()
    .required(dictionary.validation.sponsorName)
    .max(100, dictionary.validation.sponsorNameMax),
  physicianId: Yup.string()
    .required(dictionary.validation.physicianName)
    .max(100, dictionary.validation.invalidPhysicianName),
});

export default schedulesValidationSchema;
