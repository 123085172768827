import { UilBell } from '@iconscout/react-unicons';
import { useGetCountOfRejectedQuery } from '@api/appointments/appointmentsApi';
import './styles.scss';
import { useLanguageContext } from '@hooks/useLanguageContext';

export const Notifications = () => {
  const { data: countOfNotification } = useGetCountOfRejectedQuery();

  const { dictionary } = useLanguageContext();

  return (
    <div
      className="notifications"
      role="button"
      tabIndex={0}
    >
      <span className="notifications-control">
        <span className="notifications-amount">{countOfNotification?.count || 0}</span>
        <UilBell size={24} />
      </span>
      <div className="notifications-tooltip">
        {`${dictionary.labels.youHave} ${countOfNotification?.count || 0} ${dictionary.labels.rejectedAppointments}`}
      </div>
    </div>
  );
};
