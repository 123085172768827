import React, { FC } from 'react';
import { Form, Formik, FormikValues } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Button } from '@components/Button';
import { useConfirmationModal } from '@components/Confirm/context/useConfirmContext';
import { ModalTypes } from '@components/Confirm/types';
import { InputField } from '@components/Form/InputField';
import { PhoneField } from '@components/Form/PhoneField';
import { Info } from '@components/Info';
import { Section } from '@components/Section';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { useAddNewPhysiciansMutation } from '@api/physicians/physiciansAPI';
import { errorAlert } from '@helpers/alerts/alerts';
import { WorkingHours } from '@pages/Physician/pages/AddPhysician/components/WorkingHours';
import { TPhysician } from '@pages/Physicians/components/PhysiciansTable/types';
import physicianValidationSchema from '@helpers/validationSchemas/physicianValidationSchema';
import { Routes } from '../../../../../../router';

export const AddPhysicianForm: FC = () => {
  const { dictionary } = useLanguageContext();

  const navigate = useNavigate();

  const [createPhysician] = useAddNewPhysiciansMutation();

  const initialValues = {
    name: '',
    phone: '',
    schedule: {
      sunday: [],
      monday: [
        {
          from: '',
          to: '',
        },
      ],
      tuesday: [
        {
          from: '',
          to: '',
        },
      ],
      wednesday: [
        {
          from: '',
          to: '',
        },
      ],
      thursday: [
        {
          from: '',
          to: '',
        },
      ],
      friday: [
        {
          from: '',
          to: '',
        },
      ],
      saturday: [],
    },
  };

  const { openConfirmationModal } = useConfirmationModal();

  const onCancelClick = () => openConfirmationModal({
    variant: ModalTypes.decline,
    title: dictionary.physicians.modals.cancelAddingPhysician.title,
    text: dictionary.physicians.modals.cancelAddingPhysician.text,
    submitButtonTitle: dictionary.table.ok,
    handleConfirmClick: () => navigate(Routes.Physicians),
  });

  const onSubmitForm = async (formValues: FormikValues) => {
    createPhysician(formValues as TPhysician)
      .unwrap()
      .then(async ({ success,
        message }) => {
        if (success === 1) {
          navigate(Routes.Physicians);
        } else {
          errorAlert(message);
        }
      })
      .catch(({ message }) => errorAlert(message));
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={physicianValidationSchema(dictionary)}
      onSubmit={onSubmitForm}
    >
      {({ errors }) => (
        <Form className="form" noValidate>
          <div className="form-body">
            <Section title={dictionary.physicians.modals.addPhysician.title}>
              <section className="form-section">
                <h2 className="form-title title-md is-underlined">
                  {dictionary.physicians.modals.addPhysician.generalInformation}
                </h2>
                <div className="row">
                  <div className="col-xs-12">
                    <InputField
                      type="text"
                      placeholder={dictionary.placeholders.enterPhysicianName}
                      label={dictionary.labels.physicianName}
                      required
                      name="name"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <PhoneField
                      type="number"
                      placeholder={dictionary.placeholders.phoneMask}
                      label={dictionary.labels.physicianPhone}
                      required
                      name="phone"
                    />
                  </div>
                </div>
              </section>
              <section className="form-section">
                <h2 className="form-title title-md is-underlined">
                  {dictionary.physicians.modals.addPhysician.workingHours}
                </h2>
                <div className="working-hours">
                  {!!Object.keys(errors).length && <Info text={dictionary.physician.modals.editPhysician.errorTitle} variant="warning" />}
                  <ul className="working-hours-list">
                    {Object.keys(initialValues.schedule)
                      .map((day) => <WorkingHours day={day} dictionary={dictionary} />)}
                  </ul>
                </div>
              </section>
              <section className="form-section form-footer">
                <Button
                  label={dictionary.actions.cancel}
                  variant="default"
                  size="static"
                  handleClick={onCancelClick}
                />
                <Button
                  label={dictionary.table.add}
                  size="static"
                  type="submit"
                />
              </section>
            </Section>
          </div>
        </Form>
      )}
    </Formik>
  );
};
