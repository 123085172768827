import { Roles } from '@constants/roles';
import { IUser } from '@store/types';
import { TUserWithParents } from '@api/user/types';

export const getUserRole = (user: IUser | TUserWithParents | null | undefined, token: string | null | undefined) => {
  if (token) {
    if (user?.employee === 1 && user?.super_admin === 0 && user?.admin === 0) {
      return Roles.staff;
    } if (user?.super_admin === 1 && user?.employee === 1 && user?.admin === 1) {
      return Roles.superAdmin;
    } if (user?.admin === 1 && user?.super_admin === 0 && user?.employee === 1) {
      return Roles.admin;
    }
    return Roles.patient;
  }
  return Roles.guest;
};
