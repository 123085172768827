import React, { FC, useRef, useState } from 'react';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import { Button } from '@components/Button';
import { useConfirmationModal } from '@components/Confirm/context/useConfirmContext';
import { Info } from '@components/Info';
import { useModal } from '@components/Modal/context/useModalContext';
import { Section } from '@components/Section';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { IUpdateUser } from '@api/patients/types';
import {
  useChangeEmailMutation,
  useChangePasswordMutation,
  useChangePhoneMutation,
  useChangeUserDataMutation,
  useVerifyNewPhoneMutation,
} from '@api/user/userAPI';
import { errorAlert, successAlert } from '@helpers/alerts/alerts';
import { replaceObjectKey } from '@helpers/replaceObjectKey';
import { Loader } from '@components/Loader';
import { ParentProfile } from '@pages/Profile/components/ProfileForm/ParentProfile';
import { UserProfile } from '@pages/Profile/components/ProfileForm/UserProfile';
import { changeEmailModal } from '@pages/Profile/ModalContent/ChangeEmail/ChangeEmailModal';
import { changePasswordModal } from '@pages/Profile/ModalContent/ChangePassword/ChangePasswordModal';
import { changePhoneModal } from '@pages/Profile/ModalContent/ChangePhone/ChangePhoneModal';
import 'react-datepicker/dist/react-datepicker.css';
import { ILoggedInUser } from '@api/user/types';
import { loadState } from '@store/browserStorage';
import { IUserData } from '@store/types';
import { persistAuth } from '@constants/persist';
import { useFetchPatientByIdQuery } from '@api/patients/patientsAPI';
import { convertToCamelCase } from '@helpers/convertToCamelCase';
import { emptyString } from '@constants/emptyString';
import changeProfileValidationSchemaWithParent
  from '@helpers/validationSchemas/changeProfileValidationSchemaWithParent';
import changeProfileValidationSchema from '@helpers/validationSchemas/changeProfileValidationSchema';
import { getUserRole } from '@helpers/getUserRole';

export const ProfileForm: FC = () => {
  const { dictionary } = useLanguageContext();

  const { openModal } = useModal();

  const verification = useRef<Record<string, unknown> | null>(null);

  const [changeUser] = useChangeUserDataMutation();

  const currentUser: ILoggedInUser = JSON.parse(loadState<IUserData>(persistAuth)?.auth as unknown as string);

  const { data: userInfo, refetch: refetchUser, isLoading } = useFetchPatientByIdQuery(currentUser.user.id, {
    refetchOnMountOrArgChange: true,
  });

  const userData = convertToCamelCase(userInfo);

  const userParents = userData?.parents?.find((parents: any) => parents);

  const isStaff = !!userData?.employee;

  const userRole = getUserRole(currentUser.user, currentUser.token);

  const isUserWithParents = userData?.parents?.length;

  const isAccountVerified = !!userData?.verified;

  const isPhoneVerified = !!userData?.phone?.length;

  const initialValues = {
    name: userData?.name,
    surname: userData?.surname,
    gender: userData?.gender,
    dateOfBirth: userData?.dateOfBirth,
  };

  const initialValuesWithParents = {
    ...initialValues,
    parentName: userParents?.name,
    parentSurname: userParents?.surname,
    parentEmail: userParents?.email,
    parentPhone: userParents?.phone,
  };

  const onChangeUserProfile = (formValues: IUpdateUser) => {
    changeUser(userParents
      ? {
        user: replaceObjectKey({
          name: formValues.name,
          surname: formValues.surname,
          gender: formValues.gender,
          dateOfBirth: format(new Date(String(formValues.dateOfBirth)), 'MM/dd/yyyy'),
        }, 'dateOfBirth', 'date_of_birth'),
        parent: [
          {
            id: userParents.id,
            user_id: userData?.id,
            name: formValues.parentName,
            surname: formValues.parentSurname,
          },
        ],
      }
      : {
        user: replaceObjectKey({
          ...formValues,
          dateOfBirth: format(new Date(String(formValues.dateOfBirth)), 'MM/dd/yyyy'),
        }, 'dateOfBirth', 'date_of_birth'),
      })
      .unwrap()
      .then(({ success,
        message }) => {
        if (success === 1) {
          successAlert(emptyString, message);
          refetchUser();
        } else {
          errorAlert(message);
        }
      })
      .catch(({ data }) => errorAlert(data.message));
  };

  const currentInitialValues = isUserWithParents ? initialValuesWithParents : initialValues;

  const currentValidationSchema = isUserWithParents ? changeProfileValidationSchemaWithParent(dictionary) : changeProfileValidationSchema(dictionary);

  const [changeEmail] = useChangeEmailMutation();

  const [changePassword] = useChangePasswordMutation();

  const [newPhone, setNewPhone] = useState<string>('');

  const [isError, setIsError] = useState<string>('');

  const phoneNumber = userData?.phone;

  const [changePhone] = useChangePhoneMutation();

  const [verifyNewPhone] = useVerifyNewPhoneMutation();

  const { openConfirmationModal } = useConfirmationModal();

  const changeUserEmail = () => changeEmailModal(openModal, openConfirmationModal, changeEmail, dictionary, userData?.email, userData?.id, refetchUser);

  const changeParentEmail = () => changeEmailModal(openModal, openConfirmationModal, changeEmail, dictionary, userParents?.email, userData?.id, refetchUser, changeUser, userParents?.id, !!userParents);

  const changeUserPhone = () => changePhoneModal(openModal, changePhone, verifyNewPhone, dictionary, setIsError, newPhone, setNewPhone, isError, phoneNumber, refetchUser, verification);

  const changeParentPhone = () => changePhoneModal(openModal, changePhone, verifyNewPhone, dictionary, setIsError, newPhone, setNewPhone, isError, userParents?.phone, refetchUser);

  return (
    <Formik
      enableReinitialize
      initialValues={currentInitialValues}
      validationSchema={currentValidationSchema}
      onSubmit={onChangeUserProfile}
    >
      {isLoading ? <Loader /> : (
        <Form className="form" noValidate>
          <div className="form-body">
            <Section title={dictionary.titles.profile}>
              <section className="form-section">
                <h2 className="form-title title-md is-underlined">{dictionary.profile.contactInformation}</h2>
                {!isStaff && <Info text={dictionary.profile.callTheClinic} />}
              </section>
              <UserProfile
                patientInformation={!!isUserWithParents}
                isStaff={userRole}
                userData={userData}
                isAccountVerified={isAccountVerified}
                changeEmailModal={changeUserEmail}
                isPhoneVerified={isPhoneVerified}
                changePhoneModal={changeUserPhone}
                dictionary={dictionary}
              />
              {(!!userParents
            && (
            <ParentProfile
              patientInformation={!!userParents}
              isStaff={!!userData?.employee}
              userData={userParents}
              changeEmailModal={changeParentEmail}
              isPhoneVerified={!!userParents?.phone}
              changePhoneModal={changeParentPhone}
            />
            )
          )}
              {isStaff && (
              <section className="form-section">
                <Button
                  label={dictionary.actions.saveChanges}
                  type="submit"
                  size="static"
                />
              </section>
              )}
              <section className="form-section">
                <h2 className="form-title title-md is-underlined">{dictionary.titles.security}</h2>
                <Button
                  label={dictionary.labels.changePassword}
                  size="static"
                  handleClick={() => changePasswordModal(openModal, changePassword, dictionary)}
                />
              </section>
            </Section>
          </div>
        </Form>
      )}
    </Formik>
  );
};
