import { ITableSettings } from '@components/Table/types';
import { WorkingHoursFormatter } from '@components/Table/utils/workingHoursFormatter';
import { TWorkingDays } from '@pages/Physicians/components/PhysiciansTable/types';

export const tableSettings: ITableSettings<TWorkingDays>[] = [
  {
    accessor: 'sunday',
    title: 'sunday',
    formatter: WorkingHoursFormatter,
  },
  {
    accessor: 'monday',
    title: 'monday',
    formatter: WorkingHoursFormatter,
  },
  {
    accessor: 'tuesday',
    title: 'tuesday',
    formatter: WorkingHoursFormatter,
  },
  {
    accessor: 'wednesday',
    title: 'wednesday',
    formatter: WorkingHoursFormatter,
  },
  {
    accessor: 'thursday',
    title: 'thursday',
    formatter: WorkingHoursFormatter,
  },
  {
    accessor: 'friday',
    title: 'friday',
    formatter: WorkingHoursFormatter,
  },
  {
    accessor: 'saturday',
    title: 'saturday',
    formatter: WorkingHoursFormatter,
  },
];
