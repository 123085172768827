import React from 'react';
import { NavList as navList } from './constants/navList';
import { NavList } from './navList';
import './styles.scss';

export const Nav = () => (
  <nav className="nav">
    <NavList menuList={navList()} />
  </nav>
);
