import { appointmentsAPI } from '@api/appointments/appointmentsApi';
import { locationsAPI } from '@api/locations/locationsAPI';
import { patientsAPI } from '@api/patients/patientsAPI';
import { physiciansAPI } from '@api/physicians/physiciansAPI';
import { trialAPI } from '@api/trial/trialAPI';
import { userAPI } from '@api/user/userAPI';
import { scheduleAPI } from '@api/schedule/scheduleAPI';
import { integrationsAPI } from '@api/integrationsApi/integrationsApi';
import { organizationsAPI } from '@api/organizations/organizationsAPI';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REHYDRATE,
} from 'redux-persist';
import { REGISTER } from 'redux-persist/es/constants';
import storage from 'redux-persist/lib/storage';
import authReducer from './auth/authSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  [userAPI.reducerPath]: userAPI.reducer,
  [trialAPI.reducerPath]: trialAPI.reducer,
  [patientsAPI.reducerPath]: patientsAPI.reducer,
  [physiciansAPI.reducerPath]: physiciansAPI.reducer,
  [locationsAPI.reducerPath]: locationsAPI.reducer,
  [appointmentsAPI.reducerPath]: appointmentsAPI.reducer,
  [scheduleAPI.reducerPath]: scheduleAPI.reducer,
  [integrationsAPI.reducerPath]: integrationsAPI.reducer,
  [organizationsAPI.reducerPath]: organizationsAPI.reducer,
});

const persistConfig = {
  key: 'auth',
  version: 1,
  storage,
  whiteList: ['userAPI'],
  blacklist: [
    'trialAPI',
    'patientsAPI',
    'organizationsAPI',
    'scheduleAPI',
    'physiciansAPI',
    'locationsAPI',
    'appointmentsAPI',
    'integrationsAPI',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const setupStore = () => configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  })
    .concat(
      userAPI.middleware,
      trialAPI.middleware,
      patientsAPI.middleware,
      physiciansAPI.middleware,
      locationsAPI.middleware,
      appointmentsAPI.middleware,
      scheduleAPI.middleware,
      integrationsAPI.middleware,
      organizationsAPI.middleware,
    ),
});

export const store = setupStore();
export const persistor = persistStore(store);

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
