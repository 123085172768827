import React, { FC } from 'react';
import { ISectionProps } from '@components/Section/types';
import { statusFormatter } from '@pages/Appointments/components/utils/statusFormatter';
import { Container } from '../Container';
import './styles.scss';

export const Section:FC<ISectionProps> = (props) => {
  const { title = '',
    controls = null,
    status,
    ref,
    containerSize,
    children } = props;

  const showHeading = title || controls;

  return (
    <section className="section">
      <Container size={containerSize}>
        {showHeading && (
        <div className="section-heading" ref={ref}>
          <div className="section-title">
            {title && <h2 className="title-xl">{title}</h2>}
            {status && statusFormatter({
              status,
            })}
          </div>
          {controls && controls}
        </div>
        )}
        {children}
      </Container>
    </section>
  );
};
