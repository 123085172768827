import React, { FC } from 'react';
import { IContainerProps } from '@components/Container/types';
import './styles.scss';

export const Container:FC<IContainerProps> = ({ children,
  size,
  isStretched }) => {
  const containerClasses = `container-${size && !isStretched ? size : 'fluid'}`;

  return (
    <div className={containerClasses}>
      {children}
    </div>
  );
};
