import React from 'react';
import { Outlet } from 'react-router-dom';
import { PrivacyHeader } from './components/Header/privacyHeader';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';

export const PrivacyLayout = () => (
  <>
    <PrivacyHeader />
    <main className="main">
      <Outlet />
    </main>
  </>
);
