import React, { Dispatch, FC, MouseEvent, TouchEvent, useState } from 'react';
import { Form } from 'formik';
import VerificationInput from 'react-verification-input';
import { PhoneField } from '@components/Form/PhoneField';
import { Info } from '@components/Info';
import { ResendCode } from '@components/ResendCode/ResendCode';
import { IChangePhone } from '@api/patients/types';
import { IResponse } from '@api/user/types';
import { errorAlert } from '@helpers/alerts/alerts';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition,
} from '@reduxjs/toolkit/query';
import { emptyString } from '@constants/emptyString';
import { IDictionary } from '@constants/languages/types';

export const phoneInitialValues = (phoneNumber?: string, verificationCode?: string) => ({
  phoneNumber,
  newPhone: '',
  code: verificationCode,
});

interface IChangePhoneFormContentProps {
  dictionary: IDictionary,
  changePhone: MutationTrigger<MutationDefinition<IChangePhone, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta>, 'Users', IResponse, 'userAPI'>>,
  setNewPhone: Dispatch<string>,
  isError: string,
  userId?: string,
  phoneNumber?: string,
  verificationRef?: any
}

export const ChangePhoneFormContent:FC<IChangePhoneFormContentProps> = (
  { dictionary,
    changePhone,
    setNewPhone,
    userId,
    phoneNumber,
    verificationRef,
    isError },
) => {
  const [verificationCode, setVerificationCode] = useState<string>('');

  const [isVerifyPhone, setVerifyPhone] = useState<boolean>(false);

  const [phone, setPhone] = useState<string>('');

  const onPhoneVerificationClick = (e: MouseEvent<HTMLElement> | TouchEvent<HTMLElement>, newPhoneNumber?: string) => {
    if (newPhoneNumber?.length) {
      setPhone(newPhoneNumber.replace(/[-()\s]/g, ''));
      setNewPhone(newPhoneNumber);
      changePhone(userId ? {
        userId,
        newPhone: newPhoneNumber.replace(/[-()\s]/g, ''),
      } : {
        newPhone: newPhoneNumber.replace(/[-()\s]/g, ''),
        phone: phoneNumber?.replace(/[-()\s]/g, ''),
      })
        .unwrap().then(() => setVerifyPhone(true))
        .catch(({ data }) => errorAlert(emptyString, data.message));
    } else {
      errorAlert(dictionary.messages.error);
    }
  };

  return (
    <>
      <div className="form-section">
        {!!isError.length && (
          <div className="row">
            <div className="col-xs-12">
              <Info variant="danger" text={isError} />
            </div>
          </div>
        )}
        <Form className="form" noValidate>
          <div className="form-field">
            <PhoneField
              isDisabled
              type="number"
              value={phoneNumber}
              placeholder={dictionary.placeholders.phoneMask}
              label={dictionary.labels.phoneNumber}
              required
              name="phoneNumber"
            />
          </div>
          <div className="form-field">
            <PhoneField
              type="number"
              placeholder={dictionary.placeholders.phoneMask}
              label={dictionary.labels.phoneNumber}
              required
              name="newPhone"
              showVerification
              handleVerificationClick={onPhoneVerificationClick}
            />
          </div>
        </Form>
      </div>
      {isVerifyPhone && (
      <div className="form-section">
        <div className="verification">
          <p className="verification-text text text-semibold">{dictionary.patient.modals.editPatientPhone.enterTextMessage}</p>
          <div className="verification-form">
            <VerificationInput ref={verificationRef} validChars="0-9" value={verificationCode} autoFocus onChange={setVerificationCode} />
          </div>
          <ResendCode isModalVisible={isVerifyPhone} phone={phone} setCode={setVerificationCode} />
        </div>
      </div>
      )}
    </>
  );
};
