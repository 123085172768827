import React, { ReactNode } from 'react';
import { isSameDay } from 'date-fns';
import { splitMonthByWeeks } from '@helpers/splitMonthByWeeks';
import { MonthSlot } from '@pages/Schedule/components/Month/MonthSlot';
import { useScheduleContext } from '@pages/Schedule/context/useScheduleContext';
import { calcAmountByProperty } from '@pages/Schedule/utils/calcAmountByProperty';
import { TEventTypes } from '@pages/Schedule/components/Slot/types';
import { IScheduleResponse } from '@api/schedule/types';
import { TAppointment } from '@api/appointments/types';

interface IMonthBodyProps {
  appointments: IScheduleResponse | undefined,
}

export const MonthBody = ({ appointments }: IMonthBodyProps) => {
  const { selectedDate } = useScheduleContext();

  const tableData = splitMonthByWeeks(selectedDate);

  const scheduleData: TAppointment[] | undefined = appointments?.data;

  const tableBody = tableData.reduce<ReactNode[]>((acc, currentWeek, rowIndex) => {
    const tableRow = currentWeek.map(({ value }) => {
      const currentDateAppointments = scheduleData?.filter(({ date }) => isSameDay(new Date(date), value));

      const appointmentsAmount = currentDateAppointments?.length;

      const groupedAppointmentsByType = currentDateAppointments?.length ? calcAmountByProperty(currentDateAppointments, 'type') : [];

      return (
        <MonthSlot
          date={value}
          appointmentsAmount={appointmentsAmount}
          eventTypes={groupedAppointmentsByType as TEventTypes[]}
        />
      );
    });

    return [...acc,
      <div
        className="schedule-row table-row"
        key={`table-row-${rowIndex}`}
        style={{
          ['height' as string]: `${100 / tableData.length}%`,
        }}
      >
        { tableRow }
      </div>,
    ];
  }, []);

  return <div className="schedule-body table-body">{ tableBody }</div>;
};
