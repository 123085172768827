import { useContext } from 'react';
import { MenuContext } from './MenuContext';

export const useMenu = () => {
  const context = useContext(MenuContext);

  if (!context) {
    throw new Error('useMenu used outside MenuContext');
  }

  return context;
};
