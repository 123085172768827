import React, { FC } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { useConfirmationModal } from '@components/Confirm/context/useConfirmContext';
import { Table } from '@components/Table';
import { IAction } from '@components/Table/utils/actionsFormatter';
import { useLanguageContext } from '@hooks/useLanguageContext';
import {
  useDeletePhysiciansMutation,
  useSearchPhysiciansQuery,
} from '@api/physicians/physiciansAPI';
import { convertToCamelCase } from '@helpers/convertToCamelCase';
import { tableSettings } from '@pages/Physicians/components/PhysiciansTable/constants/tableSettings';
import { IPhysiciansTableProps, TPhysician } from '@pages/Physicians/components/PhysiciansTable/types';
import { ModalTypes } from '@components/Confirm/types';

export const PhysiciansTable: FC<IPhysiciansTableProps> = ({ searchTitle, next, refreshFunction, dataLength, data: physicians }) => {
  const { openConfirmationModal } = useConfirmationModal();

  const { dictionary } = useLanguageContext();

  const { data: foundPhysicians } = useSearchPhysiciansQuery(searchTitle ?? skipToken);

  const [deletePhysician] = useDeletePhysiciansMutation();

  const onDeletePhysician = ({ name, id }: TPhysician) => {
    openConfirmationModal({
      variant: ModalTypes.decline,
      title: dictionary.physicians.modals.deletePhysician.title,
      text: `${dictionary.physicians.modals.deletePhysician.text} ${name}? ${dictionary.physicians.modals.deletePhysician.text2}`,
      submitButtonTitle: dictionary.table.delete,
      handleConfirmClick: () => deletePhysician(id),
    });
  };

  const tableActions: IAction<TPhysician>[] = [
    {
      type: 'delete',
      handleClick: (row) => onDeletePhysician(row),
    },
  ];

  const physicianData = (data: TPhysician[]) => data?.reduce<TPhysician[]>((acc, current) => {
    const { id,
      name,
      phone,
      schedule } = current;

    const physician = {
      id,
      name,
      phone,
      ...schedule,
    };

    return [...acc, physician];
  }, [] as TPhysician[]);

  return (
    <Table
      tableData={searchTitle.length ? physicianData(convertToCamelCase(foundPhysicians?.data.results)) : physicianData(convertToCamelCase(physicians))}
      tableSettings={tableSettings}
      actions={tableActions}
      search={!!searchTitle}
      next={next}
      dataLength={dataLength}
      refreshFunction={refreshFunction}
      dictionaryKey="physicians"
      emptyText={dictionary.physicians.table.noData}
    />
  );
};
