import React from 'react';
import { Section } from '@components/Section';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { Heading } from '../../layout/AuthLayout/components/Heading';
import { SignInForm } from './components/SignInForm';

export const SignIn = () => {
  const { dictionary } = useLanguageContext();

  return (
    <Section containerSize="sm">
      <Heading>
        {dictionary.titles.welcome}
        <br />
        <strong className="text-primary">
          {dictionary.titles.schedulapp}
        </strong>
      </Heading>
      <SignInForm />
    </Section>
  );
};
