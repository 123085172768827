import React, { FC } from 'react';
import { PhoneField } from '@components/Form/PhoneField';
import { SelectField } from '@components/Form/SelectField';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { IOptions } from '@components/Select/types';
import { Info } from '@components/Info';

interface IGeneralInformationFormProps {
  locationOptions?: IOptions[],
}

export const EditAppointmentForm:FC<IGeneralInformationFormProps> = ({ locationOptions }) => {
  const { dictionary } = useLanguageContext();

  return (
    <section className="form-section">
      <div className="row">
        <div className="col-xs-12">
          <Info text={dictionary.appointments.modals.editGeneralInfo.info} />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <PhoneField
            type="text"
            placeholder={dictionary.placeholders.phoneMask}
            label={dictionary.labels.phoneNumber}
            required
            name="contactPhone"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <SelectField
            selectOptions={locationOptions}
            placeholder={dictionary.placeholders.chooseLocation}
            label={dictionary.labels.location}
            required
            name="locationId"
          />
        </div>
      </div>
    </section>
  );
};
