import React from 'react';
import { FormikValues } from 'formik';
import { FiltersForm } from '@pages/Schedule/components/Filters/FiltersForm';
import { IModalProps } from '@components/Modal/types';
import { filtersInitialValues } from '@pages/Schedule/components/Filters/index';
import { IDictionary } from '@constants/languages/types';

export const filtersModal = (
  openModal: (state: IModalProps) => void,
  onSubmit: (formValues: FormikValues) => void,
  dictionary: IDictionary,
) => {
  const onSubmitForm = async (formValues: FormikValues, closeModal:()=> void) => {
    onSubmit(formValues);
    closeModal();
  };

  return openModal({
    children: <FiltersForm />,
    initialValues: filtersInitialValues,
    validationSchema: null,
    title: dictionary.schedule.filters.title,
    submitButtonTitle: dictionary.actions.apply,
    closeButtonTitle: dictionary.actions.reset,
    onSubmit: onSubmitForm,
  });
};
