import { LabelVariants } from '@components/Label/types';

export const InvitationStatuses: Record<LabelVariants, string> = {
  danger: 'Invite expired',
  warning: 'Invite pending',
  success: 'Invite accepted',
  info: 'Registered manually',
};

export const AppointmentStatuses: Record<LabelVariants, string> = {
  danger: 'Rejected',
  warning: 'Pending',
  success: 'Approved',
  info: '',
};
