import React, { FC } from 'react';
import { useConfirmationModal } from '@components/Confirm/context/useConfirmContext';
import { ModalTypes } from '@components/Confirm/types';
import { Table } from '@components/Table';
import { TableLegend } from '@components/Table/components/TableLegend';
import { IAction } from '@components/Table/utils/actionsFormatter';
import { statusFormatter } from '@components/Table/utils/statusFormatter';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { useDeletePatientMutation, useSearchPatientsQuery } from '@api/patients/patientsAPI';
import { useResendInviteMutation } from '@api/user/userAPI';
import { InvitationStatuses } from '@constants/invitationStatuses';
import { errorAlert, successAlert } from '@helpers/alerts/alerts';
import { convertToCamelCase } from '@helpers/convertToCamelCase';
import { tableSettings } from '@pages/Patients/components/PatientsTable/constants/tableSettings';
import { IPatientsTableProps, TPatient } from '@pages/Patients/components/PatientsTable/types';
import { skipToken } from '@reduxjs/toolkit/query';

export const PatientsTable: FC<IPatientsTableProps> = ({ searchTitle, next, refreshFunction, dataLength, data: patients }) => {
  const { openConfirmationModal } = useConfirmationModal();

  const { dictionary } = useLanguageContext();

  const { data: foundPatients } = useSearchPatientsQuery(searchTitle ?? skipToken);

  const [resendInvite] = useResendInviteMutation();

  const [deletePatient] = useDeletePatientMutation();

  const onDeleteUser = ({ name, surname, id }: TPatient) => {
    openConfirmationModal({
      variant: ModalTypes.decline,
      title: dictionary.patients.modals.deletePatient.title,
      text: `${dictionary.patients.modals.deletePatient.confirmationText}`,
      submitButtonTitle: dictionary.table.delete,
      handleConfirmClick: () => deletePatient({
        id,
      }),
    });
  };

  const onResendAppointment = (patient: TPatient) => {
    resendInvite(patient.id).unwrap().then(({ success, message }) => {
      if (success === 1) {
        successAlert(message);
        refreshFunction();
      } else {
        errorAlert(message);
      }
    }).catch(({ data }) => errorAlert(data.message));
  };

  const onCheckRegistrationStatus = ({ registrationStatus }: TPatient): boolean => {
    const inviteExpired = 'Invite expired';

    const invitePending = 'Invite pending';

    return registrationStatus === inviteExpired || registrationStatus === invitePending;
  };

  const tableActions: IAction<TPatient>[] = [
    {
      type: 'refresh',
      handleClick: (row) => onResendAppointment(row),
      showCondition: (row) => onCheckRegistrationStatus(row),
    },
    {
      type: 'delete',
      handleClick: (row) => onDeleteUser(row),
    },
  ];

  return (
    <>
      <TableLegend items={Object.values(InvitationStatuses)} formatter={statusFormatter} title={dictionary.patients.table.legendTitle} />
      <Table
        dataLength={dataLength}
        tableData={searchTitle.length ? convertToCamelCase(foundPatients?.data.results) : convertToCamelCase(patients)}
        tableSettings={tableSettings}
        actions={tableActions}
        search={!!searchTitle}
        dictionaryKey="patients"
        next={next}
        refreshFunction={refreshFunction}
      />
    </>
  );
};
