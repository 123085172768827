import React, { FC, useEffect, useState } from 'react';
import emptySearch from '@components/Table/img/empty-data.svg';
import emptyData from '@components/Table/img/empty-search.svg';
import { useLanguageContext } from '@hooks/useLanguageContext';

export enum TableEmptyIcon {
  search = 'search',
  data = 'data',
}

export interface ITableEmptyProps {
  emptyType?: `${TableEmptyIcon}`;
  emptyText?: string;
}

export const TableEmpty: FC<ITableEmptyProps> = ({ emptyType = TableEmptyIcon.data, emptyText = '' }) => {
  const { dictionary } = useLanguageContext();

  const icon = emptyType === TableEmptyIcon.data ? emptySearch : emptyData;

  const [noDataText, setNoDataText] = useState('');

  useEffect(() => {
    if (emptyType === TableEmptyIcon.search) {
      setNoDataText(dictionary.table.noResults);
      return;
    }

    if (!emptyText) {
      setNoDataText(dictionary.table.noData);
      return;
    }

    setNoDataText(emptyText);
  }, [emptyText]);

  return (
    <div className="table-empty">
      <img className="table-empty-icon" src={`${icon}`} alt={noDataText} />
      {!!noDataText.length && <strong className="table-empty-title title-md">{noDataText}</strong>}
    </div>
  );
};
