import { format } from 'date-fns';
import {
  IChangeEmail,
  IChangePassword,
  IChangePhone, IEmployeeInvite,
  IInvite,
  IInviteEmail,
  IPhoneVerifying,
} from '@api/patients/types';
import {
  IForgotPassword,
  IResponse,
  ISetPassword,
  IUserLogin,
  IUserResponse,
  IVerify,
  TUserWithParents,
} from '@api/user/types';
import { RequestRoutes } from '@constants/requestRoutes';
import { apiHeader, headerJson } from '@helpers/commonHeaders';
import { convertToCamelCase } from '@helpers/convertToCamelCase';
import { replaceObjectKey } from '@helpers/replaceObjectKey';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IAdminRegistration, IUserRegistration } from '@store/types';
import { userTag } from '@constants/apiTags';
import { language } from '@constants/language';

const lang = localStorage.getItem(language)?.slice(1, 3) || 'en';

export const userAPI = createApi({
  reducerPath: 'userAPI',
  tagTypes: [userTag],
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_API_URL}`,
    prepareHeaders: apiHeader,
  }),
  refetchOnFocus: true,
  refetchOnReconnect: true,
  refetchOnMountOrArgChange: true,
  endpoints: (build) => ({
    fetchUserById: build.query<TUserWithParents, string | undefined>({
      query: (id) => `${RequestRoutes.User}/${id}`,
      transformResponse(response: TUserWithParents) {
        return convertToCamelCase(response);
      },
    }),
    createUser: build.mutation<IResponse, { userData: IUserRegistration, invitation?: boolean | undefined, token: string| null}>({
      query: ({ userData,
        invitation, token }) => ({
        url: `${RequestRoutes.User}${RequestRoutes.Register}`,
        headers: headerJson,
        method: 'POST',
        params: {
          invitation,
          token,
          lang,
        },
        body: userData.parentPhone?.length
          ? replaceObjectKey({
            ...userData,
            phone: userData.phone.replace(/[-()\s]/g, ''),
            parentPhone: userData?.parentPhone.replace(/[-()\s]/g, ''),
            dateOfBirth: format(new Date(userData.dateOfBirth), 'MM/dd/yyyy'),
          }, 'dateOfBirth', 'date_of_birth')
          : replaceObjectKey({
            ...userData,
            phone: userData.phone.replace(/[-()\s]/g, ''),
            dateOfBirth: format(new Date(userData.dateOfBirth), 'MM/dd/yyyy'),
          }, 'dateOfBirth', 'date_of_birth'),
      }),
      invalidatesTags: [userTag],
    }),
    createAdmin: build.mutation<IResponse, { userData: IAdminRegistration, token: string| null}>({
      query: ({ userData,
        token }) => ({
        url: `${RequestRoutes.Organization}${RequestRoutes.EmployeeRegistration}`,
        headers: headerJson,
        method: 'POST',
        params: {
          token,
          lang,
        },
        body: {
          ...userData,
          phone: userData.phone.replace(/[-()\s]/g, ''),
        },
        invalidatesTags: [userTag],
      }),
    }),
    userVerifying: build.query<IResponse, IVerify>({
      query: ({ email,
        token }) => `${RequestRoutes.Verifying}?tk=${token}&email=${email}`,
    }),
    loginUser: build.mutation<IUserResponse, IUserLogin>({
      query: (userData: IUserLogin) => ({
        url: `${RequestRoutes.User}${RequestRoutes.Login}`,
        headers: headerJson,
        method: 'POST',
        body: userData,
        params: {
          lang,
        },
      }),
      invalidatesTags: [userTag],
    }),
    logout: build.query<IResponse, string>({
      query: (method) => ({
        url: `${RequestRoutes.User}${RequestRoutes.Logout}`,
        headers: headerJson,
        method,
        params: {
          lang,
        },
      }),
    }),
    phoneVerifying: build.mutation<IResponse, IPhoneVerifying>({
      query: (phone: IPhoneVerifying) => ({
        url: `${RequestRoutes.Sms}${RequestRoutes.Registration}`,
        headers: headerJson,
        method: 'POST',
        body: {
          ...phone,
          phone: phone.phone.replace(/[-()\s]/g, ''),
        },
        params: {
          lang,
        },
      }),
      invalidatesTags: [userTag],
    }),
    resendCode: build.mutation<IResponse, IPhoneVerifying>({
      query: (phone: IPhoneVerifying) => ({
        url: `${RequestRoutes.Sms}${RequestRoutes.Registration}${RequestRoutes.ResendSms}`,
        headers: headerJson,
        method: 'POST',
        body: {
          phone: phone.phone.replace(/[-()\s]/g, ''),
        },
        params: {
          lang,
        },
      }),
      invalidatesTags: [userTag],
    }),
    smsVerifying: build.mutation<IResponse, IPhoneVerifying>({
      query: (code: IPhoneVerifying) => ({
        url: `${RequestRoutes.Sms}${RequestRoutes.Registration}${RequestRoutes.Verifying}`,
        headers: headerJson,
        method: 'POST',
        body: {
          code: Number(code.code),
          phone: code.phone.replace(/[-()\s]/g, ''),
        },
        params: {
          lang,
        },
      }),
      invalidatesTags: [userTag],
    }),
    forgotPassword: build.mutation<IResponse, IForgotPassword>({
      query: (email: IForgotPassword) => ({
        url: `${RequestRoutes.RecoverPassword}${RequestRoutes.SendEmail}`,
        headers: headerJson,
        method: 'POST',
        body: email,
        params: {
          lang,
        },
      }),
      invalidatesTags: [userTag],
    }),
    setNewPassword: build.mutation<IResponse, ISetPassword>({
      query: (passwords: ISetPassword) => ({
        url: `${RequestRoutes.RecoverPassword}${RequestRoutes.SetNewPassword}`,
        headers: headerJson,
        method: 'POST',
        body: passwords,
        params: {
          lang,
        },
      }),
      invalidatesTags: [userTag],
    }),
    resendVerifyEmail: build.mutation<IResponse, IInviteEmail>({
      query: (email: IInviteEmail) => ({
        url: RequestRoutes.SendVerifyingEmail,
        headers: headerJson,
        method: 'POST',
        body: email,
        params: {
          lang,
        },
      }),
      invalidatesTags: [userTag],
    }),
    sendInvite: build.mutation<IResponse, IInvite>({
      query: (invite: IInvite) => ({
        url: `${RequestRoutes.User}${RequestRoutes.SendInvite}`,
        headers: headerJson,
        method: 'POST',
        body: invite,
        params: {
          lang,
        },
      }),
      invalidatesTags: [userTag],
    }),
    changePassword: build.mutation<IResponse, IChangePassword>({
      query: (password: IChangePassword) => ({
        url: RequestRoutes.ChangePassword,
        headers: headerJson,
        method: 'POST',
        body: password,
        params: {
          lang,
        },
      }),
      invalidatesTags: [userTag],
    }),
    changeEmail: build.mutation<IResponse, IChangeEmail>({
      query: (email: IChangeEmail) => ({
        url: `${RequestRoutes.Profile}${RequestRoutes.ChangeEmail}`,
        headers: headerJson,
        params: {
          patientId: email.userId ? email.userId : null,
          lang,
        },
        method: 'POST',
        body: {
          currentEmail: email.currentEmail,
          newEmail: email.newEmail,
        },
      }),
      invalidatesTags: [userTag],
    }),
    changePhone: build.mutation<IResponse, IChangePhone>({
      query: (phone: IChangePhone) => ({
        url: `${RequestRoutes.Profile}${RequestRoutes.SendSmsToNewPhone}`,
        headers: headerJson,
        method: 'POST',
        params: {
          patientId: phone.userId ? phone.userId : undefined,
          lang,
        },
        body: {
          newPhone: phone.newPhone?.replace(/[-()\s]/g, ''),
        },
      }),
      invalidatesTags: [userTag],
    }),
    verifyNewPhone: build.mutation<IResponse, IChangePhone>({
      query: (phone: IChangePhone) => ({
        url: `${RequestRoutes.Profile}${RequestRoutes.VerifyNewPhone}`,
        headers: headerJson,
        method: 'POST',
        params: {
          patientId: phone.userId ? phone.userId : undefined,
          lang,
        },
        body: {
          code: phone.code,
        },
      }),
      invalidatesTags: [userTag],
    }),
    changeUserData: build.mutation<IUserResponse, any>({
      query: (userData: any) => userData && ({
        url: `${RequestRoutes.Profile}${RequestRoutes.ChangeCommonInfo}`,
        headers: headerJson,
        method: 'POST',
        body: userData,
        params: {
          lang,
        },
      }),
      invalidatesTags: [userTag],
    }),
    resendInvite: build.mutation<IResponse, string>({
      query: (userId: string) => ({
        url: `${RequestRoutes.User}${RequestRoutes.ResendInviteLetter}`,
        headers: headerJson,
        method: 'POST',
        body: {
          id: userId,
        },
        params: {
          lang,
        },
      }),
      invalidatesTags: [userTag],
    }),
    sendEmployeeInvite: build.mutation<IResponse, IEmployeeInvite>({
      query: (invite) => ({
        url: `${RequestRoutes.Organization}${RequestRoutes.SendInviteToEmployee}`,
        headers: headerJson,
        method: 'POST',
        body: invite,
        params: {
          lang,
        },
      }),
      invalidatesTags: [userTag],
    }),
    resendEmployeeInvite: build.mutation<IResponse, string>({
      query: (userId: string) => ({
        url: `${RequestRoutes.Organization}${RequestRoutes.ResendInviteToEmployee}`,
        headers: headerJson,
        method: 'POST',
        body: {
          id: userId,
        },
        params: {
          lang,
        },
      }),
      invalidatesTags: [userTag],
    }),
    changeMemberRole: build.mutation<IResponse, { userId: string, admin: number, superAdmin: number, employee: number }>({
      query: ({ userId, admin, superAdmin, employee }) => ({
        url: `${RequestRoutes.Organization}${RequestRoutes.ChangeMemberRoleInOrganization}`,
        headers: headerJson,
        method: 'POST',
        body: {
          admin,
          super_admin: superAdmin,
          employee,
        },
        params: {
          userId,
          lang,
        },
      }),
      invalidatesTags: [userTag],
    }),
  }),
});

export const { useSendInviteMutation,
  useChangePhoneMutation, useCreateUserMutation,
  useSmsVerifyingMutation,
  useLoginUserMutation,
  useResendVerifyEmailMutation,
  useResendCodeMutation,
  useChangeMemberRoleMutation,
  useResendInviteMutation,
  useSetNewPasswordMutation,
  useForgotPasswordMutation,
  useResendEmployeeInviteMutation,
  useSendEmployeeInviteMutation,
  useCreateAdminMutation,
  usePhoneVerifyingMutation,
  useVerifyNewPhoneMutation,
  useChangeEmailMutation,
  useChangePasswordMutation,
  useChangeUserDataMutation,
  useFetchUserByIdQuery,
  useUserVerifyingQuery } = userAPI;
