import React from 'react';
import { addDays, differenceInDays, getMonth, getYear } from 'date-fns';
import DatePicker from 'react-datepicker';
import ReactSelect from 'react-select';
import { Button } from '@components/Button';
import { Container as CalendarContainer } from '@components/Calendar/components/Container';
import { Option } from '@components/Select/Option';
import { months } from '@constants/month';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { generateYearsBetween } from '@helpers/generateYearsBetween';
import { UilCalendarAlt } from '@iconscout/react-unicons';
import { IScheduleCalendarProps } from '@pages/Schedule/components/Calendar/types';
import { useScheduleContext } from '@pages/Schedule/context/useScheduleContext';
import { checkIsWeekday } from '@helpers/checkIsWeekday';

export const ScheduleCalendar = ({ handleChange, name, protocolWindow, visitDate, recepitDate, unavailableDays, defaultDate, controls = true, excludeDates }: IScheduleCalendarProps) => {
  const { dictionary } = useLanguageContext();

  const schedule = useScheduleContext();

  const selectedDate = schedule.selectedDate;

  const years = generateYearsBetween(getYear(new Date()) - 1, getYear(new Date()) + 1);

  const getDate = () => {
    if (defaultDate === null) {
      return null;
    }
    if (visitDate) {
      return new Date(visitDate);
    }

    return selectedDate;
  };

  return (
    <DatePicker
      name={name || 'schedule-calendar'}
      calendarContainer={CalendarContainer}
      selected={getDate()}
      onChange={handleChange}
      excludeDates={excludeDates ? [new Date(excludeDates)] : undefined}
      formatWeekDay={(str) => str.slice(0, 3)}
      minDate={recepitDate && addDays(new Date(recepitDate), -Math.abs((differenceInDays(new Date(recepitDate), new Date()) < 2 ? 0 : protocolWindow) as number))}
      maxDate={recepitDate && addDays(new Date(recepitDate), Number(protocolWindow))}
      filterDate={(day: Date) => (unavailableDays ? checkIsWeekday(day, unavailableDays) : true)}
      inline
      renderCustomHeader={({ date, changeYear, changeMonth }) => (
        <div className="calendar-header">
          <div className="calendar-dropdown">
            <div className="select">
              <ReactSelect
                name="schedule-month"
                value={months(dictionary)[getMonth(date)]}
                options={months(dictionary)}
                isSearchable={false}
                onChange={(option) => changeMonth(Number(option?.id))}
                formatOptionLabel={Option}
                classNamePrefix="select"
              />
            </div>
          </div>
          <div className="calendar-dropdown">
            <div className="select">
              <ReactSelect
                name="schedule-year"
                value={years?.find((option) => option.value === getYear(date))}
                onChange={(option) => changeYear(Number(option?.id))}
                isSearchable={false}
                options={years}
                formatOptionLabel={Option}
                classNamePrefix="select"
              />
            </div>
          </div>
          {controls && (
          <div className="calendar-control">
            <Button
              size="small"
              variant="success"
              label={dictionary.schedule.today}
              iconPrepend={<UilCalendarAlt />}
              handleClick={() => handleChange(new Date())}
            />
          </div>
          )}
        </div>
      )}
    />
  );
};
