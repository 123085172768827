import React, { FC } from 'react';
import { Link } from '@components/Link';
import { Routes } from '../../../../router';

export interface INameFormatterProps {
  id: string;
  name: string;
}

export const nameFormatter:FC<INameFormatterProps> = ({ id, name }) => <Link key={id} variant="primary" to={`${Routes.Appointments}/${id}`} label={`${name}`} />;
