export const appointmentsTag = 'Appointments';
export const integrationsTag = 'Integration';
export const locationsTag = 'Locations';
export const patientsTag = 'Patients';
export const physiciansTag = 'Physicians';
export const scheduleTag = 'Schedule';
export const dayScheduleTag = 'DaySchedule';
export const MonthScheduleTag = 'MonthSchedule';
export const trialTag = 'Trial';
export const userTag = 'Users';
export const organizationsTag = 'Organizations';
