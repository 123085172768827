import { oneHundredAndFiftyMinutes, rangeStep } from '@pages/Schedule/constants/ScheduleSettings';
import { IDictionary } from '@constants/languages/types';

export const timeFormatter = (n: number, dictionary: IDictionary) => {
  const time = new Date((rangeStep + (n * rangeStep)) * 1000);

  let formattedTime = '';

  if (time.getMinutes()) {
    if (time.getMinutes() === 2) {
      formattedTime += `${time.getMinutes()} ${dictionary.appointments.duration.hours}`;
    } else {
      formattedTime += `${time.getMinutes()} ${dictionary.appointments.duration.hour}`;
    }
  }
  if (time.getSeconds()) {
    formattedTime += ` ${time.getSeconds()} ${dictionary.appointments.duration.minutes}`;
  }

  return formattedTime.trim();
};

export const durationOptions = (dictionary: IDictionary) => Array.from(Array(Math.floor((oneHundredAndFiftyMinutes - rangeStep) / rangeStep) + 1), (i, n) => ({
  value: String(rangeStep + (n * rangeStep)), label: timeFormatter(n, dictionary),
}));
