import React, { useState } from 'react';
import { Section } from '@components/Section';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { useLocation } from 'react-router-dom';
import { Heading } from '../../layout/AuthLayout/components/Heading';
import { SignUpForm } from './components/SignUpForm';

export const EmployeeSignUp = () => {
  const { dictionary } = useLanguageContext();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [userRegisterEmail, setUserRegisterEmail] = useState<string>('');

  const useQuery = () => new URLSearchParams(useLocation().search);

  const query = useQuery();

  const email = query.get('email');

  const token = query.get('tk');

  return (
    <Section containerSize="sm">
      <Heading>
        {dictionary.titles.welcome}
        <br />
        <strong className="text-primary">
          {dictionary.titles.schedulapp}
        </strong>
      </Heading>
      <SignUpForm token={token} email={email} setUserRegisterEmail={setUserRegisterEmail} />
    </Section>
  );
};
