import React, { FC } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { Link } from '@components/Link';
import { Status } from '@components/Status';
import {
  useAddAppointmentsToCalendarMutation,
  useAddAppointmentsToOutlookCalendarMutation, useDisconnectGoogleMutation,
  useDisconnectOutlookMutation, useSendOutlookTokenMutation,
  useSendTokenMutation,
} from '@api/integrationsApi/integrationsApi';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { IIntegrationsItemProps } from '@pages/Profile/components/Integrations/types';
import { successAlert } from '@helpers/alerts/alerts';
import { emptyString } from '@constants/emptyString';
import { ILoggedInUser } from '@api/user/types';
import { loadState } from '@store/browserStorage';
import { IUserData } from '@store/types';
import { persistAuth } from '@constants/persist';
import { Routes } from '../../../../router';

export const IntegrationsItem:FC<IIntegrationsItemProps> = ({ icon, refetch, type, title, isConnected }) => {
  const { dictionary } = useLanguageContext();

  const statusLabel = isConnected ? dictionary.statuses.connected : dictionary.statuses.notConnected;

  const google = 'google';

  const authData = loadState<IUserData>(persistAuth)?.auth;

  const { user }: ILoggedInUser = authData ? JSON.parse(authData as unknown as string) : {
    user: null,
  };

  const statusVariant = isConnected ? 'success' : 'warning';

  const controlLabel = isConnected ? dictionary.actions.disconnect : dictionary.actions.connect;

  const controlVariant = isConnected ? 'danger' : 'primary';

  const [disconnectOutlookAccount] = useDisconnectOutlookMutation();

  const [disconnectGoogleAccount] = useDisconnectGoogleMutation();

  const [sendToken] = useSendTokenMutation();

  const [getLink] = useSendOutlookTokenMutation();

  const [syncAppointments] = useAddAppointmentsToCalendarMutation();

  const [syncAppointmentsWithOutlook] = useAddAppointmentsToOutlookCalendarMutation();

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      sendToken({
        code: tokenResponse.code, userId: String(user?.id),
      }).unwrap().then(({ message }) => {
        successAlert(emptyString, message);
        refetch && refetch();
      });
    },
    flow: 'auth-code',
    scope: 'openid email profile https://www.googleapis.com/auth/calendar',
  });

  const azureLogin = () => {
    getLink().unwrap().then(({ url }) => window?.open(url)?.focus()).then(() => refetch && refetch());
  };

  return (
    <li className="integrations-item">
      <div className="integrations-heading">
        <div className="integrations-icon">
          <img src={icon} alt={title} />
        </div>
        <strong className="integrations-title">{title}</strong>
        <div className="integrations-status">
          <Status text={statusLabel} variant={statusVariant} />
        </div>
      </div>
      <p className="integrations-text">{dictionary.profile.seeAppointments}</p>
      {type === google
        ? (
          <div className="integrations-controls">
            <div className="integrations-control">
              <Link
                to={Routes.SchedulePatient}
                label={controlLabel}
                variant={controlVariant}
                handleClick={isConnected ? () => disconnectGoogleAccount({
                  userId: String(user?.id),
                }).then(() => refetch && refetch()) : () => login()}
              />
            </div>
            {isConnected && (
            <div className="integrations-control">
              <Link
                to={Routes.SchedulePatient}
                label={dictionary.statuses.share}
                variant="primary"
                handleClick={() => syncAppointments({
                  userId: String(user?.id),
                }).unwrap().then(({ message }) => successAlert(emptyString, message))}
              />
            </div>
            )}
          </div>
        )
        : (
          <div className="integrations-controls">
            <div className="integrations-control">
              <Link
                to={Routes.SchedulePatient}
                label={controlLabel}
                variant={controlVariant}
                handleClick={isConnected ? () => disconnectOutlookAccount({
                  userId: String(user?.id),
                }).then(() => refetch && refetch()) : () => azureLogin()}
              />
            </div>
            {isConnected && (
            <div className="integrations-control">
              <Link
                to={Routes.SchedulePatient}
                label={dictionary.statuses.share}
                variant="primary"
                handleClick={() => syncAppointmentsWithOutlook({
                  userId: String(user?.id),
                }).then(() => successAlert(emptyString, dictionary.actions.done))}
              />
            </div>
            )}
          </div>
        )}
    </li>
  );
};
