import { ITableSettings } from '@components/Table/types';
import { nameFormatter } from '@components/Table/utils/nameFormatter';
import { phoneFormatter } from '@components/Table/utils/phoneFormatter';
import { statusFormatter } from '@components/Table/utils/statusFormatter';
import { trialsFormatter } from '@components/Table/utils/trialsFormatter';
import { TPatient } from '@pages/Patients/components/PatientsTable/types';
import { patientIdFormatter } from '@components/Table/utils/patientIdFormatter';

export const tableSettings: ITableSettings<TPatient>[] = [
  {
    accessor: 'name',
    title: 'Name',
    formatter: nameFormatter,
  },
  {
    accessor: 'email',
    title: 'Email',
  },
  {
    accessor: 'phone',
    title: 'Phone number',
    formatter: phoneFormatter,
  },
  {
    accessor: 'trials',
    title: 'Trials',
    formatter: trialsFormatter,
  },
  {
    accessor: 'userIdFromSponsor',
    title: 'PatientId',
    formatter: patientIdFormatter,
  },
  {
    accessor: 'registrationStatus',
    title: 'Status',
    formatter: statusFormatter,
  },
];
