import React, { ReactNode } from 'react';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { useFetchAllPhysiciansQuery } from '@api/physicians/physiciansAPI';
import { useScheduleContext } from '@pages/Schedule/context/useScheduleContext';

export const WeekHeader = () => {
  const { dictionary } = useLanguageContext();

  const schedule = useScheduleContext();

  const { data: physicians } = useFetchAllPhysiciansQuery();

  const physiciansName = physicians?.data.results.map((physician) => (schedule.selectedPhysician?.length ? physician.id === schedule.selectedPhysician && physician.name : physician.name));

  const tableSettings = physiciansName && [dictionary.schedule.table.time, ...physiciansName];

  const tableHeader = tableSettings?.reduce<ReactNode[] | any>((acc, item, currentIndex) => [...acc,
    <div className="schedule-cell table-cell is-heading" key={`schedule-cell-${currentIndex}`}>
      { item }
    </div>], []).map((children: { props: { children: string; }; }) => children?.props.children.length && children);

  return (
    <div className="schedule-header table-header">
      <div className="schedule-row table-row">{ tableHeader }</div>
    </div>
  );
};
