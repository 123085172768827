import { IDictionary } from '@constants/languages/types';

export const months = (dictionary: IDictionary) => [
  {
    id: '0',
    label: dictionary.month.january,
    value: dictionary.month.january,
  },
  {
    id: '1',
    label: dictionary.month.february,
    value: dictionary.month.february,
  },
  {
    id: '2',
    label: dictionary.month.march,
    value: dictionary.month.march,
  },
  {
    id: '3',
    label: dictionary.month.april,
    value: dictionary.month.april,
  },
  {
    id: '4',
    label: dictionary.month.may,
    value: dictionary.month.may,
  },
  {
    id: '5',
    label: dictionary.month.june,
    value: dictionary.month.june,
  },
  {
    id: '6',
    label: dictionary.month.july,
    value: dictionary.month.july,
  },
  {
    id: '7',
    label: dictionary.month.august,
    value: dictionary.month.august,
  },
  {
    id: '8',
    label: dictionary.month.september,
    value: dictionary.month.september,
  },
  {
    id: '9',
    label: dictionary.month.october,
    value: dictionary.month.october,
  },
  {
    id: '10',
    label: dictionary.month.november,
    value: dictionary.month.november,
  },
  {
    id: '11',
    label: dictionary.month.december,
    value: dictionary.month.december,
  },
];
