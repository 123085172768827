import { ITableSettings } from '@components/Table/types';
import { TTrial } from '@pages/Trials/components/TrialsTable/types';
import { physiciansFormatter } from '@pages/Trials/components/utils/physicianFormatter';
import { nameFormatter } from '../../utils/nameFormatter';

export const tableSettings: ITableSettings<TTrial>[] = [
  {
    accessor: 'protocolName',
    title: 'Protocol name',
    isSortable: true,
    formatter: nameFormatter,
  },
  {
    accessor: 'protocolNumber',
    title: 'Protocol number',
    isSortable: true,
  },
  {
    accessor: 'sponsorName',
    title: 'Sponsor name',
    isSortable: true,
  },
  {
    accessor: 'physicians',
    title: 'Physician\'s name (PI)',
    isSortable: true,
    formatter: physiciansFormatter,
  },
];
