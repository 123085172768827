import { getYear } from 'date-fns';
import range from 'lodash/range';

const numberOfPastYears = 100;

const numberOfFutureYears = 10;

export const pastYears = range(getYear(new Date()) - numberOfPastYears, getYear(new Date()) + 1, 1)
  .map((year) => ({
    id: String(year),
    value: year,
    label: year,
  })).reverse();

export const futureYears = range(getYear(new Date()), getYear(new Date()) + numberOfFutureYears, 1)
  .map((year) => ({
    id: String(year),
    value: year,
    label: year,
  }));
