import { Roles } from '@constants/roles';
import { ILoggedInUser } from '@api/user/types';
import { loadState } from '@store/browserStorage';
import { IUserData } from '@store/types';
import { persistAuth } from '@constants/persist';
import { Routes } from '../../../../../router';

export const MenuList = () => {
  const authData = loadState<IUserData>(persistAuth)?.auth;

  const { user }: ILoggedInUser = authData ? JSON.parse(authData as unknown as string) : {
    user: null,
  };

  return [
    {
      key: 'patients',
      title: 'Patients',
      link: Routes.Patients,
      roles: [Roles.staff, Roles.admin, Roles.superAdmin],
    },
    {
      key: 'trials',
      title: 'Trials',
      link: Routes.Trials,
      roles: [Roles.staff, Roles.admin, Roles.superAdmin],
    },
    {
      key: 'appointments',
      title: 'Appointments',
      link: Routes.Appointments,
      roles: [Roles.staff, Roles.admin, Roles.superAdmin],
    },
    {
      key: 'schedule',
      title: 'Schedule',
      link: Routes.Schedule,
      roles: [Roles.staff, Roles.admin, Roles.superAdmin],
    },
    {
      key: 'schedule',
      title: 'Schedule',
      link: Routes.SchedulePatient,
      roles: [Roles.patient],
    },
    {
      key: 'physicians',
      title: 'Physicians',
      link: Routes.Physicians,
      roles: [Roles.staff, Roles.admin, Roles.superAdmin],
    },
    {
      key: 'locations',
      title: 'Locations',
      link: Routes.Locations,
      roles: [Roles.staff, Roles.admin, Roles.superAdmin],
    },
    {
      key: 'requestedAppointments',
      title: 'Requested appointments',
      link: Routes.RequestedAppointments,
      roles: [Roles.patient],
    },
    {
      key: 'organizations',
      title: 'organizations',
      link: Routes.Organizations,
      roles: [Roles.superAdmin],
    },
    {
      key: 'organization',
      title: 'organization',
      link: `${Routes.Organization}/${user?.organization_id}`,
      roles: [Roles.admin, Roles.superAdmin],
    },
    {
      key: 'profile',
      title: 'Profile',
      link: Routes.MyProfile,
      roles: [Roles.staff, Roles.admin, Roles.superAdmin],
    },
    {
      key: 'profile',
      title: 'Profile',
      link: Routes.Profile,
      roles: [Roles.patient],
    },
  ];
};
