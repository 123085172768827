import React, { FC, useState } from 'react';
import { InputField } from '@components/Form/InputField';
import { IFormFieldProps } from '@components/Form/types';
import { UilEye, UilEyeSlash } from '@iconscout/react-unicons';

export const PasswordField: FC<IFormFieldProps> = (props) => {
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const inputType = isPasswordVisible ? 'text' : 'password';

  const onChangeInputType = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  const passwordControlIcon = isPasswordVisible ? <UilEye size="17" /> : <UilEyeSlash size="17" />;

  const passwordControl = (
    <span
      className="input-btn"
      role="button"
      tabIndex={0}
      onClick={onChangeInputType}
      aria-label="Close"
    >
      {passwordControlIcon}
    </span>
  );

  return (
    <InputField
      {...props}
      type={inputType}
      iconAppend={passwordControl}
    />
  );
};
