import React, { FC } from 'react';
import { UilInfoCircle } from '@iconscout/react-unicons';
import { emptyString } from '@constants/emptyString';
import { AlertVariants, IAlertProps } from './types';
import './styles.scss';

export const Alert:FC<IAlertProps> = ({ title = emptyString, text = emptyString, variant = AlertVariants.info }) => {
  const alertClassName = `alert ${variant && `is-${variant}`}`;

  return (
    <span className={alertClassName}>
      <div className="alert-icon">
        <UilInfoCircle size={20} />
      </div>
      <div className="alert-content">
        {title && <strong className="alert-title">{title}</strong>}
        {text && <p className="alert-text">{text}</p>}
      </div>
    </span>
  );
};
