import * as Yup from 'yup';
import { nameExpression, passwordExpression, phoneExpression } from '@constants/regularExpression';
import { IDictionary } from '@constants/languages/types';

const sighUpValidationSchema = (dictionary: IDictionary) => Yup.object({
  name: Yup.string()
    .required(dictionary.validation.firstName)
    .min(1, dictionary.validation.firstNameMin)
    .max(50, dictionary.validation.firstNameMax)
    .matches(nameExpression, dictionary.validation.firstNameLatter),
  surname: Yup.string()
    .required(dictionary.validation.lastName)
    .min(1, dictionary.validation.lastNameMin)
    .max(50, dictionary.validation.lastNameMax)
    .matches(nameExpression, dictionary.validation.lastNameLatter),
  email: Yup.string()
    .email(dictionary.validation.invalidEmail)
    .min(1, dictionary.validation.emptyEmail),
  phone: Yup.string()
    .required(dictionary.validation.phone)
    .min(17, dictionary.validation.invalidPhone)
    .matches(phoneExpression, dictionary.validation.invalidPhone),
  password: Yup.string()
    .required(dictionary.validation.createPassword)
    .min(8, dictionary.validation.passwordMin)
    .matches(passwordExpression, dictionary.validation.passwordMin),
  repeatedPassword: Yup.string()
    .required(dictionary.validation.passwordAgain)
    .oneOf([Yup.ref('password')], dictionary.validation.matchPassword),
});

export default sighUpValidationSchema;
