import { camelCase } from 'lodash';

export const convertToCamelCase: any = (obj: { [x: string]: any; map?: void; constructor?: any; } | null) => {
  if (Array.isArray(obj)) {
    return obj.map((v) => convertToCamelCase(v));
  }
  if (obj != null && obj.constructor === Object) {
    return Object.keys(obj)
      .reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: convertToCamelCase(obj[key]),
        }),
        {
        },
      );
  }
  return obj;
};
