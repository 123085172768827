import React, { FC } from 'react';
import { FieldArray } from 'formik';
import { Button } from '@components/Button';
import { Checkbox } from '@components/Checkbox';
import { Select } from '@components/Select';
import { intervalsGroup } from '@constants/options/timeOptions';
import { UilCopy, UilPlus, UilTrash } from '@iconscout/react-unicons';
import './styles.scss';
import { IDictionary } from '@constants/languages/types';

export interface IWorkingHoursProps {
  day: string;
  dictionary: IDictionary
}

export const WorkingHours: FC<IWorkingHoursProps> = ({ day, dictionary }) => (
  <FieldArray
    name={`schedule.${day}`}
    render={(arrayHelpers) => {
      const isInactive = !arrayHelpers.form.values.schedule[day].length;

      const workHours = {
        from: '',
        to: '',
      };

      const onDayCheckChange = () => {
        isInactive
          ? arrayHelpers.insert(arrayHelpers.form.values.schedule[day].length, workHours)
          : arrayHelpers.form.setFieldValue(`schedule.${day}`, []);
      };

      const onAddIntervalClick = () => {
        arrayHelpers.insert(arrayHelpers.form.values.schedule[day].length, workHours);
      };

      const onRemoveIntervalClick = (index: number) => {
        arrayHelpers.remove(index);
      };

      const onCopyIntervalsClick = () => {
        Object.keys(arrayHelpers.form.values.schedule).forEach((workingDay) => {
          arrayHelpers.form.setFieldValue(`schedule.${workingDay}`, arrayHelpers.form.values.schedule[workingDay].length
            ? arrayHelpers.form.values.schedule[day] : arrayHelpers.form.values.schedule[workingDay]);
        });
      };

      return (
        <li className="working-hours-item">
          <div className="working-hours-col">
            <div className="working-hours-checkbox">
              <Checkbox
                name={`${day}-check`}
                label={dictionary.physician?.table[day as keyof typeof dictionary.physician.table]}
                checked={!isInactive}
                handleChange={onDayCheckChange}
              />
            </div>
          </div>
          <div className="working-hours-col working-hours-intervals">
            {isInactive && <span className="working-hours-inactive">{dictionary.labels.unavailable}</span>}
            {!isInactive && arrayHelpers.form.values.schedule[day].map((item: {from: string, to: string}, index: number) => (
              <div key={`${day}.${index}`} id={`${day}.${index}`} className="form-field interval">
                <Select
                  isDisabled={index !== 0 && !arrayHelpers.form.values.schedule[day].at(0).to}
                  name={`schedule.${day}.${index}.from`}
                  value={arrayHelpers.form.values.schedule[day]?.at(index)?.from ?? ''}
                  options={intervalsGroup(index !== 0 && arrayHelpers.form.values.schedule[day].at(0).to)}
                />
                <span className="interval-divider">-</span>
                <Select
                  isDisabled={!arrayHelpers.form.values.schedule[day].at(index).from}
                  name={`schedule.${day}.${index}.to`}
                  value={arrayHelpers.form.values.schedule[day]?.at(index)?.to ?? ''}
                  options={intervalsGroup(item.from)}
                />
                {arrayHelpers.form.values.schedule[day].length > 1 && (
                  <div className="interval-remove">
                    <Button
                      size="small"
                      type="button"
                      variant="danger"
                      handleClick={() => onRemoveIntervalClick(index)}
                      iconAppend={<UilTrash />}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="working-hours-col">
            <div className="working-hours-actions">
              <Button
                isDisabled={!(arrayHelpers.form.values.schedule[day].length < 2)}
                size="small"
                type="button"
                variant="success"
                handleClick={onAddIntervalClick}
                iconPrepend={<UilPlus />}
              />
              <Button
                isDisabled={!arrayHelpers.form.values.schedule[day].length}
                size="small"
                type="button"
                variant="info"
                handleClick={onCopyIntervalsClick}
                iconPrepend={<UilCopy />}
              />
            </div>
          </div>
        </li>
      );
    }}
  />
);
