import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@components/Button';
import { SearchForm } from '@components/SearchForm';
import { Section } from '@components/Section';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { UilPlus } from '@iconscout/react-unicons';
import { AppointmentsTable } from '@pages/Appointments/components/AppointmentsTable';
import { Filter } from '@components/Filter';
import { useFetchAllAppointmentsQuery } from '@api/appointments/appointmentsApi';
import { Loader } from '@components/Loader';
import { skipToken } from '@reduxjs/toolkit/query';
import { Routes } from '../../router';

export const Appointments = () => {
  const { dictionary } = useLanguageContext();

  const navigate = useNavigate();

  const [searchTitle, setSearchTitle] = useState<string>('');

  const [filteredValue, setFilteredValue] = useState<string | unknown>('');

  const startPage = 1;

  const [limit, setLimit] = useState<number>(20);

  const { data: appointments, refetch, isLoading } = useFetchAllAppointmentsQuery({
    searchString: searchTitle, limit, page: startPage, filterString: (filteredValue as { value: string }).value || '',
  } ?? skipToken);

  const next = () => {
    if (appointments?.data.currentPage !== appointments?.data.totalPages) {
      setLimit(limit + 20);
    }
  };

  useEffect(() => {
    if (!appointments?.data) {
      refetch();
    }
  }, [appointments, refetch]);

  return (
    <div className="main-content">
      <Section
        title={dictionary.appointments.title}
        controls={(
          <>
            <SearchForm setSearchTitle={setSearchTitle} />
            <Filter setFilteredValue={setFilteredValue} filteredValue={filteredValue} />
            <div className="section-control">
              <Button
                label={dictionary.appointments.modals.createAppointment.title}
                iconPrepend={<UilPlus />}
                handleClick={() => navigate(`${Routes.Appointments}${Routes.Add}`)}
                size="static"
              />
            </div>
          </>
        )}
      >
        {isLoading ? <Loader /> : (
          <AppointmentsTable
            dataLength={appointments?.data.total}
            refreshFunction={refetch}
            next={next}
            searchTitle={searchTitle}
            appointments={appointments}
            refetch={refetch}
          />
        )}
      </Section>
    </div>
  );
};
