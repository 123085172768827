import React, { FC } from 'react';
import { ButtonSizes, ButtonTypes, ButtonVariants, IButtonProps } from '@components/Button/types';
import './styles.scss';

export const Button: FC<IButtonProps> = ({ label,
  type = ButtonTypes.button,
  variant = ButtonVariants.primary,
  size = ButtonSizes.medium,
  isDisabled = false,
  iconPrepend = null,
  iconAppend = null,
  handleClick,
  ...rest }) => {
  const buttonClassName = `button ${variant && `button-${variant}`} ${size && `button-${size}`} ${isDisabled ? 'is-disabled' : ''}`;

  return (
    <button
      className={buttonClassName}
      type={type}
      disabled={isDisabled}
      onClick={handleClick}
      {...rest}
    >
      {iconPrepend && (
        <span className="button-prepend-icon" role="presentation">
          {iconPrepend}
        </span>
      )}
      {label && (
        <span className="button-label">
          {label}
        </span>
      )}
      {iconAppend && (
        <span className="button-append-icon" role="presentation">
          {iconAppend}
        </span>
      )}
    </button>
  );
};
