import * as Yup from 'yup';
import { IDictionary } from '@constants/languages/types';

const physiciansValidationSchema = (dictionary: IDictionary) => Yup.object({
  name: Yup.string()
    .required(dictionary.validation.physicianName)
    .max(100, dictionary.validation.invalidPhysicianName),
  phone: Yup.string()
    .required(dictionary.validation.physicianPhone)
    .min(12, dictionary.validation.invalidPhysicianPhone),
});

export default physiciansValidationSchema;
