import esIcon from '@components/LanguageMenu/img/ES.svg';
import usIcon from '@components/LanguageMenu/img/US.svg';
import { languageOptions } from '@constants/languages';
import { useLanguageContext } from '@hooks/useLanguageContext';

export const useLanguageSelect = () => {
  const { dictionary } = useLanguageContext();

  const [en, es] = Object.keys(languageOptions);

  const langOptions = [{
    id: en,
    value: 'EN',
    label: dictionary.languages.english,
    shortLabel: 'EN',
    img: usIcon,
  }, {
    id: es,
    value: 'ES',
    label: dictionary.languages.espano,
    shortLabel: 'ES',
    img: esIcon,
  }];

  return {
    langOptions,
  };
};
