import React, { FC } from 'react';
import { useConfirmationModal } from '@components/Confirm/context/useConfirmContext';
import { ModalTypes } from '@components/Confirm/types';
import { Table } from '@components/Table';
import { IAction } from '@components/Table/utils/actionsFormatter';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { IOrganizationsTableProps, TOrganization } from '@pages/Organizations/components/OrganizationsTable/types';
import { useDeleteOrganizationMutation } from '@api/organizations/organizationsAPI';
import { tableSettings } from '@pages/Organizations/components/constants/tableSettings';

export const OrganizationsTable: FC<IOrganizationsTableProps> = ({ setSortField, searchTitle, organisations, refetch, next, refreshFunction, dataLength }) => {
  const { openConfirmationModal } = useConfirmationModal();

  const { dictionary } = useLanguageContext();

  const [deleteOrganization] = useDeleteOrganizationMutation();

  const onDeleteOrganization = ({ id }: TOrganization) => {
    openConfirmationModal({
      variant: ModalTypes.decline,
      title: dictionary.organizations.modals.deleteOrganization.title,
      text: dictionary.organizations.modals.deleteOrganization.text,
      submitButtonTitle: dictionary.table.delete,
      handleConfirmClick: () => {
        deleteOrganization(id).then(() => refetch());
      },
    });
  };

  const tableActions: IAction<TOrganization>[] = [
    {
      type: 'delete',
      handleClick: (row) => onDeleteOrganization(row),
    },
  ];

  return (
    <Table
      next={next}
      refreshFunction={refreshFunction}
      dataLength={dataLength}
      setSort={setSortField}
      tableData={organisations?.data.results}
      tableSettings={tableSettings}
      actions={tableActions}
      search={!!searchTitle}
      dictionaryKey="organizations"
    />
  );
};
