export function loadState<StateType>(key: string) {
  try {
    const serializedState = localStorage.getItem(key);

    if (!serializedState) {
      return undefined;
    }
    return JSON.parse(serializedState) as StateType;
  } catch (e) {
    throw new Error(`Upload error: ${e}`);
  }
}

export function saveState<StateType>(state: StateType, key: string) {
  try {
    const serializedState = JSON.stringify(state);

    localStorage.setItem(key, serializedState);
  } catch (e) {
    throw new Error(`Error: ${e}`);
  }
}
