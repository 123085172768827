import React, { FC, useCallback, useEffect, useState } from 'react';
import { intervalToDuration } from 'date-fns';
import { Button } from '@components/Button';
import { IResendCodeProps } from '@components/ResendCode/types';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { useResendCodeMutation } from '@api/user/userAPI';
import { errorAlert, successAlert } from '@helpers/alerts/alerts';
import { emptyString } from '@constants/emptyString';

export const ResendCode:FC<IResendCodeProps> = ({ isModalVisible, phone, setCode }) => {
  const [resendCode] = useResendCodeMutation();

  const { dictionary } = useLanguageContext();

  const [timer, setTimer] = useState<number>(60);

  const timeOutCallback = useCallback(() => (isModalVisible ? setTimer((currTimer) => currTimer - 1) : null), [isModalVisible]);

  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);

  const sendCode = () => {
    if (!timer) {
      setTimer(61);
      resendCode({
        phone,
      }).unwrap().then(({ success, message }) => {
        if (success === 1) {
          successAlert(emptyString, message);
        } else {
          errorAlert(dictionary.messages.errorTitle, message);
        }
      }).catch(({ data }) => errorAlert(data.message));
    }

    setCode('');
  };

  const { minutes, seconds } = intervalToDuration({
    start: 0, end: timer * 1000,
  });

  const timerLabel = timer
    ? `${dictionary.actions.resendCode} in ${minutes ? `in${minutes}m ${seconds}s` : `${seconds}s`}`
    : `${dictionary.actions.resendCode}`;

  return (
    <Button
      variant="link"
      isDisabled={!!timer}
      handleClick={sendCode}
      label={timerLabel}
    />
  );
};
