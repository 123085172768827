import { ScheduleForm } from '@pages/Trial/pages/CreateTrial/components/ScheduleForm';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { useNavigate, useParams } from 'react-router-dom';
import React, { FC } from 'react';
import { Formik, Form, FormikValues } from 'formik';
import { Button } from '@components/Button';
import { Section } from '@components/Section';
import { IInitialTrial } from '@api/trial/types';
import { useEditTrialMutation, useFetchTrialByIdQuery } from '@api/trial/trialAPI';
import { skipToken } from '@reduxjs/toolkit/query';
import { Loader } from '@components/Loader';
import { errorAlert } from '@helpers/alerts/alerts';
import snakecaseKeys from 'snakecase-keys';
import { ModalTypes } from '@components/Confirm/types';
import { useConfirmationModal } from '@components/Confirm/context/useConfirmContext';
import editTrialValidationSchema from '@helpers/validationSchemas/editTrialValidationSchema';
import { Routes } from '../../../../../router';

export const EditTrialForm: FC = () => {
  const { dictionary } = useLanguageContext();

  const { openConfirmationModal } = useConfirmationModal();

  const { id } = useParams();

  const [editTrial] = useEditTrialMutation();

  const { data: trial, isLoading } = useFetchTrialByIdQuery(id ?? skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const navigate = useNavigate();

  const visitValues = {
    name: '',
    type: '',
    protocolWindow: '',
    duration: '',
    date: '',
    notificationsSettings: {
      types: {
        sms: '',
        email: '',
      },
      first: '',
      second: '',
    },
  };

  const initialValues: IInitialTrial = {
    appointmentTemplate: trial && Object.values(trial?.data.appointmentTemplate),
  };

  const onSubmitForm = (formValues: FormikValues) => {
    editTrial(snakecaseKeys({
      trial: {
        appointmentTemplate: Object.assign({
        }, ...(formValues.appointmentTemplate.map((item: string, index: number) => ({
          [index]: item,
        })))),
      },
      id: String(id),
    }))
      .unwrap()
      .then(() => navigate(`${Routes.Trial}/${id}`))
      .catch(({ message }) => errorAlert(message));
  };

  const onCancelClick = () => openConfirmationModal({
    variant: ModalTypes.decline,
    title: dictionary.trials.confirmModals.title2,
    text: dictionary.trials.confirmModals.text,
    submitButtonTitle: dictionary.table.ok,
    handleConfirmClick: () => navigate(Routes.Trials),
  });

  return (
    isLoading ? <Loader />
      : (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={editTrialValidationSchema(dictionary)}
          onSubmit={onSubmitForm}
        >
          <Form className="form" noValidate>
            <div className="form-body">
              <Section title={dictionary.trials.editTrial.title}>
                <section className="form-section">
                  <ScheduleForm dictionary={dictionary} isEdit visitValues={visitValues} />
                </section>
                <section className="form-section form-footer">
                  <Button
                    label={dictionary.actions.cancel}
                    variant="default"
                    handleClick={onCancelClick}
                    size="static"
                  />
                  <Button
                    type="submit"
                    label={dictionary.actions.save}
                    size="static"
                  />
                </section>
              </Section>
            </div>
          </Form>
        </Formik>
      )
  );
};
