import React, { FC } from 'react';
import { TAppointment } from '@api/appointments/types';
import { useLanguageContext } from '@hooks/useLanguageContext';

export const NotificationFormatter:FC<TAppointment> = ({ notificationsSettings }) => {
  const smsNotification = notificationsSettings.types.sms;

  const { dictionary } = useLanguageContext();

  const emailNotification = notificationsSettings.types.email;

  let allNotification;
  if (smsNotification && emailNotification) {
    allNotification = `${dictionary.labels.email}, ${dictionary.labels.sms}`;
  } else if (smsNotification) {
    allNotification = dictionary.labels.sms;
  } else if (emailNotification) {
    allNotification = dictionary.labels.email;
  } else {
    allNotification = '-';
  }

  return <ul>{allNotification}</ul>;
};
