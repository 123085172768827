import { addHours, addMinutes, differenceInMinutes, endOfDay, startOfDay } from 'date-fns';
import { timeFormatter } from '@helpers/timeFormatter';
import { rangeStep } from '@pages/Schedule/constants/ScheduleSettings';

export const generateTimeBetween = (start: string, end: string) => {
  const startTime = addHours(startOfDay(new Date()), 6);

  const endTime = endOfDay(new Date());

  const timeIntervals = [];

  const step = rangeStep;

  let currentTime = startTime;

  while (differenceInMinutes(endTime, currentTime)) {
    timeIntervals.push(timeFormatter(currentTime));
    currentTime = addMinutes(currentTime, step);
  }

  if ((!start && !end) || start === end) {
    return timeIntervals.map((time) => ({
      value: time,
      label: time,
    }));
  }

  if (!end || end === timeFormatter(startTime)) {
    return timeIntervals.map((time) => ({
      value: time,
      label: time,
    })).slice(timeIntervals.indexOf(start));
  }

  if (!start) {
    return timeIntervals.map((time) => ({
      value: time,
      label: time,
    })).slice(timeIntervals.indexOf(end) + 1);
  }

  return timeIntervals.map((time) => ({
    value: time,
    label: time,
  })).slice(timeIntervals.indexOf(start), timeIntervals.indexOf(end));
};
