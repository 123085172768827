import { IOutlookResponse, ITrialResponse } from '@api/trial/types';
import { RequestRoutes } from '@constants/requestRoutes';
import { apiHeader } from '@helpers/commonHeaders';
import { convertToCamelCase } from '@helpers/convertToCamelCase';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ISendToken } from '@api/integrationsApi/types';
import { integrationsTag } from '@constants/apiTags';
import { IResponse } from '@api/user/types';
import { language } from '@constants/language';

const lang = localStorage.getItem(language)?.slice(1, 3) || 'en';

export const integrationsAPI = createApi({
  reducerPath: 'integrationsAPI',
  tagTypes: [integrationsTag],
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_API_URL}`,
    prepareHeaders: apiHeader,
  }),
  endpoints: (build) => ({
    sendToken: build.mutation<ITrialResponse, ISendToken>({
      query: (code) => ({
        url: `${RequestRoutes.Google}${RequestRoutes.Calendar}${RequestRoutes.Tokens}`,
        method: 'POST',
        params: {
          code: code.code,
          userId: code.userId,
          lang,
        },
      }),
      transformResponse(response: ITrialResponse) {
        return convertToCamelCase(response);
      },
      invalidatesTags: [integrationsTag],
    }),
    addAppointmentsToCalendar: build.mutation<IResponse, ISendToken>({
      query: (code) => ({
        url: `${RequestRoutes.Google}${RequestRoutes.Calendar}${RequestRoutes.AddAppointmentsToCalendar}`,
        method: 'POST',
        params: {
          userId: code.userId,
          lang,
        },
      }),
      transformResponse(response: IResponse) {
        return convertToCamelCase(response);
      },
      invalidatesTags: [integrationsTag],
    }),
    sendOutlookToken: build.mutation<IOutlookResponse, void>({
      query: (code) => ({
        url: `${RequestRoutes.Microsoft}${RequestRoutes.Outlook}${RequestRoutes.GenerateUrl}`,
        method: 'GET',
        params: {
          lang,
        },
      }),
      transformResponse(response: IResponse) {
        return convertToCamelCase(response);
      },
      invalidatesTags: [integrationsTag],
    }),
    addAppointmentsToOutlookCalendar: build.mutation<IResponse, ISendToken>({
      query: (code) => ({
        url: `${RequestRoutes.Microsoft}${RequestRoutes.Outlook}${RequestRoutes.AddAppointmentsToCalendar}`,
        method: 'POST',
        params: {
          userId: code.userId,
          lang,
        },
      }),
      transformResponse(response: IResponse) {
        return convertToCamelCase(response);
      },
      invalidatesTags: [integrationsTag],
    }),
    disconnectOutlook: build.mutation<IResponse, ISendToken>({
      query: (code) => ({
        url: `${RequestRoutes.Microsoft}${RequestRoutes.Outlook}${RequestRoutes.DeleteToken}`,
        method: 'POST',
        body: {
          id: code.userId,
        },
        params: {
          lang,
        },
      }),
      invalidatesTags: [integrationsTag],
    }),
    disconnectGoogle: build.mutation<IResponse, ISendToken>({
      query: (code) => ({
        url: `${RequestRoutes.Google}${RequestRoutes.Calendar}${RequestRoutes.DeleteToken}`,
        method: 'POST',
        body: {
          id: code.userId,
        },
        params: {
          lang,
        },
      }),
      invalidatesTags: [integrationsTag],
    }),
  }),
});

export const { useSendTokenMutation,
  useSendOutlookTokenMutation,
  useDisconnectOutlookMutation,
  useDisconnectGoogleMutation,
  useAddAppointmentsToOutlookCalendarMutation,
  useAddAppointmentsToCalendarMutation } = integrationsAPI;
