import { differenceInMinutes, parse } from 'date-fns';
import { getEndTime, getStartTime } from '@helpers/getVisitTime';

export const getDuration = (time: string) => {
  const start = parse(getStartTime(time), 'h:mm a', new Date());

  const end = parse(getEndTime(time), 'h:mm a', new Date());

  return differenceInMinutes(end, start);
};
