import React from 'react';
import { Container } from '@components/Container';
import { keyFormatter } from '@helpers/keyFormatter';
import { NavLink } from 'react-router-dom';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { Routes } from '../../../../router';
import { Logo } from '../Logo';
import './styles.scss';

export const PrivacyHeader = () => {
  const { dictionary } = useLanguageContext();

  return (
    <header className="header">
      <Container isStretched>
        <div className="header-content-privacy">
          <div className="header-logo">
            <Logo />
          </div>
          <nav className="nav-privacy">
            <ul className="nav-list">
              <li className="nav-item" key={`nav_${keyFormatter('privacyPolicy')}`} title="Privacy Policy">
                <NavLink
                  to={Routes.PrivacyPolicy}
                  className={({ isActive }) => (`nav-link ${isActive && 'is-active'}`)}
                >
                  <span className="nav-link-label">{dictionary.privacyPolicy.title}</span>
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </Container>
    </header>
  );
};
