export enum InfoVariants {
  success = 'success',
  warning = 'warning',
  danger = 'danger',
  info = 'info',
}

export interface IInfoProps {
  text: string;
  phone?: string;
  variant?: `${InfoVariants}`;
}
