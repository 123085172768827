import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { apiHeader, headerJson } from '@helpers/commonHeaders';
import { RequestRoutes } from '@constants/requestRoutes';
import {
  IFetchScheduleByDay,
  IFetchScheduleByMonth,
  IFetchScheduleByWeek,
  IScheduleResponse,
} from '@api/schedule/types';
import { convertToCamelCase } from '@helpers/convertToCamelCase';
import { dayScheduleTag, MonthScheduleTag, scheduleTag } from '@constants/apiTags';
import { language } from '@constants/language';

const lang = localStorage.getItem(language)?.slice(1, 3) || 'en';

export const scheduleAPI = createApi({
  reducerPath: 'scheduleAPI',
  tagTypes: [scheduleTag, dayScheduleTag, MonthScheduleTag],
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_API_URL}`,
    prepareHeaders: apiHeader,
  }),
  endpoints: (build) => ({
    fetchScheduleByDay: build.query<IScheduleResponse, IFetchScheduleByDay>({
      query: ({ day,
        location,
        physician,
        patient,
        type,
        trial,
        from,
        to }) => ({
        url: `${RequestRoutes.Calendar}${RequestRoutes.GetDay}`,
        params: {
          day,
          location,
          physician,
          patient,
          type,
          trial,
          from,
          to,
        },
        headers: headerJson,
      }),
      transformResponse(response: IScheduleResponse) {
        return convertToCamelCase(response);
      },
      providesTags: [dayScheduleTag],
    }),
    fetchScheduleByWeek: build.query<IScheduleResponse, IFetchScheduleByWeek>({
      query: ({ weekDays,
        location,
        physician,
        patient,
        type,
        trial,
        from,
        to }) => ({
        url: `${RequestRoutes.Calendar}${RequestRoutes.GetWeek}`,
        params: {
          weekDays,
          location,
          physician,
          patient,
          type,
          trial,
          from,
          to,
          lang,
        },
        headers: headerJson,
        keepUnusedDataFor: 5,
      }),
      transformResponse(response: IScheduleResponse) {
        return convertToCamelCase(response);
      },
      providesTags: [scheduleTag],
    }),
    fetchScheduleByMonth: build.query<IScheduleResponse, IFetchScheduleByMonth>({
      query: ({ month,
        location,
        physician,
        patient,
        type,
        trial,
        from,
        to }) => ({
        url: `${RequestRoutes.Calendar}${RequestRoutes.GetMonth}`,
        params: {
          month,
          location,
          physician,
          patient,
          type,
          trial,
          from,
          to,
          lang,
        },
        headers: headerJson,
        keepUnusedDataFor: 5,
      }),
      transformResponse(response: IScheduleResponse) {
        return convertToCamelCase(response);
      },
      providesTags: [MonthScheduleTag],
    }),
  }),
});

export const { useFetchScheduleByDayQuery,
  useFetchScheduleByWeekQuery,
  useFetchScheduleByMonthQuery } = scheduleAPI;
