import React, { FC } from 'react';
import Select from 'react-select';
import { ISelectWithIconProps } from '@components/Dropdown/types';
import { Option } from '@components/Select/Option';
import './style.scss';

export const SelectWithIcon: FC<ISelectWithIconProps> = ({ options, defaultValue, handleChange }) => (
  <div className="select">
    <Select
      options={options}
      isSearchable={false}
      onChange={handleChange}
      formatOptionLabel={Option}
      defaultValue={defaultValue}
      value={defaultValue}
      classNamePrefix="select"
    />
  </div>
);
