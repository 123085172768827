import * as Yup from 'yup';
import { IDictionary } from '@constants/languages/types';

const editAppointmentValidationSchema = (dictionary: IDictionary) => Yup.object({
  date: Yup.string()
    .required(dictionary.validation.date),
  time: Yup.string()
    .required(dictionary.validation.time),
});

export default editAppointmentValidationSchema;
