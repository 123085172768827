import React from 'react';
import { useNavigate } from 'react-router-dom';
import { UilClock, UilUser } from '@iconscout/react-unicons';
import { IDaySlotProps } from '@pages/Schedule/components/Day/types';
import { Slot } from '@pages/Schedule/components/Slot';
import { minuteHeight } from '@pages/Schedule/constants/ScheduleSettings';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { Routes } from '../../../../router';

export const DaySlot = ({ patient, isAdult, duration, appointmentId, patientNumber, eventType }: IDaySlotProps) => {
  const slotHeight = `${minuteHeight * (duration)}px`;

  const { dictionary } = useLanguageContext();

  const hours = Math.floor(duration / 60);

  const formattedHours = hours > 0 ? `${hours}h` : '';

  const minutes = duration % 60;

  const formattedMinutes = minutes > 0 ? `${minutes}m` : '';

  const slotDetails = (
    <div className="slot-details">
      <div className="slot-details-row slot-details-title">
        <span className="slot-details-icon">
          <UilUser size={16} />
        </span>
        <span className="slot-details-label">{`${patient} ${patientNumber ? `(${patientNumber})` : '-'}`}</span>
      </div>
      <div className="slot-details-row slot-details-info">
        <span className="slot-details-icon">
          <UilClock size={16} />
        </span>
        <span className="slot-details-label">{`${formattedHours} ${formattedMinutes} - ${eventType || ''}`}</span>
      </div>
    </div>
  );

  const navigate = useNavigate();

  const onClick = () => {
    navigate(`${Routes.Appointments}/${appointmentId}`);
  };

  return (
    <div
      className="schedule-cell-content"
      style={{
        ['height' as string]: slotHeight,
      }}
    >
      {!patient && (
        <Slot
          details={<span className="text-semibold">{dictionary.labels.unavailable}</span>}
        />
      )}
      {patient && (
        <Slot
          variant={isAdult ? 'success' : 'warning'}
          details={slotDetails}
          handleClick={onClick}
        />
      )}
    </div>
  );
};
