import * as Yup from 'yup';
import { nameExpression, passwordExpression, phoneExpression } from '@constants/regularExpression';
import { calculateAge } from '@helpers/calculateAge';
import { format } from 'date-fns';
import { IDictionary } from '@constants/languages/types';

const sighUpValidationSchemaWithParent = (dictionary: IDictionary) => Yup.object({
  name: Yup.string()
    .trim()
    .required(dictionary.validation.firstName)
    .min(1, dictionary.validation.firstNameMin)
    .max(50, dictionary.validation.firstNameMax)
    .matches(nameExpression, dictionary.validation.firstNameLatter),
  surname: Yup.string()
    .trim()
    .required(dictionary.validation.lastName)
    .min(1, dictionary.validation.lastNameMin)
    .max(50, dictionary.validation.lastNameMax)
    .matches(nameExpression, dictionary.validation.lastNameLatter),
  dateOfBirth: Yup.date()
    .required(dictionary.validation.dateOfBirth),
  gender: Yup.string()
    .required(dictionary.validation.gender),
  email: Yup.string()
    .email(dictionary.validation.invalidEmail)
    .min(1, dictionary.validation.emptyEmail),
  phone: Yup.string()
    .required(dictionary.validation.phone)
    .min(17, dictionary.validation.invalidPhone)
    .matches(phoneExpression, dictionary.validation.invalidPhone),
  password: Yup.string()
    .required(dictionary.validation.createPassword)
    .min(8, dictionary.validation.passwordMin)
    .matches(passwordExpression, dictionary.validation.passwordMin),
  repeatedPassword: Yup.string()
    .required(dictionary.validation.passwordAgain)
    .oneOf([Yup.ref('password')], dictionary.validation.matchPassword),
  parentEmail: Yup.string()
    .when('dateOfBirth', {
      is: (val: number | Date) => (val ? calculateAge(format(val, 'yyyy-MM-dd')) : false),
      then: (schema) => schema
        .email(dictionary.validation.invalidEmail)
        .required(dictionary.validation.enterEmail),
      otherwise: (schema) => schema.nullable(),
    }),
  parentName: Yup.string()
    .when('dateOfBirth', {
      is: (val: number | Date) => (val ? calculateAge(format(val, 'yyyy-MM-dd')) : false),
      then: (schema) => schema
        .required(dictionary.validation.parentName)
        .min(1, dictionary.validation.parentMinChars)
        .max(50, dictionary.validation.firstNameMax)
        .matches(nameExpression, dictionary.validation.parentNameLatter),
      otherwise: (schema) => schema.nullable(),
    }),
  parentSurname: Yup.string()
    .when('dateOfBirth', {
      is: (val: number | Date) => (val ? calculateAge(format(val, 'yyyy-MM-dd')) : false),
      then: (schema) => schema
        .required(dictionary.validation.parentSurName)
        .min(1, dictionary.validation.parentSurNameMin)
        .max(50, dictionary.validation.lastNameMax)
        .matches(nameExpression, dictionary.validation.parentSurnameLatter),
      otherwise: (schema) => schema.nullable(),
    }),
  parentPhone: Yup.string()
    .when('dateOfBirth', {
      is: (val: number | Date) => (val ? calculateAge(format(val, 'yyyy-MM-dd')) : false),
      then: (schema) => schema
        .required(dictionary.validation.phone)
        .min(17, dictionary.validation.invalidPhone)
        .matches(phoneExpression, dictionary.validation.invalidPhone),
      otherwise: (schema) => schema.nullable(),
    }),
});

export default sighUpValidationSchemaWithParent;
