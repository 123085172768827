import { components, GroupBase, OptionProps } from 'react-select';
import { Checkbox } from '@components/Checkbox';
import { TTrial } from '@pages/Trials/components/TrialsTable/types';

export const MultiOptions = (props:OptionProps<TTrial, boolean, GroupBase<TTrial>>) => {
  const { data, isSelected } = props;

  return (
    <div className="option">
      <components.Option {...props}>
        <div className="option-label">
          <Checkbox
            checked={isSelected}
            label={data?.protocolName}
            name={data?.protocolName}
          />
        </div>
      </components.Option>
    </div>
  );
};
