import React, { FC } from 'react';
import { Button } from '@components/Button';
import { DateField } from '@components/Form/DateField';
import { InputField } from '@components/Form/InputField';
import { PhoneField } from '@components/Form/PhoneField';
import { SelectField } from '@components/Form/SelectField';
import { TUserWithParents } from '@api/user/types';
import { GenderOptions } from '@constants/options/genderOptions';
import { IDictionary } from '@constants/languages/types';
import { Roles } from '@constants/roles';

interface IUserProfileProps {
  changePatient?: boolean,
  patientInformation?: boolean,
  isStaff: string,
  userData?: TUserWithParents,
  isAccountVerified: boolean,
  changeEmailModal: ()=>void,
  modal?: boolean,
  isPhoneVerified: boolean,
  changePhoneModal: ()=>void,
  dictionary: IDictionary
}

export const UserProfile:FC<IUserProfileProps> = ({ patientInformation,
  changePatient = false,
  isStaff,
  userData,
  isAccountVerified,
  modal,
  changeEmailModal,
  isPhoneVerified,
  changePhoneModal,
  dictionary }) => (
    <section className="form-section">
      {patientInformation && <h2 className="form-title title-md">{dictionary.titles.patientInformation}</h2>}
      <div className="row">
        <div className="col-xs-12 col-md-6">
          <InputField
            disabled={isStaff === Roles.patient}
            placeholder={dictionary.placeholders.firstName}
            label={dictionary.labels.firstName}
            required
            name="name"
          />
        </div>
        <div className="col-xs-12 col-md-6">
          <InputField
            disabled={isStaff === Roles.patient}
            placeholder={dictionary.placeholders.lastName}
            label={dictionary.labels.lastName}
            required
            name="surname"
          />
        </div>
      </div>
      {isStaff === Roles.patient || changePatient
          && (
          <div className="row">
            <div className="col-xs-12 col-md-6">
              <DateField
                maxDate={false}
                onChangeAge
                disabled={isStaff === Roles.patient}
                type="text"
                placeholder="MM/DD/YYYY"
                label={dictionary.labels.dateOfBirth}
                required
                name="dateOfBirth"
              />
            </div>
            <div className="col-xs-12 col-md-6">
              <SelectField
                value={userData?.gender}
                disabled={isStaff === Roles.patient}
                selectOptions={GenderOptions()}
                placeholder={dictionary.placeholders.chooseGenderByBirth}
                label={dictionary.labels.genderByBirth}
                required
                name="gender"
              />
            </div>
          </div>
          )}
      {modal && (
      <div className="row">
        <div className="col-xs-12">
          <InputField
            disabled={isStaff === Roles.patient}
            placeholder={dictionary.placeholders.enterPatientId}
            label={dictionary.labels.patientId}
            name="userIdFromSponsor"
          />
        </div>
      </div>
      )}
      <div className="row">
        <div className="col-xs-12">
          <InputField
            status
            isVerified={isAccountVerified}
            value={userData?.email}
            disabled
            type="email"
            placeholder={dictionary.placeholders.enterYourEmail}
            label={dictionary.labels.email}
            required
            name="email"
          />
          { (isStaff === Roles.admin || isStaff === Roles.superAdmin || isStaff === Roles.staff) && (
            <Button
              variant="link"
              handleClick={changeEmailModal}
              label={dictionary.labels.changeEmail}
            />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <PhoneField
            status
            value={userData?.phone}
            isVerified={isPhoneVerified}
            isDisabled
            type="text"
            placeholder={dictionary.placeholders.phoneMask}
            label={dictionary.labels.phoneNumber}
            required
            name="phone"
          />
          { (isStaff === Roles.admin || isStaff === Roles.superAdmin || isStaff === Roles.staff) && (
            <Button
              variant="link"
              handleClick={changePhoneModal}
              label={dictionary.labels.changePhoneNumber}
            />
          )}
        </div>
      </div>
    </section>
);
