import { eachDayOfInterval, startOfYear, endOfYear, addDays } from 'date-fns';
import { IDictionary } from '@constants/languages/types';

export const getDaysOfYear = (dictionary: IDictionary, startFrom?: number, selectedDates?: string[], currentDate?: string) => {
  const startDate = startOfYear(new Date());

  const endDate = endOfYear(new Date());

  const dates = eachDayOfInterval({
    start: startDate, end: addDays(endDate, 1),
  });

  if (selectedDates) {
    return dates.map((date, index) => ({
      label: `${dictionary.schedule.table.day} ${index}`, value: String(index),
    })).slice(startFrom).filter((val) => !selectedDates?.includes(val.value)).concat({
      label: `${dictionary.schedule.table.day} ${currentDate}`, value: String(currentDate),
    });
  }

  return dates.map((date, index) => ({
    label: `${dictionary.schedule.table.day} ${index}`, value: String(index),
  })).slice(startFrom);
};
