import React, { FC } from 'react';
import { Button } from '@components/Button';
import { ButtonVariants } from '@components/Button/types';
import { UilBell, UilCheck, UilEnvelopeRedo, UilMultiply, UilPen, UilPlus, UilTrash } from '@iconscout/react-unicons';

export enum ActionType {
  add = 'add',
  delete = 'delete',
  refresh = 'refresh',
  edit = 'edit',
  approve = 'approve',
  decline = 'decline',
  notification = 'notification'
}

export type TActionTypeValue = `${ActionType}`;

export interface IAction<T extends Record<string, unknown> = Record<string, unknown>> {
  type: TActionTypeValue;
  text?: string;
  showCondition?: (row: T) => boolean;
  isDisabled?: (row: T) => boolean;
  handleClick: (row: T) => void;
}

export interface IActionsFormatterProps<T extends Record<string, unknown> = Record<string, unknown>> {
  row?: T;
  actions: IAction<T>[];
}

export const actionsFormatter: FC<IActionsFormatterProps> = ({ row = {
},
actions }) => {
  const actionIcons: Record<ActionType, JSX.Element | false> = {
    add: <UilPlus />,
    delete: <UilTrash />,
    refresh: <UilEnvelopeRedo />,
    edit: <UilPen />,
    approve: <UilCheck />,
    decline: <UilMultiply />,
    notification: <UilBell />,
  };

  const actionVariants: Record<ActionType, ButtonVariants | undefined> = {
    add: ButtonVariants.success,
    delete: ButtonVariants.danger,
    refresh: ButtonVariants.info,
    edit: ButtonVariants.warning,
    approve: ButtonVariants.success,
    decline: ButtonVariants.danger,
    notification: ButtonVariants.info,
  };

  const getIcon = (type: TActionTypeValue) => actionIcons[type];

  const getVariant = (type: TActionTypeValue) => actionVariants[type];

  const actionGroup = actions.map(({ type: actionType,
    showCondition = () => true,
    text,
    isDisabled = () => false,
    handleClick }, index) => (
    (showCondition && showCondition(row)) && (
      <Button
        key={index}
        size="small"
        isDisabled={isDisabled(row)}
        iconPrepend={getIcon(actionType)}
        variant={getVariant(actionType)}
        handleClick={() => handleClick(row)}
        label={text}
      />
    )
  ));

  return <div className="table-actions-list">{actionGroup}</div>;
};
