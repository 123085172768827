import React from 'react';
import { FormikValues } from 'formik';
import { IModalProps as IConfirmModalProps, ModalTypes } from '@components/Confirm/types';
import { IModalProps } from '@components/Modal/types';
import { IChangeEmail } from '@api/patients/types';
import { IResponse, IUserResponse } from '@api/user/types';
import changeEmailValidationSchema from '@helpers/validationSchemas/changeEmailValidationSchema';
import { ChangeEmailFormContent, emailInitialValues } from '@pages/Profile/ModalContent/ChangeEmail/Form';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition,
} from '@reduxjs/toolkit/query';
import { errorAlert, successAlert } from '@helpers/alerts/alerts';
import { IDictionary } from '@constants/languages/types';
import { emptyString } from '@constants/emptyString';

export const changeEmailModal = (
  openModal: (state: IModalProps) => void,
  openConfirmationModal: (state: IConfirmModalProps) => void,
  onSubmit: MutationTrigger<MutationDefinition<IChangeEmail, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta>, 'Users', IResponse, 'userAPI'>>,
  dictionary: IDictionary,
  currentEmail?: string,
  userId?: string,
  refetch?: () => void,
  changeParentEmail?: MutationTrigger<MutationDefinition<any, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta>, 'Users', IUserResponse, 'userAPI'>>,
  parentId?: string,
  parentEmail?: boolean,

) => {
  const onEditEmail = (newEmail: string) => openConfirmationModal({
    variant: ModalTypes.approve,
    title: dictionary.patient.modals.verifyEmail.title,
    text: `${dictionary.patient.modals.verifyEmail.text} ${newEmail} ${dictionary.patient.modals.verifyEmail.text2}`,
    submitButtonTitle: dictionary.table.ok,
    showCloseBtn: false,
  });

  const onSubmitParentForm = async (formValues: FormikValues, closeModal: () => void) => {
    if (formValues.newEmail && formValues.repeatedNewEmail) {
      changeParentEmail && changeParentEmail({
        parent: [
          {
            id: parentId,
            user_id: userId,
            email: formValues.newEmail,
          },
        ],
      })
        .unwrap()
        .then(async ({ success,
          message }) => {
          if (success === 1) {
            closeModal();
            successAlert(emptyString, message);
          } else {
            errorAlert(emptyString, message);
          }
        }).then(() => refetch && refetch())
        .catch(({ message }) => errorAlert(emptyString, message));
    } else {
      errorAlert(emptyString, dictionary.validation.emptyEmail);
    }
  };

  const onSubmitForm = async (formValues: FormikValues, closeModal: () => void) => {
    if (!!formValues.newEmail && !!formValues.repeatedNewEmail) {
      onSubmit({
        userId,
        currentEmail,
        newEmail: formValues.newEmail,
        repeatedEmail: formValues.repeatedEmail,
      })
        .unwrap()
        .then(async ({ success,
          message }) => {
          if (success === 1) {
            await closeModal();
            setTimeout(() => onEditEmail(formValues.newEmail), 1000);
          } else {
            errorAlert(emptyString, message);
          }
        })
        .then(() => refetch && refetch())
        .then(() => onEditEmail(formValues.newEmail))
        .catch(({ message }) => errorAlert(emptyString, message));
    } else {
      errorAlert(emptyString, dictionary.validation.emptyEmail);
    }
  };

  openModal({
    children: <ChangeEmailFormContent parent={parentEmail} dictionary={dictionary} currentEmail={currentEmail} />,
    initialValues: emailInitialValues(currentEmail),
    validationSchema: changeEmailValidationSchema(dictionary),
    title: dictionary.profile.modals.changeEmail,
    submitButtonTitle: dictionary.buttons.saveChanges as string,
    onSubmit: parentEmail ? onSubmitParentForm : onSubmitForm,
  });
};
