import React, { FC } from 'react';
import { ITrial } from '@api/trial/types';

export interface ITrialsFormatterProps {
  trials?: ITrial[];
}

export const trialsFormatter:FC<ITrialsFormatterProps> = ({ trials }) => {
  if (!trials?.length) {
    return <span className="table-cell-placeholder">-</span>;
  }

  const trialsList = trials?.map(({ protocolName }) => <li>{protocolName}</li>);

  return <ul>{trialsList}</ul>;
};
