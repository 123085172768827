import { useLanguageContext } from '@hooks/useLanguageContext';

export const ReasonsOptions = () => {
  const { dictionary } = useLanguageContext();

  return [
    {
      value: dictionary.appointments.modals.declineAppointment.reason.scheduleConflict,
      label: dictionary.appointments.modals.declineAppointment.reason.scheduleConflict,
    },
    {
      value: dictionary.appointments.modals.declineAppointment.reason.noTransportation,
      label: dictionary.appointments.modals.declineAppointment.reason.noTransportation,
    },
    {
      value: dictionary.appointments.modals.declineAppointment.reason.feelingUnwell,
      label: dictionary.appointments.modals.declineAppointment.reason.feelingUnwell,
    },
    {
      value: dictionary.appointments.modals.declineAppointment.reason.other,
      label: dictionary.appointments.modals.declineAppointment.reason.other,
    },
  ];
};
