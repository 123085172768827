import React from 'react';
import { Container } from '@components/Container';
import { Section } from '@components/Section';
import { ProfileForm } from '@pages/Profile/components/ProfileForm';

export const Profile = () => (
  <div className="main-content">
    <Container size="lg">
      <div className="panel">
        <Section containerSize="md">
          <ProfileForm />
        </Section>
      </div>
    </Container>
  </div>
);
