import { TUserWithParents } from '@api/user/types';
import { IUser } from '@store/types';

export const UserOption = (currentUser: TUserWithParents | IUser) => {
  const userData = `${currentUser?.name} ${currentUser?.surname}`;

  return {
    value: userData,
    label: userData,
  };
};
