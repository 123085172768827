import React, { FC } from 'react';
import { IInfoProps, InfoVariants } from '@components/Info/types';
import { UilInfoCircle } from '@iconscout/react-unicons';
import './styles.scss';

export const Info:FC<IInfoProps> = ({ text, phone, variant = InfoVariants.info }) => {
  const infoClassName = `info ${variant && `is-${variant}`}`;

  const renderText = () => (
    <p className="info-content">
      {text}
      {phone && (
        <span className="info-content-phone">
          {phone}
        </span>
      )}
    </p>
  );

  return (
    <div className={infoClassName}>
      <span className="info-icon">
        <UilInfoCircle size={16} />
      </span>
      {renderText()}
    </div>
  );
};
