import React, { FC } from 'react';
import { SingleValue } from 'react-select';
import { SelectWithIcon } from '@components/Dropdown';
import { IOptions } from '@components/Select/types';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { useLanguageSelect } from '@hooks/useLanguageSelect';

export const LanguageMenu: FC = () => {
  const { userLanguage, changeLanguage } = useLanguageContext();

  const { langOptions } = useLanguageSelect();

  const onChangeLang = (newValue: SingleValue<IOptions>) => changeLanguage(newValue?.id);

  const defaultValue = langOptions.find(({ id }) => id === userLanguage);

  return (
    <div className="dropdown">
      <SelectWithIcon
        options={langOptions}
        handleChange={onChangeLang}
        defaultValue={defaultValue}
      />
    </div>
  );
};
