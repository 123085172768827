import React, { FC } from 'react';
import { AutocompleteVariants, IInputProps } from '@components/Input/types';
import './styles.scss';

export const Input:FC<IInputProps> = (props) => {
  const { type = 'text',
    disabled,
    required,
    value = '',
    placeholder = 'Some text...',
    autocomplete = AutocompleteVariants.off,
    hasError,
    iconAppend,
    iconPrepend } = props;

  const inputClassName = `input-control ${hasError ? 'has-error' : ''}`;

  return (
    <div className="input">
      {iconPrepend && (
      <span className="input-prepend-icon" role="presentation">
        {iconPrepend}
      </span>
      )}
      {iconAppend && (
      <span className="input-append-icon" role="presentation">
        {iconAppend}
      </span>
      )}
      <input
        className={inputClassName}
        type={type}
        disabled={disabled}
        value={value}
        placeholder={placeholder}
        required={required}
        autoComplete={autocomplete}
        {...props}
      />
    </div>
  );
};
