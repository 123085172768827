import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@components/Button';
import { Container } from '@components/Container';
import { ContainerSizes } from '@components/Container/types';
import { UilCheck } from '@iconscout/react-unicons';
import { Routes } from '../../router';
import { ISuccessScreenProps } from './types';
import './styles.scss';

export const SuccessScreen:FC<ISuccessScreenProps> = (props) => {
  const { title = '',
    description = '',
    linkText = '',
    linkUrl = Routes.SignUp,
    containerSize = ContainerSizes.xl,
    children } = props;

  const navigate = useNavigate();

  const onDoneBtnClick = () => {
    navigate(linkUrl);
  };

  return (
    <div className="success">
      <Container size={containerSize}>
        <div className="success-content">
          <span className="success-icon">
            <UilCheck />
          </span>
          {title && (
          <h2 className="success-title title-lg">
            {title}
          </h2>
          )}
          {description && (
          <p className="success-text text-md">
            {description}
          </p>
          )}
          {children && (
            <div className="success-text">
              {children}
            </div>
          )}
          {linkText && (
          <Button
            label={linkText}
            size="large"
            handleClick={onDoneBtnClick}
          />
          )}
        </div>
      </Container>
    </div>
  );
};
