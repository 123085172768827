export const calcAmountByProperty = (objectArray: Record<string, unknown>[], property: string) => {
  const properties = objectArray?.length ? Array.from(new Set(objectArray.map((item) => item[property]))) : [];

  return properties.reduce((typesAcc, currentValue) => [
    ...typesAcc as Record<string, unknown>[],
    {
      [property]: currentValue,
      amount: objectArray.filter((item) => item[property] === currentValue).length,
    },
  ], []);
};
