import React, { FC } from 'react';
import { InputField } from '@components/Form/InputField';
import { TLocations } from '@pages/Locations/LocationsTable/types';
import { IDictionary } from '@constants/languages/types';
import { PhoneField } from '@components/Form/PhoneField';

interface IFormContentProps {
  dictionary: IDictionary
}
export const editLocationInitialValues = (name: string, number: string, address: string, id?: number) => ({
  name,
  number,
  address,
  id,
});

export const addLocationInitialValues: TLocations = {
  name: '',
  number: '',
  address: '',
};

export const FormContent: FC<IFormContentProps> = ({ dictionary }) => (
  <div className="form-field">
    <div className="row">
      <div className="col-xs-12">
        <InputField
          type="text"
          placeholder={dictionary.placeholders.locationName}
          label={dictionary.labels.locationName}
          required
          name="name"
        />
      </div>
    </div>
    <div className="row">
      <div className="col-xs-12">
        <PhoneField
          type="text"
          placeholder={dictionary.placeholders.enterPhoneNumber}
          label={dictionary.labels.phoneNumber}
          required
          name="number"
        />
      </div>
    </div>
    <div className="row">
      <div className="col-xs-12">
        <InputField
          type="text"
          placeholder={dictionary.placeholders.locationAddress}
          label={dictionary.labels.locationAddress}
          required
          name="address"
        />
      </div>
    </div>
  </div>
);
