import { IDictionary } from '@constants/languages/types';
import * as Yup from 'yup';
import { nameExpression } from '@constants/regularExpression';

const changeProfileValidationSchema = (dictionary: IDictionary) => Yup.object({
  name: Yup.string()
    .required(dictionary.validation.firstName)
    .min(1, dictionary.validation.firstNameMin)
    .max(50, dictionary.validation.firstNameMax)
    .matches(nameExpression, dictionary.validation.firstNameLatter),
  surname: Yup.string()
    .required(dictionary.validation.lastName)
    .min(1, dictionary.validation.lastNameMin)
    .max(50, dictionary.validation.lastNameMax)
    .matches(nameExpression, dictionary.validation.lastNameLatter),
  date_of_birth: Yup.date(),
  gender: Yup.string(),
  userIdFromSponsor: Yup.string().nullable().notRequired(),
});

export default changeProfileValidationSchema;
