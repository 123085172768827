import React, { Dispatch } from 'react';
import { FormikValues } from 'formik';
import { IModalProps } from '@components/Modal/types';
import { IChangePhone } from '@api/patients/types';
import { IResponse } from '@api/user/types';
import { errorAlert, successAlert } from '@helpers/alerts/alerts';

import { ChangePhoneFormContent, phoneInitialValues } from '@pages/Profile/ModalContent/ChangePhone/Form';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition,
} from '@reduxjs/toolkit/query';
import { IDictionary } from '@constants/languages/types';
import { emptyString } from '@constants/emptyString';

export const changePhoneModal = (
  openModal: (state: IModalProps) => void,
  changePhone: MutationTrigger<MutationDefinition<IChangePhone, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta>, 'Users' | 'Patients', IResponse, 'userAPI' | 'patientsAPI'>>,
  onSubmit: MutationTrigger<MutationDefinition<IChangePhone, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta>, 'Users' | 'Patients', IResponse, 'userAPI' | 'patientsAPI'>>,
  dictionary: IDictionary,
  setIsError: Dispatch<string>,
  newPhone: string,
  setNewPhone: Dispatch<string>,
  isError: string,
  phoneNumber?: string,
  refetch?: () => void,
  verification?: React.MutableRefObject<Record<string, unknown> | null>,
  userId?: string,
) => {
  const onSubmitForm = async (formValues: FormikValues, closeModal: () => void) => {
    verification?.current?.value
      ? onSubmit(userId ? {
        userId,
        code: Number(verification?.current?.value),
      } : {
        code: Number(verification?.current?.value),
        phone: phoneNumber,
        newPhone: formValues.newPhone.replace(/[-()\s]/g, ''),
      })
        .unwrap()
        .then(async ({ success,
          message }) => {
          if (success === 1) {
            closeModal();
            refetch && refetch();
            successAlert(emptyString, message);
          } else {
            errorAlert(emptyString, message);
          }
        })
        .catch(({ data }) => errorAlert(emptyString, data.message)) : errorAlert(emptyString, dictionary.profile.modals.verifyPhone);
  };

  return openModal({
    children: <ChangePhoneFormContent
      dictionary={dictionary}
      verificationRef={verification}
      changePhone={changePhone}
      userId={userId}
      setNewPhone={setNewPhone}
      phoneNumber={phoneNumber}
      isError={isError}
    />,
    initialValues: phoneInitialValues(phoneNumber),
    validationSchema: null,
    title: dictionary.profile.modals.changePhoneNumber,
    submitButtonTitle: dictionary.buttons.saveChanges as string,
    setError: setIsError,
    onSubmit: onSubmitForm,
  });
};
