import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import { Button } from '@components/Button';
import { Section } from '@components/Section';
import { DetailsForm } from '@pages/Appointment/pages/CreateAppointment/components/DetailsForm';
import { GeneralInformationForm } from '@pages/Appointment/pages/CreateAppointment/components/GeneralInformationForm';
import { useAddNewAppointmentsMutation } from '@api/appointments/appointmentsApi';
import { useFetchAllLocationsWithoutPaginationQuery } from '@api/locations/locationsAPI';
import { useFetchAllPatientsWithoutPaginationQuery } from '@api/patients/patientsAPI';
import { useFetchAllTrialsWithoutPaginationQuery } from '@api/trial/trialAPI';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { errorAlert } from '@helpers/alerts/alerts';
import { ModalTypes } from '@components/Confirm/types';
import { useConfirmationModal } from '@components/Confirm/context/useConfirmContext';
import appointmentsValidationSchema from '@helpers/validationSchemas/appointmentsValidationSchema';
import { IEditAppointments } from '@api/appointments/types';
import { convertTime } from '@helpers/convertTime';
import { getEndTime, getStartTime } from '@helpers/getVisitTime';
import { timeToTimestampFormatter } from '@helpers/timeToTimestampFormatter';
import { emptyString } from '@constants/emptyString';
import { Routes } from '../../../../../router';

export const CreateAppointmentForm: FC = () => {
  const { dictionary } = useLanguageContext();

  const { openConfirmationModal } = useConfirmationModal();

  const { data: patients } = useFetchAllPatientsWithoutPaginationQuery();

  const [addAppointment] = useAddNewAppointmentsMutation();

  const { data: trials } = useFetchAllTrialsWithoutPaginationQuery();

  const { data: locations } = useFetchAllLocationsWithoutPaginationQuery();

  const trialOptions = trials?.data.results.map(({ protocolName: label,
    id: value }) => ({
    label,
    value,
  }));

  const navigate = useNavigate();

  const appointmentSettings = {
    trialId: '',
    patientId: '',
    contactPhone: '',
    locationId: '',
    physicianId: '',
    adult: 0,
    appointmentSettings: [],
  };

  const patientsOptions = patients?.data.results.map(({ name, surname,
    id }) => ({
    label: `${name} ${surname}`,
    value: id,
  }));

  const locationOptions = locations?.data.results.map(({ name: label,
    id: value }) => ({
    label,
    value: String(value),
  }));

  const onSubmitForm = async (formValues: any) => {
    const currentPatient = patients?.data.results.find(({ id }) => id === formValues.patientId);

    if (
      formValues.appointmentSettings[0].date !== formValues.appointmentSettings[1].date
    ) {
      if (currentPatient) {
        addAppointment({
          ...formValues,
          adult: formValues?.adult,
          contactPhone: formValues.contactPhone.replace(/[-()\s]/g, ''),
          appointmentSettings: formValues.appointmentSettings.map((setting: IEditAppointments) => ({
            ...setting,
            from: timeToTimestampFormatter(setting.time.slice(0, 8), new Date(setting.date)),
            to: timeToTimestampFormatter(setting.time.slice(-8), new Date(setting.date)),
            date: format(new Date(setting?.date), 'MM/dd/yyyy'),
            fromTime: convertTime(getStartTime(setting.time)),
            toTime: convertTime(getEndTime(setting.time)),
          })),
        })
          .unwrap()
          .then(async ({ success,
            message }) => {
            if (success === 1) {
              navigate(Routes.Appointments);
            } else {
              errorAlert(message);
            }
          })
          .catch(({ message }) => errorAlert(message));
      }
    } else {
      errorAlert(emptyString, dictionary.validation.sameTime);
    }
  };

  const onCancelClick = () => openConfirmationModal({
    variant: ModalTypes.decline,
    title: dictionary.appointments.modals.cancel.title,
    text: dictionary.appointments.modals.cancel.text,
    submitButtonTitle: dictionary.table.ok,
    handleConfirmClick: () => navigate(Routes.Appointments),
  });

  return (
    <Formik
      enableReinitialize
      initialValues={appointmentSettings}
      validationSchema={appointmentsValidationSchema(dictionary)}
      onSubmit={onSubmitForm}
      validateOnMount
      validateOnBlur
      validateOnChange
    >
      <Form className="form" noValidate>
        <div className="form-body">
          <Section title={dictionary.appointments.modals.createAppointment.title}>
            <section className="form-section">
              <h2 className="form-title title-md is-underlined">{dictionary.trials.modals.createTrial.generalInformation}</h2>
            </section>
            <section className="form-section">
              <GeneralInformationForm patientOptions={patientsOptions} trialOptions={trialOptions} locationOptions={locationOptions} />
            </section>
            <section className="form-section">
              <DetailsForm />
            </section>
            <section className="form-section form-footer">
              <Button
                label={dictionary.actions.cancel}
                variant="default"
                handleClick={onCancelClick}
                size="static"
              />
              <Button
                type="submit"
                label={dictionary.actions.create}
                size="static"
              />
            </section>
          </Section>
        </div>
      </Form>
    </Formik>
  );
};
