import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormikValues } from 'formik';
import { Breadcrumbs } from '@components/Breadcrumbs';
import { Card } from '@components/Card';
import { ModalTypes } from '@components/Confirm/types';
import { Section } from '@components/Section';
import { IAction } from '@components/Table/utils/actionsFormatter';
import { skipToken } from '@reduxjs/toolkit/query';
import { Table } from '@components/Table';
import { EditNotification } from '@pages/Appointment/components/EditNotification';
import { EditAppointmentForm } from '@pages/Appointment/components/EditAppointment';
import { useFetchLocationsQuery } from '@api/locations/locationsAPI';
import {
  useDeleteAppointmentsMutation,
  useEditAppointmentsMutation,
  useEditGeneralInfoAppointmentsMutation,
  useFetchAllUserAppointmentsQuery,
  useFetchAppointmentByIdQuery, useResendAppointmentMutation,
} from '@api/appointments/appointmentsApi';
import { errorAlert } from '@helpers/alerts/alerts';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { useModal } from '@components/Modal/context/useModalContext';
import { useConfirmationModal } from '@components/Confirm/context/useConfirmContext';
import { TAppointment } from '@api/appointments/types';
import { ContainerSizes } from '@components/Container/types';
import { StatusVariants } from '@constants/options/statusOptions';
import notificationValidationSchema from '@helpers/validationSchemas/notificationValidationSchema';
import { Loader } from '@components/Loader';
import { emptyString } from '@constants/emptyString';
import { Routes } from '../../router';
import { tableSettings } from './constants/tableSettings';

export const Appointment = () => {
  const { dictionary } = useLanguageContext();

  const { id } = useParams();

  const { data: appointment, refetch, isLoading } = useFetchAppointmentByIdQuery(id ?? skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const { data: allUserAppointments, isLoading: isLoadingAppointments } = useFetchAllUserAppointmentsQuery((appointment && {
    patientId: appointment?.data.patientId || emptyString, trialId: appointment?.data.trialId || emptyString,
  }) ?? skipToken);

  const { data: AllLocations } = useFetchLocationsQuery();

  const [deleteAppointments] = useDeleteAppointmentsMutation();

  const [resendAppointment] = useResendAppointmentMutation();

  const [editAppointment] = useEditAppointmentsMutation();

  const [editGeneralInfo] = useEditGeneralInfoAppointmentsMutation();

  const { openModal } = useModal();

  const { openConfirmationModal } = useConfirmationModal();

  const navigate = useNavigate();

  const isLoadingData = isLoading || isLoadingAppointments;

  const locationOptions = AllLocations?.data.results.map(({ name: label,
    id: value }) => ({
    label,
    value: String(value),
  }));

  const appointmentData = ({ trials, patients, contactPhone, locations }: TAppointment) => [
    {
      label: dictionary.appointments.cards.trial,
      value: trials.protocolName,
    },
    {
      label: dictionary.appointments.cards.physicianName,
      value: trials.physicians.name,
    },
    {
      label: dictionary.appointments.cards.patient,
      value: `${patients.name} ${patients.surname}`,
    },
    {
      label: dictionary.appointments.cards.phone,
      value: contactPhone,
    },
    {
      label: dictionary.appointments.cards.location,
      value: locations.name,
    },
    {
      label: dictionary.appointments.cards.address,
      value: locations.address,
    },
  ];

  const rejectedAppointment = ({ comment }: TAppointment) => [
    {
      label: dictionary.appointments.cards.reason,
      value: comment.reason,
    },
    {
      label: dictionary.appointments.cards.comment,
      value: comment.comment.length ? comment.comment : '-',
    },
  ];

  const generalInitialValue = {
    contactPhone: appointment?.data.contactPhone,
    locationId: appointment?.data.locationId,
  };

  const onSubmitForm = async (formValues: FormikValues, closeModal: () => void) => {
    if (allUserAppointments?.data) {
      editGeneralInfo({
        location_id: formValues.locationId,
        contact_phone: formValues.contactPhone.replace(/[-()\s]/g, ''),
        appointmentsIds: allUserAppointments?.data.map(({ id: usersAppointmentId }) => usersAppointmentId),
      })
        .unwrap()
        .then(async () => {
          await closeModal();
          refetch();
        })
        .catch(({ message }) => errorAlert(message));
    }
  };

  const onSubmitEditNotification = async (formValues: FormikValues, closeModal: () => void) => {
    if (!!formValues.notificationsSettings.types.sms || !!formValues.notificationsSettings.types.email) {
      editAppointment({
        notificationsSettings: formValues.notificationsSettings,
        id: formValues.id,
      }).unwrap()
        .then(async () => {
          await closeModal();
        })
        .catch(({ message }) => errorAlert(message));
    } else errorAlert(emptyString, dictionary.messages.fieldError);
  };

  const onEditNotification = (selectedAppointment: TAppointment) => {
    openModal({
      children: <EditNotification appointmentTemplate={selectedAppointment} id={selectedAppointment.id} />,
      initialValues: {
        id: selectedAppointment.id,
        notificationsSettings: {
          types: {
            sms: selectedAppointment.notificationsSettings.types.sms,
            email: selectedAppointment.notificationsSettings.types.email,
          },
          first: selectedAppointment.notificationsSettings.first,
          second: selectedAppointment.notificationsSettings.second,
        },
      },
      containerSize: ContainerSizes.md,
      validationSchema: notificationValidationSchema(dictionary),
      title: dictionary.appointments.modals.createAppointment.notification,
      submitButtonTitle: dictionary.actions.save,
      onSubmit: onSubmitEditNotification,
    });
  };

  const onDeleteAppointment = (SelectedAppointment: TAppointment) => {
    openConfirmationModal({
      variant: ModalTypes.decline,
      title: dictionary.appointments.modals.deleteAppointments.title,
      text: dictionary.appointments.modals.deleteAppointments.text,
      submitButtonTitle: dictionary.table.delete,
      handleConfirmClick: () => {
        if (id === SelectedAppointment.id) {
          deleteAppointments(SelectedAppointment.id);
          navigate(Routes.Appointments);
        } else {
          deleteAppointments(SelectedAppointment.id);
          refetch();
        }
      },
    });
  };

  const onEditAppointment = () => {
    openModal({
      children: <EditAppointmentForm locationOptions={locationOptions} />,
      initialValues: generalInitialValue,
      validationSchema: null,
      title: dictionary.labels.editInformation,
      submitButtonTitle: dictionary.actions.save,
      onSubmit: onSubmitForm,
    });
  };

  const informationActions: IAction<Record<string, unknown>>[] = [
    {
      type: 'edit',
      text: dictionary.table.edit,
      handleClick: onEditAppointment,
    },
  ];

  const onResendAppointment = (selectedAppointment: TAppointment) => {
    resendAppointment(selectedAppointment.id);
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  };

  const rejectedAppointmentActions: IAction<Record<string, unknown>>[] = [
    {
      type: 'refresh',
      text: dictionary.labels.resendAppointment,
      handleClick: (selectedAppointment) => onResendAppointment(selectedAppointment as TAppointment),
    },
  ];

  const appointmentsActions: IAction<Record<string, unknown>>[] = [
    {
      type: 'edit',
      text: dictionary.table.edit,
      handleClick: () => navigate(`${Routes.Appointments}${Routes.Edit}/${appointment?.data.patientId || ''}/${appointment?.data.trialId}`),
    },
  ];

  const tableActions: IAction<TAppointment>[] = [
    {
      type: 'notification',
      handleClick: (selectedAppointment) => onEditNotification(selectedAppointment),
    },
    {
      type: 'decline',
      isDisabled: () => (allUserAppointments?.data ? allUserAppointments?.data.length <= 2 : false),
      handleClick: (selectedAppointment) => onDeleteAppointment(selectedAppointment),
    },
  ];

  return (
    <div className="main-content">
      {isLoadingData && <Loader />}
      <Breadcrumbs prevPageRoute={Routes.Appointments} prevPageTitle={dictionary.appointments.title} currentPageTitle={String(appointment?.data.name)} />
      <Section status={appointment?.data.status} title={String(appointment?.data.name)}>
        {appointment?.data.status === StatusVariants.rejected && (
        <Card
          title={dictionary.labels.rejectionInformation}
          data={appointment?.data && rejectedAppointment({
            ...appointment.data,
          })}
          actions={rejectedAppointmentActions}
          row={{
            ...appointment?.data,
          }}
        />
        )}
        {appointmentData && (
        <Card
          title={dictionary.trials.addTrial.generalInformation}
          data={appointment?.data && appointmentData({
            ...appointment.data,
          })}
          actions={informationActions}
          row={{
            ...appointment?.data,
          }}
        />
        )}
        <Card
          actions={appointmentsActions}
          title={dictionary.appointments.title}
          isDetailsCollapsible={false}
          details={!!allUserAppointments?.data && (
          <Table
            actions={tableActions}
            tableData={allUserAppointments?.data.map((appointments) => ({
              ...appointments,
            }))
              .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())}
            tableSettings={tableSettings}
            dictionaryKey="appointments"
          />
          )}
        />
      </Section>
    </div>
  );
};
