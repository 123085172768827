import { PropsWithChildren, useMemo, useRef, useState } from 'react';
import { Formik } from 'formik';
import { Info } from '@components/Info';
import { Modal } from '@components/Modal';
import { ModalContext } from '@components/Modal/context/ModalContext';
import { IModalProps, IModalRef } from '@components/Modal/types';

export const ModalContextProvider = ({ children }: PropsWithChildren) => {
  const ref = useRef<IModalRef>(null);

  const [modalForm, setModalForm] = useState<IModalProps | undefined>(undefined);

  const openModal = async (state: IModalProps) => {
    await setModalForm(state);

    ref.current?.openModal();
  };

  const closeModal = async () => {
    if (modalForm?.handleCloseClick) {
      await modalForm.handleCloseClick();
    }
    setModalForm(undefined);
  };

  const contextValues = {
    openModal,
    closeModal,
  };

  const cachedProvider = useMemo(() => contextValues, [contextValues]);

  return (
    <ModalContext.Provider value={cachedProvider}>
      {children}
      {modalForm && (
        <Formik
          initialValues={modalForm.initialValues}
          validationSchema={modalForm.validationSchema}
          onSubmit={(values) => modalForm.onSubmit && modalForm.onSubmit(values, closeModal)}
        >
          {({ submitForm, errors, touched, isValid, resetForm }) => (
            <Modal
              isSubmitDisabled={!Object.keys(touched).length || !isValid}
              ref={ref}
              handleCancelClick={() => resetForm()}
              title={modalForm.title}
              text={modalForm.text}
              variant={modalForm.variant}
              containerSize={modalForm.containerSize}
              submitButtonTitle={modalForm.submitButtonTitle}
              btnSize={modalForm.btnSize}
              handleConfirmClick={submitForm}
              handleCloseClick={closeModal}
            >
              {(!!Object.keys(errors).length && modalForm.errorTitle?.length) && <Info text={modalForm?.errorTitle} variant="warning" /> }
              {modalForm.children}
            </Modal>
          )}
        </Formik>
      )}
    </ModalContext.Provider>
  );
};
