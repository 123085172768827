import React, { PropsWithChildren } from 'react';
import { TableCell } from '@components/Table/components/TableCell';
import { actionsFormatter, IAction } from '@components/Table/utils/actionsFormatter';

export const TableRow = ({ children,
  actions,
  row }: PropsWithChildren<{ actions?: IAction<Record<string, unknown>>[], row?: Record<string, unknown> }>) => (
    <tr className="table-row">
      {children}
      {actions && (
      <TableCell isSticky>
        {actionsFormatter({
          actions, row,
        })}
      </TableCell>
      )}
    </tr>
);
