import React from 'react';
import { useLanguageContext } from '@hooks/useLanguageContext';
import './styles.scss';

export const Legend = () => {
  const { dictionary } = useLanguageContext();

  const legendList = [
    {
      label: dictionary.schedule.table.minorClient,
      type: 'warning',
    },
    {
      label: dictionary.schedule.table.adultClient,
      type: 'primary',
    },
    {
      label: dictionary.schedule.table.unavailableTime,
      type: 'default',
    },
  ];

  return (
    <div className="legend">
      <span className="legend-title">{`${dictionary.schedule.table.appointment}: `}</span>
      <ul className="legend-list">
        {legendList.map(({ label, type }, index) => (
          <li key={index} className={`legend-item is-${type}`}>
            <span className="legend-icon" />
            {label}
          </li>
        ))}
      </ul>
    </div>
  );
};
