export enum AlertVariants {
  success = 'success',
  warning = 'warning',
  danger = 'danger',
  info = 'info',
}

export interface IAlertProps {
  title?: string;
  text?: string;
  variant?: `${AlertVariants}`;
}
