import React from 'react';
import { ITableLegendProps } from '@components/Table/types';

export const TableLegend = ({ title, items, formatter }: ITableLegendProps) => (
  <div className="table-legend">
    <span className="table-legend-title">{title}</span>
    <ul className="table-legend-list">
      {items.map((item) => (
        <div className="table-legend-item">
          {formatter({
            registrationStatus: item,
          })}
        </div>
      ))}
    </ul>
  </div>
);
