import { ITableSettings } from '@components/Table/types';
import { NotificationFormatter } from '@pages/Appointment/utils/notificationFormatter';
import { FirstNotificationFormatter } from '@pages/Appointment/utils/firstNotificationFormatter';
import { SecondNotificationFormatter } from '@pages/Appointment/utils/secondNotificationFormatter';
import { dateFormatter } from '@pages/Appointment/utils/dateFormatter';
import { TAppointment } from '@api/appointments/types';

export const tableSettings: ITableSettings<TAppointment>[] = [
  {
    accessor: 'name',
    title: 'Appointment name',
  },
  {
    accessor: 'date',
    title: 'Date',
    formatter: dateFormatter,
  },
  {
    accessor: 'time',
    title: 'Time',
  },
  {
    accessor: 'type',
    title: 'Time',
  },
  {
    accessor: 'notificationsSettings',
    title: 'Send notification using',
    formatter: NotificationFormatter,
  },
  {
    accessor: 'first',
    title: 'First notification',
    formatter: FirstNotificationFormatter,
  },
  {
    accessor: 'second',
    title: 'Second notification',
    formatter: SecondNotificationFormatter,
  },
];
