import React, { useCallback, useEffect } from 'react';
import { useFormikContext } from 'formik';
import debounce from 'lodash.debounce';
import { IAutoSaveProps } from '@components/AutoSave/types';

export const AutoSave = ({ debounceMs }: IAutoSaveProps) => {
  const formik = useFormikContext();

  const debouncedSubmit = useCallback(
    debounce(
      () => formik.submitForm(),
      debounceMs,
    ),
    [debounceMs, formik.submitForm],
  );

  useEffect(() => {
    debouncedSubmit();
  }, [debouncedSubmit, formik.values]);

  return (
    <>
    </>
  );
};
