import React, { FC } from 'react';
import { Link } from '@components/Link';
import { Routes } from '../../../router';

export interface INameFormatterProps {
  id?: string;
  name?: string;
}

export const physicianFormatter: FC<INameFormatterProps> = ({ id, name }) => {
  if (!id) {
    return <span className="table-cell-placeholder">-</span>;
  }

  return (
    <div className="table-name">
      <Link variant="primary" to={`${Routes.Physicians}/${id}`} label={name} />
    </div>
  );
};
