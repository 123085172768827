import React, { useState } from 'react';
import { Section } from '@components/Section';
import { SuccessScreen } from '@components/SuccessScreen';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { useResendVerifyEmailMutation } from '@api/user/userAPI';
import { errorAlert, successAlert } from '@helpers/alerts/alerts';
import { useLocation } from 'react-router-dom';
import { emptyString } from '@constants/emptyString';
import { Heading } from '../../layout/AuthLayout/components/Heading';
import { Routes } from '../../router';
import { SignUpForm } from './components/SignUpForm';

export const SignUp = () => {
  const { dictionary } = useLanguageContext();

  const [userRegisterEmail, setUserRegisterEmail] = useState<string>('');

  const [verifyEmail] = useResendVerifyEmailMutation();

  const useQuery = () => new URLSearchParams(useLocation().search);

  const query = useQuery();

  const email = query.get('email');

  const token = query.get('tk');

  const resendEmail = () => {
    verifyEmail({
      email: userRegisterEmail,
    }).unwrap().then(({ success,
      message }) => {
      success === 1 ? successAlert(emptyString, message) : errorAlert(emptyString, message);
    })
      .catch(({ data }) => errorAlert(emptyString, data.message));
  };

  return (
    userRegisterEmail && !email
      ? (
        <SuccessScreen
          title={dictionary.signUp.confirmYourEmail}
          containerSize="sm"
          linkText={dictionary.actions.done}
          linkUrl={Routes.SignIn}
        >
          <p className="text-md">
            {dictionary.signUp.confirmationLinkSent}
          </p>
          <p className="text-md">
            {dictionary.signUp.haveNotReceived}
            {'? '}
            <span
              role="button"
              className="link link-inline link-outlined"
              tabIndex={0}
              onClick={resendEmail}
            >
              {dictionary.signUp.sendAgain}
            </span>
          </p>
        </SuccessScreen>
      )
      : (
        <Section containerSize="sm">
          <Heading>
            {dictionary.titles.welcome}
            <br />
            <strong className="text-primary">
              {dictionary.titles.schedulapp}
            </strong>
          </Heading>
          <SignUpForm token={token} email={email} setUserRegisterEmail={setUserRegisterEmail} />
        </Section>
      )
  );
};
