import React from 'react';
import { useParams } from 'react-router-dom';
import { Breadcrumbs } from '@components/Breadcrumbs';
import { Card } from '@components/Card';
import { useModal } from '@components/Modal/context/useModalContext';
import { Section } from '@components/Section';
import { useLanguageContext } from '@hooks/useLanguageContext';
import {
  useFetchUserByIdQuery,
} from '@api/user/userAPI';

import { Loader } from '@components/Loader';
import { skipToken } from '@reduxjs/toolkit/query';
import { IAction } from '@components/Table/utils/actionsFormatter';
import { getUserRole } from '@helpers/getUserRole';
import { editMemberInitialValues, MemberFormContent } from '@pages/Organization/components/memberForm';
import locationsValidationSchema from '@helpers/validationSchemas/locationsValidationSchema';
import { TUserWithParents } from '@api/user/types';
import { useFetchOrganizationByIdQuery } from '@api/organizations/organizationsAPI';
import { Routes } from '../../router';

export const OrganizationMember = () => {
  const { dictionary } = useLanguageContext();

  const { id } = useParams();

  const { data: userData } = useFetchUserByIdQuery(id ?? skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const { data: organization, isLoading } = useFetchOrganizationByIdQuery(userData?.organization_id ?? skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const { openModal } = useModal();

  const patientFullName = `${userData?.name} ${userData?.surname}`;

  const patientData = [
    {
      label: dictionary.labels.organizationName,
      value: organization?.data.name,
    },
    {
      label: dictionary.labels.email,
      value: userData?.email,
    },
    {
      label: dictionary.labels.phoneNumber,
      value: userData?.phone,
    },
    {
      label: dictionary.labels.memberId,
      value: userData?.id.slice(0, 8),
    },
    {
      label: dictionary.labels.role,
      value: userData?.userIdFromSponsor,
    },
  ];

  const onEditMember = (user: Record<string, unknown>) => {
    const userRole = getUserRole(user as TUserWithParents, user.token as string);

    openModal({
      children: <MemberFormContent dictionary={dictionary} isEdit />,
      initialValues: editMemberInitialValues(user.email as string, userRole),
      validationSchema: locationsValidationSchema(dictionary),
      title: dictionary.labels.editMemberRole,
      submitButtonTitle: dictionary.actions.save,
      onSubmit: (values) => console.log(values),
    });
  };

  const patientsActions: IAction[] = [
    {
      type: 'edit',
      text: dictionary.table.edit,
      handleClick: onEditMember,
    },
  ];

  return (
    <>
      <Breadcrumbs
        currentPageTitle={patientFullName}
        prevPageTitle={dictionary.organizations.title}
        prevPageRoute={Routes.Patients}
      />
      <Section title={patientFullName}>
        {isLoading ? <Loader /> : (
          <Card
            title={dictionary.labels.memberDetails}
            data={patientData}
            actions={patientsActions}
            row={userData}
          />
        )}
      </Section>
    </>
  );
};
