import { IDictionary } from '@constants/languages/types';

export enum StatusVariants {
  approved = 'Approved',
  pending = 'Pending',
  rejected = 'Rejected',
}

export const statusOptions = (dictionary: IDictionary) => [
  {
    value: StatusVariants.approved,
    label: dictionary.warnings.approved,
  },
  {
    value: StatusVariants.pending,
    label: dictionary.warnings.pending,
  },
  {
    value: StatusVariants.rejected,
    label: dictionary.warnings.rejected,
  },
];
