import React, { FC } from 'react';
import { LabelVariants } from '@components/Label/types';
import { IStatusFormatterProps } from '@components/Table/utils/statusFormatter';
import { InvitationStatuses } from '@constants/invitationStatuses';

export const shortStatusFormatter: FC<IStatusFormatterProps> = ({ registrationStatus }) => {
  const variant = (Object.keys(InvitationStatuses) as LabelVariants[]).find((key) => InvitationStatuses[key] === registrationStatus) || LabelVariants.info;

  const dotClassName = `table-status ${variant && `is-${variant}`}`;

  return registrationStatus ? <span className={dotClassName} /> : null;
};
