import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { errorAlert } from '@helpers/alerts/alerts';
import { getUserRole } from '@helpers/getUserRole';
import { keyFormatter } from '@helpers/keyFormatter';
import { logout } from '@store/auth/authSlice';
import { useAppDispatch } from '@store/hooks/redux';
import { ILoggedInUser } from '@api/user/types';
import { loadState } from '@store/browserStorage';
import { IUserData } from '@store/types';
import { persistAuth } from '@constants/persist';
import { Routes } from '../../../../router';
import { MenuList as menuList } from './constants/menuList';
import { useMenu } from './context/useMenuContext';
import { IMenuItem } from './types';

export const MenuList = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const menu = useMenu();

  const authData = loadState<IUserData>(persistAuth)?.auth;

  const { user, token }: ILoggedInUser = authData ? JSON.parse(authData as unknown as string) : {
    user: null,
    token: '',
  };

  const { dictionary } = useLanguageContext();

  const userRole = getUserRole(user, token);

  const onLogOut = async () => {
    try {
      await dispatch(logout());
      navigate(Routes.SignIn);
      menu.toggleMenu();
    } catch (e) {
      errorAlert(dictionary.messages.errorTitle, dictionary.messages.error);
    }
  };

  const onLinkChange = () => {
    menu.toggleMenu();
  };

  return (
    <ul className="menu-list">
      {token && menuList().map(({ key, title, link, roles }: IMenuItem) => (
        roles.includes(userRole) && (
        <li className="menu-item" key={`nav_${keyFormatter(title)}`} title={title}>
          <NavLink
            to={link}
            className={({ isActive }) => (`menu-link ${isActive && 'is-active'}`)}
            onClick={onLinkChange}
          >
            <span className="menu-link-label">{dictionary[key].title}</span>
          </NavLink>
        </li>
        )
      ))}
      <li className="menu-item">
        <button className="menu-link menu-logout" onClick={onLogOut} title={dictionary.profileMenu.logOut}>
          {dictionary.profileMenu.logOut}
        </button>
      </li>
    </ul>
  );
};
