import React from 'react';
import { Form, Formik, FormikValues } from 'formik';
import { Button } from '@components/Button';
import { FiltersForm } from '@pages/Schedule/components/Filters/FiltersForm';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { useScheduleContext } from '@pages/Schedule/context/useScheduleContext';
import './styles.scss';

export const filtersInitialValues = {
  location: '',
  patient: '',
  schedule: {
    from: '',
    to: '',
  },
  physician: '',
  type: '',
  trial: '',
};

export const Filters = () => {
  const { dictionary } = useLanguageContext();

  const { setSelectedLocation,
    setSelectedType,
    setSelectedTrial,
    setSelectedPatient,
    setSelectedPhysician,
    setSelectedHourFrom,
    setSelectedHourTo } = useScheduleContext();

  const onSubmitForm = (formValues: FormikValues) => {
    !!formValues.location && setSelectedLocation(formValues.location);
    !!formValues.patient && setSelectedPatient(formValues.patient);
    !!formValues.physician && setSelectedPhysician(formValues.physician);
    !!formValues.schedule.from && setSelectedHourFrom(formValues.schedule.from);
    !!formValues.schedule.to && setSelectedHourTo(formValues.schedule.to);
    !!formValues.trial && setSelectedTrial(formValues.trial);
    !!formValues.type && setSelectedType(formValues.type);
  };

  const onResetForm = () => {
    setSelectedLocation('');
    setSelectedPatient('');
    setSelectedPhysician('');
    setSelectedHourFrom('');
    setSelectedHourTo('');
    setSelectedTrial('');
    setSelectedType('');
  };

  return (
    <div className="filters">
      <Formik
        enableReinitialize
        initialValues={filtersInitialValues}
        validationSchema={null}
        onSubmit={onSubmitForm}
      >
        {(helpers) => (
          <Form className="form" noValidate>
            <div className="filters-header">
              <span className="title-lg">{dictionary.schedule.filters.title}</span>
              <div className="filters-controls">
                <div className="filters-control">
                  <Button
                    variant="link"
                    label={dictionary.actions.reset}
                    handleClick={() => {
                      onResetForm();
                      helpers.resetForm();
                    }}
                  />
                </div>
                <div className="filters-control">
                  <Button
                    variant="primary"
                    type="submit"
                    label={dictionary.actions.apply}
                  />
                </div>
              </div>
            </div>
            <div className="filters-body">
              <FiltersForm />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
