import { ReactNode } from 'react';

export enum ContainerSizes {
  xxs = 'xxs',
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl'
}

export interface IContainerProps {
  size?: `${ContainerSizes}`;
  isStretched?: boolean;
  children: ReactNode;
}
