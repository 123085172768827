import { ITableSettings } from '@components/Table/types';
import { TOrganization } from '@pages/Organizations/components/OrganizationsTable/types';
import { nameFormatter } from '@pages/Organizations/components/utils/nameFormatter';
import { dateFormatter } from '@pages/Organizations/components/utils/dateFormatter';

export const tableSettings: ITableSettings<TOrganization>[] = [
  {
    accessor: 'name',
    title: 'Organization name',
    isSortable: true,
    formatter: nameFormatter,
  },
  {
    accessor: 'owner',
    title: 'Owner',
    isSortable: true,
  },
  {
    accessor: 'internalOrganizationId',
    title: 'Organization ID',
    isSortable: true,
  },
  {
    accessor: 'createdAt',
    title: 'Date added',
    isSortable: true,
    formatter: dateFormatter,
  },
];
