import * as Yup from 'yup';
import { IDictionary } from '@constants/languages/types';

const declineAppointmentValidationSchema = (dictionary: IDictionary) => Yup.object({
  reason: Yup.string().required(dictionary.validation.reason),
  comment: Yup.string().when('reason', {
    is: dictionary.appointments.modals.declineAppointment.reason.other,
    then: Yup.string()
      .required(dictionary.validation.commentRequire)
      .max(200, dictionary.validation.commentMax),
    otherwise: Yup.string().max(200, dictionary.validation.commentMax),
  }),
});

export default declineAppointmentValidationSchema;
