import React, { FC, useEffect, useState } from 'react';
import { FormikValues, useFormikContext } from 'formik';
import { SelectField } from '@components/Form/SelectField';
import { Checkbox } from '@components/Checkbox';
import { Info } from '@components/Info';
import {
  firstNotificationOptions,
  secondNotificationOptions,
} from '@constants/options/notificationOptions';
import { useFetchAppointmentByIdQuery } from '@api/appointments/appointmentsApi';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { skipToken } from '@reduxjs/toolkit/query';

interface IEditNotificationProps {
  id?: string,
  appointmentTemplate?: any
}

export const EditNotification: FC<IEditNotificationProps> = ({ id, appointmentTemplate }) => {
  const { dictionary } = useLanguageContext();

  const { data: currentAppointment, refetch } = useFetchAppointmentByIdQuery(id ?? skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const [prevId, setPrevId] = useState<string | null>(null);

  useEffect(() => {
    if (id !== prevId) {
      id && setPrevId(id);
      refetch();
    }
  }, [id, refetch]);

  const { setFieldValue, values, setValues } = useFormikContext<FormikValues>();

  useEffect(() => {
    setValues({
      id: id || appointmentTemplate.id,
      notificationsSettings: {
        types: {
          sms: appointmentTemplate ? appointmentTemplate.notificationsSettings.types.sms : currentAppointment?.data.notificationsSettings.types.sms,
          email: appointmentTemplate ? appointmentTemplate.notificationsSettings.types.email : currentAppointment?.data.notificationsSettings.types.sms,
        },
        first: appointmentTemplate ? appointmentTemplate.notificationsSettings.first : currentAppointment?.data.notificationsSettings.first,
        second: appointmentTemplate ? appointmentTemplate.notificationsSettings.second : currentAppointment?.data.notificationsSettings.first,
      },
    });
  }, [currentAppointment, appointmentTemplate, setValues]);

  return (
    <>
      <div className="form-section">
        <Info text={dictionary.appointments.modals.createAppointment.infoNotifications} />
      </div>
      <>
        <div className="form-section">
          <h2 className="form-title title-md">
            {dictionary.appointments.modals.createAppointment.sendNotification}
          </h2>
          <div className="row">
            <div className="col-xs-12">
              <div className="field">
                <Checkbox
                  checked={values.notificationsSettings.types.email}
                  handleChange={() => {
                    setFieldValue('notificationsSettings.types.email', !values.notificationsSettings.types.email);
                  }}
                  name="notificationsSettings.types.email"
                  label={dictionary.labels.email}
                />
              </div>
              <div className="field">
                <Checkbox
                  checked={values.notificationsSettings.types.sms}
                  handleChange={() => {
                    setFieldValue('notificationsSettings.types.sms', !values.notificationsSettings.types.sms);
                  }}
                  name="notificationsSettings.types.sms"
                  label={dictionary.labels.sms}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form-section">
          <h2 className="form-title title-md">
            {dictionary.appointments.modals.editNotification.notificationSettings}
          </h2>
          <div className="row">
            <div className="col-xs-12">
              <SelectField
                selectOptions={firstNotificationOptions(dictionary)}
                placeholder={dictionary.placeholders.chooseNotification}
                label={dictionary.labels.firstNotification}
                required
                name="notificationsSettings.first"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <SelectField
                selectOptions={secondNotificationOptions(dictionary)}
                placeholder={dictionary.placeholders.chooseNotification}
                label={dictionary.labels.secondNotification}
                required
                name="notificationsSettings.second"
              />
            </div>
          </div>
        </div>
      </>
    </>
  );
};
