import React, { PropsWithChildren, useState } from 'react';

export const TableHeading = ({ children,
  isSticky,
  isSortable,
  className }: PropsWithChildren<{ isSticky?: boolean, isSortable?: boolean, className?: string }>) => {
  const [sortDirection, setSortDirection] = useState('');

  const setSortAsc = () => {
    setSortDirection('asc');
  };

  const setSortDesc = () => {
    setSortDirection('desc');
  };

  const handleSortClick = () => {
    if (sortDirection !== 'desc') {
      setSortDesc();
    } else {
      setSortAsc();
    }
  };

  const headingClassName = `table-cell${isSticky ? ' is-sticky' : ''}${isSortable ? ' is-sortable' : ''} ${className || ''}${sortDirection ? ` is-${sortDirection}` : ''}`;

  return (
    <th
      className={headingClassName}
      onClick={handleSortClick}
    >
      <div className="table-heading">
        {children}
      </div>
    </th>
  );
};
