import { PropsWithChildren, useMemo, useRef, useState } from 'react';
import { Confirm } from '@components/Confirm';
import { ConfirmationModalContext } from '@components/Confirm/context/ConfirmationModalContext';
import { IModalProps, IModalRef } from '@components/Confirm/types';

export const ConfirmationModalContextProvider = ({ children }: PropsWithChildren) => {
  const ref = useRef<IModalRef>(null);

  const [modalInfo, setModalInfo] = useState<IModalProps | undefined>(undefined);

  const openConfirmationModal = async (state: IModalProps) => {
    await setModalInfo(state);

    ref.current?.openModal();
  };

  const closeConfirmationModal = async () => {
    if (modalInfo?.handleCloseClick) {
      await modalInfo.handleCloseClick();
    }

    setModalInfo(undefined);
  };

  const onConfirmClick = async () => {
    if (modalInfo?.handleConfirmClick) {
      await modalInfo.handleConfirmClick();
    }
    await ref.current?.closeModal();

    setModalInfo(undefined);
  };

  const contextValues = {
    openConfirmationModal,
    closeConfirmationModal,
  };

  const cachedProvider = useMemo(() => contextValues, [contextValues]);

  return (
    <ConfirmationModalContext.Provider value={cachedProvider}>
      {children}
      {modalInfo && (
      <Confirm
        ref={ref}
        title={modalInfo.title}
        text={modalInfo.text}
        variant={modalInfo.variant}
        modalSize={modalInfo.modalSize}
        submitButtonTitle={modalInfo.submitButtonTitle}
        showCloseBtn={modalInfo.showCloseBtn}
        handleConfirmClick={onConfirmClick}
        handleCloseClick={closeConfirmationModal}
      />
      )}
    </ConfirmationModalContext.Provider>
  );
};
