import React, { FC } from 'react';
import { IntegrationsItem } from '@pages/Profile/components/Integrations/IntegrationsItem';
import { IIntegrationsProps } from '@pages/Profile/components/Integrations/types';
import './styles.scss';

export const Integrations:FC<IIntegrationsProps> = ({ integrations, refetch }) => (
  <ul className="integrations">
    {
      integrations.map((item) => <IntegrationsItem refetch={refetch} type={item.type} icon={item.icon} title={item.title} isConnected={item.isConnected} />)
    }
  </ul>
);
