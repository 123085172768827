import { Roles } from '@constants/roles';

export const roleFormatter = (role: string) => {
  if (role === Roles.admin) {
    return {
      admin: '1',
      employee: '1',
      superAdmin: '0',
    };
  }
  return {
    admin: '0',
    employee: '1',
    superAdmin: '0',
  };
};
