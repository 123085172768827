import React, { useEffect, useState } from 'react';
import { Container } from '@components/Container';
import { ScheduleCalendar } from '@pages/Schedule/components/Calendar';
import { useScheduleContext } from '@pages/Schedule/context/useScheduleContext';
import { differenceInHours, format, isBefore } from 'date-fns';
import {
  useDeclineAppointmentMutation,
  useEditAppointmentMutation,
  useFetchAllUserAppointmentsWithFilterQuery,
} from '@api/appointments/appointmentsApi';
import { skipToken } from '@reduxjs/toolkit/query';
import { Card } from '@components/Card';
import { IAction } from '@components/Table/utils/actionsFormatter';
import { IDecline, TAppointment } from '@api/appointments/types';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { useModal } from '@components/Modal/context/useModalContext';
import { EditAppointment } from '@pages/SchedulePatient/components/EditAppointment';
import { timeToTimestampFormatter } from '@helpers/timeToTimestampFormatter';
import { DeclineAppointment } from '@pages/SchedulePatient/components/DeclineAppointment';
import declineAppointmentValidationSchema from '@helpers/validationSchemas/declineAppointmentValidationSchema';
import { FormikValues } from 'formik';
import { errorAlert, successAlert } from '@helpers/alerts/alerts';
import { Button } from '@components/Button';
import { Loader } from '@components/Loader';
import { ModalTypes } from '@components/Confirm/types';
import { ContainerSizes } from '@components/Container/types';
import { ButtonSizes } from '@components/Button/types';
import { TableEmpty } from '@components/Table/components/TableEmpty';
import { StatusVariants } from '@constants/options/statusOptions';
import { Info } from '@components/Info';
import editAppointmentValidationSchema from '@helpers/validationSchemas/editAppointmentValidationSchema';
import { getEndTime, getStartTime } from '@helpers/getVisitTime';
import { convertTime } from '@helpers/convertTime';
import { Integrations } from '@pages/Profile/components/Integrations';
import { userIntegrations } from '@pages/Profile/constants/integrations';
import { ILoggedInUser } from '@api/user/types';
import { loadState } from '@store/browserStorage';
import { IUserData } from '@store/types';
import { persistAuth } from '@constants/persist';
import { useFetchPatientByIdQuery } from '@api/patients/patientsAPI';
import { emptyString } from '@constants/emptyString';
import { AppointmentInfo } from '@components/AppointmentInfo';

export const SchedulePatient = () => {
  const schedule = useScheduleContext();

  const [declineAppointment] = useDeclineAppointmentMutation();

  const [editAppointment] = useEditAppointmentMutation();

  const { dictionary } = useLanguageContext();

  const [selectedDate, setSelectedDate] = useState<string>('');

  const [currentDate, setCurrentDate] = useState<Date | null>(null);

  const currentUser: ILoggedInUser = JSON.parse(loadState<IUserData>(persistAuth)?.auth as unknown as string);

  const { openModal } = useModal();

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the threshold as needed
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const timeBeforeRejection = 72;

  const { data: appointments, isLoading, refetch } = useFetchAllUserAppointmentsWithFilterQuery({
    patientId: currentUser.user.id, date: selectedDate,
  } ?? skipToken);

  const initialValuesDeclineAppointment = {
    reason: '',
    comment: '',
  };

  const { data: userData, refetch: refetchUser } = useFetchPatientByIdQuery(currentUser.user.id, {
    refetchOnMountOrArgChange: true,
  });

  const appointmentData = ({ date, time, serialNumber, trials, contactPhone }: TAppointment) => [
    {
      label: dictionary.appointments.cards.date,
      value: format(new Date(date), 'MM/dd/yyyy'),
    },
    {
      label: dictionary.appointments.cards.time,
      value: time,
    },
    {
      label: dictionary.appointments.cards.doctor,
      value: trials.physicians.name,
    },
    {
      label: dictionary.appointments.cards.doctorsNumber,
      value: contactPhone,
    },
  ];

  const appointmentDetails = ({ serialNumber, locations }: TAppointment) => [
    {
      label: dictionary.appointments.cards.serialNumber,
      value: serialNumber,
    },
    {
      label: dictionary.appointments.cards.locations,
      value: locations.name,
    },
    {
      label: dictionary.appointments.cards.locationAddress,
      value: locations.address,
    },
    {
      label: dictionary.appointments.cards.locationNumber,
      value: locations.number,
    },
  ];

  const onSelectDate = (date: Date) => {
    schedule.setDate(date);
    setCurrentDate(date);
    setSelectedDate(format(date, 'MM/dd/yyyy'));
  };

  const onEdit = async (selectedAppointment: TAppointment, formValues: FormikValues, closeModal: () => void) => {
    editAppointment({
      id: selectedAppointment.id,
      date: formValues.date,
      time: formValues.time,
      from: String(timeToTimestampFormatter(getStartTime(formValues.time), new Date(formValues.date))),
      to: String(timeToTimestampFormatter(getEndTime(formValues.time), new Date(formValues.date))),
      fromTime: convertTime(getStartTime(formValues.time)),
      toTime: convertTime(getEndTime(formValues.time)),
    }).unwrap()
      .then(async ({ message }) => {
        successAlert(emptyString, dictionary.actions.done);
        await closeModal();
        refetch();
      })
      .catch(({ message }) => errorAlert(message));
  };

  const editAppointmentInitialValues = {
    date: '',
    time: '',
  };

  const onEditAppointment = (selectedAppointment: TAppointment) => {
    openModal({
      children: <EditAppointment selectedAppointment={selectedAppointment} />,
      initialValues: editAppointmentInitialValues,
      validationSchema: editAppointmentValidationSchema(dictionary),
      title: dictionary.labels.editAppointment,
      submitButtonTitle: dictionary.actions.confirm,
      containerSize: ContainerSizes.xxs,
      btnSize: ButtonSizes.large,
      onSubmit: (formValues, closeModal) => onEdit(selectedAppointment, formValues, closeModal),
    });
  };

  const onDecline = async (selectedAppointment: TAppointment, formValues: FormikValues, closeModal: () => void) => {
    if (formValues) {
      declineAppointment({
        id: selectedAppointment.id,
        comment: formValues as IDecline,
      })
        .unwrap()
        .then(async ({ success,
          message }) => {
          if (success === 1) {
            successAlert(emptyString, message);
            await closeModal();
            refetch();
          } else {
            errorAlert(message);
          }
        })
        .catch(({ message }) => errorAlert(message));
    }
  };

  const checkDifference = (time: string, date: string) => differenceInHours(timeToTimestampFormatter(String(time).slice(0, 8), new Date(date)), new Date()) <= timeBeforeRejection;

  const onDeclineAppointment = (selectedAppointment: TAppointment) => openModal({
    children: <DeclineAppointment />,
    title: dictionary.appointments.modals.declineAppointment.title,
    text: dictionary.appointments.modals.declineAppointment.text,
    variant: ModalTypes.decline,
    initialValues: initialValuesDeclineAppointment,
    validationSchema: isMobile ? null : declineAppointmentValidationSchema(dictionary),
    submitButtonTitle: dictionary.actions.confirm,
    btnSize: ButtonSizes.large,
    onSubmit: (formValues, closeModal) => onDecline(selectedAppointment, formValues, closeModal),
  });

  const cardActions: IAction<Record<string, unknown>>[] = [
    {
      type: 'edit',
      text: dictionary.table.edit,
      showCondition: (selectedAppointment) => selectedAppointment.status !== 'Rejected',
      isDisabled: (selectedAppointment) => differenceInHours(timeToTimestampFormatter(getStartTime(String(selectedAppointment.time)), new Date(selectedAppointment.date as string)), new Date()) <= timeBeforeRejection,
      handleClick: (selectedAppointment) => onEditAppointment(selectedAppointment as TAppointment),
    },
    {
      type: 'decline',
      text: dictionary.table.decline,
      showCondition: (selectedAppointment) => selectedAppointment.status !== 'Rejected',
      isDisabled: (selectedAppointment) => differenceInHours(timeToTimestampFormatter(String(selectedAppointment.time).slice(0, 8), new Date(selectedAppointment.date as string)), new Date()) <= timeBeforeRejection,
      handleClick: (selectedAppointment) => onDeclineAppointment(selectedAppointment as TAppointment),
    },
  ];

  const formattedTitle = () => {
    if (selectedDate) {
      return `${dictionary.appointments.title3} ${format(new Date(selectedDate), 'MMMM dd, yyyy')}`;
    }

    return dictionary.appointments.title2;
  };

  const unavailableTime = !!appointments?.data.find((appointment) => !isBefore(new Date(appointment.date), new Date()) && (checkDifference(appointment.time, appointment.date)));

  return (
    <div className="content">
      <aside className="sidebar">
        <div className="sidebar-content">
          <div className="sidebar-row">
            <ScheduleCalendar defaultDate={selectedDate.length ? currentDate : null} handleChange={onSelectDate} />
          </div>
          <div className="sidebar-row">
            <Integrations refetch={refetchUser} integrations={userIntegrations(userData?.googleAuth, userData?.outlookAuth)} />
          </div>
        </div>
      </aside>
      {isLoading ? <Loader /> : (
        <div className="schedule is-scrollable">
          <div className="schedule-inner">
            <Container>
              <div className="section-heading">
                <h2 className="section-title title-xl">{formattedTitle()}</h2>
                {selectedDate && (
                  <div className="section-control">
                    <Button
                      label={dictionary.labels.showAll}
                      handleClick={() => setSelectedDate('')}
                      type="submit"
                      size="large"
                    />
                  </div>
                )}
              </div>
              <section className="section">
                {unavailableTime && <Info text={dictionary.labels.rescheduleAppointmentInfo.text} phone={dictionary.labels.rescheduleAppointmentInfo.phone} />}
              </section>
              <div className="section-body">
                {!!appointments?.data && appointments?.data.map((appointment) => appointment.status === StatusVariants.approved && (
                  <Card
                    data={appointment && appointmentData({
                      ...appointment,
                    })}
                    title={appointment.name}
                    actions={cardActions}
                    detailsExpandTitle={dictionary.labels.showInformation}
                    detailsCollapseTitle={dictionary.labels.hideInformation}
                    isDetailsCollapsible
                    details={(
                      <AppointmentInfo
                        data={appointment && appointmentDetails({
                          ...appointment,
                        })}
                        actions={cardActions}
                        isDetailsCollapsible={false}
                        row={{
                          ...appointment,
                        }}
                      />
)}
                    row={{
                      ...appointment,
                    }}
                  />
                ))}
                {(!!appointments?.data && !appointments?.data.find((appointment) => appointment.status === StatusVariants.approved)) && <TableEmpty />}
              </div>
            </Container>
          </div>
        </div>
      )}
    </div>
  );
};
