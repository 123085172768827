import React, { FC } from 'react';
import { Button } from '@components/Button';
import { InputField } from '@components/Form/InputField';
import { PhoneField } from '@components/Form/PhoneField';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { TParents } from '@api/user/types';

interface IParentProfileProps {
  patientInformation?: boolean,
  isStaff: boolean,
  userData?: TParents,
  changeEmailModal: (email: string | undefined, parentEmail: boolean)=>void,
  isPhoneVerified: boolean,
  changePhoneModal: ()=>void,

}
export const ParentProfile:FC<IParentProfileProps> = ({ patientInformation,
  isStaff,
  userData,
  changeEmailModal,
  isPhoneVerified,
  changePhoneModal }) => {
  const { dictionary } = useLanguageContext();

  return (
    <section className="form-section">
      {patientInformation && <h2 className="form-title title-md">{dictionary.titles.parentInformation}</h2>}
      <div className="row">
        <div className="col-xs-12 col-md-6">
          <InputField
            disabled={!isStaff}
            placeholder={dictionary.placeholders.firstName}
            label={dictionary.labels.firstName}
            required
            name="parentName"
          />
        </div>
        <div className="col-xs-12 col-md-6">
          <InputField
            disabled={!isStaff}
            placeholder={dictionary.placeholders.lastName}
            label={dictionary.labels.lastName}
            required
            name="parentSurname"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <InputField
            disabled
            value={userData?.email}
            type="email"
            placeholder={dictionary.placeholders.enterYourEmail}
            label={dictionary.labels.email}
            required
            name="parentEmail"
          />
          {isStaff && (
            <Button
              variant="link"
              handleClick={() => changeEmailModal(userData?.email, !!userData?.email)}
              label="Change email"
            />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <PhoneField
            status
            isVerified={isPhoneVerified}
            disabled
            type="number"
            value={userData?.phone}
            placeholder={dictionary.placeholders.phoneMask}
            label={dictionary.labels.phoneNumber}
            required
            name="parentPhone"
          />
          {isStaff && (
            <Button
              variant="link"
              handleClick={changePhoneModal}
              label={dictionary.labels.changePhoneNumber}
            />
          )}
        </div>
      </div>
    </section>
  );
};
