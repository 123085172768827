import React, { ReactNode } from 'react';
import { TableCell } from '@components/Table/components/TableCell';
import { TableEmpty } from '@components/Table/components/TableEmpty';
import { TableHeading } from '@components/Table/components/TableHeading';
import { TableRow } from '@components/Table/components/TableRow';
import { TableSorting } from '@components/Table/components/TableSorting';
import { ITableProps } from '@components/Table/types';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { convertToDashes } from '@helpers/convertToDashes';
import { keyFormatter } from '@helpers/keyFormatter';
import './styles.scss';
import InfiniteScroll from 'react-infinite-scroll-component';

export const Table = <T extends Record<string, unknown>>({ tableSettings,
  tableData,
  tableLegend,
  actions,
  setSort,
  dictionaryKey,
  emptyText = '',
  dataLength,
  refreshFunction,
  next,
  search }: ITableProps<T>) => {
  const isEmpty = !tableData?.length && !search;

  const isSearchResultEmpty = !tableData?.length && search;

  const { dictionary } = useLanguageContext();

  const tableHeader = tableSettings.reduce<ReactNode[]>((acc, { title, isSortable, accessor }) => [...acc,
    <TableHeading key={`table_heading_${keyFormatter(title)}`} className={`is-heading table-cell-${convertToDashes(accessor.toString())}${isSortable ? ' is-sortable' : ''}`}>
      {dictionary[dictionaryKey].table[accessor]}
      {isSortable && <TableSorting setSort={setSort} column={String(accessor).replace(/[A-Z]/g, (name) => `_${name.toLowerCase()}`)} />}
    </TableHeading>], []);

  const infiniteScrollProps = {
    dataLength: Number(dataLength),
    next,
    hasMore: true,
    loader: null,
    refreshFunction,
  };

  const tableBody = tableData?.reduce<ReactNode[]>((acc, current, rowIndex) => {
    const row = tableSettings.map((column, cellIndex) => {
      const currentCell = current[column.accessor];

      const cellClassName = `table-cell-${convertToDashes(column.accessor.toString())}`;

      if (column.formatter) {
        return <TableCell key={`table_row_${rowIndex}_${cellIndex}`} className={cellClassName}>{column.formatter(current, column.accessor.toString())}</TableCell>;
      }

      if (!currentCell) {
        return (
          <TableCell key={`table_row_${rowIndex}_${cellIndex}`} className={cellClassName}>
            <span className="table-cell-placeholder">-</span>
          </TableCell>
        );
      }

      return (
        <TableCell key={`table_row_${rowIndex}_${cellIndex}`} className={cellClassName}>
          {String(currentCell)}
        </TableCell>
      );
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return [...acc, <TableRow key={`table_row_${rowIndex}`} row={current} actions={actions}>{row}</TableRow>];
  }, []);

  const colAmount = actions ? tableSettings.length + 1 : tableSettings.length;

  const tableClassName = `table ${isEmpty || isSearchResultEmpty ? 'is-empty' : ''}`;

  return (
    !!dataLength && !!refreshFunction && !!next ? (
      <div className={tableClassName}>
        {tableLegend}
        <InfiniteScroll
          height={600}
          {...infiniteScrollProps}
        >
          <table className="table-content">
            <thead className="table-header">
              <TableRow>
                {tableHeader}
                {actions && <TableHeading isSticky className="table-actions">{dictionary.table.actions}</TableHeading>}
              </TableRow>
            </thead>
            <tbody className="table-body">
              {!isEmpty && tableBody}
              {isEmpty && (
                <TableRow>
                  <td colSpan={colAmount}>
                    <TableEmpty />
                  </td>
                </TableRow>
              )}
              {isSearchResultEmpty && (
                <TableRow>
                  <td colSpan={colAmount}>
                    <TableEmpty emptyType="search" />
                  </td>
                </TableRow>
              )}
            </tbody>
          </table>
        </InfiniteScroll>
        <div className="table-placeholder">
          {isEmpty && <TableEmpty emptyText={emptyText} />}
          {isSearchResultEmpty && <TableEmpty emptyType="search" />}
        </div>
      </div>
    )
      : (
        <div className={tableClassName}>
          {tableLegend}
          <table className="table-content">
            <thead className="table-header">
              <TableRow>
                {tableHeader}
                {actions && <TableHeading isSticky className="table-actions">{dictionary.table.actions}</TableHeading>}
              </TableRow>
            </thead>
            <tbody className="table-body">
              {!isEmpty && tableBody}
              {isEmpty && (
              <TableRow>
                <td colSpan={colAmount}>
                  <TableEmpty />
                </td>
              </TableRow>
              )}
              {isSearchResultEmpty && (
              <TableRow>
                <td colSpan={colAmount}>
                  <TableEmpty emptyType="search" />
                </td>
              </TableRow>
              )}
            </tbody>
          </table>
          <div className="table-placeholder">
            {isEmpty && <TableEmpty emptyText={emptyText} />}
            {isSearchResultEmpty && <TableEmpty emptyType="search" />}
          </div>
        </div>
      )
  );
};
