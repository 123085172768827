import React, { FC, useId } from 'react';
import { useField, useFormikContext } from 'formik';
import { IFormFieldProps } from '@components/Form/types';
import { Search } from '@components/Search';
import { Field } from '../Field';

export const SearchField: FC<IFormFieldProps> = (props) => {
  const { id = '',
    showLabel = false,
    label = '',
    showHint = false,
    hint = '',
    showRequired = false,
    required = true,
    name } = props;

  const uniqId = useId();

  const fieldId = id || uniqId;

  const [field, meta] = useField(props);

  const formikProps = useFormikContext();

  const clearValue = () => {
    formikProps.setFieldValue(`${name}`, '');
  };

  return (
    <Field
      id={fieldId}
      showLabel={showLabel}
      label={label}
      showError={meta.touched}
      error={meta.error}
      showHint={showHint}
      hint={hint}
      showRequired={showRequired}
      isRequired={required}
    >
      <div className="field-control">
        <Search {...field} {...props} clearValue={clearValue} hasError={meta.touched && !!meta.error} />
      </div>
    </Field>
  );
};
