import React from 'react';
import { Container } from '@components/Container';
import { Section } from '@components/Section';
import { CreateAppointmentForm } from '@pages/Appointment/pages/CreateAppointment/CreateAppointmentForm';

export const CreateAppointment = () => (
  <div className="main-content">
    <Container size="lg">
      <div className="panel">
        <Section containerSize="md">
          <CreateAppointmentForm />
        </Section>
      </div>
    </Container>
  </div>
);
