import React, { FC, useMemo, useState } from 'react';
import { language } from '@constants/language';
import { dictionaryList, englishLanguage, IDictionary, languageOptions } from '@constants/languages';
import { loadState, saveState } from '@store/browserStorage';
import { LanguageContext } from '../../languageContext/languageContext';
import { ILanguageProviderProps } from './types';

export const LanguageProvider: FC<ILanguageProviderProps> = ({ children }) => {
  const defaultLanguage = loadState<string>(language);

  const [userLanguage, setUserLanguage] = useState<string>(defaultLanguage || englishLanguage);

  const provider = {
    userLanguage,
    dictionary: dictionaryList[userLanguage as keyof IDictionary],
    changeLanguage: (languageName: string) => {
      const newLanguage = languageOptions[languageName as keyof IDictionary] ? languageName : englishLanguage;

      setUserLanguage(newLanguage);
      saveState<string>(newLanguage, language);
    },
  };

  const cachedProvider = useMemo(() => provider, [provider]);

  return (
    <LanguageContext.Provider value={cachedProvider}>
      {children}
    </LanguageContext.Provider>
  );
};
