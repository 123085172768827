import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { UilArrowLeft } from '@iconscout/react-unicons';
import { ILinkBackProps } from './types';
import './styles.scss';

export const LinkBack = (props: ILinkBackProps) => {
  const { dictionary } = useLanguageContext();

  const { label = dictionary.links.goBack } = props;

  const navigate = useNavigate();

  return (
    <button className="link-back link" onClick={() => navigate(-1)}>
      <span className="link-prepend-icon">
        <UilArrowLeft />
      </span>
      <span className="link-label">{label}</span>
    </button>
  );
};
