import React, { FC, useEffect, useState } from 'react';
import { useField } from 'formik';
import ReactSelect, { MenuPosition, MultiValue, SingleValue } from 'react-select';
import { MultiOptions } from '@components/Select/MultiOption';
import { Option } from '@components/Select/Option';
import { SelectInput } from '@components/Select/SelectInput';
import { IOptions, ISingleSelectProps } from '@components/Select/types';
import { TTrial } from '@pages/Trials/components/TrialsTable/types';
import './styles.scss';

export const Select: FC<ISingleSelectProps> = (props) => {
  const { isDisabled = false,
    isRequired = false,
    isSearchable = true,
    handleBlur,
    multiOptions,
    handleChange,
    getSelectedValue,
    isMulti,
    autoField,
    placeholder,
    showDropdownIndicator,
    isError,
    chooseNested,
    value,
    isEditing,
    hasError = false,
    options,
    iconAppend,
    menuPosition = 'fixed',
    iconPrepend } = props;

  const [field, meta, helpers] = useField(props);

  const { setValue } = helpers;

  const selectClassName = `select ${hasError || (isError && !meta.value.length) ? 'has-error' : ''}`;

  const select = 'select';

  const selectProps = {
    required: isRequired,
    isDisabled,
    classNamePrefix: select,
    isSearchable,
    placeholder,
    isClearable: false,
    name: field.name,
    onBlur: handleBlur,
    instanceId: {
      ...props,
    }.id,
  };

  const onChange = (option: SingleValue<IOptions>) => {
    setValue(chooseNested ? option?.label : option?.value);
  };

  const handleMultiChange = (newValue: MultiValue<TTrial> | SingleValue<TTrial>) => {
    meta && setValue(Array.isArray(newValue) ? newValue.map((trial) => trial.id) : []);
  };

  useEffect(() => {
    if (autoField && value) {
      setValue(value);
    }
    if (isEditing) {
      setValue(value);
    }
  }, [autoField, isEditing, value]);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (getSelectedValue) {
      getSelectedValue(field.value);
    }
  }, [field.value, getSelectedValue]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the threshold as needed
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={selectClassName}>
      {iconPrepend && (
        <span className="input-prepend-icon" role="presentation">
          {iconPrepend}
        </span>
      )}
      {iconAppend && (
        <span className="input-append-icon" role="presentation">
          {iconAppend}
        </span>
      )}
      {isMulti ? (
        <ReactSelect
          {...field}
          {...selectProps}
          isMulti
          options={multiOptions}
          isSearchable={!isMobile}
          hideSelectedOptions={false}
          getOptionLabel={(multiOption) => multiOption.protocolName}
          getOptionValue={(option) => option.id}
          value={multiOptions?.find((option) => option.id === field.value)}
          onChange={handleMultiChange}
          name={field.name}
          placeholder={placeholder}
          components={{
            Input: SelectInput,
            Option: MultiOptions,
          }}
          menuPosition={menuPosition as MenuPosition}
        />
      ) : (
        <ReactSelect
          {...field}
          {...selectProps}
          options={options}
          hideSelectedOptions={false}
          defaultValue={options.find((option) => (option.label === value ? value : field.value))}
          value={value === '' ? [] : options?.find((option) => (option.value === field.value || option.label === value || option.label === field.value))}
          onChange={handleChange ? (option) => handleChange(Number(option?.id)) : onChange}
          formatOptionLabel={Option}
          menuPosition={menuPosition as MenuPosition}
          components={showDropdownIndicator ? {
            DropdownIndicator: () => null,
          } : undefined}
        />
      )}
    </div>
  );
};
