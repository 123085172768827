import React, { FC } from 'react';
import { Label } from '@components/Label';
import { LabelVariants } from '@components/Label/types';
import { AppointmentStatuses } from '@constants/invitationStatuses';
import { IDictionary } from '@constants/languages/types';

export interface IStatusFormatterProps {
  dictionary?: IDictionary,
  status?: string;
}

export const statusFormatter: FC<IStatusFormatterProps> = ({ dictionary, status }) => {
  const variant = (Object.keys(AppointmentStatuses) as LabelVariants[]).find((key) => AppointmentStatuses[key] === status) || LabelVariants.info;

  return status ? (<Label variant={variant} text={dictionary ? dictionary.warnings[status.toLowerCase() as keyof typeof dictionary.warnings] : status} />) : null;
};
