import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { CloseButton } from '@components/Alert/CloseButton';
import { useConfirmationModal } from '@components/Confirm/context/useConfirmContext';
import { useModal } from '@components/Modal/context/useModalContext';
import { useAutoLogOut } from '@hooks/useAutoLogOut';
import { logout } from '@store/auth/authSlice';
import { useAppDispatch } from '@store/hooks/redux';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';
import { ILoggedInUser } from '@api/user/types';
import { loadState } from '@store/browserStorage';
import { IUserData } from '@store/types';
import { persistAuth } from '@constants/persist';
import { Routes } from '../../router';
import { Header } from './components/Header';

export const AppLayout = () => {
  const dispatch = useAppDispatch();

  const { closeModal } = useModal();

  const { closeConfirmationModal } = useConfirmationModal();

  const navigate = useNavigate();

  const authData = loadState<IUserData>(persistAuth)?.auth;

  const { user, token }: ILoggedInUser = authData ? JSON.parse(authData as unknown as string) : {
    user: null,
    token: '',
  };

  const [authStatus, setAuthStatus] = useState<boolean>(!!token);

  const logoutAction = () => {
    if (user !== null && authStatus) {
      setAuthStatus(false);
      dispatch(logout());
      navigate(Routes.SignIn);
    }
  };

  useAutoLogOut(logoutAction, closeModal, closeConfirmationModal, !!token);

  return (
    <>
      <ToastContainer className="toasts" closeButton={CloseButton} />
      <Header />
      <main className="main">
        <Outlet />
      </main>
    </>
  );
};
