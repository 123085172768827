import React from 'react';
import { Table } from '@components/Table';
import { tableSettings } from '@pages/Physician/components/ScheduleTable/constants/tableSettings';
import { TWorkingDays } from '@pages/Physicians/components/PhysiciansTable/types';

export const ScheduleTable = ({ workingHours }: Record<string, TWorkingDays>) => {
  const data = Array.of(workingHours) || [];

  return (
    <Table
      tableData={data}
      tableSettings={tableSettings}
      dictionaryKey="physician"
    />
  );
};
