import React, { FC, useEffect } from 'react';
import { isSameDay } from 'date-fns';
import { FieldArray, FormikValues, useFormikContext } from 'formik';
import { skipToken } from '@reduxjs/toolkit/query';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { InputField } from '@components/Form/InputField';
import { Loader } from '@components/Loader';
import { TWorkingDays } from '@pages/Physicians/components/PhysiciansTable/types';
import { IAppointmentSettings } from '@pages/Appointment/pages/CreateAppointment/components/DetailsForm/types';
import { IBusySlot } from '@api/trial/types';
import { useFetchTrialByIdQuery } from '@api/trial/trialAPI';
import { durationOptions } from '@constants/options/durationOptions';
import { timeFormatter } from '@helpers/timeFormatter';
import { getFreeOfDay } from '@helpers/getFreeOfDay';
import { convertTimeToOptions } from '@helpers/convertTimeToOptions';
import { useFetchUserByIdQuery } from '@api/user/userAPI';
import { calculateAge } from '@helpers/calculateAge';
import { checkTimeInRange } from '@helpers/checkTimeInRange';
import { TypeOptions } from '@constants/options/typeOptions';
import { SelectField } from '@components/Form/SelectField';
import { DateField } from '@components/Form/DateField';
import { checkIsWeekday } from '@helpers/checkIsWeekday';
import { IVisitsRange } from '@pages/Trials/components/TrialsTable/types';
import { firstNotificationOptions } from '@constants/options/notificationOptions';
import { Checkbox } from '@components/Checkbox';
import { Info } from '@components/Info';

export const DetailsForm:FC = () => {
  const { dictionary } = useLanguageContext();

  const { setFieldValue, values } = useFormikContext<FormikValues>();

  const { data: trial, isLoading } = useFetchTrialByIdQuery(values.trialId ?? skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const unavailableDays = trial?.data.physicians.schedule && Object.keys(trial.data.physicians.schedule)
    .filter((day) => trial.data.physicians.schedule && !trial.data.physicians.schedule[day as keyof TWorkingDays]?.length)
    .map((days) => days);

  const { data: patient } = useFetchUserByIdQuery(values.patientId ?? skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const appointmentVisit = (appointment: any) => ({
    name: appointment.name,
    type: appointment.type,
    duration: appointment.duration,
    date: '',
    time: appointment.time,
    from: '',
    to: '',
    notificationsSettings: {
      types: {
        sms: appointment.notificationsSettings.types.sms,
        email: appointment.notificationsSettings.types.email,
      },
      first: appointment.notificationsSettings.first,
      second: appointment.notificationsSettings.second,
    },
  });

  useEffect(() => {
    const appointmentSettings = trial && Object.values(trial?.data.appointmentTemplate).map((data) => appointmentVisit(data));

    if (appointmentSettings) {
      setFieldValue('appointmentSettings', [...appointmentSettings as IAppointmentSettings[]]);
      setFieldValue('contactPhone', trial?.data.physicians.phone);
      setFieldValue('physicianId', trial?.data.physicianId);
    }
    if (patient?.dateOfBirth) {
      setFieldValue('adult', calculateAge(patient.dateOfBirth) ? 0 : 1);
    }
  }, [setFieldValue, trial, patient, values?.patientId]);

  const getDatesInRange = (date: string, duration: number) => {
    const currentDay = new Date(date).toLocaleDateString('en-us', {
      weekday: 'long',
    }).toLowerCase();

    const busySlots = trial?.data.busySlots.map(({ from, to }) => {
      if (isSameDay(new Date(from), new Date(date)) && isSameDay(new Date(to), new Date(date))) {
        return {
          from: timeFormatter(new Date(from)),
          to: timeFormatter(new Date(to)),
        };
      }
      return undefined;
    }).filter((slot: any) => slot !== undefined);

    return (trial?.data.physicians.schedule && busySlots) && getFreeOfDay(duration || 15, trial.data.physicians.schedule[currentDay as keyof TWorkingDays], busySlots as IBusySlot[]);
  };

  useEffect(() => {
    !String(values.contactPhone).length && setFieldValue('contactPhone', trial?.data.physicians.phone);
  }, [setFieldValue, trial?.data.physicians.phone, values.contactPhone]);

  const timeRange = (index: number) => convertTimeToOptions(getDatesInRange(values.appointmentSettings[index].date, Number(durationOptions(dictionary).find(({ label }) => label === values.appointmentSettings[index].duration)?.value) || values.appointmentSettings[index].duration));

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <FieldArray
          name="appointmentSettings"
          render={(arrayHelpers) => trial && arrayHelpers.form.values.appointmentSettings
            .map((visit: IAppointmentSettings, index: number) => (
              <React.Fragment key={index}>
                <section className="form-section">
                  <h2 className="form-title title-md is-underlined">{dictionary.appointments.visitsRange[index]}</h2>
                </section>
                <section className="form-section">
                  <h2 className="form-title title-md">{dictionary.labels.appointmentDetails}</h2>
                  <div className="row">
                    <div className="col-xs-12">
                      <InputField
                        type="text"
                        placeholder={dictionary.placeholders.enterAppointmentName}
                        label={dictionary.labels.appointmentName}
                        required
                        name={`appointmentSettings.${index}.name`}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12">
                      <SelectField
                        chooseNested
                        value={TypeOptions(dictionary).find(({ label }) => label === values.appointmentSettings[index].type)?.value}
                        selectOptions={TypeOptions(dictionary)}
                        placeholder={dictionary.placeholders.chooseType}
                        label={dictionary.labels.type}
                        required
                        name={`appointmentSettings.${index}.type`}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12">
                      <SelectField
                        chooseNested={!values.appointmentSettings[index].duration.length}
                        autoField
                        isDisabled={!values.appointmentSettings[index].type}
                        value={TypeOptions(dictionary).find(({ label }) => label === values.appointmentSettings[index].type)?.value}
                        selectOptions={durationOptions(dictionary)}
                        placeholder={dictionary.placeholders.chooseDuration}
                        label={dictionary.labels.appointmentDuration}
                        required
                        name={`appointmentSettings.${index}.duration`}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-md-6">
                      <DateField
                        selectedDate={index >= 1 ? values.appointmentSettings[0].date : undefined}
                        onEffect={() => arrayHelpers.form.values.appointmentSettings.map((appointmentVisits: IAppointmentSettings, appointmentIndex: number) => setFieldValue(`appointmentSettings.${appointmentIndex}.time`, Object.values(trial?.data.appointmentTemplate)[index].time.length ? Object.values(trial?.data.appointmentTemplate)[index].time : ''))}
                        maxDate={false}
                        protocolWindow={Number(trial.protocolWindow)}
                        dateValue={index >= 1 ? values.appointmentSettings[index - 1].date : undefined}
                        visitRange={index >= 1 ? Number(trial?.data?.appointmentTemplate[index as unknown as keyof IVisitsRange]?.date) : undefined}
                        disabled={index >= 1 ? !values.appointmentSettings[index - 1].date : false}
                        unavailableDays={unavailableDays}
                        calendarStartDay={1}
                        excludeDates={index > 1 ? values.appointmentSettings?.map(({ date }:{date: Date}) => date) : undefined}
                        date
                        isWeekend={(day) => checkIsWeekday(day, unavailableDays)}
                        type="text"
                        placeholder="MM/DD/YYYY"
                        label={dictionary.appointments.table.date}
                        required
                        name={`appointmentSettings.${index}.date`}
                      />
                    </div>
                    <div className="col-xs-12 col-md-6">
                      <SelectField
                        isEditing={values.appointmentSettings[index].time === Object.values(trial?.data.appointmentTemplate)[index]?.time}
                        isError={!!values.appointmentSettings[0].date && !checkTimeInRange(timeRange(index), Object.values(trial?.data.appointmentTemplate)[index].time)}
                        isDisabled={!values.appointmentSettings[0].date}
                        value={(values.appointmentSettings[0].date && checkTimeInRange(timeRange(index), Object.values(trial?.data.appointmentTemplate)[index].time)) && Object.values(trial?.data.appointmentTemplate)[index].time}
                        selectOptions={timeRange(index)}
                        placeholder={dictionary.placeholders.chooseTime}
                        label={dictionary.appointments.table.time}
                        required
                        name={`appointmentSettings.${index}.time`}
                      />
                    </div>
                  </div>
                </section>
                <section className="form-section">
                  <h2 className="form-title title-md">{dictionary.appointments.modals.createAppointment.notification}</h2>
                  <div className="row">
                    <div className="col-xs-12">
                      <Info text={dictionary.appointments.modals.createAppointment.infoNotifications} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12">
                      <label className="field-label">{dictionary.appointments.modals.createAppointment.sendNotification}</label>
                      <div className="field">
                        <Checkbox
                          checked={values.appointmentSettings[index].notificationsSettings.types.email}
                          handleChange={() => {
                            setFieldValue(`appointmentSettings.${index}.notificationsSettings.types.email`, !values.appointmentSettings[index].notificationsSettings.types.email);
                          }}
                          name={`appointmentSettings.${index}.notificationsSettings.types.email`}
                          label={dictionary.labels.email}
                        />
                      </div>
                      <div className="field">
                        <Checkbox
                          checked={values.appointmentSettings[index].notificationsSettings.types.sms}
                          handleChange={() => {
                            setFieldValue(`appointmentSettings.${index}.notificationsSettings.types.sms`, !values.appointmentSettings[index].notificationsSettings.types.sms);
                          }}
                          name={`appointmentSettings.${index}.notificationsSettings.types.sms`}
                          label={dictionary.labels.sms}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12">
                      <SelectField
                        selectOptions={firstNotificationOptions(dictionary)}
                        placeholder={dictionary.placeholders.chooseNotification}
                        label={dictionary.labels.firstNotification}
                        required
                        name={`appointmentSettings.${index}.notificationsSettings.first`}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12">
                      <SelectField
                        selectOptions={firstNotificationOptions(dictionary)}
                        placeholder={dictionary.placeholders.chooseNotification}
                        label={dictionary.labels.secondNotification}
                        required
                        name={`appointmentSettings.${index}.notificationsSettings.second`}
                      />
                    </div>
                  </div>
                </section>
              </React.Fragment>
            ))}
        />
      )}
    </>
  );
};
