import React, { FC } from 'react';
import { Link } from '@components/Link';
import { shortStatusFormatter } from '@components/Table/utils/shortStatusFormatter';
import { InvitationStatuses } from '@constants/invitationStatuses';
import { Routes } from '../../../router';

export interface INameFormatterProps {
  id: string;
  name: string;
  surname: string;
  registrationStatus?: string;
}

export const nameFormatter: FC<INameFormatterProps> = ({ id, name, surname, registrationStatus = '' }) => {
  if (!id || registrationStatus === InvitationStatuses.danger || registrationStatus === InvitationStatuses.warning) {
    return <span className="table-cell-placeholder">-</span>;
  }

  return (
    <div className="table-name">
      {shortStatusFormatter({
        registrationStatus,
      })}
      <Link variant="primary" to={`${Routes.Patients}/${id}`} label={`${name} ${surname}`} />
    </div>
  );
};
