import React, { FC, useEffect, useState } from 'react';
import { FormikValues, useFormikContext } from 'formik';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { InputField } from '@components/Form/InputField';
import { SelectField } from '@components/Form/SelectField';
import { Info } from '@components/Info';
import { firstNotificationOptions } from '@constants/options/notificationOptions';
import { TypeOptions } from '@constants/options/typeOptions';
import { durationOptions } from '@constants/options/durationOptions';
import { convertTimeToOptions } from '@helpers/convertTimeToOptions';
import { Checkbox } from '@components/Checkbox';
import { ProtocolWindowOptions } from '@constants/options/protocolWindowOptions';
import { getDaysOfYear } from '@helpers/getDaysOfYear';
import { getFreeOfDay } from '@helpers/getFreeOfDay';
import { endOfDay, startOfDay } from '@constants/dayRanger';

interface IAppointmentDetailsProps {
  index: number,
  isEdit?: boolean,
}

export const AppointmentDetails:FC<IAppointmentDetailsProps> = ({ index, isEdit }) => {
  const { values, setFieldValue }: FormikValues = useFormikContext();

  const { dictionary } = useLanguageContext();

  const [initialType] = useState(values?.appointmentTemplate[index].type || '');

  const [durationTime, setDurationTime] = useState('');

  const [checkType, setCheckType] = useState(true);

  const getFilteredDaysOfYear = () => {
    if (index === 0) {
      return [...getDaysOfYear(dictionary)];
    }

    const prevDate = Number(values?.appointmentTemplate[index - 1].date);

    const startFrom = isEdit ? prevDate + 1 : prevDate;

    return [...getDaysOfYear(dictionary, startFrom).filter((value) => {
      const dateValues = values?.appointmentTemplate.map((item: { date: Date }) => item.date);

      return !dateValues.includes(isEdit ? +value.value - 1 : value.value);
    })];
  };

  useEffect(() => {
    const getSelectedDuration = () => {
      const valueType = values?.appointmentTemplate[index].type;

      const valueDuration = values?.appointmentTemplate[index].duration;

      const matchedOption = TypeOptions(dictionary).find(({ label }: { label: string }) => label === valueType);

      if (initialType !== valueType) {
        setCheckType(false);
      }

      if (checkType && valueDuration) {
        return valueDuration;
      }

      if (matchedOption) {
        return matchedOption.value;
      }

      return '';
    };

    setDurationTime(getSelectedDuration());
  }, [values, index, initialType, checkType, dictionary]);

  const timeRange = (duration: number) => convertTimeToOptions(getFreeOfDay(duration, [{
    from: startOfDay, to: endOfDay,
  }]));

  return (
    <React.Fragment key={index}>
      <section className="form-section">
        <h2 className="form-title title-md">{dictionary.labels.appointmentDetails}</h2>
        <div className="row">
          <div className="col-xs-12">
            <InputField
              type="text"
              placeholder={dictionary.placeholders.enterAppointmentName}
              label={dictionary.labels.appointmentName}
              required
              name={`appointmentTemplate.${index}.name`}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <SelectField
              chooseNested
              selectOptions={TypeOptions(dictionary)}
              placeholder={dictionary.placeholders.chooseType}
              label={dictionary.labels.type}
              required
              name={`appointmentTemplate.${index}.type`}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <SelectField
              selectOptions={ProtocolWindowOptions()}
              placeholder={dictionary.placeholders.chooseProtocolWindow}
              label={dictionary.labels.protocolWindow}
              required
              name={`appointmentTemplate.${index}.protocolWindow`}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <SelectField
              chooseNested={!values.appointmentTemplate[index].duration.length}
              autoField
              showDropdownIndicator={false}
              isDisabled={!values?.appointmentTemplate[index].type}
              value={durationTime}
              selectOptions={durationOptions(dictionary)}
              placeholder={dictionary.placeholders.chooseDuration}
              label={dictionary.labels.appointmentDuration}
              required
              name={`appointmentTemplate.${index}.duration`}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <SelectField
              isDisabled={index === 0}
              showDropdownIndicator={index === 0}
              autoField
              value={index === 0 ? getDaysOfYear(dictionary)[0].value : undefined}
              selectOptions={getFilteredDaysOfYear()}
              placeholder={dictionary.trials.appointmentDate[index as keyof typeof dictionary.trials.appointmentDate]}
              label={dictionary.trials.appointmentDate[index as keyof typeof dictionary.trials.appointmentDate]}
              required
              name={`appointmentTemplate.${index}.date`}
            />
          </div>
          <div className="col-xs-12 col-md-6">
            {values?.appointmentTemplate[index].time && (
              <SelectField
                isDisabled={!values?.appointmentTemplate[index].duration}
                selectOptions={timeRange(values?.appointmentTemplate[index].duration)}
                placeholder={dictionary.placeholders.chooseTime}
                label={dictionary.appointments.table.time}
                required
                name={`appointmentTemplate.${index}.time`}
              />
            )}
          </div>
        </div>
      </section>
      <section className="form-section">
        <h2 className="form-title title-md">{dictionary.appointments.modals.createAppointment.notification}</h2>
        <div className="row">
          <div className="col-xs-12">
            <Info text={dictionary.appointments.modals.createAppointment.infoNotifications} />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <label className="field-label">{dictionary.appointments.modals.createAppointment.sendNotification}</label>
            <div className="field">
              <Checkbox
                checked={values?.appointmentTemplate[index].notificationsSettings.types.email}
                handleChange={() => {
                  setFieldValue(`appointmentTemplate.${index}.notificationsSettings.types.email`, !values?.appointmentTemplate[index].notificationsSettings.types.email);
                }}
                name={`appointmentTemplate.${index}.notificationsSettings.types.email`}
                label={dictionary.labels.email}
              />
            </div>
            <div className="field">
              <Checkbox
                checked={values?.appointmentTemplate[index].notificationsSettings.types.sms}
                handleChange={() => {
                  setFieldValue(`appointmentTemplate.${index}.notificationsSettings.types.sms`, !values?.appointmentTemplate[index].notificationsSettings.types.sms);
                }}
                name={`appointmentTemplate.${index}.notificationsSettings.types.sms`}
                label={dictionary.labels.sms}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <SelectField
              selectOptions={firstNotificationOptions(dictionary)}
              placeholder={dictionary.placeholders.chooseNotification}
              label={dictionary.labels.firstNotification}
              required
              name={`appointmentTemplate.${index}.notificationsSettings.first`}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <SelectField
              selectOptions={firstNotificationOptions(dictionary)}
              placeholder={dictionary.placeholders.chooseNotification}
              label={dictionary.labels.secondNotification}
              required
              name={`appointmentTemplate.${index}.notificationsSettings.second`}
            />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
