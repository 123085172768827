import React from 'react';
import { WeekBody } from '@pages/Schedule/components/Week/WeekBody';
import { WeekHeader } from '@pages/Schedule/components/Week/WeekHeader';
import { TableEmpty } from '@components/Table/components/TableEmpty';
import { useScheduleContext } from '@pages/Schedule/context/useScheduleContext';
import { useFetchScheduleByWeekQuery } from '@api/schedule/scheduleAPI';
import { generateString } from '@helpers/generateString';
import { formatToTimezone } from '@helpers/formatToTimezone';
import { generateWeekDays } from '@helpers/generateWeekDays';

export const Week = () => {
  const { selectedDate,
    selectedPatient,
    selectedLocation,
    selectedPhysician,
    selectedType,
    selectedTrial,
    selectedHourFrom,
    selectedHourTo } = useScheduleContext();

  const tableData = generateWeekDays(selectedDate);

  const { data: appointments } = useFetchScheduleByWeekQuery({
    weekDays: generateString(tableData),
    patient: selectedPatient || undefined,
    location: selectedLocation || undefined,
    physician: selectedPhysician || undefined,
    type: selectedType || undefined,
    trial: selectedTrial || undefined,
    from: !!selectedHourFrom && !!selectedDate ? formatToTimezone(selectedHourFrom, selectedDate) : undefined,
    to: !!selectedHourTo && !!selectedDate ? formatToTimezone(selectedHourTo, selectedDate) : undefined,
  }, {
    refetchOnMountOrArgChange: true,
  });

  const unscheduledAppointments = (selectedTrial?.length || selectedHourFrom?.length || selectedHourTo?.length || selectedType?.length || selectedPhysician?.length || selectedLocation?.length || selectedPatient?.length) && !appointments;

  return (
    <div className="schedule-table table week">
      {unscheduledAppointments ? <TableEmpty emptyType="search" />
        : (
          <div className="schedule-content table-content">
            <WeekHeader />
            <WeekBody appointments={appointments} />
          </div>
        )}
    </div>
  );
};
