export const Routes = {
  SignUp: '/sign-up',
  PrivacyPolicy: '/privacy',
  EmployeeSignUp: '/employee-signUp',
  SignIn: '/',
  ForgotPassword: '/forgot-password',
  ResetPassword: '/reset-password',
  Schedule: '/schedule',
  Day: 'day',
  Week: 'week',
  Month: 'month',
  Appointments: '/appointments',
  Appointment: '/appointment',
  Profile: '/profile',
  MyProfile: '/my-profile',
  ProfileWithId: ':id',
  SignUpByInvite: ':email',
  Patients: '/patients',
  Patient: ':id',
  Trials: '/trials',
  Locations: '/locations',
  Physicians: '/physicians',
  ActivateAccount: '/verifying',
  PatientId: ':id',
  Physician: '/physician',
  PhysicianId: ':id',
  Add: '/add',
  Trial: '/trial',
  TrialId: ':id',
  AppointmentWithId: ':id',
  Edit: '/edit',
  CurrentPatientId: ':patientId',
  trialId: ':trialId',
  SchedulePatient: '/patient-schedule',
  RequestedAppointments: '/requested',
  Organizations: '/organizations',
  Organization: '/organization',
  OrganisationWithId: ':id',
  Member: '/member',
  CurrentOrganization: ':organizationId',
};
