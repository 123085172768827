import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SingleValue } from 'react-select';
import { SelectWithIcon } from '@components/Dropdown';
import { IOptions } from '@components/Select/types';
import { useFetchPatientByIdQuery } from '@api/patients/patientsAPI';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { navigateOptions } from '@constants/options/navigateOptions';
import { UserOption } from '@constants/options/userOption';
import { errorAlert } from '@helpers/alerts/alerts';
import { logout } from '@store/auth/authSlice';
import { useAppDispatch } from '@store/hooks/redux';
import { ILoggedInUser } from '@api/user/types';
import { loadState } from '@store/browserStorage';
import { IUserData } from '@store/types';
import { persistAuth } from '@constants/persist';
import { getUserRole } from '@helpers/getUserRole';
import { Roles } from '@constants/roles';
import { Routes } from '../../router';
import './styles.scss';

export const ProfileMenu = () => {
  const { dictionary } = useLanguageContext();

  const authData = loadState<IUserData>(persistAuth)?.auth;

  const { user, token }: ILoggedInUser = authData ? JSON.parse(authData as unknown as string) : {
    user: null,
    token: '',
  };

  const userRole = getUserRole(user, token);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const logoutValue = 'logout';

  const { data: currentUser } = useFetchPatientByIdQuery(String(user?.id) || undefined, {
    refetchOnMountOrArgChange: true,
  });

  const onSelectControl = async (newValue: SingleValue<IOptions>) => {
    if (newValue?.value === logoutValue) {
      try {
        await dispatch(logout());
        navigate(Routes.SignIn);
      } catch (e) {
        errorAlert(dictionary.messages.errorTitle, dictionary.messages.error);
      }
    } else {
      newValue && navigate(userRole === Roles.patient ? Routes.Profile : Routes.MyProfile);
    }
  };

  return (
    <div className="profile-menu dropdown">
      {(token && user) && (
      <SelectWithIcon
        options={navigateOptions(dictionary)}
        handleChange={onSelectControl}
        defaultValue={UserOption(currentUser || user)}
      />
      )}
    </div>
  );
};
