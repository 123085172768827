import React, { FC } from 'react';
import { InputField } from '@components/Form/InputField';
import { Info } from '@components/Info';
import { IDictionary } from '@constants/languages/types';

export const emailInitialValues = (currentEmail?: string) => ({
  currentEmail,
  newEmail: '',
  repeatedNewEmail: '',
  gender: '',
  surname: '',
  name: '',
  dateOfBirth: '',
});

interface IChangeEmailFormContentProps {
  dictionary: IDictionary,
  currentEmail?: string,
  parent?: boolean
}

export const ChangeEmailFormContent:FC<IChangeEmailFormContentProps> = ({ currentEmail, dictionary, parent = false }) => (
  <>
    <div className="form-section">
      {!parent && <Info text={dictionary.profile.modals.changeEmailTitle} />}
    </div>
    <div className="form-section">
      <div className="form-field">
        <InputField
          disabled
          value={currentEmail}
          type="email"
          placeholder={dictionary.placeholders.currentEmail}
          label={dictionary.placeholders.currentEmail}
          required
          name="currentEmail"
        />
      </div>
      <div className="form-field">
        <InputField
          type="email"
          placeholder={dictionary.placeholders.enterYourEmail}
          label={dictionary.labels.newEmail}
          required
          name="newEmail"
        />
      </div>
      <div className="form-field">
        <InputField
          type="email"
          placeholder={dictionary.placeholders.confirmNewEmail}
          label={dictionary.placeholders.confirmNewEmail}
          required
          name="repeatedNewEmail"
        />
      </div>
    </div>
  </>
);
