import React, { useState } from 'react';
import { Section } from '@components/Section';
import { SuccessScreen } from '@components/SuccessScreen';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { Heading } from '../../layout/AuthLayout/components/Heading';
import { Routes } from '../../router';
import { ForgotPasswordForm } from './components/ForgotPasswordForm';

export const ForgotPassword = () => {
  const [isForgotPasswordSent, setForgotPasswordSent] = useState(false);

  const { dictionary } = useLanguageContext();

  return (
    isForgotPasswordSent
      ? (
        <SuccessScreen
          title={dictionary.forgotPassword.emailInstructions}
          linkText={dictionary.actions.done}
          linkUrl={Routes.SignIn}
          containerSize="sm"
        />
      )
      : (
        <Section containerSize="sm">
          <Heading showBackControl>
            {dictionary.forgotPassword.forgotPassword}
          </Heading>
          <ForgotPasswordForm setForgotPasswordSent={setForgotPasswordSent} />
        </Section>
      )
  );
};
