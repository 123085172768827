import React from 'react';
import { FormikValues, useFormikContext } from 'formik';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { SelectField } from '@components/Form/SelectField';
import { intervalsGroup } from '@constants/options/timeOptions';
import { useFetchAllPhysiciansQuery } from '@api/physicians/physiciansAPI';
import { useFetchAllLocationsWithoutPaginationQuery } from '@api/locations/locationsAPI';
import { useFetchAllTrialsWithoutPaginationQuery } from '@api/trial/trialAPI';
import { useFetchAllPatientsWithoutPaginationQuery } from '@api/patients/patientsAPI';

import { IOptions } from '@components/Select/types';
import './styles.scss';
import { TypeOptions } from '@constants/options/typeOptions';

export const FiltersForm = () => {
  const { values }: FormikValues = useFormikContext();

  const { dictionary } = useLanguageContext();

  const { data: physicians } = useFetchAllPhysiciansQuery();

  const { data: locations } = useFetchAllLocationsWithoutPaginationQuery();

  const { data: trials } = useFetchAllTrialsWithoutPaginationQuery();

  const { data: patients } = useFetchAllPatientsWithoutPaginationQuery();

  const physiciansOptions = physicians?.data.results.map(({ name: label, id: value }) => ({
    label,
    value,
  }));

  const locationsOptions = locations?.data.results.map(({ name: label, id: value }) => ({
    label,
    value,
  }));

  const trialsOptions = trials?.data.results.map(({ protocolName: label, id: value }) => ({
    label,
    value,
  }));

  const patientsOptions = patients?.data.results.map(({ name, surname, id: value }) => ({
    label: `${name} ${surname}`,
    value,
  }));

  return (
    <div className="form-body">
      <div className="row">
        <div className="col-xs-12">
          <div className="form-field">
            <SelectField
              selectOptions={locationsOptions as IOptions[]}
              placeholder={dictionary.placeholders.chooseLocation}
              label={dictionary.labels.location}
              name="location"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <label className="field-label" id="hours">
            {dictionary.labels.hours}
          </label>
          <div className="form-field interval">
            <SelectField
              name="schedule.from"
              selectOptions={intervalsGroup()}
              placeholder={dictionary.placeholders.from}
            />
            <span className="interval-divider">-</span>
            <SelectField
              name="schedule.to"
              selectOptions={intervalsGroup(values.schedule.from)}
              placeholder={dictionary.placeholders.to}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <SelectField
            selectOptions={physiciansOptions}
            placeholder={dictionary.placeholders.choosePhysician}
            label={dictionary.labels.physician}
            name="physician"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <SelectField
            selectOptions={patientsOptions}
            placeholder={dictionary.placeholders.choosePatient}
            label={dictionary.labels.patient}
            required
            name="patient"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <SelectField
            chooseNested
            selectOptions={TypeOptions(dictionary)}
            placeholder={dictionary.placeholders.chooseType}
            label={dictionary.labels.type}
            name="type"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <SelectField
            selectOptions={trialsOptions as IOptions[]}
            placeholder={dictionary.placeholders.chooseTrial}
            label={dictionary.labels.trial}
            name="trial"
          />
        </div>
      </div>
    </div>
  );
};
