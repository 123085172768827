import * as yup from 'yup';
import { IDictionary } from '@constants/languages/types';

const inviteValidationSchema = (dictionary: IDictionary) => yup.object()
  .shape({
    invite: yup.array()
      .of(yup.object()
        .shape({
          email: yup.string()
            .email(dictionary.validation.invalidEmail)
            .required(dictionary.validation.emptyEmail),
        })),
  });

export default inviteValidationSchema;
