import { MouseEvent, ReactNode, TouchEvent } from 'react';

export enum ButtonTypes {
  button = 'button',
  submit = 'submit',
  reset = 'reset',
}

export enum ButtonSizes {
  small = 'small',
  medium = 'medium',
  large = 'large',
  static = 'static',
}

export enum ButtonVariants {
  primary = 'primary',
  secondary = 'secondary',
  warning = 'warning',
  danger = 'danger',
  success = 'success',
  info = 'info',
  default = 'default',
  link = 'link',
}

export interface IButtonProps {
  size?: `${ButtonSizes}`;
  variant?: `${ButtonVariants}`;
  type?: `${ButtonTypes}`;
  label?: string;
  isStretched?: boolean;
  isDisabled?: boolean;
  iconPrepend?: ReactNode;
  iconAppend?: ReactNode;
  handleClick?: (e: MouseEvent<HTMLElement> | TouchEvent<HTMLElement>) => void;
}
