import React from 'react';
import { Container } from '@components/Container';
import { Section } from '@components/Section';
import { CreateTrialForm } from '@pages/Trial/pages/CreateTrial/CreateTrialForm';

export const CreateTrial = () => (
  <div className="main-content">
    <Container size="lg">
      <div className="panel">
        <Section containerSize="md">
          <CreateTrialForm />
        </Section>
      </div>
    </Container>
  </div>
);
