import { timeRange } from '@constants/timeRange';
import {
  addMinutes, endOfDay,
  format,
  addHours,
  getMinutes,
  setMinutes,
  startOfDay,
} from 'date-fns';

export const intervalsGroup = (startTime?: string, endTime?:string) => {
  const startDate = addHours(startOfDay(new Date()), 6);

  const endDate = endOfDay(new Date());

  let current = setMinutes(startDate, Math.ceil(getMinutes(startDate) / timeRange) * timeRange);
  const group = [];

  while (endDate >= current) {
    group.push(format(new Date(current), 'hh:mm a'));
    current = addMinutes(current, timeRange);
  }

  if (!startTime && !endTime) {
    return group.map((time) => ({
      label: time,
      value: time,
    }));
  }
  return group.map((time) => ({
    label: time,
    value: time,
  })).slice(startTime ? group.indexOf(startTime) : undefined, endTime ? group.indexOf(endTime) + 1 : undefined);
};
