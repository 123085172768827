import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { getUserRole } from '@helpers/getUserRole';
import { keyFormatter } from '@helpers/keyFormatter';
import { ILoggedInUser } from '@api/user/types';
import { loadState } from '@store/browserStorage';
import { IUserData } from '@store/types';
import { persistAuth } from '@constants/persist';
import { IMenuItem, IMenuListProps } from './types';

export const NavList: FC<IMenuListProps> = ({ menuList }) => {
  const authData = loadState<IUserData>(persistAuth)?.auth;

  const { user, token }: ILoggedInUser = authData ? JSON.parse(authData as unknown as string) : {
    user: null,
    token: '',
  };

  const { dictionary } = useLanguageContext();

  const userRole = getUserRole(user, token);

  return (
    <ul className="nav-list">
      {token && menuList.map(({ key, title, link, roles }: IMenuItem) => (
        roles.includes(userRole) && (
        <li className="nav-item" key={`nav_${keyFormatter(title)}`} title={title}>
          <NavLink
            to={link}
            className={({ isActive }) => (`nav-link ${isActive && 'is-active'}`)}
          >
            <span className="nav-link-label">{dictionary[key].title}</span>
          </NavLink>
        </li>
        )
      ))}
    </ul>
  );
};
