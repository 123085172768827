import React, { FC } from 'react';
import { Label } from '@components/Label';
import { LabelVariants } from '@components/Label/types';
import { InvitationStatuses } from '@constants/invitationStatuses';

export interface IStatusFormatterProps {
  registrationStatus?: string;
}

export const statusFormatter: FC<IStatusFormatterProps> = ({ registrationStatus }) => {
  const variant = (Object.keys(InvitationStatuses) as LabelVariants[]).find((key) => InvitationStatuses[key] === registrationStatus) || LabelVariants.info;

  return registrationStatus ? (<Label variant={variant} text={registrationStatus} />) : null;
};
