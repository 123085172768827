import { useEffect } from 'react';
import { logoutEvents } from '@constants/logoutEvents';

export const useAutoLogOut = (
  handleLogout: () => void,
  closeModal: () => void,
  closeConfirmationModal: () => void,
  isLoggedIn: boolean,
) => {
  useEffect(() => {
    let timer: NodeJS.Timeout;

    const handleLogoutTimer = () => {
      timer = setTimeout(() => {
        resetTimer();
        Object.values(logoutEvents).forEach((item) => {
          window.removeEventListener(item, resetTimer);
        });
        closeModal();
        closeConfirmationModal();
        handleLogout();
      }, 900000);
    };

    const resetTimer = () => {
      if (timer) clearTimeout(timer);
    };

    if (isLoggedIn) {
      Object.values(logoutEvents).forEach((item) => {
        window.addEventListener(item, () => {
          resetTimer();
          handleLogoutTimer();
        });
      });
    }

    return () => {
      if (isLoggedIn) {
        Object.values(logoutEvents).forEach((item) => {
          window.removeEventListener(item, resetTimer);
        });
      }
      resetTimer();
    };
  }, [closeConfirmationModal, closeModal, handleLogout, isLoggedIn]);
};
