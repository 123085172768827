import * as Yup from 'yup';
import { IDictionary } from '@constants/languages/types';
import { phoneExpression } from '@constants/regularExpression';

function organizationsValidationSchema(dictionary: IDictionary) {
  return Yup.object({
    name: Yup.string()
      .required(dictionary.validation.organizationName)
      .max(50, dictionary.validation.organizationCharacters),
    owner: Yup.string()
      .required(dictionary.validation.ownerName)
      .max(100, dictionary.validation.ownerNameCharacters),
    phone: Yup.string()
      .required(dictionary.validation.organizationPhone)
      .min(17, dictionary.validation.invalidOrganizationPhone)
      .matches(phoneExpression, dictionary.validation.invalidOrganizationPhone),
  });
}

export default organizationsValidationSchema;
