import { IDictionary } from '@constants/languages/types';
import * as Yup from 'yup';
import { nameExpression } from '@constants/regularExpression';

const changeProfileValidationSchemaWithParent = (dictionary: IDictionary) => Yup.object({
  name: Yup.string()
    .required(dictionary.validation.firstName)
    .min(1, dictionary.validation.firstNameMin)
    .max(50, dictionary.validation.firstNameMax)
    .matches(nameExpression, dictionary.validation.firstNameLatter),
  surname: Yup.string()
    .required(dictionary.validation.lastName)
    .min(1, dictionary.validation.lastNameMin)
    .max(50, dictionary.validation.lastNameMax)
    .matches(nameExpression, dictionary.validation.lastNameLatter),
  date_of_birth: Yup.date(),
  gender: Yup.string(),
  parentName: Yup.string()
    .min(2, dictionary.validation.parentNameMin)
    .max(50, dictionary.validation.parentNameMax)
    .matches(nameExpression, dictionary.validation.parentNameLatter),
  parentSurname: Yup.string()
    .min(3, dictionary.validation.parentSurnameMin)
    .max(50, dictionary.validation.parentSurnameMax)
    .matches(nameExpression, dictionary.validation.parentSurnameLatter),
  parentPhone: Yup.string(),
  userIdFromSponsor: Yup.string().nullable().notRequired(),
});

export default changeProfileValidationSchemaWithParent;
