import { ToastOptions } from 'react-toastify';

export const alertOptions: ToastOptions = {
  position: 'top-right',
  autoClose: 5000,
  icon: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  className: 'toast',
  bodyClassName: 'toast-body',
};
