import React, { useState } from 'react';
import { FormikValues } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { Breadcrumbs } from '@components/Breadcrumbs';
import { Card } from '@components/Card';
import { useModal } from '@components/Modal/context/useModalContext';
import { Section } from '@components/Section';
import { Table } from '@components/Table';
import { IAction } from '@components/Table/utils/actionsFormatter';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { useFetchAllPhysiciansQuery } from '@api/physicians/physiciansAPI';
import { useEditTrialMutation, useFetchTrialByIdQuery } from '@api/trial/trialAPI';
import { IInitialTrial, ITrial } from '@api/trial/types';
import { errorAlert } from '@helpers/alerts/alerts';
import { tableSettings } from '@pages/Trial/components/tableSettings';
import { TrialForm } from '@pages/Trial/pages/CreateTrial/components/TrialForm';
import { skipToken } from '@reduxjs/toolkit/query';
import { Loader } from '@components/Loader';
import schedulesValidationSchema from '@helpers/validationSchemas/schedulesValidationSchema';
import { IEditAppointment, TAppointment } from '@api/appointments/types';
import { EditNotification } from '@pages/Appointment/components/EditNotification';
import { ContainerSizes } from '@components/Container/types';
import notificationValidationSchema from '@helpers/validationSchemas/notificationValidationSchema';
import { ModalTypes } from '@components/Confirm/types';
import { useConfirmationModal } from '@components/Confirm/context/useConfirmContext';
import { emptyString } from '@constants/emptyString';
import { Routes } from '../../router';

export const Trial = () => {
  const { dictionary } = useLanguageContext();

  const { id } = useParams();

  const { data: trial, refetch, isLoading } = useFetchTrialByIdQuery(id ?? skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const [editTrial] = useEditTrialMutation();

  const trialInitialValue = {
    protocolName: trial?.data.protocolName,
    protocolNumber: trial?.data.protocolNumber,
    sponsorName: trial?.data.sponsorName,
    physicianId: trial?.data.physicianId,
  };

  const trialData = ({ protocolName, protocolNumber, sponsorName, physicians }: ITrial) => [
    {
      label: dictionary.trials.cards.protocolName,
      value: protocolName,
    },
    {
      label: dictionary.trials.cards.protocolNumber,
      value: protocolNumber,
    },
    {
      label: dictionary.trials.cards.sponsorName,
      value: sponsorName,
    },
    {
      label: dictionary.trials.cards.physicianName,
      value: physicians.name,
    },
  ];

  const { openModal } = useModal();

  const { data: physicians } = useFetchAllPhysiciansQuery();

  const physiciansOptions = physicians?.data.results.map(({ name: label,
    id: value }) => ({
    label,
    value,
  }));

  const onSubmitForm = async (formValues: FormikValues, closeModal: () => void) => {
    editTrial(formValues.physicianId ? {
      trial: {
        ...formValues,
        appointmentTemplate: trial?.data.appointmentTemplate,
      } as IInitialTrial,
      id: String(trial?.data.id),
    } : {
      trial: {
        ...formValues,
        appointmentTemplate: trial?.data.appointmentTemplate,
      } as IInitialTrial,
      id: String(trial?.data.id),
    })
      .unwrap()
      .then(async () => {
        await closeModal();
        refetch();
      })
      .catch(({ message }) => errorAlert(message));
  };

  const onEditInformation = () => {
    openModal({
      children: <TrialForm physiciansOptions={physiciansOptions} dictionary={dictionary} />,
      initialValues: trialInitialValue,
      validationSchema: schedulesValidationSchema(dictionary),
      title: dictionary.trials.editTrial.editGeneral,
      submitButtonTitle: dictionary.actions.save,
      onSubmit: onSubmitForm,
    });
  };

  const informationActions: IAction[] = [
    {
      type: 'edit',
      text: dictionary.table.edit,
      handleClick: onEditInformation,
    },
  ];

  const appointmentActions: IAction[] = [
    {
      type: 'edit',
      text: dictionary.table.edit,
      handleClick: () => navigate(`${Routes.Trials}${Routes.Edit}/${trial?.data.id}`),
    },
  ];

  const [notificationInitialValue] = useState<IEditAppointment>({
    id: '',
    notificationsSettings: {
      types: {
        sms: '',
        email: '',
      },
      first: '',
      second: '',
    },
  });

  const onSubmitEditNotification = async (formValues: FormikValues, closeModal: () => void, visit?: number) => {
    if ((!!formValues.notificationsSettings.types.sms || !!formValues.notificationsSettings.types.email) && trial) {
      editTrial({
        trial: {
          appointmentTemplate: Object.entries(trial.data.appointmentTemplate).reduce(
            (acc, [key, value]) => {
              if (key === String(visit)) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                acc[key] = {
                  ...value,
                  notificationsSettings: formValues.notificationsSettings,
                };
              } else {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                acc[key] = value;
              }
              return acc;
            },
            {
            },
          ),
        },
        id: trial.data.id,
      }).unwrap()
        .then(async () => {
          await closeModal();
          refetch();
        })
        .catch(({ message }) => errorAlert(message));
    } else errorAlert(emptyString, dictionary.messages.fieldError);
  };

  const onEditNotification = (selectedAppointment: TAppointment) => {
    openModal({
      children: <EditNotification appointmentTemplate={selectedAppointment} />,
      initialValues: notificationInitialValue,
      containerSize: ContainerSizes.md,
      validationSchema: notificationValidationSchema(dictionary),
      title: dictionary.appointments.modals.createAppointment.notification,
      submitButtonTitle: dictionary.actions.save,
      onSubmit: (formValues, closeModal) => onSubmitEditNotification(formValues, closeModal, selectedAppointment.visit),
    });
  };

  const { openConfirmationModal } = useConfirmationModal();

  const navigate = useNavigate();

  const onDeleteAppointment = (SelectedAppointment: TAppointment) => {
    openConfirmationModal({
      variant: ModalTypes.decline,
      title: dictionary.appointments.modals.deleteAppointments.title,
      text: dictionary.appointments.modals.deleteAppointments.text,
      submitButtonTitle: dictionary.table.delete,
      handleConfirmClick: () => {
        if (trial) {
          editTrial({
            trial: {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              appointmentTemplate: Object.entries(trial.data.appointmentTemplate).reduce(
                (acc, [key, value]) => {
                  if (key !== String(SelectedAppointment.visit)) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    acc[key] = value;
                  }
                  return acc;
                },
                {
                },
              ),
            },
            id: trial.data.id,
          }).unwrap()
            .then(refetch)
            .catch(({ message }) => errorAlert(message));
        } else errorAlert(emptyString, dictionary.messages.fieldError);
      },
    });
  };

  const tableActions: IAction<TAppointment>[] = [
    {
      type: 'notification',
      handleClick: (selectedAppointment) => onEditNotification(selectedAppointment),
    },
    {
      type: 'decline',
      isDisabled: () => (trial ? Object.values(trial.data.appointmentTemplate).length <= 2 : false),
      handleClick: (selectedAppointment) => onDeleteAppointment(selectedAppointment),
    },
  ];

  return (
    <>
      <Breadcrumbs prevPageRoute={Routes.Trials} prevPageTitle={dictionary.trials.title} currentPageTitle={String(trial?.data.protocolName)} />
      <Section title={String(trial?.data.protocolName)}>
        {trialData && (
        <Card
          title={dictionary.trials.addTrial.generalInformation}
          data={trial?.data && trialData({
            ...trial.data,
          })}
          actions={informationActions}
          row={{
            ...trial,
          }}
        />
        )}
        {isLoading ? <Loader /> : (
          <Card
            title={dictionary.trials.addTrial.appointmentSchedule}
            actions={appointmentActions}
            isDetailsCollapsible={false}
            details={!!trial?.data && (
            <Table
              actions={tableActions}
              tableData={trial && Object.values(trial.data.appointmentTemplate).map((template, index) => ({
                ...template, visit: index,
              }))}
              tableSettings={tableSettings}
              dictionaryKey="trials"
            />
            )}
          />
        )}
      </Section>
    </>
  );
};
