import { ITableSettings } from '@components/Table/types';
import { physicianFormatter } from '@components/Table/utils/physicianFormatter';
import { WorkingHoursFormatter } from '@components/Table/utils/workingHoursFormatter';

import { TPhysicianSettings } from '@pages/Physicians/components/PhysiciansTable/types';

export const tableSettings: ITableSettings<TPhysicianSettings>[] = [
  {
    accessor: 'name',
    title: 'Physician\'s name (PI)',
    formatter: physicianFormatter,
  },
  {
    accessor: 'phone',
    title: 'Physician\'s phone number',
  },
  {
    accessor: 'sunday',
    title: 'Sun',
    formatter: WorkingHoursFormatter,
  },
  {
    accessor: 'monday',
    title: 'Mon',
    formatter: WorkingHoursFormatter,
  },
  {
    accessor: 'tuesday',
    title: 'Tue',
    formatter: WorkingHoursFormatter,
  },
  {
    accessor: 'wednesday',
    title: 'Wed',
    formatter: WorkingHoursFormatter,
  },
  {
    accessor: 'thursday',
    title: 'Thu',
    formatter: WorkingHoursFormatter,
  },
  {
    accessor: 'friday',
    title: 'Fri',
    formatter: WorkingHoursFormatter,
  },
  {
    accessor: 'saturday',
    title: 'Sat',
    formatter: WorkingHoursFormatter,
  },
];
