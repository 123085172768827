import React, { FC } from 'react';
import { Form, Formik } from 'formik';
import { Button } from '@components/Button';
import { PasswordField } from '@components/Form/PasswordField';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { ISetPassword } from '@api/user/types';
import { useSetNewPasswordMutation } from '@api/user/userAPI';
import { errorAlert, successAlert } from '@helpers/alerts/alerts';
import resetPasswordValidationSchema from '@helpers/validationSchemas/resetPasswordValidationSchema';
import { IResetPasswordFormProps } from '../../types';

export const ResetPasswordForm: FC<IResetPasswordFormProps> = ({ setResetPasswordSent, email, token }) => {
  const [setPassword] = useSetNewPasswordMutation();

  const { dictionary } = useLanguageContext();

  const initialValues = {
    newPassword: '',
    repeatedNewPassword: '',
  };

  const onResetPassword = (formValues: ISetPassword) => {
    if (email && token) {
      setPassword({
        ...formValues, email, token,
      })
        .unwrap().then(({ success, message }) => {
          success === 1 ? setResetPasswordSent(true) : successAlert(message);
        }).catch(({ data }) => errorAlert(data.message));
    } else {
      errorAlert(dictionary.message.error);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={resetPasswordValidationSchema(dictionary)}
      onSubmit={onResetPassword}
    >
      <Form className="form" noValidate>
        <div className="form-body">
          <section className="form-section">
            <div className="row">
              <div className="col-xs-12">
                <PasswordField
                  placeholder={dictionary.placeholders.yourPassword}
                  label={dictionary.labels.newPassword}
                  required
                  name="newPassword"
                  hint={dictionary.profile.modals.changePasswordValidation}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <PasswordField
                  placeholder={dictionary.placeholders.confirmNewPassword}
                  label={dictionary.labels.confirmNewPassword}
                  required
                  name="repeatedNewPassword"
                />
              </div>
            </div>
          </section>
        </div>
        <div className="form-footer">
          <section className="form-section">
            <Button
              label={dictionary.actions.confirm}
              type="submit"
              size="large"
            />
          </section>
        </div>
      </Form>
    </Formik>
  );
};
