import { IUserResponse } from '@api/user/types';
import { userAPI } from '@api/user/userAPI';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from '@store/types';

export interface IUserState {
  user: IUser | null;
  token: string | null;
}

const initialState: IUserState = {
  user: null,
  token: '',
};

const saveUserData = (state: IUserState, action: PayloadAction<IUserResponse>) => action.payload.userData;

const updateUserState = (state: IUserState, action: PayloadAction<IUserResponse>) => {
  const userState = action.payload.userData;

  return {
    ...state,
    userState,
  };
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(userAPI.endpoints.loginUser.matchFulfilled, saveUserData);
    builder.addMatcher(userAPI.endpoints.changeUserData.matchFulfilled, updateUserState);
    builder.addMatcher(userAPI.endpoints.loginUser.matchRejected, () => initialState);
    builder.addMatcher(userAPI.endpoints.logout.matchFulfilled, () => initialState);
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
