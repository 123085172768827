import { NavigateOptions } from 'react-router/dist/lib/context';

export enum LabelVariants {
  success = 'success',
  warning = 'warning',
  danger = 'danger',
  info = 'info',
}

export interface ILabelProps extends NavigateOptions {
  variant: `${LabelVariants}`;
  text: string;
}
