import React, { FC, useEffect, useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { redirectPaths } from '@constants/redirectPaths';
import { getUserRole } from '@helpers/getUserRole';

import { ILoggedInUser } from '@api/user/types';
import { loadState } from '@store/browserStorage';
import { IUserData } from '@store/types';
import { persistAuth } from '@constants/persist';
import { IProtectedRouteProps } from './types';

export const ProtectedRoute: FC<IProtectedRouteProps> = ({ roles, redirectPath }) => {
  const authData = loadState<IUserData>(persistAuth)?.auth;

  const { user, token }: ILoggedInUser = authData ? JSON.parse(authData as unknown as string) : {
    user: null,
    token: '',
  };

  const userRole = useMemo(() => getUserRole(user, token), [user, token]);

  const isAllowed = useMemo(() => roles.includes(userRole), [roles, userRole]);

  const navigate = useNavigate(); // Use React Router's useNavigate hook for navigation

  useEffect(() => {
    if (!isAllowed) {
      navigate(redirectPaths[userRole], {
        replace: true,
      });
    }
  }, [isAllowed, redirectPath, userRole, navigate]);

  return (
    <Outlet />
  );
};
