import { ITableSettings } from '@components/Table/types';
import { nameFormatter } from '@pages/Organization/components/utils/nameFormatter';
import { TEmployee } from '@api/user/types';
import { memberIdFormatter } from '@pages/Organization/components/utils/memberIdFormatter';
import { statusFormatter } from '@components/Table/utils/statusFormatter';
import { RoleFormatter } from '@pages/Organization/components/utils/roleFormatter';

export const tableSettings: ITableSettings<TEmployee>[] = [
  {
    accessor: 'name',
    title: 'Organization name',
    formatter: nameFormatter,
  },
  {
    accessor: 'email',
    title: 'Owner',
  },
  {
    accessor: 'phone',
    title: 'Organization ID',
    formatter: ({ phone }) => (phone.length > 13 ? '-' : phone),
  },
  {
    accessor: 'id',
    title: 'Owner',
    formatter: memberIdFormatter,
  },
  {
    accessor: 'employee',
    title: 'Owner',
    formatter: RoleFormatter,
  },
  {
    accessor: 'registrationStatus',
    title: 'Owner',
    formatter: statusFormatter,
  },
];
