import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ConfirmationModalContextProvider } from '@components/Confirm/context/ConfirmationModalContextProvider';
import { ModalContextProvider } from '@components/Modal/context/ModalContextProvider';
import { ScheduleContextProvider } from '@pages/Schedule/context/ScheduleContextProvider';
import { persistor, store } from '@store/store';
import { PersistGate } from 'redux-persist/integration/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { MenuContextProvider } from './layout/AppLayout/components/Menu/context/MenuContextProvider';
import { LanguageProvider } from './providers/LanguageProvider/LanguageProvider';
import { Router } from './router';

export const App = () => (
  <GoogleOAuthProvider clientId={String(process.env.REACT_APP_BASE_GOOGLE_CLIENT_ID)}>
    <Provider store={store}>
      <ScheduleContextProvider>
        <MenuContextProvider>
          <ConfirmationModalContextProvider>
            <LanguageProvider>
              <ModalContextProvider>
                <PersistGate persistor={persistor}>
                  <BrowserRouter>
                    <Router />
                  </BrowserRouter>
                </PersistGate>
              </ModalContextProvider>
            </LanguageProvider>
          </ConfirmationModalContextProvider>
        </MenuContextProvider>
      </ScheduleContextProvider>
    </Provider>
  </GoogleOAuthProvider>
);
