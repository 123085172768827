import { addWeeks, endOfMonth, endOfWeek, startOfMonth, startOfWeek } from 'date-fns';
import { generateWeekDays } from '@helpers/generateWeekDays';

export const splitMonthByWeeks = (date = new Date()) => {
  const weeks = [];

  const startMonth = startOfMonth(date);

  const endMonth = endOfMonth(date);

  let startOfCurrentWeek = startOfWeek(startMonth);

  const endOfLastWeek = endOfWeek(endMonth);

  while (endOfLastWeek >= startOfCurrentWeek) {
    weeks.push(generateWeekDays(startOfCurrentWeek));

    startOfCurrentWeek = addWeeks(startOfCurrentWeek, 1);
  }

  return weeks;
};
