import { IDictionary } from '@constants/languages/types';
import { Roles } from '@constants/roles';

export const RoleOptions = (dictionary: IDictionary) => [
  {
    value: Roles.admin,
    label: dictionary.role.admin,
  },
  {
    value: Roles.staff,
    label: dictionary.role.staff,
  },
];
