import React, { useState } from 'react';
import { FormikValues } from 'formik';
import { Roles } from '@constants/roles';
import { persistAuth } from '@constants/persist';
import { ILoggedInUser } from '@api/user/types';
import { IUserData } from '@store/types';
import { loadState } from '@store/browserStorage';
import { ModalToggle } from '@components/Modal/ModalToggler';
import { SearchForm } from '@components/SearchForm';
import { Section } from '@components/Section';
import { Loader } from '@components/Loader';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { useAddNewLocationMutation, useFetchAllLocationsQuery } from '@api/locations/locationsAPI';
import locationsValidationSchema from '@helpers/validationSchemas/locationsValidationSchema';
import { errorAlert } from '@helpers/alerts/alerts';
import { getUserRole } from '@helpers/getUserRole';
import { LocationsTable } from '@pages/Locations/LocationsTable';
import { TLocations } from '@pages/Locations/LocationsTable/types';
import { addLocationInitialValues, FormContent } from '@pages/Locations/ManageLocationModal';

export const Locations = () => {
  const [searchTitle, setSearchTitle] = useState<string>('');

  const [limit, setLimit] = useState<number>(20);

  const authData = loadState<IUserData>(persistAuth)?.auth;

  const { user, token }: ILoggedInUser = authData ? JSON.parse(authData as unknown as string) : {
    user: null,
    token: '',
  };

  const userRole = getUserRole(user, token);

  const isStaff = Roles.staff === userRole;

  const { dictionary } = useLanguageContext();

  const [addLocation] = useAddNewLocationMutation();

  const startPage = 1;

  const { data: locations, refetch, isLoading } = useFetchAllLocationsQuery({
    page: startPage, limit,
  });

  const next = () => {
    if (locations?.data.currentPage !== locations?.data.totalPages) {
      setLimit(limit + 20);
    }
  };

  const onSubmitForm = async (formValues: FormikValues, closeModal: () => void) => {
    addLocation({
      ...formValues,
      number: formValues.number.replace(/[-()\s]/g, ''),
    } as TLocations)
      .unwrap()
      .then(async ({ success,
        message }) => {
        if (success === 1) {
          await closeModal();
        } else {
          errorAlert(message);
        }
      })
      .catch(({ message }) => errorAlert(message));
  };

  const modalProps = {
    children: <FormContent dictionary={dictionary} />,
    initialValues: addLocationInitialValues,
    validationSchema: locationsValidationSchema(dictionary),
    title: dictionary.locations.modals.addLocation.title,
    submitButtonTitle: dictionary.table.add,
    onSubmit: onSubmitForm,
  };

  return (
    <Section
      title={dictionary.locations.title}
      controls={(
        <>
          <SearchForm setSearchTitle={setSearchTitle} />
          {!isStaff && <ModalToggle buttonTitle={dictionary.locations.modals.addLocation.title} modalProps={modalProps} />}
        </>
      )}
    >
      {isLoading ? <Loader /> : <LocationsTable next={next} refreshFunction={refetch} dataLength={locations?.data.total} data={locations?.data.results} searchTitle={searchTitle} isStaff={isStaff} />}
    </Section>
  );
};
