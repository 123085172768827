import { ICardData } from '@components/Card/types';
import { TParents, TUserWithParents } from '@pages/Patient/types';

export const parentData = ({ parentInfo,
  patient }: { parentInfo?: TParents, patient: { firstName: string; lastName: string; email: string; phone: string } }): ICardData[] => [
  {
    label: patient.firstName,
    value: parentInfo?.name,
  },
  {
    label: patient.lastName,
    value: parentInfo?.surname,
  },
  {
    label: patient.email,
    value: parentInfo?.email,
  },
  {
    label: patient.phone,
    value: parentInfo?.phone,
  },
];

export const patientData = ({ userData,
  patient }: { userData?: TUserWithParents, patient: { firstName: string; userIdFromSponsor: string; lastName: string; email: string; phone: string; gender: string; dateOfBirth: string } }) => [
  {
    label: patient.firstName,
    value: userData?.name,
  },
  {
    label: patient.lastName,
    value: userData?.surname,
  },
  {
    label: patient.email,
    value: userData?.email,
  },
  {
    label: patient.phone,
    value: userData?.phone,
  },
  {
    label: patient.userIdFromSponsor,
    value: userData?.userIdFromSponsor,
  },
  {
    label: patient.gender,
    value: userData?.gender,
  },
  {
    label: patient.dateOfBirth,
    value: userData?.dateOfBirth,
  },
];
