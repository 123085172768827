import React from 'react';
import { ISlotProps, SlotVariants } from '@pages/Schedule/components/Slot/types';
import './styles.scss';
import { Tooltip } from '@components/Tooltip';
import { PropsGetterArgs } from 'react-popper-tooltip';

export const Slot = ({ details, shortDetails, variant = SlotVariants.default, handleClick, eventTypes }: ISlotProps) => {
  const slotClassName = `slot is-${variant} ${handleClick ? 'is-clickable' : ''}`;

  const eventTypesVisibleAmount = 3;

  const showMoreEventTypes = eventTypes && eventTypes.length > eventTypesVisibleAmount;

  const eventTypesPreview = showMoreEventTypes ? eventTypes?.slice(0, eventTypesVisibleAmount - 1) : eventTypes;

  const tooltip = ({ tooltipRef,
    getArrowProps,
    getTooltipProps }: { tooltipRef: HTMLElement | null,
    getArrowProps: (args?: (PropsGetterArgs | undefined)) => {style: React.CSSProperties, 'data-popper-arrow': boolean},
    getTooltipProps: (args?: (PropsGetterArgs | undefined)) => {'data-popper-interactive': boolean | undefined, style: React.CSSProperties} }) => (
      <div
        {...getTooltipProps({
          ref: tooltipRef,
          className: 'tooltip-container',
        })}
      >
        <div
          {...getArrowProps({
            className: 'tooltip-arrow',
          })}
        />
        {eventTypes?.map((event, index) => (
          <div className="slot-events-item">
            {`-\u00A0${event.amount}\u00A0${event.type}${index < eventTypes.length - 1 ? ',' : ''}`}
          </div>
        ))}
      </div>
  );

  return (
    <div
      className={slotClassName}
      onClick={handleClick}
    >
      <span className="slot-label">{details}</span>
      <span className="slot-label-short">{shortDetails}</span>
      {eventTypes && (
        <div className="slot-events">
          {
            eventTypesPreview?.map((event, index) => (
              <div className="slot-events-item">
                {`-\u00A0${event.amount}\u00A0${event.type}${index < (eventTypesPreview.length - 1) ? ',' : ''}${showMoreEventTypes && (index === eventTypesPreview.length - 1) ? '...' : ''}`}
              </div>
            ))
          }
          {eventTypes.length > eventTypesVisibleAmount && (
            <Tooltip
              title="More"
              tooltip={tooltip}
            />
          )}
        </div>
      )}
    </div>
  );
};
