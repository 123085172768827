import React, { PropsWithChildren, useState } from 'react';
import { Button } from '@components/Button';
import { ICardProps } from '@components/Card/types';
import { actionsFormatter } from '@components/Table/utils/actionsFormatter';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { keyFormatter } from '@helpers/keyFormatter';
import { UilAngleDown, UilAngleUp } from '@iconscout/react-unicons';
import './styles.scss';

export const Card = ({ title,
  details,
  data,
  actions,
  row,
  detailsCollapseTitle,
  detailsExpandTitle,
  isDetailsCollapsible = true }: PropsWithChildren<ICardProps>) => {
  const { dictionary } = useLanguageContext();

  const [isCollapsed, setCollapsed] = useState(isDetailsCollapsible);

  const showDetailsToggle = details && isDetailsCollapsible;

  const cardClassName = `card ${details ? 'is-collapsible' : ''} ${isCollapsed ? 'is-collapsed' : 'is-open'}`;

  const onCollapseClick = () => {
    setCollapsed(!isCollapsed);
  };

  return (
    <div className={cardClassName}>
      <div className="card-info">
        <div className="card-heading">
          <strong className="title-lg">{title}</strong>
          <div className="card-controls">
            {showDetailsToggle && (
            <Button
              label={isCollapsed ? (detailsExpandTitle || dictionary.patient.actions.showAppointments) : (detailsCollapseTitle || dictionary.patient.actions.hideAppointments)}
              iconPrepend={isCollapsed ? <UilAngleDown /> : <UilAngleUp />}
              variant="info"
              size="small"
              handleClick={onCollapseClick}
            />
            )}
            {(actions && !row) && actionsFormatter({
              actions,
            })}
            {
              (actions && row) && actionsFormatter({
                actions,
                row,
              })
            }
          </div>
        </div>
        <span className="card-content">
          {!!data && data?.map(({ value,
            label }, index) => (
              <div className="card-content-cell" key={`card_item_${index}_${keyFormatter(label)}`}>
                <span className="card-content-label">{label}</span>
                <span className="card-content-value">{value}</span>
              </div>
          ))}
        </span>
      </div>
      {details && <div className="card-details">{details}</div>}
    </div>
  );
};
