import React from 'react';
import { Container } from '@components/Container';
import { Logo } from '../Logo';
import { Menu } from '../Menu';
import { Nav } from '../Nav';
import { HeaderControls } from './HeaderControls';
import './styles.scss';

export const Header = () => (
  <>
    <header className="header">
      <Container isStretched>
        <div className="header-content">
          <div className="header-logo">
            <Logo />
          </div>
          <Nav />
          <HeaderControls />
        </div>
      </Container>
    </header>
    <Menu />
  </>
);
