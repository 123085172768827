import { ILocationResponse, ISearchLocations } from '@api/locations/types';
import { IResponse } from '@api/user/types';
import { RequestRoutes } from '@constants/requestRoutes';
import { apiHeader, headerJson } from '@helpers/commonHeaders';
import { TLocations } from '@pages/Locations/LocationsTable/types';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { locationsTag } from '@constants/apiTags';
import { language } from '@constants/language';

const lang = localStorage.getItem(language)?.slice(1, 3) || 'en';

export const locationsAPI = createApi({
  reducerPath: 'locationsAPI',
  tagTypes: [locationsTag],
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_API_URL}`,
    prepareHeaders: apiHeader,
  }),
  endpoints: (build) => ({
    fetchAllLocations: build.query<ILocationResponse, { page: number, limit: number }>({
      query: ({ page,
        limit }) => ({
        url: `${RequestRoutes.Locations}${RequestRoutes.All}`,
        params: {
          page,
          limit,
          lang,
        },
        headers: headerJson,
        keepUnusedDataFor: 5,
      }),
      providesTags: [locationsTag],
    }),
    fetchAllLocationsWithoutPagination: build.query<ILocationResponse, void>({
      query: () => ({
        url: `${RequestRoutes.Locations}${RequestRoutes.All}`,
        headers: headerJson,
        keepUnusedDataFor: 5,
        params: {
          lang,
        },
      }),
      providesTags: [locationsTag],
    }),
    fetchLocations: build.query<ILocationResponse, void>({
      query: () => ({
        url: `${RequestRoutes.Locations}${RequestRoutes.All}`,
        headers: headerJson,
        keepUnusedDataFor: 5,
        params: {
          lang,
        },
      }),
      providesTags: [locationsTag],
    }),
    searchLocations: build.query<ISearchLocations, { searchString: string }>({
      query: ({ searchString }) => ({
        url: `${RequestRoutes.Locations}${RequestRoutes.Search}`,
        params: {
          searchString,
          lang,
        },
        method: 'POST',
        keepUnusedDataFor: 5,
      }),
      providesTags: [locationsTag],
    }),
    addNewLocation: build.mutation<ILocationResponse, TLocations>({
      query: ({ orgId, ...location }: TLocations) => ({
        url: `${RequestRoutes.Locations}${RequestRoutes.AddNew}`,
        headers: headerJson,
        method: 'POST',
        body: location,
        params: {
          lang,
          orgId,
        },
      }),
      invalidatesTags: [locationsTag],
    }),
    editLocation: build.mutation<ILocationResponse, TLocations>({
      query: (location: TLocations) => ({
        url: `${RequestRoutes.Locations}${RequestRoutes.EditInfo}`,
        headers: headerJson,
        params: {
          id: location.id,
          lang,
        },
        method: 'PATCH',
        body: location,
      }),
      invalidatesTags: [locationsTag],
    }),
    deleteLocation: build.mutation<IResponse, string>({
      query: (locationId: string) => ({
        url: `${RequestRoutes.Locations}${RequestRoutes.Delete}`,
        method: 'DELETE',
        params: {
          id: locationId,
          lang,
        },
        keepUnusedDataFor: 5,
      }),
      invalidatesTags: () => [locationsTag],
    }),
  }),
});

export const { useSearchLocationsQuery,
  useFetchAllLocationsQuery,
  useFetchLocationsQuery,
  useFetchAllLocationsWithoutPaginationQuery,
  useDeleteLocationMutation,
  useEditLocationMutation,
  useAddNewLocationMutation } = locationsAPI;
