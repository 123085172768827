import React, { FC } from 'react';
import { InputField } from '@components/Form/InputField';
import { IDictionary } from '@constants/languages/types';
import { TMember } from '@api/organizations/types';
import { SelectField } from '@components/Form/SelectField';
import { RoleOptions } from '@constants/options/roles';

interface IFormContentProps {
  dictionary: IDictionary,
  isEdit?: boolean
}
export const editMemberInitialValues = (email: string, role: string) => ({
  email,
  role,
});

export const addMemberInitialValues: TMember = {
  email: '',
  role: '',
};

export const MemberFormContent: FC<IFormContentProps> = ({ dictionary, isEdit = false }) => (
  <div className="form-field">
    {!isEdit
          && (
          <div className="row">
            <div className="col-xs-12">
              <InputField
                type="text"
                placeholder={dictionary.placeholders.enterMemberEmail}
                label={dictionary.labels.memberEmail}
                required
                name="email"
              />
            </div>
          </div>
          )}
    <div className="row">
      <div className="col-xs-12">
        <SelectField
          selectOptions={RoleOptions(dictionary)}
          placeholder={dictionary.placeholders.chooseMemberRole}
          label={dictionary.labels.memberRole}
          required
          name="role"
        />
      </div>
    </div>
  </div>
);
