import React, { FC, useEffect, useState } from 'react';
import { TAppointment } from '@api/appointments/types';
import { ScheduleCalendar } from '@pages/Schedule/components/Calendar';
import { format, isSameDay } from 'date-fns';
import { SelectField } from '@components/Form/SelectField';
import { convertTimeToOptions } from '@helpers/convertTimeToOptions';
import { timeFormatter } from '@helpers/timeFormatter';
import { getFreeOfDay } from '@helpers/getFreeOfDay';
import { TWorkingDays } from '@pages/Physicians/components/PhysiciansTable/types';
import { IBusySlot } from '@api/trial/types';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { Info } from '@components/Info';
import { ColorDescription } from '@components/ColorDescription';
import { FormikValues, useFormikContext } from 'formik';
import { checkTimeInRange } from '@helpers/checkTimeInRange';

interface IEditAppointmentProps {
  selectedAppointment: TAppointment,
}

export const EditAppointment:FC<IEditAppointmentProps> = ({ selectedAppointment }) => {
  const { dictionary } = useLanguageContext();

  const firstAppointmentDate = selectedAppointment.patients.appointments
    .filter((appointments: { trialId: string; }) => appointments.trialId === selectedAppointment.trialId)
    .sort((a: { date: string | number | Date; }, b: { date: string | number | Date; }) => new Date(a.date).getTime() - new Date(b.date).getTime())[0].date;

  const [dateOfVisit, setDateOfVisit] = useState<Date>(new Date(format(new Date(selectedAppointment.date), 'MM/dd/yyyy')));

  const { setFieldValue, values } = useFormikContext<FormikValues>();

  const schedule = selectedAppointment.trials.physicians.schedule;

  const onSelectDate = async (date: Date) => {
    setFieldValue('date', format(dateOfVisit, 'MM/dd/yyyy'));
    setDateOfVisit(date);
  };

  const unavailableDays = schedule && Object.keys(schedule)
    .filter((day) => schedule && !schedule[day as keyof TWorkingDays]?.length)
    .map((days) => days);

  const getDatesInRange = (date: string, duration: number) => {
    const currentDay = new Date(date).toLocaleDateString('en-us', {
      weekday: 'long',
    }).toLowerCase();

    const busySlots = selectedAppointment.trials.busySlots.map(({ from, to }: {from: string, to:string}) => {
      if (isSameDay(new Date(from), new Date(date)) && isSameDay(new Date(to), new Date(date))) {
        return {
          from: timeFormatter(new Date(from)),
          to: timeFormatter(new Date(to)),
        };
      }
      return undefined;
    }).filter((slot:any) => slot !== undefined);

    return (schedule && busySlots) && getFreeOfDay(duration || 15, schedule[currentDay as keyof TWorkingDays], busySlots as IBusySlot[]);
  };

  useEffect(() => {
    setFieldValue('date', format(dateOfVisit, 'MM/dd/yyyy'));
  }, [setFieldValue, dateOfVisit]);

  const currentSlot = selectedAppointment.date === values.date ? selectedAppointment.time : undefined;

  const timeRange = () => convertTimeToOptions(getDatesInRange(values.date || selectedAppointment.date, Number(selectedAppointment.duration)), currentSlot);

  const isEditing = () => !checkTimeInRange(timeRange(), values.time);

  const timeValue = () => {
    if (checkTimeInRange(timeRange(), selectedAppointment.time)) {
      return selectedAppointment.time;
    } if (checkTimeInRange(timeRange(), values.time)) {
      return values.time;
    }
    return '';
  };

  return (
    <>
      <section className="form-section">
        <Info text={dictionary.appointments.modals.editAppointment.info} />
      </section>
      <section className="form-section">
        <ColorDescription />
      </section>
      <section className="form-section">
        <div className="row">
          <div className="col-xs-12">
            <ScheduleCalendar
              name="date"
              controls={false}
              unavailableDays={unavailableDays}
              visitDate={dateOfVisit}
              excludeDates={firstAppointmentDate === selectedAppointment.date ? undefined : firstAppointmentDate}
              recepitDate={new Date(selectedAppointment.originalDate)}
              protocolWindow={Number(Object.values(selectedAppointment.trials.appointmentTemplate).find((trial) => trial?.name === selectedAppointment.name || trial?.notifications_settings === selectedAppointment.notificationsSettings || trial?.duration === selectedAppointment.duration || trial?.type === selectedAppointment.type)?.protocolWindow)}
              handleChange={onSelectDate}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <SelectField
              autoField={values.time !== ''}
              selectOptions={timeRange()}
              isEditing={isEditing()}
              value={timeValue()}
              placeholder={dictionary.placeholders.chooseTime}
              label={dictionary.appointments.table.time}
              required
              name="time"
            />
          </div>
        </div>
      </section>
    </>
  );
};
