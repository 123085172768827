import React, { Dispatch, FC } from 'react';
import Select, { components, GroupBase, OptionProps, ValueContainerProps } from 'react-select';
import _ from 'lodash';
import { Formik } from 'formik';
import { UilFilter } from '@iconscout/react-unicons';
import { Checkbox } from '@components/Checkbox';
import { Label } from '@components/Label';
import { LabelVariants } from '@components/Label/types';
import { statusOptions } from '@constants/options/statusOptions';
import { useLanguageContext } from '@hooks/useLanguageContext';
import './style.scss';

interface IFilterProps {
  setFilteredValue: Dispatch<unknown>,
  filteredValue: string | unknown
}

export const Filter: FC<IFilterProps> = ({ setFilteredValue, filteredValue }) => {
  const { dictionary } = useLanguageContext();

  const option = (optionProps?: OptionProps<unknown, boolean, GroupBase<unknown>>) => {
    let variant;
    if (optionProps?.label === dictionary.warnings.rejected) {
      variant = LabelVariants.danger;
    } else if (optionProps?.label === dictionary.warnings.pending) {
      variant = LabelVariants.warning;
    } else {
      variant = LabelVariants.success;
    }

    return (
      optionProps ? (
        <components.Option {...optionProps}>
          <div className="option">
            <Checkbox
              checked={_.isEqual(JSON.stringify(optionProps.data), JSON.stringify(filteredValue))}
              name={optionProps?.label}
            />
            <Label variant={variant as LabelVariants} text={optionProps?.label} />
          </div>
        </components.Option>
      ) : null
    );
  };

  const valueCont = (props: JSX.IntrinsicAttributes & ValueContainerProps<unknown, boolean, GroupBase<unknown>>) => {
    const { children } = props;

    return (
      <components.ValueContainer className="filter-icon" {...props}>
        <UilFilter size={16} />
        {_.last(React.Children.toArray(children))}
      </components.ValueContainer>
    );
  };

  const initialValues = {
    status: '',
  };

  const onSelectFilter = (value: unknown) => setFilteredValue(_.isEqual(JSON.stringify(value), JSON.stringify(filteredValue)) ? '' : value);

  return (
    <div className="section-control">
      <div className="filter">
        <div className="select">
          <Formik
            initialValues={initialValues}
            onSubmit={onSelectFilter}
          >
            <Select
              components={{
                DropdownIndicator: () => null,
                ValueContainer: valueCont,
                Option: (props) => option(props),
              }}
              options={statusOptions(dictionary)}
              isSearchable={false}
              onChange={onSelectFilter}
              classNamePrefix="select"
            />
          </Formik>
        </div>
      </div>
    </div>
  );
};
