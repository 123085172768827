import { useContext } from 'react';
import { ScheduleContext } from './ScheduleContext';

export const useScheduleContext = () => {
  const context = useContext(ScheduleContext);

  if (!context) {
    throw new Error('useSchedule used outside ScheduleContext');
  }

  return context;
};
