import { ReactNode } from 'react';
import { NavigateOptions } from 'react-router/dist/lib/context';

export enum LinkVariants {
  text = 'text',
  primary = 'primary',
  danger = 'danger',
  outlined = 'outlined',
}

export interface ILinkProps extends NavigateOptions {
  variant?: `${LinkVariants}`;
  handleClick?: () => void;
  label?: string;
  target?: string;
  to: string;
  inline?: boolean;
  iconPrepend?: ReactNode;
  iconAppend?: ReactNode;
}
