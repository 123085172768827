import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { FormikValues } from 'formik';
import { Breadcrumbs } from '@components/Breadcrumbs';
import { Card } from '@components/Card';
import { useConfirmationModal } from '@components/Confirm/context/useConfirmContext';
import { useModal } from '@components/Modal/context/useModalContext';
import { Section } from '@components/Section';
import { IAction } from '@components/Table/utils/actionsFormatter';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { ITrial } from '@api/trial/types';
import {
  useChangeEmailMutation,
  useChangePhoneMutation, useChangeUserDataMutation,
  useFetchUserByIdQuery,
  useVerifyNewPhoneMutation,
} from '@api/user/userAPI';
import {
  useChangeParentPhoneMutation,
  useChangePatientDataMutation,
  useVerifyNewParentPhoneMutation,
} from '@api/patients/patientsAPI';
import { initialParentInfoValues } from '@pages/Patient/constants/initialParentInfo';
import { parentData, patientData } from '@pages/Patient/constants/patientInfo';
import { TParents, TUserWithParents } from '@pages/Patient/types';
import { ParentProfile } from '@pages/Profile/components/ProfileForm/ParentProfile';
import { UserProfile } from '@pages/Profile/components/ProfileForm/UserProfile';
import changeProfileValidationSchema from '@helpers/validationSchemas/changeProfileValidationSchema';
import changeProfileValidationSchemaWithParent
  from '@helpers/validationSchemas/changeProfileValidationSchemaWithParent';
import { changeEmailModal } from '@pages/Profile/ModalContent/ChangeEmail/ChangeEmailModal';
import { changePhoneModal } from '@pages/Profile/ModalContent/ChangePhone/ChangePhoneModal';
import { Loader } from '@components/Loader';
import { AppointmentsTable } from '@pages/Patient/components/AppointmentsTable';
import { errorAlert } from '@helpers/alerts/alerts';
import { skipToken } from '@reduxjs/toolkit/query';
import { replaceObjectKey } from '@helpers/replaceObjectKey';
import { getUserRole } from '@helpers/getUserRole';
import { ILoggedInUser } from '@api/user/types';
import { loadState } from '@store/browserStorage';
import { IUserData } from '@store/types';
import { persistAuth } from '@constants/persist';
import { Routes } from '../../router';

export const Patient = () => {
  const { dictionary } = useLanguageContext();

  const { id } = useParams();

  const authData = loadState<IUserData>(persistAuth)?.auth;

  const { user, token }: ILoggedInUser = authData ? JSON.parse(authData as unknown as string) : {
    user: null,
    token: '',
  };

  const { data: userData, refetch, isLoading } = useFetchUserByIdQuery(id ?? skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const userRole = getUserRole(user, token);

  const parentInfo = userData?.parents?.reduce<TParents>((acc, item) => ({
    ...acc, ...item,
  }), initialParentInfoValues);

  const trialData = ({ protocolName, protocolNumber, sponsorName, physicians }: ITrial) => [
    {
      label: dictionary.patient.cards.trial.protocolName,
      value: protocolName,
    },
    {
      label: dictionary.patient.cards.trial.protocolNumber,
      value: protocolNumber,
    },
    {
      label: dictionary.patient.cards.trial.sponsorName,
      value: sponsorName,
    },
    {
      label: dictionary.patient.cards.trial.physicianName,
      value: physicians.name,
    },
  ];

  const { openModal } = useModal();

  const initialValues = {
    name: userData?.name,
    surname: userData?.surname,
    gender: userData?.gender,
    userIdFromSponsor: userData?.userIdFromSponsor,
    dateOfBirth: userData?.dateOfBirth,
  };

  const patientFullName = `${userData?.name} ${userData?.surname}`;

  const [changeEmail] = useChangeEmailMutation();

  const [newPhone, setNewPhone] = useState<string>('');

  const [isError, setIsError] = useState<string>('');

  const phoneNumber = userData?.phone;

  const [changePhone] = useChangePhoneMutation();

  const [changeParentsPhone] = useChangeParentPhoneMutation();

  const [verifyNewPhone] = useVerifyNewPhoneMutation();

  const [verifyParentPhone] = useVerifyNewParentPhoneMutation();

  const { openConfirmationModal } = useConfirmationModal();

  const verification = useRef(null);

  const [changeUser] = useChangeUserDataMutation();

  const [changePatient] = useChangePatientDataMutation();

  const changeUserEmail = () => userData && changeEmailModal(openModal, openConfirmationModal, changeEmail, dictionary, userData.email, userData.id, refetch);

  const changeParentEmail = () => changeEmailModal(openModal, openConfirmationModal, changeEmail, dictionary, parentInfo?.email, userData?.id, refetch, changeUser, parentInfo?.id, !!parentInfo);

  const changeUserPhone = () => changePhoneModal(openModal, changePhone, verifyNewPhone, dictionary, setIsError, newPhone, setNewPhone, isError, phoneNumber, refetch, verification, id);

  const changeParentPhone = () => changePhoneModal(openModal, changeParentsPhone, verifyParentPhone, dictionary, setIsError, newPhone, setNewPhone, isError, parentInfo?.phone, refetch, verification);

  const onSubmitEditPatientForm = async (formValues: FormikValues, closeModal: () => void) => {
    id && changePatient({
      userData: {
        user: replaceObjectKey({
          name: formValues.name,
          surname: formValues.surname,
          gender: formValues.gender,
          user_id_from_sponsor: formValues.userIdFromSponsor ? formValues.userIdFromSponsor : '',
          dateOfBirth: format(new Date(String(formValues.dateOfBirth)), 'MM/dd/yyyy'),
        }, 'dateOfBirth', 'date_of_birth'),
      },
      patientId: id,
    })
      .unwrap()
      .then(async () => {
        await closeModal();
        refetch();
      })
      .catch(({ message }) => errorAlert(message));
  };

  const onSubmitEditParentForm = async (formValues: FormikValues, closeModal: () => void) => {
    id && changePatient({
      userData: {
        parent: [{
          id: parentInfo?.id,
          user_id: id,
          name: formValues.parentName,
          surname: formValues.parentSurname,
        }],
      },
      patientId: id,
    })
      .unwrap()
      .then(async () => {
        await closeModal();
        refetch();
      })
      .catch(({ message }) => errorAlert(message));
  };

  const onEditPatient = (patient: TUserWithParents) => {
    openModal({
      children: <UserProfile
        changePatient
        isStaff={userRole}
        modal
        userData={userData}
        isAccountVerified={!!patient.verified}
        changeEmailModal={changeUserEmail}
        isPhoneVerified={!!patient.phoneVerified}
        changePhoneModal={changeUserPhone}
        dictionary={dictionary}
      />,
      initialValues,
      validationSchema: changeProfileValidationSchema(dictionary),
      title: dictionary.patient.modals.editPatient.title,
      submitButtonTitle: dictionary.actions.save,
      onSubmit: onSubmitEditPatientForm,
    });
  };

  const initialValuesWithParents = {
    ...initialValues,
    parentName: parentInfo?.name,
    parentSurname: parentInfo?.surname,
    parentEmail: parentInfo?.email,
    parentPhone: parentInfo?.phone,
  };

  const onEditParent = (parent: TParents) => {
    openModal({
      children: <ParentProfile
        isStaff={!userData?.employee}
        userData={parentInfo}
        changeEmailModal={changeParentEmail}
        isPhoneVerified={!!parent.phone}
        changePhoneModal={changeParentPhone}
      />,
      initialValues: initialValuesWithParents,
      validationSchema: changeProfileValidationSchemaWithParent(dictionary),
      title: dictionary.patient.modals.editParent.title,
      submitButtonTitle: dictionary.actions.save,
      onSubmit: onSubmitEditParentForm,
    });
  };

  const patientsActions: IAction<Record<string, unknown>>[] = [
    {
      type: 'edit',
      text: dictionary.table.edit,
      handleClick: (data) => onEditPatient(data as TUserWithParents),
    },
  ];

  const parentActions: IAction<Record<string, unknown>>[] = [
    {
      type: 'edit',
      text: dictionary.table.edit,
      handleClick: (data) => onEditParent(data as TParents),
    },
  ];

  return (
    <>
      <Breadcrumbs
        currentPageTitle={patientFullName}
        prevPageTitle={dictionary.patients.title}
        prevPageRoute={Routes.Patients}
      />
      <Section title={dictionary.patient.title1}>
        {isLoading ? <Loader /> : (
          <Card
            title={dictionary.labels.patient}
            data={patientData({
              userData,
              patient: dictionary.patient.cards.patient,
            })}
            actions={patientsActions}
            row={userData}
          />
        )}
        {!!userData?.parents?.length && (
          <Card
            title={dictionary.labels.parent}
            data={parentData({
              parentInfo,
              patient: dictionary.patient.cards.patient,
            })}
            actions={parentActions}
            row={userData?.parents[0]}
          />
        )}
      </Section>
      {!!userData?.trials?.length && (
      <Section title={dictionary.patient.title2}>
        { userData.trials?.map((trial, index) => (
          <Card
            title={`${dictionary.patient.cards.trial.title} ${index + 1}`}
            key={`trial_${index}`}
            data={trialData(trial)}
            details={(!!userData?.trials && id) && (
              <AppointmentsTable trialId={trial.id} userId={id} />
            )}
          />
        ))}
      </Section>
      )}
    </>
  );
};
