import React, { FC } from 'react';
import { FormikValues } from 'formik';
import { skipToken } from '@reduxjs/toolkit/query';
import { useConfirmationModal } from '@components/Confirm/context/useConfirmContext';
import { ModalTypes } from '@components/Confirm/types';
import { useModal } from '@components/Modal/context/useModalContext';
import { Table } from '@components/Table';
import { IAction } from '@components/Table/utils/actionsFormatter';
import { useLanguageContext } from '@hooks/useLanguageContext';
import {
  useDeleteLocationMutation,
  useEditLocationMutation, useSearchLocationsQuery,
} from '@api/locations/locationsAPI';
import { errorAlert } from '@helpers/alerts/alerts';
import { convertToCamelCase } from '@helpers/convertToCamelCase';
import locationsValidationSchema from '@helpers/validationSchemas/locationsValidationSchema';
import { tableSettings } from '@pages/Locations/LocationsTable/constants/tableSettings';
import { ILocationsTableProps, TLocations } from '@pages/Locations/LocationsTable/types';
import { editLocationInitialValues, FormContent } from '@pages/Locations/ManageLocationModal';

export const LocationsTable:FC<ILocationsTableProps> = ({ searchTitle, next, refreshFunction, dataLength, data: locations, isStaff }) => {
  const { openModal } = useModal();

  const { openConfirmationModal } = useConfirmationModal();

  const { data: foundLocation } = useSearchLocationsQuery({
    searchString: searchTitle,
  } ?? skipToken);

  const [editLocation] = useEditLocationMutation();

  const [deleteLocation] = useDeleteLocationMutation();

  const { dictionary } = useLanguageContext();

  const onSubmitForm = async (formValues?: FormikValues, closeModal?:()=> void) => {
    editLocation(formValues as TLocations)
      .unwrap()
      .then(async ({ success,
        message }) => {
        if (success === 1) {
          closeModal && closeModal();
        } else {
          errorAlert(message);
        }
      })
      .catch(({ message }) => errorAlert(message));
  };

  const onEditLocation = ({ name, number, address, id }: TLocations) => {
    openModal({
      children: <FormContent dictionary={dictionary} />,
      initialValues: editLocationInitialValues(name, number, address, id),
      validationSchema: locationsValidationSchema(dictionary),
      title: dictionary.locations.modals.editLocation.title,
      submitButtonTitle: dictionary.actions.save,
      onSubmit: onSubmitForm,
    });
  };

  const onDeleteLocation = ({ name: locationName, id }: TLocations) => {
    openConfirmationModal({
      variant: ModalTypes.decline,
      title: dictionary.locations.modals.deleteLocation.title,
      text: `${dictionary.locations.modals.deleteLocation.text} ${locationName}?`,
      submitButtonTitle: dictionary.table.delete,
      handleConfirmClick: () => deleteLocation(String(id)).unwrap()
        .then(async ({ success,
          message }) => {
          if (success === 1) {
          } else {
            errorAlert(message);
          }
        })
        .catch(({ message }) => errorAlert(message)),
    });
  };

  const editAction: IAction<TLocations> = {
    type: 'edit',
    handleClick: onEditLocation,
  };

  const deleteAction: IAction<TLocations> = {
    type: 'delete',
    handleClick: onDeleteLocation,
  };

  const tableActions: IAction<TLocations>[] = [editAction].concat(isStaff ? [] : [deleteAction]);

  return (
    <Table
      dataLength={dataLength}
      tableData={searchTitle.length ? convertToCamelCase(foundLocation?.data.results) : convertToCamelCase(locations)}
      tableSettings={tableSettings}
      actions={tableActions}
      search={!!searchTitle}
      dictionaryKey="locations"
      next={next}
      refreshFunction={refreshFunction}
    />
  );
};
