import React, { FC, useId } from 'react';
import { useField } from 'formik';
import { TPhoneFieldProps } from '@components/Form/types';
import { Phone } from '@components/Phone';
import { Status } from '@components/Status';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { Field } from '../Field';

export const PhoneField:FC<TPhoneFieldProps> = (props) => {
  const { dictionary } = useLanguageContext();

  const { id = '',
    showLabel = true,
    label = '',
    showHint = true,
    hint = '',
    status,
    isVerified,
    showRequired = false,
    required = true,
    fieldControls,
    showVerification = false,
    handleVerificationClick } = props;

  const uniqId = useId();

  const fieldId = id || uniqId;

  const [field, meta] = useField(props);

  const statusLabel = isVerified ? dictionary.statuses.verified : dictionary.statuses.notVerified;

  const statusVariant = isVerified ? 'success' : 'warning';

  return (
    <Field
      id={fieldId}
      showLabel={showLabel}
      label={label}
      showError={meta.touched}
      error={meta.error}
      showHint={showHint}
      hint={hint}
      showRequired={showRequired}
      isRequired={required}
      fieldControls={fieldControls}
      status={status && !showVerification && <Status text={statusLabel} variant={statusVariant} />}
    >
      <div className="field-control">
        <Phone
          handleBlur={field.onBlur}
          handleChange={field.onChange}
          {...field}
          {...props}
          hasError={Boolean(meta.touched && !!meta.error)}
          showVerification={showVerification}
          connectionStatus={isVerified}
          handleVerificationClick={handleVerificationClick}
        />
      </div>
    </Field>
  );
};
