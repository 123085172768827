export const RequestRoutes = {
  User: '/user',
  Register: '/register',
  Verifying: '/verifying',
  Trial: '/trial',
  AddNew: '/addNew',
  Sms: '/sms',
  Registration: '/registration',
  SendVerifyingEmail: '/sendVerifyingEmail',
  Login: '/login',
  ResendSms: '/resendSms',
  Logout: '/logout',
  RecoverPassword: '/recoverPassword',
  SendEmail: '/sendEmail',
  SetNewPassword: '/setNewPassword',
  Profile: '/profile',
  Patients: '/patient',
  ChangeCommonInfo: '/changeCommonInfo',
  ChangePassword: '/changePassword',
  ChangeEmail: '/changeEmail',
  ChangePhone: '/changePhone',
  SendSms: '/sendSms',
  VerifyPhone: '/verifyPhone',
  SendSmsToNewPhone: '/sendSmsToNewPhone',
  VerifyNewPhone: '/verifyNewPhone',
  SendInvite: '/sendInvite',
  Search: '/search',
  Locations: '/location',
  ResendInviteLetter: '/resendInviteLetter',
  GetAll: '/getAll',
  DeleteAccount: '/deleteAccount',
  Physician: '/physician',
  All: '/all',
  EditInfo: '/editInfo',
  Delete: '/delete',
  GetOne: '/getOne',
  DeleteTrial: '/deleteTrial',
  EditTrial: '/editTrial',
  Staff: '/staff',
  Appointment: '/appointment',
  Patient: '/patient',
  Approve: '/approve',
  Cancel: '/cancel',
  DeleteOrganisation: '/deleteOrganization',
  Edit: '/edit',
  CreateNewAppointment: '/createNewAppointment',
  DeleteAppointment: '/deleteAppointment',
  EditAppointmentsGeneralInfo: '/editAppointmentsGeneralInfo',
  GetAllUsersAppointments: '/getAllUsersAppointments',
  EditAppointmentsDetails: '/editAppointmentsDetails',
  ResendAppointmentToStaff: '/resendAppointmentToStaff ',
  Calendar: '/calendar',
  GetDay: '/getDay',
  GetWeek: '/getWeek',
  GetMonth: '/getMonth',
  Parent: '/parent',
  Google: '/google',
  Microsoft: '/microsoft',
  Outlook: '/outlook',
  GenerateUrl: '/generateUrl',
  Tokens: '/tokens',
  AddAppointmentsToCalendar: '/addAppointmentsToCalendar',
  GetCountOfRejected: '/getCountOfRejected',
  DeleteToken: '/deleteToken',
  Organization: '/organization',
  GetAllOrganizations: '/getAllOrganizations',
  CreateOrganization: '/createOrganization',
  EditOrganization: '/editOrganization',
  GetOrganizationById: '/getOrganizationById',
  EmployeeRegistration: '/employeeRegistration',
  SendInviteToEmployee: '/sendInviteToEmployee',
  ResendInviteToEmployee: '/resendInviteToEmployee',
  ChangeMemberRoleInOrganization: '/changeMemberRoleInOrganization',
};
