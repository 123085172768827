import { useContext } from 'react';
import { LanguageContext } from '../languageContext/languageContext';

export const useLanguageContext = () => {
  const context = useContext(LanguageContext);

  return {
    ...context,
    changeLanguage: context.changeLanguage as (languageName?: string) => void,
    dictionary: JSON.parse(JSON.stringify(context.dictionary)),
  };
};
