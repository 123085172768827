import React from 'react';
import { FormikValues } from 'formik';
import { IModalProps } from '@components/Modal/types';
import { IChangePassword } from '@api/patients/types';
import { IResponse } from '@api/user/types';
import { errorAlert, successAlert } from '@helpers/alerts/alerts';
import changePasswordValidationSchema from '@helpers/validationSchemas/validationSchema';
import { ChangePasswordFormContent, passwordInitialValues } from '@pages/Profile/ModalContent/ChangePassword/Form';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition,
} from '@reduxjs/toolkit/query';
import { IDictionary } from '@constants/languages/types';
import { emptyString } from '@constants/emptyString';

export const changePasswordModal = (
  openModal: (state: IModalProps) => void,
  onSubmit: MutationTrigger<MutationDefinition<IChangePassword, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta>, 'Users', IResponse, 'userAPI'>>,
  dictionary: IDictionary,
) => {
  const onSubmitForm = async (formValues: FormikValues, closeModal:()=> void) => {
    onSubmit(formValues as IChangePassword).unwrap()
      .then(async ({ success,
        message }) => {
        if (success === 1) {
          closeModal();
          successAlert(emptyString, message);
        } else {
          errorAlert(message);
        }
      })
      .catch(({ data }) => errorAlert(data.message));
  };

  return openModal({
    children: <ChangePasswordFormContent />,
    initialValues: passwordInitialValues,
    validationSchema: changePasswordValidationSchema(dictionary),
    title: dictionary.labels.changePassword,
    submitButtonTitle: dictionary.buttons.saveChanges as string,
    onSubmit: onSubmitForm,
  });
};
