import React, { FC, useId } from 'react';
import { useField } from 'formik';
import { Calendar } from '@components/Calendar/Calendar';
import { IFormFieldProps } from '@components/Form/types';
import { Field } from '../Field';

export const DateField: FC<IFormFieldProps> = (props) => {
  const { id = '',
    showLabel = true,
    label = '',
    showHint = true,
    hint = '',
    showRequired = false,
    required = true,
    fieldControls } = props;

  const uniqId = useId();

  const fieldId = id || uniqId;

  const [field, meta] = useField(props);

  return (
    <Field
      id={fieldId}
      showLabel={showLabel}
      label={label}
      showError={meta.touched}
      error={meta.error}
      showHint={showHint}
      hint={hint}
      showRequired={showRequired}
      isRequired={required}
      fieldControls={fieldControls}
    >
      <div className="field-control">
        <Calendar {...field} {...props} hasError={meta.touched && !!meta.error} />
      </div>
    </Field>
  );
};
