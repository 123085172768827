import React, { FC } from 'react';
import { CloseButtonProps } from 'react-toastify';
import { UilMultiply } from '@iconscout/react-unicons';
import './styles.scss';

export const CloseButton: FC<CloseButtonProps> = ({ closeToast }) => (
  <span
    className="alert-close"
    onClick={closeToast}
  >
    <UilMultiply size={14} />
  </span>
);
