import logOutIcon from '@components/ProfileMenu/img/log-out.svg';
import userIcon from '@components/ProfileMenu/img/user.svg';
import { IDictionary } from '@constants/languages/types';
import { Routes } from '../../router';

export const navigateOptions = (dictionary: IDictionary) => [
  {
    id: 'profile',
    value: Routes.Profile,
    label: dictionary.profileMenu.profile,
    img: userIcon,
  }, {
    id: 'logout',
    value: 'logout',
    label: dictionary.profileMenu.logOut,
    img: logOutIcon,
  },
];
