import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import { Button } from '@components/Button';
import { Container } from '@components/Container';
import { Portal } from '@components/Portal';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { UilCheck, UilMultiply } from '@iconscout/react-unicons';
import { ModalTypes } from '@components/Confirm/types';
import { ButtonVariants } from '@components/Button/types';
import { ContainerSizes } from '@components/Container/types';
import { IModalProps, IModalRef } from '@components/Modal/types';
import './styles.scss';

export const Modal = React.forwardRef<IModalRef, IModalProps>((
  { title = '',
    children,
    handleConfirmClick,
    containerSize = ContainerSizes.sm,
    submitButton = true,
    closeButton = true,
    text,
    handleCloseClick,
    handleCancelClick,
    variant = undefined,
    approveModal,
    userData,
    closeButtonTitle,
    btnSize,
    submitButtonTitle },
  ref,
) => {
  const [isModalVisible, setModalVisible] = useState(false);

  const { dictionary } = useLanguageContext();

  const closeModal = async () => {
    if (handleCloseClick) {
      await handleCloseClick();
    }

    setModalVisible(false);
  };

  useImperativeHandle<IModalRef, IModalRef>(ref, () => ({
    isModalVisible,
    closeModal,
    openModal: () => setModalVisible(true),
  }));

  const targetRef = useRef<HTMLDivElement>(null);

  useEffect(() => () => clearAllBodyScrollLocks(), []);

  if (!isModalVisible) {
    return null;
  }

  const confirmIcon = variant === ModalTypes.decline ? <UilMultiply /> : <UilCheck />;

  const buttonVariant = variant === ModalTypes.decline ? ButtonVariants.secondary : ButtonVariants.primary;

  const confirmClassName = variant === ModalTypes.decline ? 'is-danger' : 'is-success';

  return (
    <Portal>
      <div
        className="modal"
        ref={targetRef}
      >
        <div
          className="modal-overlay"
          role="presentation"
          onClick={closeModal}
        />
        <Container size={containerSize}>
          <div className={variant ? `modal-window confirm ${confirmClassName}` : 'modal-window'}>
            {!variant && (
            <div className="modal-header">
              {title && (<div className="modal-title title-md">{title}</div>)}
              <span
                className="modal-close"
                role="button"
                tabIndex={0}
                onClick={closeModal}
                aria-label="Close"
              >
                <UilMultiply size={24} />
              </span>
            </div>
            )}
            <div className="modal-body">
              {variant && (
                <>
                  <span className="confirm-icon">
                    {confirmIcon}
                  </span>
                  <strong className="title-lg text-semibold">{title}</strong>
                  <p className="confirm-text text">{text}</p>
                </>
              )}
              {children}
            </div>
            <div className="modal-footer">
              {closeButton && (
              <Button
                label={closeButtonTitle || dictionary.actions.cancel}
                variant="default"
                size={btnSize || 'static'}
                handleClick={closeModal}
              />
              )}
              {submitButton && (
              <Button
                label={submitButtonTitle || dictionary.actions.saveChanges}
                size={btnSize || 'static'}
                variant={variant && buttonVariant}
                handleClick={handleConfirmClick}
              />
              )}
            </div>
          </div>
        </Container>
      </div>
    </Portal>
  );
});
