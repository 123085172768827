import React from 'react';
import { DayBody } from '@pages/Schedule/components/Day/DayBody';
import { DayHeader } from '@pages/Schedule/components/Day/DayHeader';
import { Legend } from '@pages/Schedule/components/Legend';
import { useScheduleContext } from '@pages/Schedule/context/useScheduleContext';
import { TableEmpty } from '@components/Table/components/TableEmpty';
import { useFetchScheduleByDayQuery } from '@api/schedule/scheduleAPI';
import { format } from 'date-fns';
import { formatToTimezone } from '@helpers/formatToTimezone';

export const Day = () => {
  const { selectedDate,
    selectedPatient,
    selectedLocation,
    selectedPhysician,
    selectedType,
    selectedTrial,
    selectedHourFrom,
    selectedHourTo } = useScheduleContext();

  const { data: appointments, isLoading } = useFetchScheduleByDayQuery({
    day: format(selectedDate, 'MM/dd/yyyy'),
    patient: selectedPatient || undefined,
    location: selectedLocation || undefined,
    physician: selectedPhysician || undefined,
    type: selectedType || undefined,
    trial: selectedTrial || undefined,
    from: !!selectedHourFrom && !!selectedDate ? formatToTimezone(selectedHourFrom, selectedDate) : undefined,
    to: !!selectedHourTo && !!selectedDate ? formatToTimezone(selectedHourTo, selectedDate) : undefined,
  }, {
    refetchOnMountOrArgChange: true,
  });

  const unscheduledAppointments = (selectedTrial?.length || selectedHourFrom?.length || selectedHourTo?.length || selectedType?.length || selectedPhysician?.length || selectedLocation?.length || selectedPatient?.length) && !appointments;

  return (
    <>
      <Legend />
      <div className="schedule-table table day">
        {unscheduledAppointments ? <TableEmpty emptyType="search" />
          : (
            <div className="schedule-content table-content">
              <DayHeader />
              <DayBody appointments={appointments} isLoading={isLoading} />
            </div>
          )}
      </div>
    </>
  );
};
