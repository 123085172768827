import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Slot } from '@pages/Schedule/components/Slot';
import { IWeekSlotProps } from '@pages/Schedule/components/Week/types';
import { useScheduleContext } from '@pages/Schedule/context/useScheduleContext';
import { Routes } from '../../../../router';

export const WeekSlot = ({ date, appointmentsAmount, eventTypes }: IWeekSlotProps) => {
  const schedule = useScheduleContext();

  const navigate = useNavigate();

  const onClick = () => {
    schedule.setDate(date);

    navigate(Routes.Day);
  };

  const slotDetails = !!appointmentsAmount && `${appointmentsAmount}${'\u00A0'}appointment${appointmentsAmount > 1 ? 's' : ''}`;

  return (
    <div className="schedule-cell table-cell">
      {!!appointmentsAmount && (
        <Slot
          variant="info"
          details={slotDetails}
          eventTypes={eventTypes}
          handleClick={onClick}
        />
      )}
    </div>
  );
};
