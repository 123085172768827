import { IResponse } from '@api/user/types';
import { RequestRoutes } from '@constants/requestRoutes';
import { apiHeader, headerJson } from '@helpers/commonHeaders';
import { convertToCamelCase } from '@helpers/convertToCamelCase';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { organizationsTag } from '@constants/apiTags';
import { language } from '@constants/language';
import { IModifyOrganization, IOrganizationResponse, IOrganizationsResponse } from '@api/organizations/types';
import snakecaseKeys from 'snakecase-keys';

const lang = localStorage.getItem(language)?.slice(1, 3) || 'en';

export const organizationsAPI = createApi({
  reducerPath: 'organizationsAPI',
  tagTypes: [organizationsTag],
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_API_URL}`,
    prepareHeaders: apiHeader,
  }),
  endpoints: (build) => ({
    fetchAllOrganizations: build.query<IOrganizationsResponse, {searchString?: string, limit: number, page?: number, sortField?: string}>({
      query: ({ searchString, page, sortField, limit }) => ({
        url: `${RequestRoutes.Organization}${RequestRoutes.GetAllOrganizations}`,
        params: {
          limit,
          page,
          sortField,
          searchString,
          lang,
        },
      }),
      transformResponse(response: IOrganizationsResponse) {
        return convertToCamelCase(response);
      },
    }),
    fetchOrganizationById: build.query<any, string>({
      query: (id) => ({
        url: `${RequestRoutes.Organization}${RequestRoutes.GetOrganizationById}`,
        params: {
          id,
        },
      }),
      transformResponse(response: IOrganizationResponse) {
        return convertToCamelCase(response);
      },
    }),
    addOrganization: build.mutation<IOrganizationResponse, IModifyOrganization>({
      query: (organization: IModifyOrganization) => ({
        url: `${RequestRoutes.Organization}${RequestRoutes.CreateOrganization}`,
        headers: headerJson,
        method: 'POST',
        body: organization,
        params: {
          lang,
        },
      }),
      invalidatesTags: [organizationsTag],
    }),
    deleteOrganization: build.mutation<IResponse, string>({
      query: (organizationId: string) => ({
        // TODO Change routes
        url: `${RequestRoutes.Organization}${RequestRoutes.DeleteOrganisation}`,
        method: 'DELETE',
        params: {
          id: organizationId,
        },
        keepUnusedDataFor: 5,
      }),
      invalidatesTags: () => [organizationsTag],
    }),
    editOrganization: build.mutation<IResponse, { organization: IModifyOrganization, id: string }>({
      query: ({ organization, id }) => ({
        url: `${RequestRoutes.Organization}${RequestRoutes.EditOrganization}`,
        headers: headerJson,
        params: {
          id,
        },
        method: 'POST',
        body: snakecaseKeys(organization),
      }),
      invalidatesTags: () => [organizationsTag],
    }),
  }),
});

export const { useFetchAllOrganizationsQuery,
  useFetchOrganizationByIdQuery,
  useEditOrganizationMutation,
  useAddOrganizationMutation,
  useDeleteOrganizationMutation } = organizationsAPI;
