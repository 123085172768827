import React from 'react';
import './styles.scss';

export const Loader = () => (
  <div className="overlay">
    <div className="spinner">
      <div className="circle-1 spinner-circle" />
      <div className="circle-2 spinner-circle" />
      <div className="circle-3 spinner-circle" />
      <div className="circle-4 spinner-circle" />
      <div className="circle-5 spinner-circle" />
      <div className="circle-6 spinner-circle" />
      <div className="circle-7 spinner-circle" />
      <div className="circle-8 spinner-circle" />
      <div className="circle-9 spinner-circle" />
      <div className="circle-10 spinner-circle" />
      <div className="circle-11 spinner-circle" />
      <div className="circle-12 spinner-circle" />
    </div>
  </div>
);
