import React, { useState } from 'react';
import { SearchForm } from '@components/SearchForm';
import { Section } from '@components/Section';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { skipToken } from '@reduxjs/toolkit/query';
import { Loader } from '@components/Loader';
import { useAddOrganizationMutation, useFetchAllOrganizationsQuery } from '@api/organizations/organizationsAPI';
import { OrganizationsTable } from '@pages/Organizations/components/OrganizationsTable';
import { ModalToggle } from '@components/Modal/ModalToggler';
import { errorAlert } from '@helpers/alerts/alerts';
import { IModifyOrganization } from '@api/organizations/types';
import { addOrganizationInitialValues, FormContent } from '@pages/Organizations/ManageOrganizationModal';
import organizationsValidationSchema from '@helpers/validationSchemas/organizationsValidationSchema';
import { FormikValues } from 'formik';

export const Organizations = () => {
  const { dictionary } = useLanguageContext();

  const [searchTitle, setSearchTitle] = useState<string>('');

  const [limit, setLimit] = useState<number>(20);

  const [sortField, setSortField] = useState<string>('');

  const startPage = 1;

  const { data: organizations, refetch, isLoading } = useFetchAllOrganizationsQuery({
    searchString: searchTitle, limit, page: startPage, sortField,
  } ?? skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const [createOrganization] = useAddOrganizationMutation();

  const next = () => {
    if (organizations?.data.currentPage !== organizations?.data.totalPages) {
      setLimit(limit + 20);
    }
  };

  const onSubmitForm = async (formValues: FormikValues, closeModal: () => void) => {
    createOrganization(formValues as IModifyOrganization)
      .unwrap()
      .then(async ({ success,
        message }) => {
        refetch();
        if (success === 1) {
          closeModal();
        } else {
          errorAlert(message);
        }
      })
      .catch(({ message }) => errorAlert(message));
  };

  const modalProps = {
    children: <FormContent dictionary={dictionary} />,
    initialValues: addOrganizationInitialValues,
    validationSchema: organizationsValidationSchema(dictionary),
    title: dictionary.organizations.modals.createOrganization.title,
    submitButtonTitle: dictionary.table.add,
    onSubmit: onSubmitForm,
  };

  return (
    <Section
      title={dictionary.organizations.title}
      controls={(
        <>
          <SearchForm setSearchTitle={setSearchTitle} />
          <ModalToggle
            buttonTitle={dictionary.organizations.modals.createOrganization.createBtn}
            modalProps={modalProps}
          />
        </>
      )}
    >
      {isLoading ? <Loader /> : (
        <OrganizationsTable
          setSortField={setSortField}
          organisations={organizations}
          searchTitle={searchTitle}
          refetch={refetch}
          refreshFunction={refetch}
          next={next}
          dataLength={organizations?.data.total}
        />
      )}
    </Section>
  );
};
