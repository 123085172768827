import React from 'react';
import { NavLink } from 'react-router-dom';
import { TScheduleRoutes } from '@pages/Schedule/components/Switcher/types';
import { ScheduleRoutes } from '@pages/Schedule/constants/ScheduleRoutes';
import './styles.scss';

export const Switcher = () => (
  <ul className="switcher">
    {
        Object.keys(ScheduleRoutes).map((key, index) => (
          <li key={index} className="switcher-item">
            <NavLink
              to={ScheduleRoutes[key as keyof TScheduleRoutes]}
              className={({ isActive }) => (`switcher-link ${isActive && 'is-active'}`)}
            >
              {key}
            </NavLink>
          </li>
        ))
      }
  </ul>
);
