import React, { ReactNode } from 'react';
import { generateWeekDays } from '@helpers/generateWeekDays';

export const MonthHeader = () => {
  const tableSettings = generateWeekDays();

  const tableHeader = tableSettings.reduce<ReactNode[]>((acc, { id, label, shortLabel }) => [...acc,
    <div className="schedule-cell table-cell is-heading" key={`schedule-cell-${id}`}>
      <span className="schedule-heading">
        { label }
      </span>
      {shortLabel && (
        <span className="schedule-heading-short">
          {shortLabel}
        </span>
      )}
    </div>], []);

  return (
    <div className="schedule-header table-header">
      <div className="schedule-row table-row">{ tableHeader }</div>
    </div>
  );
};
