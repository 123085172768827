import { PropsWithChildren, useMemo, useState } from 'react';
import { ScheduleContext } from './ScheduleContext';

export const ScheduleContextProvider = ({ children }: PropsWithChildren) => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [selectedLocation, setSelectedLocation] = useState<string | null>(null);

  const [selectedPhysician, setSelectedPhysician] = useState<string | null>(null);

  const [selectedPatient, setSelectedPatient] = useState<string | null>(null);

  const [selectedType, setSelectedType] = useState<string | null>(null);

  const [selectedTrial, setSelectedTrial] = useState<string | null>(null);

  const [selectedHourFrom, setSelectedHourFrom] = useState<string | null>(null);

  const [selectedHourTo, setSelectedHourTo] = useState<string | null>(null);

  const contextValues = {
    selectedDate,
    selectedLocation,
    selectedPhysician,
    selectedPatient,
    selectedType,
    selectedTrial,
    selectedHourFrom,
    selectedHourTo,
    setDate: (date: Date) => setSelectedDate(date),
    setSelectedLocation: (location: string) => setSelectedLocation(location),
    setSelectedPhysician: (physician: string) => setSelectedPhysician(physician),
    setSelectedPatient: (patient: string) => setSelectedPatient(patient),
    setSelectedType: (type: string) => setSelectedType(type),
    setSelectedTrial: (trial: string) => setSelectedTrial(trial),
    setSelectedHourFrom: (hourFrom: string) => setSelectedHourFrom(hourFrom),
    setSelectedHourTo: (hourTo: string) => setSelectedHourTo(hourTo),
  };

  const cachedProvider = useMemo(() => contextValues, [contextValues]);

  return (
    <ScheduleContext.Provider value={cachedProvider}>
      {children}
    </ScheduleContext.Provider>
  );
};
