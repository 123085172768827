export const replaceObjectKey = (object: Record<string, unknown>, key: string | number, newKey: string | number) => {
  const clonedObj = {
    ...object,
  };

  const targetKey = clonedObj[key];

  delete clonedObj[key];

  clonedObj[newKey] = targetKey;

  return clonedObj;
};
