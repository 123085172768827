import React, { FC, FocusEvent, useId } from 'react';
import { useField } from 'formik';
import { IFormFieldProps } from '@components/Form/types';
import { Select } from '@components/Select';
import { Field } from '../Field';

export const SelectField:FC<IFormFieldProps> = (props) => {
  const { id = '',
    showLabel = true,
    label = '',
    disabled,
    multiselectOptions,
    showHint = true,
    hint = '',
    isMulti,
    selectOptions,
    showRequired = false,
    required = true,
    fieldControls } = props;

  const uniqId = useId();

  const fieldId = id || uniqId;

  const [field, meta, { setTouched }] = useField(props);

  const onSelectBlur = (ev: FocusEvent<HTMLInputElement>) => {
    field.onBlur(ev);
    setTouched(true);
  };

  return (
    <Field
      id={fieldId}
      showLabel={showLabel}
      label={label}
      showError={meta.touched}
      error={meta.error}
      showHint={showHint}
      hint={hint}
      showRequired={showRequired}
      isRequired={required}
      fieldControls={fieldControls}
    >
      <div className="field-control">
        <Select
          isDisabled={disabled}
          isMulti={isMulti}
          multiOptions={multiselectOptions || []}
          options={selectOptions || []}
          {...field}
          {...props}
          handleBlur={onSelectBlur}
          hasError={meta.touched && !!meta.error}
        />
      </div>
    </Field>
  );
};
