import React, { FC } from 'react';
import { IDictionary } from '@constants/languages/types';
import { AppointmentDetails } from '@pages/Appointment/pages/CreateAppointment/components/AppointmentDetails';
import { FieldArray, FormikValues } from 'formik';
import { Button } from '@components/Button';
import { UilPlus, UilTrash } from '@iconscout/react-unicons';

interface IScheduleFormProps {
    dictionary: IDictionary,
    isEdit?: boolean;
    visitValues?: FormikValues,
}

export const ScheduleForm: FC<IScheduleFormProps> = ({ dictionary, isEdit, visitValues }) => (
  <div className="col-xs-12">
    <FieldArray
      name="appointmentTemplate"
      render={(arrayHelpers) => (
        <>
          {[...arrayHelpers.form.values.appointmentTemplate].map((visit: string, index: number) => (
            <React.Fragment key={index}>
              <section className="form-section">
                <div className="card-heading">
                  <h2 className="form-title title-md">{dictionary.appointments.visitsRange[index as keyof typeof dictionary.appointments.visitsRange]}</h2>
                  <div className="card-controls">
                    {(arrayHelpers.form.values.appointmentTemplate.length >= 3 && index >= 2) && (
                      <Button
                        size="small"
                        type="button"
                        variant="danger"
                        handleClick={() => {
                          arrayHelpers.remove(index);
                        }}
                        iconAppend={<UilTrash />}
                      />
                    )}
                  </div>
                </div>
                <AppointmentDetails index={index} isEdit={isEdit} />
              </section>
            </React.Fragment>
          ))}
          {arrayHelpers.form.values.appointmentTemplate.length < 30 && (
            <Button
              variant="link"
              type="button"
              handleClick={() => arrayHelpers.insert(arrayHelpers.form.values.appointmentTemplate.length, visitValues)}
              label={dictionary.trials.addTrial.addVisit}
              iconPrepend={<UilPlus />}
            />
          )}
        </>
      )}
    />
  </div>
);
