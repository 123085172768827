import React, { FC, useEffect } from 'react';
import { addDays, endOfDay, format, getMonth, getYear } from 'date-fns';
import { useField } from 'formik';
import DatePicker from 'react-datepicker';
import { Container } from '@components/Calendar/components/Container';
import { AutocompleteVariants, IInputProps } from '@components/Input/types';
import { Select } from '@components/Select';
import { months } from '@constants/month';
import { futureYears, pastYears } from '@constants/pastYears';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { calculateAge } from '@helpers/calculateAge';
import './styles.scss';

export const Calendar: FC<IInputProps> = (props) => {
  const { type = 'text',
    disabled = false,
    required = false,
    value = '',
    isWeekend,
    onChangeAge,
    placeholder = 'Some text...',
    autocomplete = AutocompleteVariants.off,
    hasError = false,
    iconAppend,
    protocolWindow,
    excludeDates,
    dateValue,
    visitRange,
    onEffect,
    selectedDate,
    calendarStartDay,
    maxDate = true,
    iconPrepend,
    name } = props;

  const { dictionary } = useLanguageContext();

  const inputClassName = `input-control ${hasError ? 'has-error' : ''}`;

  const [field, meta, helpers] = useField(props);

  const { setValue } = helpers;

  const selectDate = () => {
    if (selectedDate && visitRange) {
      if (field.value) {
        return new Date(field.value);
      }
      return addDays(new Date(selectedDate), visitRange);
    }
    return (field.value && new Date(field.value)) || null;
  };

  useEffect(() => {
    if (selectedDate && visitRange !== undefined) {
      if (field.value) {
        setValue(new Date(field.value));
      }
      setValue(addDays(new Date(selectedDate), visitRange));
    }
  }, [selectedDate, visitRange]);

  return (
    <div className="input">
      {iconPrepend && (
        <span className="input-prepend-icon" role="presentation">
          {iconPrepend}
        </span>
      )}
      {iconAppend && (
        <span className="input-append-icon" role="presentation">
          {iconAppend}
        </span>
      )}
      <DatePicker
        {...field}
        {...props}
        {...meta}
        calendarClassName="rasta-stripes"
        calendarContainer={Container}
        disabled={disabled}
        excludeDates={excludeDates}
        minDate={dateValue ? addDays(dateValue, -Math.abs(protocolWindow as number)) : null}
        maxDate={maxDate ? endOfDay(new Date()) : dateValue && addDays(dateValue, Number(protocolWindow))}
        calendarStartDay={calendarStartDay ? 0 : 1}
        formatWeekDay={(str) => str.slice(0, 3)}
        name={name}
        filterDate={isWeekend}
        dateFormat="MM/dd/yyyy"
        placeholderText={placeholder}
        selected={selectDate()}
        onChange={(val) => {
          onEffect && onEffect();

          if (val) {
            setValue(val);
            if (typeof onChangeAge !== 'boolean') {
              if (onChangeAge) {
                onChangeAge(calculateAge(format(val, 'yyyy-MM-dd')));
              }
            }
          }
        }}
        customInput={(
          <input
            className={inputClassName}
            type={type}
            maxLength={10}
            value={value}
            required={required}
            onSelect={(selectedDate && isWeekend && isWeekend(new Date(selectDate()))) ? () => setValue(selectDate()) : undefined}
            autoComplete={autocomplete}
            {...props}
          />
        )}
        renderCustomHeader={({ date,
          changeYear,
          changeMonth }) => (
            <div className="calendar-header">
              <div className="calendar-dropdown">
                <Select
                  name={name}
                  value={months(dictionary)[getMonth(date)].value}
                  options={months(dictionary)}
                  isSearchable={false}
                  handleChange={changeMonth}
                  menuPosition="absolute"
                />
              </div>
              <div className="calendar-dropdown">
                <Select
                  name={name}
                  value={getYear(date)}
                  handleChange={changeYear}
                  isSearchable={false}
                  options={!maxDate && !!onChangeAge ? pastYears : futureYears}
                  menuPosition="absolute"
                />
              </div>
            </div>
        )}
      />
    </div>
  );
};
