import {
  IAllUserAppointment,
  IAppointment,
  IAppointmentResponse, IApproveAppointmentInfo, ICountAppointments,
  ICreateAppointment, IDeclineAppointment,
  IEditAppointment, IEditAppointments, IEditAppointmentsInfo,
} from '@api/appointments/types';
import { IResponse } from '@api/user/types';
import { RequestRoutes } from '@constants/requestRoutes';
import { apiHeader, headerJson } from '@helpers/commonHeaders';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import snakecaseKeys from 'snakecase-keys';
import { convertToCamelCase } from '@helpers/convertToCamelCase';
import { appointmentsTag } from '@constants/apiTags';
import { emptyString } from '@constants/emptyString';
import { language } from '@constants/language';

const lang = localStorage.getItem(language)?.slice(1, 3) || 'en';

export const appointmentsAPI = createApi({
  reducerPath: 'appointmentsAPI',
  refetchOnFocus: true,
  tagTypes: [appointmentsTag],
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_API_URL}`,
    prepareHeaders: apiHeader,
  }),
  endpoints: (build) => ({
    fetchAllAppointments: build.query<IAppointmentResponse, {searchString: string, page?: number, limit: number, sortField?: string, filterString: string}>({
      query: ({ searchString = emptyString, page, sortField, limit, filterString }) => ({
        url: `${RequestRoutes.Appointment}${RequestRoutes.Staff}${RequestRoutes.GetAll}`,
        params: {
          limit,
          lang,
          page,
          sortField,
          searchString,
          filterString,
        },
        headers: headerJson,
        keepUnusedDataFor: 5,
      }),
      providesTags: [appointmentsTag],
    }),
    fetchAllAppointmentsWithoutPagination: build.query<IAppointmentResponse, void>({
      query: () => ({
        url: `${RequestRoutes.Appointment}${RequestRoutes.Staff}${RequestRoutes.GetAll}`,
        params: {
          searchString: '',
          lang,
        },
        headers: headerJson,
        keepUnusedDataFor: 5,
      }),
      providesTags: [appointmentsTag],
    }),
    fetchAllUserAppointments: build.query<IAllUserAppointment, {patientId: string, trialId?: string}>({
      query: ({ patientId, trialId }) => ({
        url: `${RequestRoutes.Appointment}${RequestRoutes.Staff}${RequestRoutes.GetAllUsersAppointments}`,
        params: {
          patient_id: patientId,
          trial_id: trialId,
          lang,
        },
        headers: headerJson,
        keepUnusedDataFor: 5,
      }),
      transformResponse(response: IAppointmentResponse) {
        return convertToCamelCase(response);
      },
      providesTags: [appointmentsTag],
    }),
    fetchAllUserAppointmentsWithFilter: build.query<IAllUserAppointment, {patientId: string, date?: string}>({
      query: ({ patientId, date }) => ({
        url: `${RequestRoutes.Appointment}${RequestRoutes.Staff}${RequestRoutes.GetAllUsersAppointments}`,
        params: {
          patient_id: patientId,
          date: String(date),
          lang,
        },
        headers: headerJson,
        keepUnusedDataFor: 5,
      }),
      transformResponse(response: IAppointmentResponse) {
        return convertToCamelCase(response);
      },
      providesTags: [appointmentsTag],
    }),
    fetchAppointmentById: build.query<IAppointment, string>({
      query: (id) => ({
        url: `${RequestRoutes.Appointment}/${id}`,
        params: {
          lang,
        },
      }),
      transformResponse(response: IAppointmentResponse) {
        return convertToCamelCase(response);
      },
    }),
    addNewAppointments: build.mutation<IAppointmentResponse, ICreateAppointment>({
      query: (appointment: ICreateAppointment) => ({
        url: `${RequestRoutes.Appointment}${RequestRoutes.Staff}${RequestRoutes.CreateNewAppointment}`,
        headers: headerJson,
        method: 'POST',
        params: {
          lang,
        },
        body: snakecaseKeys(appointment),
      }),
      invalidatesTags: [appointmentsTag],
    }),
    editAppointments: build.mutation<IResponse, IEditAppointment>({
      query: (appointment: IEditAppointment) => ({
        url: `${RequestRoutes.Appointment}${RequestRoutes.Staff}${RequestRoutes.EditAppointmentsGeneralInfo}`,
        headers: headerJson,
        params: appointment.id ? {
          id: appointment.id,
          lang,
        } : {
          lang,
        },
        method: 'POST',
        body: {
          notifications_settings: appointment.notificationsSettings,
        },
      }),
      invalidatesTags: [appointmentsTag],
    }),
    editGeneralInfoAppointments: build.mutation<IResponse, IEditAppointment>({
      query: (appointment: IEditAppointment) => ({
        url: `${RequestRoutes.Appointment}${RequestRoutes.Staff}${RequestRoutes.EditAppointmentsGeneralInfo}`,
        headers: headerJson,
        method: 'POST',
        params: {
          lang,
        },
        body: appointment,
      }),
      invalidatesTags: [appointmentsTag],
    }),
    editAppointmentsInfo: build.mutation<IResponse, IEditAppointmentsInfo>({
      query: (appointment: IEditAppointmentsInfo) => ({
        url: `${RequestRoutes.Appointment}${RequestRoutes.Staff}${RequestRoutes.EditAppointmentsDetails}`,
        headers: headerJson,
        method: 'POST',
        body: appointment,
        params: {
          lang,
        },
      }),
      invalidatesTags: [appointmentsTag],
    }),
    deleteAppointments: build.mutation<IResponse, string>({
      query: (locationId: string) => ({
        url: `${RequestRoutes.Appointment}${RequestRoutes.Staff}${RequestRoutes.DeleteAppointment}`,
        method: 'DELETE',
        params: {
          id: locationId,
          lang,
        },
        keepUnusedDataFor: 5,
      }),
      invalidatesTags: () => [appointmentsTag],
    }),
    approveAppointment: build.mutation<IResponse, IApproveAppointmentInfo>({
      query: (appointment: IApproveAppointmentInfo) => ({
        url: `${RequestRoutes.Appointment}${RequestRoutes.Patient}${RequestRoutes.Approve}`,
        headers: headerJson,
        method: 'POST',
        body: appointment,
        params: {
          lang,
        },
      }),
      invalidatesTags: [appointmentsTag],
    }),
    declineAppointment: build.mutation<IResponse, IDeclineAppointment>({
      query: (appointment: IDeclineAppointment) => ({
        url: `${RequestRoutes.Appointment}${RequestRoutes.Patient}${RequestRoutes.Cancel}`,
        headers: headerJson,
        method: 'POST',
        body: appointment,
        params: {
          lang,
        },
      }),
      invalidatesTags: [appointmentsTag],
    }),
    editAppointment: build.mutation<IResponse, IEditAppointments>({
      query: (appointment: IEditAppointments) => ({
        url: `${RequestRoutes.Appointment}${RequestRoutes.Patient}${RequestRoutes.Edit}`,
        headers: headerJson,
        method: 'POST',
        body: appointment,
        params: {
          lang,
        },
      }),
      invalidatesTags: [appointmentsTag],
    }),
    resendAppointment: build.mutation<IResponse, string>({
      query: (appointmentId: string) => ({
        url: `${RequestRoutes.Appointment}${RequestRoutes.Staff}${RequestRoutes.ResendAppointmentToStaff}`,
        headers: headerJson,
        method: 'POST',
        body: {
          id: appointmentId,
        },
      }),
      invalidatesTags: [appointmentsTag],
    }),
    getCountOfRejected: build.query<ICountAppointments, void>({
      query: () => ({
        url: `${RequestRoutes.Appointment}${RequestRoutes.Staff}${RequestRoutes.GetCountOfRejected}`,
      }),
    }),
  }),
});

export const { useFetchAllAppointmentsQuery,
  useFetchAllUserAppointmentsQuery,
  useApproveAppointmentMutation,
  useResendAppointmentMutation,
  useDeclineAppointmentMutation,
  useGetCountOfRejectedQuery,
  useFetchAllAppointmentsWithoutPaginationQuery,
  useEditAppointmentMutation,
  useDeleteAppointmentsMutation,
  useFetchAllUserAppointmentsWithFilterQuery,
  useEditAppointmentsInfoMutation,
  useEditGeneralInfoAppointmentsMutation,
  useFetchAppointmentByIdQuery,
  useEditAppointmentsMutation,
  useAddNewAppointmentsMutation } = appointmentsAPI;
