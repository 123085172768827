import { IChangeEmail, IChangePhone, IDeletePatient, ISearchPatients } from '@api/patients/types';
import { IResponse, IUserResponse, TUserWithParents } from '@api/user/types';
import { RequestRoutes } from '@constants/requestRoutes';
import { apiHeader, headerJson } from '@helpers/commonHeaders';
import { IAllPatient } from '@pages/Patients/components/PatientsTable/types';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { patientsTag } from '@constants/apiTags';
import { language } from '@constants/language';

const lang = localStorage.getItem(language)?.slice(1, 3) || 'en';

export const patientsAPI = createApi({
  reducerPath: 'patientsAPI',
  tagTypes: [patientsTag],
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_API_URL}`,
    prepareHeaders: apiHeader,
  }),
  endpoints: (build) => ({
    fetchPatientById: build.query<TUserWithParents, string | undefined>({
      query: (id) => `${RequestRoutes.User}/${id}`,
    }),
    fetchAllPatients: build.query<IAllPatient, {getWithInvite: string, limit: number, page: number}>({
      query: ({ getWithInvite, page, limit }) => ({
        url: `${RequestRoutes.Patients}${RequestRoutes.GetAll}`,
        headers: headerJson,
        params: {
          getWithInvite,
          lang,
          limit,
          page,
        },
        keepUnusedDataFor: 5,
      }),
      providesTags: [patientsTag],
    }),
    fetchAllPatientsWithoutPagination: build.query<IAllPatient, void>({
      query: () => ({
        url: `${RequestRoutes.Patients}${RequestRoutes.GetAll}`,
        headers: headerJson,
        keepUnusedDataFor: 5,
        params: {
          lang,
        },
      }),
      providesTags: [patientsTag],
    }),
    searchPatients: build.query<ISearchPatients, string>({
      query: (title) => ({
        url: `${RequestRoutes.User}${RequestRoutes.Search}`,
        params: {
          searchString: title,
          lang,
        },
        keepUnusedDataFor: 5,
      }),
      providesTags: [patientsTag],
    }),
    changePatientData: build.mutation<IUserResponse, { userData:any, patientId:string }>({
      query: (userData) => ({
        url: `${RequestRoutes.Profile}${RequestRoutes.ChangeCommonInfo}`,
        params: {
          patientId: userData.patientId,
          lang,
        },
        method: 'POST',
        body: userData.userData,
      }),
      invalidatesTags: [patientsTag],
    }),
    changePatientEmail: build.mutation<IResponse, IChangeEmail>({
      query: (email: IChangeEmail) => ({
        url: `${RequestRoutes.Profile}${RequestRoutes.ChangeEmail}`,
        headers: headerJson,
        params: {
          patientId: email.userId,
          lang,
        },
        method: 'POST',
        body: email,
      }),
      invalidatesTags: [patientsTag],
    }),
    deletePatient: build.mutation<IResponse, IDeletePatient>({
      query: (patientId: IDeletePatient) => ({
        url: `${RequestRoutes.Patients}${RequestRoutes.DeleteAccount}`,
        method: 'DELETE',
        body: patientId,
        params: {
          lang,
        },
        keepUnusedDataFor: 5,
      }),
      invalidatesTags: () => [patientsTag],
    }),
    changeParentPhone: build.mutation<IResponse, IChangePhone>({
      query: (phone: IChangePhone) => ({
        url: `${RequestRoutes.Parent}${RequestRoutes.ChangePhone}${RequestRoutes.SendSms}`,
        headers: headerJson,
        method: 'POST',
        params: {
          patientId: phone.userId ? phone.userId : undefined,
          lang,
        },
        body: phone,
      }),
      invalidatesTags: [patientsTag],
    }),
    verifyNewParentPhone: build.mutation<IResponse, IChangePhone>({
      query: (phone: IChangePhone) => ({
        url: `${RequestRoutes.Parent}${RequestRoutes.ChangePhone}${RequestRoutes.VerifyPhone}`,
        headers: headerJson,
        method: 'POST',
        body: phone,
        params: {
          lang,
        },
      }),
      invalidatesTags: [patientsTag],
    }),
  }),
});

export const { useSearchPatientsQuery,
  useDeletePatientMutation,
  useChangePatientEmailMutation,
  useVerifyNewParentPhoneMutation,
  useChangeParentPhoneMutation,
  useFetchPatientByIdQuery,
  useChangePatientDataMutation,
  useFetchAllPatientsWithoutPaginationQuery,
  useFetchAllPatientsQuery } = patientsAPI;
