import { useContext } from 'react';
import { ConfirmationModalContext } from '@components/Confirm/context/ConfirmationModalContext';

export const useConfirmationModal = () => {
  const context = useContext(ConfirmationModalContext);

  if (!context) {
    throw new Error('useConfirmationModal used outside ConfirmationModalContext');
  }

  return context;
};
