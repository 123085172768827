import React, { useState } from 'react';
import { FormikValues } from 'formik';
import { ModalToggle } from '@components/Modal/ModalToggler';
import { SearchForm } from '@components/SearchForm';
import { Section } from '@components/Section';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { IInvite } from '@api/patients/types';
import { useSendInviteMutation } from '@api/user/userAPI';
import { errorAlert } from '@helpers/alerts/alerts';
import { FormContent, patientsInitialValues } from '@pages/Patients/components/InvitePatientsModal';
import inviteValidationSchema from '@helpers/validationSchemas/inviteValidationSchema';
import { PatientsTable } from '@pages/Patients/components/PatientsTable';
import { useFetchAllPatientsQuery } from '@api/patients/patientsAPI';
import { Loader } from '@components/Loader';

export const Patients = () => {
  const [searchTitle, setSearchTitle] = useState<string>('');

  const [limit, setLimit] = useState<number>(20);

  const startPage = 1;

  const { data: patients, refetch, isLoading } = useFetchAllPatientsQuery({
    page: startPage, limit, getWithInvite: 'true',
  });

  const next = () => {
    if (patients?.data.currentPage !== patients?.data.totalPages) {
      setLimit(limit + 20);
    }
  };

  const { dictionary } = useLanguageContext();

  const [sendInvite] = useSendInviteMutation();

  const onSubmitForm = async (formValues: FormikValues, closeModal:()=> void) => {
    sendInvite(formValues as IInvite)
      .unwrap()
      .then(async ({ success,
        message }) => {
        if (success === 1) {
          await closeModal();
          refetch();
        } else {
          errorAlert(message);
        }
      })
      .catch(({ message }) => errorAlert('', `The following users already got an invitation or are already registered in the system. Make sure the following emails are correct and resend the form: ${formValues.invite.map((obj: { email: string; }) => obj.email).join(', ')}.`));
  };

  const modalProps = {
    children: FormContent(dictionary),
    initialValues: patientsInitialValues,
    validationSchema: inviteValidationSchema(dictionary),
    title: dictionary.patients.modals.addPatients.invitePatients,
    submitButtonTitle: dictionary.patients.modals.addPatients.invite,
    onSubmit: onSubmitForm,
  };

  return (
    <Section
      title={dictionary.patients.title}
      controls={(
        <>
          <SearchForm setSearchTitle={setSearchTitle} />
          <ModalToggle buttonTitle={dictionary.patients.modals.addPatients.invitePatients} modalProps={modalProps} />
        </>
          )}
    >
      {isLoading ? <Loader /> : <PatientsTable refreshFunction={refetch} next={next} dataLength={patients?.data.total} data={patients?.data.results} searchTitle={searchTitle} />}
    </Section>
  );
};
