import React, { useEffect, useState } from 'react';
import { Container } from '@components/Container';
import { format } from 'date-fns';
import {
  useApproveAppointmentMutation,
  useDeclineAppointmentMutation,
  useEditAppointmentMutation,
  useFetchAllUserAppointmentsWithFilterQuery,
} from '@api/appointments/appointmentsApi';
import { Card } from '@components/Card';
import { IAction } from '@components/Table/utils/actionsFormatter';
import { IDecline, TAppointment } from '@api/appointments/types';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { useModal } from '@components/Modal/context/useModalContext';
import { EditAppointment } from '@pages/SchedulePatient/components/EditAppointment';
import { useConfirmationModal } from '@components/Confirm/context/useConfirmContext';
import { DeclineAppointment } from '@pages/SchedulePatient/components/DeclineAppointment';
import declineAppointmentValidationSchema from '@helpers/validationSchemas/declineAppointmentValidationSchema';
import { FormikValues } from 'formik';
import { errorAlert, successAlert } from '@helpers/alerts/alerts';
import { Loader } from '@components/Loader';
import { ModalTypes } from '@components/Confirm/types';
import editAppointmentValidationSchema from '@helpers/validationSchemas/editAppointmentValidationSchema';
import { ContainerSizes } from '@components/Container/types';
import { ButtonSizes } from '@components/Button/types';
import { TableEmpty } from '@components/Table/components/TableEmpty';
import { StatusVariants } from '@constants/options/statusOptions';
import { getEndTime, getStartTime } from '@helpers/getVisitTime';
import { convertTime } from '@helpers/convertTime';
import { emptyString } from '@constants/emptyString';
import { timeToTimestampFormatter } from '@helpers/timeToTimestampFormatter';
import { ILoggedInUser } from '@api/user/types';
import { loadState } from '@store/browserStorage';
import { IUserData } from '@store/types';
import { persistAuth } from '@constants/persist';

export const PatientAppointments = () => {
  const [declineAppointment] = useDeclineAppointmentMutation();

  const [editAppointment] = useEditAppointmentMutation();

  const [approveAppointment] = useApproveAppointmentMutation();

  const { dictionary } = useLanguageContext();

  const authData = loadState<IUserData>(persistAuth)?.auth;

  const { user }: ILoggedInUser = authData ? JSON.parse(authData as unknown as string) : {
    user: null,
  };

  const { openModal } = useModal();

  const { openConfirmationModal } = useConfirmationModal();

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the threshold as needed
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { data: appointments, isLoading, refetch } = useFetchAllUserAppointmentsWithFilterQuery({
    patientId: String(user?.id), date: '',
  } ?? {
    skip: user?.id,
  });

  const initialValuesDeclineAppointment = {
    reason: `${dictionary.appointments.modals.declineAppointment.reason.scheduleConflict}`,
    comment: '',
  };

  const appointmentData = ({ date, time, serialNumber, trials, locations }: TAppointment) => [
    {
      label: dictionary.appointments.cards.date,
      value: format(new Date(date), 'MM/dd/yyyy'),
    },
    {
      label: dictionary.appointments.cards.time,
      value: time,
    },
    {
      label: dictionary.appointments.cards.locations,
      value: `${locations.name} ${locations.address}`,
    },
    {
      label: dictionary.appointments.cards.doctor,
      value: trials.physicians.name,
    },
    {
      label: dictionary.appointments.cards.serialNumber,
      value: serialNumber,
    },
  ];

  const editAppointmentInitialValues = {
    date: '',
    time: '',
  };

  const onEdit = async (selectedAppointment: TAppointment, formValues: FormikValues, closeModal: () => void) => {
    editAppointment({
      id: selectedAppointment.id,
      date: formValues.date,
      time: formValues.time,
      from: String(timeToTimestampFormatter(getStartTime(formValues.time), new Date(formValues.date))),
      to: String(timeToTimestampFormatter(getEndTime(formValues.time), new Date(formValues.date))),
      fromTime: convertTime(getStartTime(formValues.time)),
      toTime: convertTime(getEndTime(formValues.time)),
    }).unwrap()
      .then(async ({ message }) => {
        successAlert(emptyString, dictionary.actions.done);
        await closeModal();
        refetch();
      })
      .catch(({ message }) => errorAlert(message));
  };

  const onEditAppointment = (selectedAppointment: TAppointment) => {
    openModal({
      children: <EditAppointment selectedAppointment={selectedAppointment} />,
      initialValues: editAppointmentInitialValues,
      validationSchema: editAppointmentValidationSchema(dictionary),
      title: dictionary.labels.editAppointment,
      submitButtonTitle: dictionary.actions.confirm,
      containerSize: ContainerSizes.xxs,
      btnSize: ButtonSizes.large,
      onSubmit: (formValues, closeModal) => onEdit(selectedAppointment, formValues, closeModal),
    });
  };

  const onDecline = async (selectedAppointment: TAppointment, formValues: FormikValues, closeModal: () => void) => {
    if (formValues) {
      declineAppointment({
        id: selectedAppointment.id,
        comment: formValues as IDecline,
      })
        .unwrap()
        .then(async ({ success,
          message }) => {
          if (success === 1) {
            successAlert(emptyString, message);
            await closeModal();
            refetch();
          } else {
            errorAlert(message);
          }
        })
        .catch(({ message }) => errorAlert(message));
    }
  };

  const onDeclineAppointment = (selectedAppointment: TAppointment) => openModal({
    children: <DeclineAppointment />,
    title: dictionary.appointments.modals.declineAppointment.title,
    text: dictionary.appointments.modals.declineAppointment.text,
    variant: ModalTypes.decline,
    initialValues: initialValuesDeclineAppointment,
    validationSchema: isMobile ? null : declineAppointmentValidationSchema(dictionary),
    submitButtonTitle: dictionary.actions.confirm,
    btnSize: ButtonSizes.large,
    onSubmit: (formValues, closeModal) => onDecline(selectedAppointment, formValues, closeModal),
  });

  const onApproveAppointment = (selectedAppointment: TAppointment) => {
    openConfirmationModal({
      variant: ModalTypes.approve,
      title: dictionary.appointments.modals.approveAppointment.title,
      text: dictionary.appointments.modals.approveAppointment.text,
      submitButtonTitle: dictionary.table.approve,
      modalSize: ContainerSizes.xxs,
      handleConfirmClick: () => approveAppointment({
        id: String(selectedAppointment.id),
      }),
    });
  };

  const cardActions: IAction<Record<string, unknown>>[] = [
    {
      type: 'approve',
      text: dictionary.table.approve,
      showCondition: (selectedAppointment) => selectedAppointment.status === 'Pending',
      handleClick: (selectedAppointment) => onApproveAppointment(selectedAppointment as TAppointment),
    },
    {
      type: 'edit',
      text: dictionary.table.edit,
      showCondition: (selectedAppointment) => selectedAppointment.status !== 'Rejected',
      handleClick: (selectedAppointment) => onEditAppointment(selectedAppointment as TAppointment),
    },
    {
      type: 'decline',
      text: dictionary.table.decline,
      showCondition: (selectedAppointment) => selectedAppointment.status !== 'Rejected',
      handleClick: (selectedAppointment) => onDeclineAppointment(selectedAppointment as TAppointment),
    },
  ];

  return (
    <div className="content">
      <div className="schedule is-scrollable">
        <div className="schedule-inner">
          {isLoading ? <Loader /> : (
            <Container>
              {(appointments && !!appointments.data) && appointments?.data.map((appointment, index) => appointment.status === StatusVariants.pending && (
                <Card
                  data={appointment && appointmentData({
                    ...appointment,
                  })}
                  key={index}
                  title={appointment.name}
                  actions={cardActions}
                  isDetailsCollapsible={false}
                  row={{
                    ...appointment,
                  }}
                />
              ))}
              {!!appointments?.data && !appointments?.data.find((appointment) => appointment.status === StatusVariants.pending) && <TableEmpty />}
            </Container>
          )}
        </div>
      </div>
    </div>
  );
};
