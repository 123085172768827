import React, { FC } from 'react';
import { Table } from '@components/Table';
import { useFetchAllUserAppointmentsQuery } from '@api/appointments/appointmentsApi';
import { skipToken } from '@reduxjs/toolkit/query';
import { tableSettings } from '@pages/Appointment/constants/tableSettings';
import { IAppointmentsTableProps } from '@pages/Patient/components/AppointmentsTable/types';

export const AppointmentsTable:FC<IAppointmentsTableProps> = ({ userId, trialId }) => {
  const { data: appointments } = useFetchAllUserAppointmentsQuery({
    patientId: userId, trialId,
  } ?? skipToken);

  return (
    <section>
      { appointments?.data && (
      <Table
        tableData={appointments?.data}
        tableSettings={tableSettings}
        dictionaryKey="appointments"
      />
      )}
    </section>
  );
};
