import React from 'react';
import { Container } from '@components/Container';
import { Logo } from '../../../AppLayout/components/Logo';
import { HeaderControls } from './HeaderControls';
import './styles.scss';

export const AuthHeader = () => (
  <header className="header header-auth">
    <Container isStretched>
      <div className="header-content">
        <div className="header-logo header-auth-logo">
          <Logo />
        </div>
        <HeaderControls />
      </div>
    </Container>
  </header>
);
