import React, { FC, useState } from 'react';
import { AutocompleteVariants, IInputProps } from '@components/Input/types';
import { UilMultiply, UilSearch } from '@iconscout/react-unicons';
import './styles.scss';

export const Search: FC<IInputProps> = (props) => {
  const { type = 'search',
    disabled,
    required,
    value = '',
    placeholder = 'Start type...',
    autocomplete = AutocompleteVariants.off,
    hasError,
    clearValue } = props;

  const [isSearchOpen, setSearchOpen] = useState(false);

  const searchClassName = `search ${isSearchOpen ? 'is-open' : ''}`;

  const inputClassName = `search-control ${hasError ? 'has-error' : ''}`;

  const openSearch = () => {
    setSearchOpen(true);
  };

  const closeSearch = () => {
    if (value.length) {
      return;
    }

    setSearchOpen(false);
  };

  const onClearButtonClick = () => {
    if (clearValue) {
      clearValue();
    }
    setSearchOpen(false);
  };

  return (
    <div className="field-control">
      <div className={searchClassName}>
        <div className="search-field">
          <span className="search-prepend-icon" role="button">
            <UilSearch size={14} />
          </span>
          <span className="search-append-icon" role="button" tabIndex={0} onClick={onClearButtonClick}>
            <UilMultiply size={12} />
          </span>
          <input
            className={inputClassName}
            type={type}
            disabled={disabled}
            value={value}
            placeholder={placeholder}
            required={required}
            autoComplete={autocomplete}
            {...props}
            onFocus={openSearch}
            onBlur={closeSearch}
          />
        </div>
      </div>
    </div>
  );
};
