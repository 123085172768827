import React, { FC } from 'react';
import { IOptions } from '@components/Select/types';
import { emptyString } from '@constants/emptyString';

export const Option: FC<IOptions> = ({ label, shortLabel = emptyString, icon, img, value }) => (
  <div className="option">
    {img && <img className="option-img" src={img} alt={String(value)} />}
    {icon && <span className="option-icon">{icon}</span>}
    {shortLabel && (
      <span className="option-short-label">
        {shortLabel}
      </span>
    )}
    <span className="option-label">
      {label}
    </span>
  </div>
);
