import React, { PropsWithChildren } from 'react';
import { ICardProps } from '@components/Card/types';
import { keyFormatter } from '@helpers/keyFormatter';
import './styles.scss';

export const AppointmentInfo = ({ data }: PropsWithChildren<ICardProps>) => (
  <div className="appointment-info-card">
    <div className="card-controls" />
    <span className="info-card-content">
      {!!data && data?.map(({ value,
        label }, index) => (
          <div className="appointment-info-card-details" key={`card_item_${index}_${keyFormatter(label)}`}>
            <span className="card-content-label">{label}</span>
            <span className="card-content-value">{value}</span>
          </div>
      ))}
    </span>
  </div>
);
