import { useLanguageContext } from '@hooks/useLanguageContext';

export const GenderOptions = () => {
  const { dictionary } = useLanguageContext();

  return [
    {
      value: dictionary.genders.femaleByBirth,
      label: dictionary.genders.femaleByBirth,
    },
    {
      value: dictionary.genders.maleByBirth,
      label: dictionary.genders.maleByBirth,
    },
  ];
};
