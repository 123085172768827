import * as Yup from 'yup';
import { passwordExpression } from '@constants/regularExpression';
import { IDictionary } from '@constants/languages/types';

const changePasswordValidationSchema = (dictionary: IDictionary) => Yup.object({
  currentPassword: Yup.string()
    .min(8, dictionary.validation.passwordMin)
    .required(dictionary.validation.password),
  newPassword: Yup.string()
    .min(8, dictionary.validation.passwordMin)
    .matches(passwordExpression, dictionary.validation.passwordMin)
    .required(dictionary.validation.newPassword),
  repeatedNewPassword: Yup.string()
    .required(dictionary.validation.repeatedPassword)
    .oneOf([Yup.ref('newPassword')], dictionary.validation.matchPassword),
});

export default changePasswordValidationSchema;
