import React, { FC } from 'react';
import { ILabelProps } from '@components/Label/types';
import './styles.scss';

export const Label: FC<ILabelProps> = ({ text, variant }) => {
  const labelClassName = `label ${variant && `is-${variant}`}`;

  return (
    <span className={labelClassName}>
      <span className="label-content">
        {text}
      </span>
    </span>
  );
};
