import React, { FC } from 'react';
import { Form, Formik } from 'formik';
import { AutoSave } from '@components/AutoSave/AutoSave';
import { SearchField } from '@components/Form/SearchField';
import { ISearch } from '@components/Search/types';
import { ISearchFormProps } from '@components/SearchForm/types';

export const SearchForm:FC<ISearchFormProps> = ({ setSearchTitle }) => {
  const initialValues: ISearch = {
    search: '',
  };

  return (
    <div className="section-control section-search">
      <Formik
        initialValues={initialValues}
        validationSchema={null}
        onSubmit={(values, { setSubmitting }) => {
          setSearchTitle(values.search);
          setSubmitting(false);
        }}
      >
        <Form className="form" noValidate>
          <SearchField name="search" />
          <AutoSave debounceMs={300} />
        </Form>
      </Formik>
    </div>
  );
};
