import { differenceInYears, parse } from 'date-fns';

export const isAdult = 18;

export const calculateAge = (dateOfBirth: string) => {
  const format = dateOfBirth.includes('-') ? 'yyyy-MM-dd' : 'MM/dd/yyyy';

  const date = parse(dateOfBirth, format, new Date());

  return differenceInYears(new Date(), date) < isAdult;
};
