import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@components/Button';
import { SearchForm } from '@components/SearchForm';
import { Section } from '@components/Section';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { UilPlus } from '@iconscout/react-unicons';
import { TrialsTable } from '@pages/Trials/components/TrialsTable';
import { useFetchAllTrialsQuery } from '@api/trial/trialAPI';
import { skipToken } from '@reduxjs/toolkit/query';
import { Loader } from '@components/Loader';
import { Routes } from '../../router';

export const Trials = () => {
  const { dictionary } = useLanguageContext();

  const navigate = useNavigate();

  const [searchTitle, setSearchTitle] = useState<string>('');

  const [limit, setLimit] = useState<number>(20);

  const [sortField, setSortField] = useState<string>('');

  const startPage = 1;

  const { data: trials, refetch, isLoading } = useFetchAllTrialsQuery({
    searchString: searchTitle, limit, page: startPage, sortField,
  } ?? skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const next = () => {
    if (trials?.data.currentPage !== trials?.data.totalPages) {
      setLimit(limit + 20);
    }
  };

  useEffect(() => {
    if (!trials?.data) {
      refetch();
    }
  }, [trials, refetch]);
  return (
    <Section
      title={dictionary.trials.title}
      controls={(
        <>
          <SearchForm setSearchTitle={setSearchTitle} />
          <div className="section-control">
            <Button
              label={dictionary.trials.modals.createTrial.title}
              iconPrepend={<UilPlus />}
              handleClick={() => navigate(`${Routes.Trials}${Routes.Add}`)}
              size="static"
            />
          </div>
        </>
          )}
    >
      {isLoading ? <Loader /> : (
        <TrialsTable
          setSortField={setSortField}
          trials={trials}
          searchTitle={searchTitle}
          refetch={refetch}
          refreshFunction={refetch}
          next={next}
          dataLength={trials?.data.total}
        />
      )}
    </Section>
  );
};
