import * as Yup from 'yup';
import { phoneExpression } from '@constants/regularExpression';
import { IDictionary } from '@constants/languages/types';

const physicianValidationSchema = (dictionary: IDictionary) => Yup.object({
  name: Yup.string()
    .required(dictionary.validation.physicianName)
    .max(100, dictionary.validation.invalidPhysicianName),
  phone: Yup.string()
    .required(dictionary.validation.physicianPhone)
    .min(17, dictionary.validation.invalidPhysicianPhone)
    .matches(phoneExpression, dictionary.validation.invalidPhysicianPhone),
  schedule: Yup.object().shape({
    friday: Yup.array()
      .of(Yup.object()
        .shape({
          from: Yup.string()
            .required(dictionary.validation.indicateSlots),
          to: Yup.string()
            .required(dictionary.validation.indicateSlots),
        })),
    monday: Yup.array()
      .of(Yup.object()
        .shape({
          from: Yup.string()
            .required(dictionary.validation.indicateSlots),
          to: Yup.string()
            .required(dictionary.validation.indicateSlots),
        })),
    saturday: Yup.array()
      .of(Yup.object()
        .shape({
          from: Yup.string()
            .required(dictionary.validation.indicateSlots),
          to: Yup.string()
            .required(dictionary.validation.indicateSlots),
        })),
    sunday: Yup.array()
      .of(Yup.object()
        .shape({
          from: Yup.string()
            .required(dictionary.validation.indicateSlots),
          to: Yup.string()
            .required(dictionary.validation.indicateSlots),
        })),
    thursday: Yup.array()
      .of(Yup.object()
        .shape({
          from: Yup.string()
            .required(dictionary.validation.indicateSlots),
          to: Yup.string()
            .required(dictionary.validation.indicateSlots),
        })),
    tuesday: Yup.array()
      .of(Yup.object()
        .shape({
          from: Yup.string()
            .required(dictionary.validation.indicateSlots),
          to: Yup.string()
            .required(dictionary.validation.indicateSlots),
        })),
    wednesday: Yup.array()
      .of(Yup.object()
        .shape({
          from: Yup.string()
            .required(dictionary.validation.indicateSlots),
          to: Yup.string()
            .required(dictionary.validation.indicateSlots),
        })),
  }),
});

export default physicianValidationSchema;
