import { Roles } from '@constants/roles';
import { Routes } from '../router';

export const redirectPaths = {
  [Roles.staff]: Routes.Schedule,
  [Roles.patient]: Routes.RequestedAppointments,
  [Roles.guest]: Routes.SignIn,
  [Roles.admin]: Routes.Schedule,
  [Roles.superAdmin]: Routes.Schedule,
};
