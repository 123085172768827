import React, { FC, MouseEvent, TouchEvent, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Formik, FormikValues } from 'formik';
import { Button } from '@components/Button';
import { InputField } from '@components/Form/InputField';
import { PasswordField } from '@components/Form/PasswordField';
import { PhoneField } from '@components/Form/PhoneField';
import { Link } from '@components/Link';
import { IModalRef } from '@components/Modal/types';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { useCreateAdminMutation, usePhoneVerifyingMutation } from '@api/user/userAPI';
import sighUpValidationSchema from '@helpers/validationSchemas/sighUpValidationSchema';
import { Verification } from '@pages/SignUp/components/Verification';
import { ISignUpProps } from '@pages/SignUp/components/Verification/types';
import { IAdminRegistration } from '@store/types';
import { errorAlert } from '@helpers/alerts/alerts';
import { emptyString } from '@constants/emptyString';
import { Routes } from '../../../../router';
import 'react-datepicker/dist/react-datepicker.css';

export const SignUpForm:FC<ISignUpProps> = ({ setUserRegisterEmail, email, token }) => {
  const { dictionary } = useLanguageContext();

  const verificationModalRef = useRef<IModalRef>(null);

  const [isVerifyPhone, setIsVerifyPhone] = useState<boolean>(false);

  const [usersPhone, setUsersPhone] = useState<string>('');

  const [registerAdmin] = useCreateAdminMutation();

  const [verifyPhone] = usePhoneVerifyingMutation();

  const navigate = useNavigate();

  const initialRegisterValues: IAdminRegistration = {
    name: '',
    surname: '',
    email: email || '',
    phone: '',
    password: '',
    repeatedPassword: '',
  };

  const onPhoneVerificationClick = (e: MouseEvent<HTMLElement> | TouchEvent<HTMLElement>, phoneNumber?: string) => {
    if (phoneNumber) {
      setUsersPhone(phoneNumber);
      verificationModalRef.current?.openModal();
      verifyPhone({
        phone: phoneNumber,
      }).unwrap();
    }
  };

  const signUp = (userData: FormikValues) => {
    registerAdmin({
      token,
      userData: userData as IAdminRegistration,
    })
      .unwrap()
      .then(({ success,
        message }) => {
        if (success === 1) {
          navigate(Routes.SignIn);
        } else errorAlert(emptyString, message);
      })
      .catch(({ data }) => errorAlert(emptyString, data.message));
  };

  return (
    <>
      <Formik
        initialValues={initialRegisterValues}
        validationSchema={sighUpValidationSchema(dictionary)}
        onSubmit={(values) => signUp(values)}
        validateOnBlur
        validateOnChange
      >
        <Form className="form" noValidate>
          <div className="form-body">
            <section className="form-section">
              <h2 className="form-title title-md">{dictionary.titles.patientInformation}</h2>
              <div className="row">
                <div className="col-xs-12 col-md-6">
                  <InputField
                    placeholder={dictionary.placeholders.yourFirstName}
                    label={dictionary.labels.firstName}
                    required
                    name="name"
                  />
                </div>
                <div className="col-xs-12 col-md-6">
                  <InputField
                    placeholder={dictionary.placeholders.yourLastName}
                    label={dictionary.labels.lastName}
                    required
                    name="surname"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <InputField
                    type="email"
                    disabled={!!email?.length}
                    placeholder={dictionary.placeholders.enterYourEmail}
                    label={dictionary.labels.email}
                    required
                    name="email"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <PhoneField
                    type="text"
                    placeholder={dictionary.placeholders.phoneMask}
                    label={dictionary.labels.phoneNumber}
                    required
                    isVerified={isVerifyPhone}
                    name="phone"
                    showVerification
                    handleVerificationClick={onPhoneVerificationClick}
                  />
                </div>
              </div>
            </section>
            <section className="form-section">
              <h2 className="form-title title-md">{dictionary.titles.security}</h2>
              <div className="row">
                <div className="col-xs-12">
                  <PasswordField
                    placeholder={dictionary.placeholders.createPassword}
                    label={dictionary.labels.createPassword}
                    required
                    name="password"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <PasswordField
                    placeholder={dictionary.placeholders.confirmPassword}
                    label={dictionary.labels.confirmPassword}
                    required
                    name="repeatedPassword"
                  />
                </div>
              </div>
            </section>
          </div>
          <div className="form-footer">
            <section className="form-section">
              <Button
                label={dictionary.signUp.signUp}
                type="submit"
                size="large"
              />
            </section>
            <section className="form-section text-center">
              <p className="text">
                {dictionary.signIn.bySigningInYouAcceptOur}
                &nbsp;
                <Link
                  to={Routes.SignUp}
                  label={dictionary.links.termsOfUse}
                  variant="outlined"
                  target="_blank"
                  inline
                />
                &nbsp;
                {dictionary.signIn.and}
                &nbsp;
                <Link
                  to={Routes.SignUp}
                  label={dictionary.links.privacyPolicy}
                  variant="outlined"
                  target="_blank"
                  inline
                />
              </p>
              <p className="text-md text-semibold">
                {dictionary.signUp.alreadyHaveAccount}
                &nbsp;
                <Link
                  to={Routes.SignIn}
                  label={dictionary.signUp.logIn}
                  variant="primary"
                  inline
                />
              </p>
            </section>
          </div>
        </Form>
      </Formik>
      <Verification
        phone={usersPhone}
        verifyPhone={setIsVerifyPhone}
        ref={verificationModalRef}
      />
    </>
  );
};
