import { IDictionary } from '@constants/languages/types';
import * as Yup from 'yup';

const changeEmailValidationSchema = (dictionary: IDictionary) => Yup.object({
  newEmail: Yup.string()
    .email(dictionary.validation.invalidEmail)
    .min(1, dictionary.validation.emptyEmail),
  repeatedNewEmail: Yup.string()
    .email(dictionary.validation.invalidEmail)
    .min(1, dictionary.validation.emptyEmail)
    .oneOf([Yup.ref('newEmail')], dictionary.validation.matchEmail),
  gender: Yup.string(),
  surname: Yup.string(),
  name: Yup.string(),
  dateOfBirth: Yup.string(),
});

export default changeEmailValidationSchema;
