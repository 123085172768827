import React, { FC } from 'react';
import { TAppointment } from '@api/appointments/types';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { firstNotificationOptions } from '@constants/options/notificationOptions';

export const FirstNotificationFormatter:FC<TAppointment> = ({ notificationsSettings }) => {
  const notification = notificationsSettings.first;

  const { dictionary } = useLanguageContext();

  return <ul>{ notification ? firstNotificationOptions(dictionary).find(({ value }) => value === Number(notification))?.label : '-'}</ul>;
};
