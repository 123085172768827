import React, { FC, useState } from 'react';
import { Form, Formik } from 'formik';
import { Button } from '@components/Button';
import { InputField } from '@components/Form/InputField';
import { Info } from '@components/Info';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { IForgotPassword } from '@api/user/types';
import { useForgotPasswordMutation } from '@api/user/userAPI';
import forgotPasswordValidationSchema from '@helpers/validationSchemas/forgotPasswordValidationSchema';
import { IForgotPasswordFormProps } from '../../types';

export const ForgotPasswordForm:FC<IForgotPasswordFormProps> = ({ setForgotPasswordSent }) => {
  const [sendEmail] = useForgotPasswordMutation();

  const [isError, setIsError] = useState<string>('');

  const { dictionary } = useLanguageContext();

  const initialValues = {
    email: '',
  };

  const onRecoverPassword = (formValues: IForgotPassword) => {
    sendEmail(formValues)
      .unwrap().then(({ success, message }) => {
        success === 1 ? setForgotPasswordSent(true) : setIsError(message);
      }).catch(({ data }) => setIsError(data.message));
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={forgotPasswordValidationSchema(dictionary)}
      onSubmit={onRecoverPassword}
    >
      <Form className="form" noValidate>
        {!!isError.length && (
          <div className="form-section">
            <div className="row">
              <div className="col-xs-12">
                <Info variant="danger" text={isError} />
              </div>
            </div>
          </div>
        )}
        <div className="form-body">
          <section className="form-section">
            <div className="row">
              <div className="col-xs-12">
                <InputField
                  type="email"
                  placeholder={dictionary.placeholders.enterYourEmail}
                  label={dictionary.labels.email}
                  required
                  name="email"
                />
              </div>
            </div>
          </section>
        </div>
        <div className="form-footer">
          <section className="form-section">
            <Button
              label={dictionary.labels.sendRecoveryLink}
              type="submit"
              size="large"
            />
          </section>
        </div>
      </Form>
    </Formik>
  );
};
