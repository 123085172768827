import React, { FC } from 'react';
import { useConfirmationModal } from '@components/Confirm/context/useConfirmContext';
import { ModalTypes } from '@components/Confirm/types';
import { Table } from '@components/Table';
import { IAction } from '@components/Table/utils/actionsFormatter';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { useDeleteAppointmentsMutation } from '@api/appointments/appointmentsApi';
import { TAppointment } from '@api/appointments/types';
import { tableSettings } from '@pages/Appointments/components/AppointmentsTable/constants/tableSettings';
import { IAppointmentsTableProps } from '@pages/Appointments/components/AppointmentsTable/types';
import { convertToCamelCase } from '@helpers/convertToCamelCase';

export const AppointmentsTable: FC<IAppointmentsTableProps> = ({ searchTitle,
  appointments, refetch, next, refreshFunction, dataLength }) => {
  const { openConfirmationModal } = useConfirmationModal();

  const { dictionary } = useLanguageContext();

  const [deleteAppointments] = useDeleteAppointmentsMutation();

  const onDeleteAppointment = ({ id }: TAppointment) => {
    openConfirmationModal({
      variant: ModalTypes.decline,
      title: dictionary.appointments.modals.deleteAppointments.title,
      text: dictionary.appointments.modals.deleteAppointments.text,
      submitButtonTitle: dictionary.table.delete,
      handleConfirmClick: () => {
        deleteAppointments(id);
        refetch();
      },
    });
  };

  const tableActions: IAction<TAppointment>[] = [
    {
      type: 'delete',
      isDisabled: (selectedAppointment) => selectedAppointment.patients.appointments
        .filter((appointment: { trialId: string; }) => appointment.trialId === selectedAppointment.trialId).length <= 2,
      handleClick: (selectedAppointment) => onDeleteAppointment(selectedAppointment),
    },
  ];

  return (
    <Table
      tableData={convertToCamelCase(appointments?.data.results) || []}
      tableSettings={tableSettings(dictionary)}
      actions={tableActions}
      search={!!searchTitle}
      dictionaryKey="appointments"
      dataLength={dataLength}
      next={next}
      refreshFunction={refreshFunction}
    />
  );
};
