import React from 'react';
import { PasswordField } from '@components/Form/PasswordField';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { IChangePassword } from '@api/patients/types';

export const passwordInitialValues: IChangePassword = {
  currentPassword: '',
  newPassword: '',
  repeatedNewPassword: '',
};

export const ChangePasswordFormContent = () => {
  const { dictionary } = useLanguageContext();

  return (
    <>
      <div className="form-field">
        <PasswordField
          placeholder={dictionary.placeholders.currentPassword}
          label={dictionary.labels.currentPassword}
          required
          name="currentPassword"
        />
      </div>
      <div className="form-field">
        <PasswordField
          placeholder={dictionary.placeholders.createPassword}
          label={dictionary.labels.newPassword}
          required
          name="newPassword"
          hint={dictionary.profile.modals.changePasswordValidation}
        />
      </div>
      <div className="form-field">
        <PasswordField
          placeholder={dictionary.placeholders.confirmNewPassword}
          label={dictionary.labels.confirmNewPassword}
          required
          name="repeatedNewPassword"
        />
      </div>
    </>
  );
};
