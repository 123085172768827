import { PropsWithChildren, useMemo, useState } from 'react';
import { MenuContext } from './MenuContext';

export const MenuContextProvider = ({ children }: PropsWithChildren) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = async () => {
    setMenuOpen(!isMenuOpen);
  };

  const contextValues = {
    isMenuOpen,
    toggleMenu,
  };

  const cachedProvider = useMemo(() => contextValues, [contextValues]);

  return (
    <MenuContext.Provider value={cachedProvider}>
      {children}
    </MenuContext.Provider>
  );
};
