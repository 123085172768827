import React, { ReactNode } from 'react';
import { format, isSameDay, parse } from 'date-fns';
import { generateTimeBetween } from '@helpers/generateTimeBetween';
import { TWorkingDays } from '@pages/Physicians/components/PhysiciansTable/types';
import { DaySlot } from '@pages/Schedule/components/Day/DaySlot';
import { dayRangeHeight, rangeStep } from '@pages/Schedule/constants/ScheduleSettings';
import { useScheduleContext } from '@pages/Schedule/context/useScheduleContext';
import { useFetchAllPhysiciansQuery } from '@api/physicians/physiciansAPI';
import { intervalsGroup } from '@constants/options/timeOptions';
import { Loader } from '@components/Loader';
import { IScheduleResponse } from '@api/schedule/types';
import { TAppointment } from '@api/appointments/types';

interface IDayBodyProps {
  appointments: IScheduleResponse | undefined,
  isLoading?: boolean
}

export const DayBody = ({ appointments, isLoading = false }: IDayBodyProps) => {
  const { selectedDate,
    selectedPhysician,
    selectedHourFrom,
    selectedHourTo } = useScheduleContext();

  const appointmentsList: TAppointment[] | undefined = appointments?.data;

  const currentDateAppointments = appointmentsList?.filter(({ date }: {date:string}) => isSameDay(parse(date, 'MM/dd/yyyy', new Date()), selectedDate));

  const { data: physicians } = useFetchAllPhysiciansQuery();

  const startTime = intervalsGroup()[intervalsGroup().findIndex((time) => time.value === selectedHourFrom) - 1]?.value || '';

  const endTime = selectedHourTo || '';

  const tableData = intervalsGroup(startTime || '06:00 AM' || undefined, endTime || undefined);

  const intervalHeight = dayRangeHeight;

  const tableBody = tableData.reduce<ReactNode[]>((acc, currentTime, rowIndex) => {
    const tableRow = physicians?.data.results.filter((physician) => (selectedPhysician?.length ? physician.id === selectedPhysician : physician)).map((physician) => {
      const currentPhysicianAppointments = currentDateAppointments?.filter(({ trials }) => (trials.physicians.id === physician.id));

      const getStartTime = (time: string) => {
        const intervals = time.split(' - ');

        return intervals[0];
      };

      const currentAppointment = currentPhysicianAppointments?.find(({ time }: {time:string}) => getStartTime(time) === currentTime.value);

      const currentWeekDay = format(selectedDate, 'eeee').toLowerCase();

      const { schedule: physicianSchedule } = physician;

      if (!physicianSchedule || !physicianSchedule[currentWeekDay as keyof TWorkingDays]) {
        return (
          <div className="schedule-cell table-cell">
            <DaySlot start={startTime} duration={tableData.length * rangeStep} />
          </div>
        );
      }

      const generateUnavailableSlots = () => {
        const physicianAvailability = physicianSchedule[currentWeekDay as keyof TWorkingDays];

        if (!physicianAvailability || physicianAvailability.length === 0) {
          return [{
            from: '06:00 AM', to: '11:45 PM', stepsInRange: generateTimeBetween('06:00 AM', '11:45 PM').length + 1,
          }];
        }

        const availableRangesAmount = Number(physicianAvailability.length);

        const unavailableSlots = [];

        for (let index = 0; index <= availableRangesAmount; index += 1) {
          const fromTime = (index === 0 || availableRangesAmount === 0) && physicianAvailability[index]?.from !== '06:00 AM' ? tableData[0].value : physicianAvailability[index - 1]?.to;

          const toTime = index <= availableRangesAmount - 1 ? physicianAvailability[index].from : endTime;

          const stepsInRange = () => {
            if (availableRangesAmount && endTime === physicianAvailability[availableRangesAmount - 1].to) {
              return 0;
            }
            if (availableRangesAmount && startTime === fromTime && endTime === physicianAvailability[0].from) {
              return 0;
            }
            return generateTimeBetween(fromTime, toTime).length;
          };

          unavailableSlots.push({
            from: fromTime,
            to: toTime,
            stepsInRange: endTime ? stepsInRange() + 1 : stepsInRange(),
          });
        }
        return unavailableSlots;
      };

      const unavailableIntervals = generateUnavailableSlots();

      const currentUnavailableSlotStart = unavailableIntervals?.find(({ from }) => from === currentTime.value);

      return (
        <div className="schedule-cell table-cell">
          {currentAppointment && (
            <DaySlot
              start={currentAppointment.time}
              appointmentId={currentAppointment.id}
              duration={Number(currentAppointment.duration)}
              patient={`${currentAppointment.patients.name} ${currentAppointment.patients.surname}`}
              eventType={currentAppointment.type}
              patientNumber={currentAppointment.patients.userIdFromSponsor}
              isAdult={!!currentAppointment.adult}
            />
          )}
          {currentUnavailableSlotStart && !!currentUnavailableSlotStart.stepsInRange && (
            <DaySlot start={startTime} duration={currentUnavailableSlotStart.stepsInRange * rangeStep} />
          )}
        </div>
      );
    });

    return [...acc,
      <div
        className="schedule-row table-row"
        key={`table-row-${rowIndex}`}
        style={{
          ['height' as string]: `${intervalHeight}px`,
        }}
      >
        <div className="schedule-cell table-cell">
          {`${tableData[rowIndex].label}`}
        </div>
        { tableRow }
      </div>,
    ];
  }, []);

  return isLoading ? <Loader /> : <div className="schedule-body table-body">{tableBody}</div>;
};
