import React, { FC } from 'react';
import { ITableSortingProps } from '@components/Table/types';
import { UilAngleDown, UilAngleUp } from '@iconscout/react-unicons';

export const TableSorting:FC<ITableSortingProps> = ({ column, setSort }) => (
  <span className="table-sorting" onClick={() => setSort && setSort(column)}>
    <span className="table-sorting-arrow is-asc">
      <UilAngleUp />
    </span>
    <span className="table-sorting-arrow is-desc">
      <UilAngleDown />
    </span>
  </span>
);
