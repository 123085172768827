import { IDictionary } from '@constants/languages/types';

export const TypeOptions = (dictionary: IDictionary) => [
  {
    value: '60',
    label: dictionary.appointments.types.screening,
  },
  {
    value: '120',
    label: dictionary.appointments.types.enrollment,
  },
  {
    value: '150',
    label: dictionary.appointments.types.screeningEnrollment,
  },
  {
    value: '45',
    label: dictionary.appointments.types.unexpectedVisit,
  },
  {
    value: '15',
    label: dictionary.appointments.types.followUp,
  },
  {
    value: '45',
    label: dictionary.appointments.types.convalescentVisit,
  },
  {
    value: '30',
    label: dictionary.appointments.types.closeout,
  },
];
