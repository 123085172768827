import React, { useEffect, useState } from 'react';
import { Section } from '@components/Section';
import { SuccessScreen } from '@components/SuccessScreen';
import { Heading } from '../../layout/AuthLayout/components/Heading';
import { Routes } from '../../router';
import { ResetPasswordForm } from './components/ResetPasswordForm';

export const ResetPassword = () => {
  const [isResetPasswordSent, setResetPasswordSent] = useState(false);

  const [userEmail, setUserEmail] = useState<string>('');

  const [userToken, setUserToken] = useState<string>('');

  useEffect(() => {
    const findData = window.location.href;

    const userAccessCredits = findData.split('email=');

    const userData = userAccessCredits[1].split('&tk=');

    setUserEmail(userData[0]);
    setUserToken(userData[1]);
  }, [userEmail, userToken]);

  return (
    isResetPasswordSent
      ? (
        <SuccessScreen
          title="Password changed!"
          description="Your password has been changed successfully. Use your new password to log in."
          linkText="Ok"
          linkUrl={Routes.SignIn}
          containerSize="sm"
        />
      )
      : (
        <Section containerSize="sm">
          <Heading>
            Welcome to
            <br />
            <strong className="text-primary">Schedulapp</strong>
          </Heading>
          <ResetPasswordForm token={userToken} email={userEmail} setResetPasswordSent={setResetPasswordSent} />
        </Section>
      )
  );
};
