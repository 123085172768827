import { useLanguageContext } from '@hooks/useLanguageContext';

export const ProtocolWindowOptions = () => {
  const { dictionary } = useLanguageContext();

  return [
    {
      value: '1',
      label: dictionary.protocolWindow.oneDay,
    },
    {
      value: '2',
      label: dictionary.protocolWindow.twoDays,
    },
    {
      value: '3',
      label: dictionary.protocolWindow.threeDays,
    },
  ];
};
