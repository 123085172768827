import { ITableSettings } from '@components/Table/types';
import { TLocations } from '@pages/Locations/LocationsTable/types';

export const tableSettings: ITableSettings<TLocations>[] = [
  {
    accessor: 'name',
    title: 'Location',
  },
  {
    accessor: 'number',
    title: 'Phone number',
  },
  {
    accessor: 'address',
    title: 'Address',
  },
];
