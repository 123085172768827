import React from 'react';
import { toast } from 'react-toastify';
import { Alert } from '@components/Alert';
import { alertOptions } from '@constants/options/alertOptions';

export const errorAlert: (title?: string, message?: string) => void = (title?: string, message?: string) => {
  toast.error(<Alert title={title} text={message} variant="danger" />, alertOptions);
};

export const successAlert: (title?: string, message?: string) => void = (title?: string, message?: string) => {
  toast.success(<Alert title={title} text={message} variant="success" />, alertOptions);
};

export const warningAlert: (title?: string, message?: string) => void = (title?: string, message?: string) => {
  toast.warning(<Alert title={title} text={message} variant="warning" />, alertOptions);
};

export const infoAlert: (title?: string, message?: string) => void = (title?: string, message?: string) => {
  toast.info(<Alert title={title} text={message} variant="info" />, alertOptions);
};
