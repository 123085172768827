import React from 'react';
import { LinkBack } from '../LinkBack';
import { IHeadingProps } from './types';

export const Heading = (props: IHeadingProps) => {
  const { children, showBackControl } = props;

  return (
    <>
      {showBackControl && <LinkBack />}
      <h1 className="auth-title title-xxl">
        {children}
      </h1>
    </>
  );
};
