import React, { useEffect, useState } from 'react';
import { FormikValues } from 'formik';
import { UilFilter } from '@iconscout/react-unicons';
import { endOfMonth, endOfWeek, format, getMonth, getYear, startOfMonth, startOfWeek } from 'date-fns';
import { matchPath, Outlet, useLocation } from 'react-router-dom';
import { Container } from '@components/Container';
import { Button } from '@components/Button';
import { ScheduleCalendar } from '@pages/Schedule/components/Calendar';
import { Filters } from '@pages/Schedule/components/Filters';
import { Switcher } from '@pages/Schedule/components/Switcher';
import { filtersModal } from '@pages/Schedule/components/Filters/FiltersModal';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { useModal } from '@components/Modal/context/useModalContext';
import { ScheduleRoutes } from '@pages/Schedule/constants/ScheduleRoutes';
import { useScheduleContext } from '@pages/Schedule/context/useScheduleContext';
import './styles.scss';

export const Schedule = () => {
  const { dictionary } = useLanguageContext();

  const { openModal } = useModal();

  const schedule = useScheduleContext();

  const selectedDate = schedule.selectedDate;

  const [startDate, setStartDate] = useState(selectedDate);

  const [endDate, setEndDate] = useState(selectedDate);

  const pathname = useLocation().pathname;

  const weekView = !!matchPath(ScheduleRoutes.Week, pathname);

  const monthView = !!matchPath(ScheduleRoutes.Month, pathname);

  const formattedTitle = () => {
    if (weekView) {
      if (getYear(startDate) !== getYear(endDate)) {
        return `${format(startDate, 'MMMM dd, yyyy')} - ${format(endDate, 'MMMM dd, yyyy')}`;
      }

      if (getMonth(startDate) !== getMonth(endDate)) {
        return `${format(startDate, 'MMMM dd')} - ${format(endDate, 'MMMM dd, yyyy')}`;
      }

      return `${format(startDate, 'MMMM dd')} - ${format(endDate, 'dd, yyyy')}`;
    }

    if (monthView) {
      return `${format(startDate, 'MMMM, yyyy')}`;
    }

    return `${format(startDate, 'MMMM dd, yyyy')}`;
  };

  const applyFilters = (values: FormikValues) => {
    console.log(values);
  };

  useEffect(() => {
    if (weekView) {
      setStartDate(startOfWeek(selectedDate));
      setEndDate(endOfWeek(selectedDate));
      return;
    }

    if (monthView) {
      setStartDate(startOfMonth(selectedDate));
      setEndDate(endOfMonth(selectedDate));
      return;
    }

    setStartDate(selectedDate);
    setEndDate(selectedDate);
  }, [pathname, selectedDate]);

  return (
    <div className="content">
      <aside className="sidebar">
        <div className="sidebar-content">
          <div className="sidebar-row">
            <ScheduleCalendar handleChange={(date) => schedule.setDate(date || new Date())} />
          </div>
          <div className="sidebar-row sidebar-filters">
            <Filters />
          </div>
        </div>
      </aside>
      <div className="schedule">
        <Container>
          <div className="section-heading">
            <h2 className="section-title title-xl">{formattedTitle()}</h2>
            <div className="section-control schedule-filters-control">
              <Button
                size="large"
                label={dictionary.schedule.filters.title}
                iconPrepend={<UilFilter />}
                handleClick={() => filtersModal(openModal, applyFilters, dictionary)}
              />
            </div>
            <div className="section-control section-switcher">
              <Switcher />
            </div>
          </div>
          <Outlet />
        </Container>
      </div>
    </div>
  );
};
