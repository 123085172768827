import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';

const canUseDOM = Boolean(
  typeof window !== 'undefined'
  && window.document
  && window.document.createElement,
);

export const Tooltip = ({ tooltip,
  title }: {
  tooltip:any,
  title: string,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const { getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    state,
    visible } = usePopperTooltip({
    trigger: 'click',
    placement: 'auto',
    closeOnOutsideClick: true,
    visible: isVisible,
    onVisibleChange: setIsVisible,
  });

  const popper = tooltip({
    tooltipRef: setTooltipRef,
    getArrowProps,
    getTooltipProps,
    placement: state ? state.placement : undefined,
  });

  const showTooltip = () => (canUseDOM ? createPortal(popper, (canUseDOM ? document.body : null) as Element | DocumentFragment) : popper);

  return (
    <>
      <button
        className="button-link"
        ref={setTriggerRef}
        type="button"
        onClick={(event) => event.stopPropagation()}
      >
        {title}
      </button>
      {visible ? showTooltip() : null}
    </>
  );
};
