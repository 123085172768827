import { eachDayOfInterval, endOfWeek, format, startOfWeek } from 'date-fns';

export const generateWeekDays = (date = new Date()) => eachDayOfInterval({
  start: startOfWeek(date), end: endOfWeek(date),
}).map((weekday, index) => ({
  id: index,
  value: weekday,
  label: format(weekday, 'eeee'),
  shortLabel: format(weekday, 'eee'),
}));
