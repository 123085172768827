import React, { FC } from 'react';
import { ITextareaProps } from '@components/Textarea/types';
import './styles.scss';

export const Textarea:FC<ITextareaProps> = (props) => {
  const { disabled,
    required,
    value = '',
    placeholder = 'Some text...',
    hasError } = props;

  const inputClassName = `textarea-control ${hasError ? 'has-error' : ''}`;

  return (
    <div className="textarea">
      <textarea
        className={inputClassName}
        disabled={disabled}
        value={value}
        placeholder={placeholder}
        required={required}
        {...props}
      />
    </div>
  );
};
