import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { Button } from '@components/Button';
import { ButtonVariants } from '@components/Button/types';
import { IModalProps, IModalRef, ModalTypes } from '@components/Confirm/types';
import { Container } from '@components/Container';
import { ContainerSizes } from '@components/Container/types';
import { Portal } from '@components/Portal';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { UilCheck, UilMultiply } from '@iconscout/react-unicons';
import './styles.scss';

export const Confirm = React.forwardRef<IModalRef, IModalProps>((
  { title = '',
    text = '',
    children,
    handleConfirmClick,
    handleCloseClick,
    modalSize = ContainerSizes.xs,
    closeButtonTitle,
    submitButtonTitle,
    showCloseBtn = true,
    variant = ModalTypes.decline },
  ref,
) => {
  const [isModalVisible, setModalVisible] = useState(false);

  const { dictionary } = useLanguageContext();

  const closeModal = async () => {
    if (handleCloseClick) {
      await handleCloseClick();
    }

    setModalVisible(false);
  };

  useImperativeHandle<IModalRef, IModalRef>(ref, () => ({
    isModalVisible,
    closeModal,
    openModal: () => setModalVisible(true),
  }));

  const targetRef = useRef<HTMLDivElement>(null);

  const toggleBodyScrollLock = (): void => {
    if (!targetRef.current) {
      return;
    }
    if (isModalVisible) {
      disableBodyScroll(targetRef.current);
    } else {
      enableBodyScroll(targetRef.current);
    }
  };

  useEffect(() => () => clearAllBodyScrollLocks(), []);

  useEffect(() => {
    if (targetRef.current) {
      toggleBodyScrollLock();
    }
  }, [isModalVisible]);

  if (!isModalVisible) {
    return null;
  }

  const confirmIcon = variant === ModalTypes.decline ? <UilMultiply /> : <UilCheck />;

  const buttonVariant = variant === ModalTypes.decline ? ButtonVariants.secondary : ButtonVariants.primary;

  const confirmClassName = variant === ModalTypes.decline ? 'is-danger' : 'is-success';

  return (
    <Portal>
      <div
        className="modal"
        ref={targetRef}
      >
        <div
          className="modal-overlay"
          role="presentation"
          onClick={closeModal}
        />
        <Container size={`${modalSize}`}>
          <div className={`modal-window confirm ${confirmClassName}`}>
            <div className="confirm-body">
              <span className="confirm-icon">
                {confirmIcon}
              </span>
              <strong className="title-lg text-semibold">{title}</strong>
              <p className="confirm-text text">{text}</p>
              {!!children && (
              <div className="confirm-content">
                {children}
              </div>
              )}
            </div>
            <div className="confirm-footer">
              {showCloseBtn && (
                <Button
                  label={closeButtonTitle || dictionary.actions.cancel}
                  variant="default"
                  size="large"
                  handleClick={closeModal}
                />
              )}
              <Button
                label={submitButtonTitle || dictionary.actions.decline}
                size="large"
                variant={buttonVariant}
                handleClick={handleConfirmClick}
              />
            </div>
          </div>
        </Container>
      </div>
    </Portal>
  );
});
