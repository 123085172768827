import { timeToTimestampFormatter } from '@helpers/timeToTimestampFormatter';
import { IBusySlot } from '@api/trial/types';
import { ITimeRange } from '@pages/Appointment/pages/CreateAppointment/components/DetailsForm/types';

export const getFreeOfDay = (duration: number, allSlots?: ITimeRange[], busySlots?: IBusySlot[]) => {
  const freeIntervals: ITimeRange[] = [];

  allSlots?.forEach((allSlot) => {
    const slotFrom = timeToTimestampFormatter(allSlot.from).getTime();

    const slotTo = timeToTimestampFormatter(allSlot.to).getTime();

    let currentTimestamp = slotFrom;

    while (currentTimestamp + duration * 60 * 1000 <= slotTo) {
      const from = new Date(currentTimestamp).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: 'numeric',
        hour12: true,
      });

      const to = new Date(currentTimestamp + duration * 60 * 1000).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: 'numeric',
        hour12: true,
      });

      if (!isTimeSlotBusy(from, to, busySlots)) {
        freeIntervals.push({
          from, to,
        });
      }

      currentTimestamp += 15 * 60 * 1000;
    }
  });

  return freeIntervals;

  function isTimeSlotBusy(from: string, to: string, allBusySlots?: IBusySlot[]): boolean {
    const slotFrom = timeToTimestampFormatter(from).getTime();

    const slotTo = timeToTimestampFormatter(to).getTime();

    return allBusySlots?.some((busySlot) => {
      const busyFromTimestamp = timeToTimestampFormatter(busySlot.from).getTime();

      const busyToTimestamp = timeToTimestampFormatter(busySlot.to).getTime();

      return slotFrom < busyToTimestamp && slotTo > busyFromTimestamp;
    }) ?? false;
  }
};
