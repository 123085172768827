import { ReactNode } from 'react';
import { ContainerSizes } from '@components/Container/types';
import { FormikValues } from 'formik';

export enum ModalTypes {
  approve = 'approve',
  decline = 'decline'
}

export interface IModalProps {
  title?: string;
  text?: string;
  initialValues?: any;
  validationSchema?: any;
  closeButtonTitle?: string;
  submitButtonTitle?: string;
  children?: ReactNode;
  modalSize?: ContainerSizes;
  handleConfirmClick?: (val?: FormikValues) => void;
  handleCloseClick?: () => void;
  showCloseBtn?: boolean;
  variant?: ModalTypes;
}

export interface IModalRef {
  isModalVisible: boolean;
  openModal: () => void;
  closeModal: () => void;
}
