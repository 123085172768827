import React from 'react';
import { LanguageMenu } from '@components/LanguageMenu';

export const HeaderControls = () => (
  <ul className="header-controls">
    <li className="header-control">
      <LanguageMenu />
    </li>
  </ul>
);
