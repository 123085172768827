import React, { FC } from 'react';
import { useConfirmationModal } from '@components/Confirm/context/useConfirmContext';
import { ModalTypes } from '@components/Confirm/types';
import { Table } from '@components/Table';
import { IAction } from '@components/Table/utils/actionsFormatter';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { useDeleteTrialMutation } from '@api/trial/trialAPI';
import { tableSettings } from '@pages/Trials/components/TrialsTable/constants/tableSettings';
import { ITrialsTableProps, TTrial } from '@pages/Trials/components/TrialsTable/types';

export const TrialsTable: FC<ITrialsTableProps> = ({ setSortField, searchTitle, trials, refetch, next, refreshFunction, dataLength }) => {
  const { openConfirmationModal } = useConfirmationModal();

  const { dictionary } = useLanguageContext();

  const [deleteTrial] = useDeleteTrialMutation();

  const onDeleteTrial = ({ id }: TTrial) => {
    openConfirmationModal({
      variant: ModalTypes.decline,
      title: dictionary.trials.modals.deleteTrial.title,
      text: dictionary.trials.modals.deleteTrial.text,
      submitButtonTitle: dictionary.table.delete,
      handleConfirmClick: () => {
        deleteTrial(id).then(() => refetch());
      },
    });
  };

  const tableActions: IAction<TTrial>[] = [
    {
      type: 'delete',
      handleClick: (row) => onDeleteTrial(row),
    },
  ];

  return (
    <Table
      next={next}
      refreshFunction={refreshFunction}
      dataLength={dataLength}
      setSort={setSortField}
      tableData={trials?.data.results}
      tableSettings={tableSettings}
      actions={tableActions}
      search={!!searchTitle}
      dictionaryKey="trials"
    />
  );
};
