import React from 'react';
import { FormikValues } from 'formik';
import { useParams } from 'react-router-dom';
import { Breadcrumbs } from '@components/Breadcrumbs';
import { Card } from '@components/Card';
import { useModal } from '@components/Modal/context/useModalContext';
import { Section } from '@components/Section';
import { IAction } from '@components/Table/utils/actionsFormatter';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { skipToken } from '@reduxjs/toolkit/query';
import { ModalTypes } from '@components/Confirm/types';
import { useConfirmationModal } from '@components/Confirm/context/useConfirmContext';
import { useEditOrganizationMutation, useFetchOrganizationByIdQuery } from '@api/organizations/organizationsAPI';
import { FormContent } from '@pages/Organizations/ManageOrganizationModal';
import organizationsValidationSchema from '@helpers/validationSchemas/organizationsValidationSchema';
import { IModifyOrganization, IOrganization } from '@api/organizations/types';
import { format } from 'date-fns';
import { errorAlert, successAlert } from '@helpers/alerts/alerts';
import { Table } from '@components/Table';
import { Loader } from '@components/Loader';
import { tableSettings } from '@pages/Organization/components/constants/tableSettings';
import {
  useChangeMemberRoleMutation,
  useResendEmployeeInviteMutation,
  useSendEmployeeInviteMutation,
} from '@api/user/userAPI';
import { TEmployee } from '@api/user/types';
import { locationTableSettings } from '@pages/Organization/components/constants/locationTableSettings';
import { TLocations } from '@pages/Locations/LocationsTable/types';
import {
  useAddNewLocationMutation,
  useDeleteLocationMutation,
  useEditLocationMutation,
} from '@api/locations/locationsAPI';
import {
  addLocationInitialValues,
  editLocationInitialValues,
  FormContent as LocationForm,
} from '@pages/Locations/ManageLocationModal';
import locationsValidationSchema from '@helpers/validationSchemas/locationsValidationSchema';
import { useDeletePatientMutation } from '@api/patients/patientsAPI';
import {
  addMemberInitialValues,
  editMemberInitialValues,
  MemberFormContent,
} from '@pages/Organization/components/memberForm';
import { getUserRole } from '@pages/Organization/components/utils/getUserRole';
import { roleFormatter } from '@helpers/roleFormatter';
import membersValidationSchema from '@helpers/validationSchemas/membersValidationSchema';
import { Routes } from '../../router';

export const Organization = () => {
  const { dictionary } = useLanguageContext();

  const { id } = useParams();

  const { data: organization, refetch, isLoading } = useFetchOrganizationByIdQuery(id ?? skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const [editOrganization] = useEditOrganizationMutation();

  const [editLocation] = useEditLocationMutation();

  const [addLocation] = useAddNewLocationMutation();

  const [deleteLocation] = useDeleteLocationMutation();

  const [inviteEmployee] = useSendEmployeeInviteMutation();

  const organizationInitialValue = {
    name: organization?.data.name,
    owner: organization?.data.owner,
    phone: organization?.data.phone,
  };

  const organizationData = ({ owner, internalOrganizationId, createdAt, phone }: IOrganization) => [
    {
      label: dictionary.organizations.cards.owner,
      value: owner,
    },
    {
      label: dictionary.organizations.cards.internalOrganizationId,
      value: internalOrganizationId,
    },
    {
      label: dictionary.organizations.cards.createdAt,
      value: format(new Date(createdAt), 'MM/dd/yyyy'),
    },
    {
      label: dictionary.organizations.cards.phone,
      value: phone,
    },
  ];

  const { openModal } = useModal();

  const onSubmitForm = async (formValues: FormikValues, closeModal: () => void) => {
    editOrganization({
      organization: formValues as IModifyOrganization,
      id: String(organization?.data.id),
    })
      .unwrap()
      .then(async () => {
        await closeModal();
        refetch();
      })
      .catch(({ message }) => errorAlert(message));
  };

  const onInviteEmployee = async (formValues: FormikValues, closeModal: () => void) => {
    const selectedRole = roleFormatter(formValues.role);

    inviteEmployee({
      email: formValues.email,
      orgId: String(id),
      super_admin: selectedRole.superAdmin,
      admin: selectedRole.admin,
      employee: selectedRole.employee,
    })
      .unwrap()
      .then(async ({ success, message }) => {
        if (success === 0) {
          errorAlert(message);
        } else {
          await closeModal();
          refetch();
        }
      })
      .catch(({ message }) => errorAlert(message));
  };

  const onEditInformation = () => {
    openModal({
      children: <FormContent dictionary={dictionary} />,
      initialValues: organizationInitialValue,
      validationSchema: organizationsValidationSchema(dictionary),
      title: dictionary.organizations.modals.editOrganization.title,
      submitButtonTitle: dictionary.actions.save,
      onSubmit: onSubmitForm,
    });
  };

  const informationActions: IAction[] = [
    {
      type: 'edit',
      text: dictionary.table.edit,
      handleClick: onEditInformation,
    },
  ];

  const onInviteMember = () => {
    openModal({
      children: <MemberFormContent dictionary={dictionary} isEdit={false} />,
      initialValues: addMemberInitialValues,
      validationSchema: membersValidationSchema(dictionary),
      title: dictionary.members.inviteMember,
      submitButtonTitle: dictionary.actions.invite,
      onSubmit: onInviteEmployee,
    });
  };

  const [changePatient] = useChangeMemberRoleMutation();

  const onEditEmployee = async (formValues: FormikValues, closeModal: () => void, user: TEmployee) => {
    const selectedRole = roleFormatter(formValues.role);

    id && changePatient({
      userId: user.id,
      superAdmin: +selectedRole.superAdmin,
      admin: +selectedRole.admin,
      employee: +selectedRole.employee,
    })
      .unwrap()
      .then(async () => {
        await closeModal();
        refetch();
      })
      .catch(({ message }) => errorAlert('', message));
  };

  const onEditMember = (user: TEmployee) => {
    const userRole = getUserRole(user);

    openModal({
      children: <MemberFormContent dictionary={dictionary} isEdit />,
      initialValues: editMemberInitialValues(user.email, userRole),
      validationSchema: membersValidationSchema(dictionary),
      title: dictionary.members.editMember,
      submitButtonTitle: dictionary.actions.save,
      onSubmit: (values, closeModal) => onEditEmployee(values, closeModal, user),
    });
  };

  const membersActions: IAction[] = [
    {
      type: 'add',
      text: dictionary.members.inviteMember,
      handleClick: onInviteMember,
    },
  ];

  const handleAddLocation = async (formValues: FormikValues, closeModal: () => void) => {
    addLocation({
      orgId: id === organization.data.id ? String(id) : undefined,
      name: formValues.name,
      address: formValues.address,
      number: formValues.number,
    })
      .unwrap()
      .then(async ({ success,
        message }) => {
        if (success === 1) {
          await closeModal();
          refetch();
        } else {
          errorAlert(message);
        }
      })
      .catch(({ message }) => errorAlert(message));
  };

  const onAddLocation = () => {
    openModal({
      children: <LocationForm dictionary={dictionary} />,
      initialValues: addLocationInitialValues,
      validationSchema: locationsValidationSchema(dictionary),
      title: dictionary.locations.modals.addLocation.title,
      submitButtonTitle: dictionary.table.add,
      onSubmit: handleAddLocation,
    });
  };

  const locationActions: IAction[] = [
    {
      type: 'add',
      text: dictionary.organisationsLocation.addLocation,
      handleClick: onAddLocation,
    },
  ];

  const { openConfirmationModal } = useConfirmationModal();

  const [deletePatient] = useDeletePatientMutation();

  const [resendInvite] = useResendEmployeeInviteMutation();

  const onResendAppointment = (patient: TEmployee) => {
    resendInvite(patient.id).unwrap().then(({ success, message }) => {
      if (success === 1) {
        successAlert(message);
        refetch();
      } else {
        errorAlert(message);
      }
    }).catch(({ data }) => errorAlert(data.message));
  };

  const onCheckRegistrationStatus = ({ registrationStatus }: TEmployee): boolean => {
    const inviteExpired = 'Invite expired';

    const invitePending = 'Invite pending';

    return registrationStatus === inviteExpired || registrationStatus === invitePending;
  };

  const handleDelete = (userId: string) => {
    deletePatient({
      id: userId,
    });
    refetch();
  };

  const onDeleteUser = ({ name, surname, id: userId }: TEmployee) => {
    openConfirmationModal({
      variant: ModalTypes.decline,
      title: dictionary.members.deleteMember.title,
      text: `${dictionary.members.deleteMember.text} ${name === 'tmpName' ? 'user' : name} ${surname === 'tmpSurname' ? '' : surname} ${dictionary.members.deleteMember.text2}`,
      submitButtonTitle: dictionary.table.delete,
      handleConfirmClick: () => handleDelete(userId),
    });
  };

  const tableActions: IAction<TEmployee>[] = [
    {
      type: 'refresh',
      handleClick: (row) => onResendAppointment(row),
      showCondition: (row) => onCheckRegistrationStatus(row),
    },
    {
      type: 'edit',
      handleClick: onEditMember,
    },
    {
      type: 'delete',
      handleClick: onDeleteUser,
    },
  ];

  const onSubmitLocationForm = async (formValues?: FormikValues, closeModal?:()=> void) => {
    editLocation(formValues as TLocations)
      .unwrap()
      .then(async ({ success,
        message }) => {
        if (success === 1) {
          closeModal && closeModal();
          refetch();
        } else {
          errorAlert(message);
        }
      })
      .catch(({ message }) => errorAlert(message));
  };

  const onEditLocation = ({ name, number, address, id: locationId }: TLocations) => {
    openModal({
      children: <LocationForm dictionary={dictionary} />,
      initialValues: editLocationInitialValues(name, number, address, locationId),
      validationSchema: locationsValidationSchema(dictionary),
      title: dictionary.locations.modals.editLocation.title,
      submitButtonTitle: dictionary.actions.save,
      onSubmit: onSubmitLocationForm,
    });
  };

  const handleDeleteLocation = (locationId: string) => {
    deleteLocation(locationId);
    refetch();
  };

  const onDeleteLocation = ({ name: locationName, id: locationId }: TLocations) => {
    openConfirmationModal({
      variant: ModalTypes.decline,
      title: dictionary.locations.modals.deleteLocation.title,
      text: `${dictionary.locations.modals.deleteLocation.text} ${locationName}?`,
      submitButtonTitle: dictionary.table.delete,
      handleConfirmClick: () => handleDeleteLocation(String(locationId)),
    });
  };

  const locationTableActions: IAction<TLocations>[] = [
    {
      type: 'edit',
      handleClick: (row) => onEditLocation(row),
    },
    {
      type: 'delete',
      handleClick: (row) => onDeleteLocation(row),
    },
  ];

  return (
    <>
      <Breadcrumbs prevPageRoute={Routes.Organizations} prevPageTitle={dictionary.organizations.title} currentPageTitle={String(organization?.data.name)} />
      <Section title={String(organization?.data.name)}>
        {organizationData && (
        <Card
          title={dictionary.trials.addTrial.generalInformation}
          data={organization?.data && organizationData({
            ...organization.data,
          })}
          actions={informationActions}
          row={{
            ...organization,
          }}
        />
        )}
        {isLoading ? <Loader /> : (
          <Card
            title={dictionary.members.title}
            actions={membersActions}
            isDetailsCollapsible={false}
            details={!!organization?.data && (
            <Table
              actions={tableActions}
              tableData={organization?.data && organization.data.users}
              tableSettings={tableSettings}
              dictionaryKey="members"
            />
            )}
          />
        )}
        {isLoading ? <Loader /> : (
          <Card
            title={dictionary.organisationsLocation.title}
            actions={locationActions}
            isDetailsCollapsible={false}
            details={!!organization?.data && (
            <Table
              actions={locationTableActions}
              tableData={organization?.data && organization.data.locations}
              tableSettings={locationTableSettings}
              dictionaryKey="organisationsLocation"
            />
            )}
          />
        )}
      </Section>
    </>
  );
};
