import React, { FC } from 'react';
import { InputField } from '@components/Form/InputField';
import { SelectField } from '@components/Form/SelectField';
import { IOptions } from '@components/Select/types';
import { IDictionary } from '@constants/languages/types';

interface ITrialFormProps {
  physiciansOptions?: IOptions[],
  dictionary: IDictionary
}

export const TrialForm:FC<ITrialFormProps> = ({ physiciansOptions, dictionary }) => (
  <section className="form-section">
    <div className="row">
      <div className="col-xs-12">
        <InputField
          type="text"
          placeholder={dictionary.placeholders.enterProtocolName}
          label={dictionary.labels.protocolName}
          required
          name="protocolName"
        />
      </div>
    </div>
    <div className="row">
      <div className="col-xs-12">
        <InputField
          type="text"
          placeholder={dictionary.placeholders.enterProtocolNumber}
          label={dictionary.labels.protocolNumber}
          required
          name="protocolNumber"
        />
      </div>
    </div>
    <div className="row">
      <div className="col-xs-12">
        <InputField
          type="text"
          placeholder={dictionary.placeholders.enterSponsorName}
          label={dictionary.labels.sponsorName}
          required
          name="sponsorName"
        />
      </div>
    </div>
    <div className="row">
      <div className="col-xs-12">
        <SelectField
          selectOptions={physiciansOptions}
          placeholder={dictionary.placeholders.enterPhysicianNamePi}
          label={dictionary.labels.physicianName}
          required
          name="physicianId"
        />
      </div>
    </div>
  </section>
);
