import React from 'react';
import { Container } from '@components/Container';
import { Section } from '@components/Section';
import { AddPhysicianForm } from '@pages/Physician/pages/AddPhysician/components/AddPhysicianForm';

export const AddPhysician = () => (
  <div className="main-content">
    <Container size="lg">
      <div className="panel">
        <Section containerSize="md">
          <AddPhysicianForm />
        </Section>
      </div>
    </Container>
  </div>
);
