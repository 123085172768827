import * as Yup from 'yup';
import { passwordExpression } from '@constants/regularExpression';
import { IDictionary } from '@constants/languages/types';

const resetPasswordValidationSchema = (dictionary: IDictionary) => Yup.object({
  newPassword: Yup.string()
    .required(dictionary.validation.password)
    .min(8, dictionary.validation.passwordMin)
    .matches(passwordExpression, dictionary.validation.passwordMin),
  repeatedNewPassword: Yup.string()
    .required(dictionary.validation.passwordAgain)
    .oneOf([Yup.ref('newPassword')], dictionary.validation.matchPassword),
});

export default resetPasswordValidationSchema;
