import * as Yup from 'yup';
import { IDictionary } from '@constants/languages/types';

const forgotPasswordValidationSchema = (dictionary: IDictionary) => Yup.object({
  email: Yup.string()
    .email(dictionary.validation.invalidEmail)
    .required(dictionary.validation.emptyEmail),
});

export default forgotPasswordValidationSchema;
