import * as Yup from 'yup';
import { IDictionary } from '@constants/languages/types';

const notificationValidationSchema = (dictionary: IDictionary) => Yup.object({
  id: Yup.string(),
  notificationsSettings: Yup.object()
    .shape({
      types: Yup.object().shape({
        email: Yup.string().nullable()
          .when('sms', {
            is: (sms: string) => !sms || String(sms) === 'false',
            then: Yup.string()
              .required(dictionary.validation.requireField),
          }),
        sms: Yup.string().nullable()
          .when('email', {
            is: (email: string) => !email || String(email) === 'false',
            then: Yup.string()
              .required(dictionary.validation.requireField),
          }),
      }, ['sms', 'email'] as any),
      first: Yup.number()
        .required(dictionary.validation.chooseNotification),
      second: Yup.number()
        .nullable()
        .lessThan(Yup.ref('first'), dictionary.validation.laterThanFirst),
    }),
});

export default notificationValidationSchema;
