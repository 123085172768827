import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { useUserVerifyingQuery } from '@api/user/userAPI';
import { errorAlert, successAlert } from '@helpers/alerts/alerts';
import { Loader } from '@components/Loader';
import { SuccessScreen } from '@components/SuccessScreen';
import { Routes } from '../../router';

export const ActivateAccount = () => {
  const navigate = useNavigate();

  const [userEmail, setUserEmail] = useState<string>('');

  const [userToken, setUserToken] = useState<string>('');

  const [isAwait, setIsAwait] = useState<boolean>(true);

  const { dictionary } = useLanguageContext();

  const { data: userVerify, isLoading } = useUserVerifyingQuery(
    {
      email: userEmail,
      token: userToken,
    },
    {
      skip: isAwait,
    },
  );

  useEffect(() => {
    const findData = window.location.href;

    const userAccessCredits = findData.split('tk=');

    const userData = userAccessCredits[1].split('&email=');

    setUserToken(userData[0]);
    setUserEmail(userData[1]);
    setIsAwait(false);

    if (userVerify && userVerify?.success === 1) {
      navigate(Routes.SignIn);
      successAlert(dictionary.messages.successVerified);
    } else {
      userVerify && errorAlert(userVerify.message);
    }
  }, [dictionary.messages.successVerified, navigate, userEmail, userToken, userVerify]);

  return (
    isLoading ? <Loader /> : (
      <SuccessScreen
        title={dictionary.email.confirmed}
        containerSize="sm"
        linkText={dictionary.email.sign}
        linkUrl={Routes.SignIn}
      >
        <p className="text-md">
          {dictionary.email.title}
        </p>
      </SuccessScreen>
    )
  );
};
