export const checkIsWeekday = (date: Date, unavailableDays?: string[]) => {
  const day: string = date.toLocaleDateString('en-us', {
    weekday: 'long',
  }).toLowerCase();

  if (unavailableDays) {
    return !unavailableDays.includes(day);
  }
  return true;
};
