import React from 'react';
import { UilBars, UilMultiply } from '@iconscout/react-unicons';
import { useMenu } from '../Menu/context/useMenuContext';
import './styles.scss';

export const Burger = () => {
  const { isMenuOpen, toggleMenu } = useMenu();

  return (
    <span
      className="menu-toggler"
      role="button"
      tabIndex={0}
      aria-expanded={isMenuOpen}
      onClick={toggleMenu}
    >
      {isMenuOpen ? <UilMultiply /> : <UilBars />}
    </span>
  );
};
