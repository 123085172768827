import React, { useEffect, useState } from 'react';
import { SelectField } from '@components/Form/SelectField';
import { TextareaField } from '@components/Form/TextareaField';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { ReasonsOptions } from '@constants/options/reasonsOptions';

export const DeclineAppointment = () => {
  const { dictionary } = useLanguageContext();

  const [selectedValue, setSelectedValue] = useState('');

  const getSelectedValue = (value: string) => {
    setSelectedValue(value);
  };

  useEffect(() => {
    setSelectedValue((value) => value);
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-xs-12">
          <SelectField
            chooseNested
            selectOptions={ReasonsOptions()}
            placeholder={dictionary.placeholders.chooseReason}
            label={dictionary.labels.reason}
            required
            name="reason"
            getSelectedValue={getSelectedValue}

          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <TextareaField
            placeholder={dictionary.placeholders.addComment}
            label={dictionary.appointments.modals.declineAppointment.reason.other !== selectedValue ? dictionary.labels.comment : dictionary.labels.commentRequire}
            required
            name="comment"
          />
        </div>
      </div>
    </>
  );
};
