import * as Yup from 'yup';
import { IDictionary } from '@constants/languages/types';

const appointmentsValidationSchema = (dictionary: IDictionary) => Yup.object({
  trialId: Yup.string()
    .required(dictionary.validation.trial),
  patientId: Yup.string()
    .required(dictionary.validation.patient),
  contactPhone: Yup.string()
    .required(dictionary.validation.contactPhone)
    .min(11, dictionary.validation.invalidPhone),
  locationId: Yup.string()
    .required(dictionary.validation.location),
  physicianId: Yup.string()
    .required(dictionary.validation.physician),
  adult: Yup.string(),
  appointmentSettings: Yup.array()
    .of(Yup.object()
      .shape({
        name: Yup.string()
          .max(50, dictionary.validation.appointmentMax)
          .required(dictionary.validation.appointmentName),
        type: Yup.string()
          .required(dictionary.validation.type),
        duration: Yup.number()
          .required(dictionary.validation.duration),
        date: Yup.string()
          .required(dictionary.validation.appointmentDate),
        time: Yup.string()
          .required(dictionary.validation.appointmentTime),
        from: Yup.string(),
        to: Yup.string(),
        notificationsSettings: Yup.object()
          .shape({
            types: Yup.object().shape({
              email: Yup.string().nullable()
                .when('sms', {
                  is: (sms: string) => !sms || sms.length === 0,
                  then: Yup.string()
                    .required(dictionary.validation.requireField),
                }),
              sms: Yup.string().nullable()
                .when('email', {
                  is: (email: string) => !email || email.length === 0,
                  then: Yup.string()
                    .required(dictionary.validation.requireField),
                }),
            }, ['sms', 'email'] as any),
            first: Yup.number()
              .required(dictionary.validation.notification),
            second: Yup.number()
              .nullable()
              .lessThan(Yup.ref('first'), dictionary.validation.laterNotification),
          }),
      })),
});

export default appointmentsValidationSchema;
