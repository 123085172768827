import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { IBreadcrumbsProps } from '@components/Breadcrumbs/types';
import { Container } from '@components/Container';
import './styles.scss';

export const Breadcrumbs:FC<IBreadcrumbsProps> = ({ currentPageTitle, prevPageTitle, prevPageRoute }) => (
  <Container>
    <ul className="breadcrumbs">
      <li className="breadcrumbs-item">
        <Link to={prevPageRoute} className="breadcrumbs-link">{prevPageTitle}</Link>
      </li>
      <li className="breadcrumbs-item">
        {currentPageTitle}
      </li>
    </ul>
  </Container>
);
