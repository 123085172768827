import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@components/Button';
import { SearchForm } from '@components/SearchForm';
import { Section } from '@components/Section';
import { useLanguageContext } from '@hooks/useLanguageContext';
import { UilPlus } from '@iconscout/react-unicons';
import { PhysiciansTable } from '@pages/Physicians/components/PhysiciansTable';
import { useFetchAllPhysiciansWithPaginationQuery } from '@api/physicians/physiciansAPI';
import { Loader } from '@components/Loader';
import { Routes } from '../../router';

export const Physicians = () => {
  const [searchTitle, setSearchTitle] = useState<string>('');

  const { dictionary } = useLanguageContext();

  const startPage = 1;

  const [limit, setLimit] = useState<number>(20);

  const { data: physicians, refetch, isLoading } = useFetchAllPhysiciansWithPaginationQuery({
    page: startPage, limit,
  });

  const next = () => {
    if (physicians?.data.currentPage !== physicians?.data.totalPages) {
      setLimit(limit + 20);
    }
  };

  const navigate = useNavigate();

  return (
    <Section
      title={dictionary.physicians.title}
      controls={(
        <>
          <SearchForm setSearchTitle={setSearchTitle} />
          <div className="section-control">
            <Button
              label={dictionary.physicians.modals.addPhysician.title}
              iconPrepend={<UilPlus />}
              handleClick={() => navigate(`${Routes.Physician}${Routes.Add}`)}
              size="static"
            />
          </div>
        </>
      )}
    >
      {isLoading ? <Loader /> : <PhysiciansTable next={next} refreshFunction={refetch} dataLength={physicians?.data.total} data={physicians?.data.results} searchTitle={searchTitle} />}
    </Section>
  );
};
