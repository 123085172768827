import en from './en.json';
import es from './es.json';

export interface IDictionary {
  en: JSON,
  es: JSON,
}

export const dictionaryList = {
  en: JSON.parse(JSON.stringify(en)),
  es: JSON.parse(JSON.stringify(es)),
} as IDictionary;

export const languageOptions = {
  en: 'English',
  es: 'Espanol',
};

export const englishLanguage = Object.keys(languageOptions)[0];
